<template>
  <v-dialog
    v-model="toogleShowModal"
    max-width="600px"
    persistent
  >
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ShowUser',
  data () {
    return {
      formValid: false
    }
  },
  computed: {
    ...mapState('client', ['saved', 'editClient']),
    ...mapState('statics', ['arrayCountry']),
    getAvatar () {
      return `${this.editClient.avatar ||
          '/assets/avatar/avatar-undefined.jpg'}`
    },
    bindProps () {
      return {
        mode: 'national',
        clearable: true,
        defaultCountry: this.editClient.country
          ? this.editClient.country
          : 'US',
        disabledFetchingCountry: false,
        autocomplete: 'off',
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    }
  },
  methods: {
    ...mapActions('client', ['toogleShowModal'])
  }
}
</script>

<style scoped></style>
