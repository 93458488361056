import api from '../config/api'

export default {
  fetchSupplys () {
    return api.get('supply')
  },
  fetchSuppliesReceived () {
    return api.post('supply/received')
  },
  sendCreateRequest (supply) {
    return api.post('supply', supply)
  },
  sendUpdateRequest (supply) {
    return api.put('supply/' + supply.id, supply)
  },
  sendDeleteRequest (supplyId) {
    return api.remove('supply/' + supplyId)
  },
  fetchSupplyByCategory (filter) {
    return api.post('supply/category', filter)
  },
  fetchSupplyByPayment (filter) {
    return api.post('supply/payment', filter)
  },
  fetchSupplyByProduct (filter) {
    return api.post('supply/product', filter)
  },
  fetchSupplyByEmployer (filter) {
    return api.post('supply/employer', filter)
  },
  fetchSupplyByLimit (filter) {
    return api.get('supply/by_limit/' + filter)
  },
  fetchSupplyStatics () {
    return api.get('supply/supplys/static')
  },
  fetchManagerSupplyData () {
    return api.post('supply/data-supply')
  },
  fetchSupplyNumber (idProvider) {
    return api.post('sale/number/facture', idProvider)
  },
  sendCreateBuyOrderRequest (supply) {
    return api.post('buy-order', supply)
  }
}
