<template>
  <v-container>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
      >
        <new-user v-if="showNewModal" />
        <edit-user v-if="showEditModal" />
        <app-data-table
          :headers="getTableColumns"
          :is-loading="isTableLoading"
          :items="users"
          :manager="'employer'"
          :sort-by="['firstName']"
          :sort-desc="[false, true]"
          :title="
            $vuetify.lang.t('$vuetify.titles.list', [
              $vuetify.lang.t('$vuetify.menu.user')
            ])
          "
          csv-filename="Employees"
          multi-sort
          @create-row="handleNewUser"
          @edit-row="editUserHandler($event)"
          @delete-row="deleteUserHandler($event)"
        >
          <template v-slot:[`item.first_name`]="{ item }">
            <v-avatar>
              <v-img
                :src=" item.avatar ? item.avatar.original_url : `/assets/avatar/avatar-undefined.jpg`
                "
              />
            </v-avatar>
            {{ item.first_name }}
          </template>
          <template v-slot:[`item.shops`]="{ item }">
            <v-chip
              v-for="(shop, i) of item.shops"
              :key="i"
            >
              {{ shop.name }}
            </v-chip>
          </template>
        </app-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewUser from './NewUser'
import EditUser from './EditUser'

export default {
  components: {
    NewUser,
    EditUser
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState('user', [
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'users',
      'isTableLoading'
    ]),
    ...mapGetters('plan', ['mySubscription']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'first_name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.lastName'),
          value: 'last_name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.email'),
          value: 'email'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.position'),
          value: 'position.name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shops',
          select_filter_many: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.getUsers()
  },
  methods: {
    ...mapActions('user', [
      'toogleNewModal',
      'openEditModal',
      'openShowModal',
      'getUsers',
      'deleteUser'
    ]),
    handleNewUser () {
      if (this.users.length >= 3 && this.mySubscription.type === 'basic') {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.titles.new', [
              this.$vuetify.lang.t('$vuetify.menu.user')
            ]),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.warning_basic_users'
            ),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'primary'
          })
      } else {
        this.toogleNewModal(true)
      }
    },
    editUserHandler ($event) {
      this.openEditModal($event)
    },
    deleteUserHandler (userId) {
      this.users.filter(c => c.id === userId)[0].position.name === 'CEO'
        ? this.$Swal
          .fire({
            title: this.$vuetify.lang.t(
              '$vuetify.titles.no_action',
              [this.$vuetify.lang.t('$vuetify.actions.delete')]
            ),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.error_delete_manager'
            ),
            icon: 'info',
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
          })
        : this.$Swal
          .fire({
            title: this.$vuetify.lang.t(
              '$vuetify.titles.delete',
              [this.$vuetify.lang.t('$vuetify.menu.user')]
            ),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.warning_delete'
            ),
            showCancelButton: true,
            cancelButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.cancel'
            ),
            icon: 'warning',
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.delete'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.value) this.deleteUser(userId)
          })
    }
  }
}
</script>

<style scoped></style>
