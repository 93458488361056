import { render, staticRenderFns } from "./NewModifiers.vue?vue&type=template&id=e934cea6&scoped=true&"
import script from "./NewModifiers.vue?vue&type=script&lang=js&"
export * from "./NewModifiers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e934cea6",
  null
  
)

export default component.exports