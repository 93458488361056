import apiArticle from '../../api/article'
import util from '../../util'

const FETCHING_ARTICLES = 'FETCHING_ARTICLES'
const FETCHING_UNDER_INVENTORY = 'FETCHING_UNDER_INVENTORY'
const FETCHING_EXPIRE_ARTICLES = 'FETCHING_EXPIRE_ARTICLES'
const FETCHING_SLOW_MOVE_ARTICLES = 'FETCHING_SLOW_MOVE_ARTICLES'
const FETCHING_SERVICES = 'FETCHING_SERVICES'
const FETCHING_ARTICLES_BY_SUPPLIER = 'FETCHING_ARTICLES_BY_SUPPLIER'
const FETCHING_ARTICLES_MERGE = 'FETCHING_ARTICLES_MERGE'
const FETCHING_ARTICLE_NUMBER = 'FETCHING_ARTICLE_NUMBER'
const SWITCH_ARTICLE_NEW_MODAL = 'SWITCH_ARTICLE_NEW_MODAL'
const SWITCH_ARTICLE_EDIT_MODAL = 'SWITCH_ARTICLE_EDIT_MODAL'
const SWITCH_ARTICLE_SHOW_MODAL = 'SWITCH_ARTICLE_SHOW_MODAL'
const SWITCH_ARTICLE_REFOUND_MODAL = 'SWITCH_ARTICLE_REFOUND_MODAL'
const SWITCH_ARTICLE_IMPORT_MODAL = 'SWITCH_ARTICLE_IMPORT_MODAL'
const ARTICLE_CREATED = 'ARTICLE_CREATED'
const ARTICLE_EDIT = 'ARTICLE_EDIT'
const ARTICLE_REFOUND = 'ARTICLE_REFOUND'
const ARTICLE_UPDATED = 'ARTICLE_UPDATED'
const UPDATE_EDIT_ARTICLE = 'UPDATE_EDIT_ARTICLE'
const ARTICLE_DELETE = 'ARTICLE_DELETE'
const ARTICLE_TABLE_LOADING = 'ARTICLE_TABLE_LOADING'
const FAILED_ARTICLE = 'FAILED_ARTICLE'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_ARTICLE = 'SET_EDIT_ARTICLE'
const SET_ARTICLE_AVATAR = 'SET_ARTICLE_AVATAR'
const SWITCH_TRANSFER_MODAL = 'SWITCH_TRANSFER_MODAL'
const UPDATE_TOTALS = 'UPDATE_TOTALS'

const state = {
  showNewModal: false,
  showTransfer: false,
  showEditModal: false,
  total_articles: null,
  total_services: null,
  total_articles_no_categories: null,
  showShowModal: false,
  showRefoundModal: false,
  articles: [],
  underInventory: [],
  expireArticles: [],
  slowMoveArticles: [],
  services: [],
  articlesBySupplier: [],
  articlesMerge: [],
  avatar: '',
  loading: false,
  saved: false,
  managerArticle: false,
  newArticle: {
    name: '',
    description: '',
    um: '',
    expire_date: null,
    price: 0.00,
    online_price: 0.00,
    online_sale: true,
    unit: 'unit',
    cost: 0.00,
    ref: '10001',
    barCode: '',
    variants: [],
    taxes: [],
    marks: [],
    variant_values: [],
    composite: false,
    service: false,
    track_inventory: true,
    category: null,
    color: '#1FBC9C',
    article_shops: [],
    composites: [],
    images: [],
    cost_id: null,
    price_id: null,
    online_price_id: null,
    remove_image: []
  },
  editArticle: {
    id: null,
    name: '',
    description: '',
    um: '',
    expire_date: null,
    price: 0.00,
    online_price: 0.00,
    online_sale: true,
    unit: 'unit',
    cost: 0.00,
    ref: '10001',
    barCode: '',
    variants: [],
    taxes: [],
    marks: [],
    variant_values: [],
    composite: false,
    service: false,
    track_inventory: true,
    category: null,
    color: '#1FBC9C',
    article_shops: [],
    composites: [],
    images: [],
    cost_id: null,
    price_id: null,
    online_price_id: null,
    remove_image: []
  },
  refound: {
    sale: {},
    article: {},
    cant: 0,
    money: 0
  },
  importArticle: {
    type: 'loyverse',
    file: '',
    online: true
  },
  showImportModal: false,
  isArticleTableLoading: false,
  isActionInProgress: false,
  isTableLoading: false,
  articleNumber: ''
}

const mutations = {
  [SWITCH_ARTICLE_REFOUND_MODAL] (state, showModal) {
    state.showRefoundModal = showModal
  },
  [SWITCH_TRANSFER_MODAL] (state, showModal) {
    state.showTransfer = showModal
  },
  [SWITCH_ARTICLE_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_ARTICLE_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_ARTICLE_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [SWITCH_ARTICLE_IMPORT_MODAL] (state, showModal) {
    state.showImportModal = showModal
  },
  [ARTICLE_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
  },
  [FETCHING_ARTICLES] (state, articles) {
    state.articles = articles
    /* articles.forEach((value) => {
      if (!value.parent_id) {
        if (value.variants.length > 0) {
          value.variants.forEach((k) => {
            k.value = JSON.parse(k.value)
          })
        }
        state.articles.push(value)
      }
    }) */
  },
  [FETCHING_UNDER_INVENTORY] (state, articles) {
    state.underInventory = articles
  },
  [FETCHING_EXPIRE_ARTICLES] (state, articles) {
    state.expireArticles = articles
  },
  [FETCHING_SLOW_MOVE_ARTICLES] (state, articles) {
    state.slowMoveArticles = []
    articles.forEach((value) => {
      if (!value.article.parent_id) {
        if (value.article.variants.length > 0) {
          value.article.variants.forEach((k) => {
            k.value = JSON.parse(k.value)
          })
        }
        state.slowMoveArticles.push(value.article)
      }
    })
  },
  [FETCHING_SERVICES] (state, articles) {
    state.services = []
    articles.forEach((value) => {
      if (!value.parent_id) {
        if (value.variants.length > 0) {
          value.variants.forEach((k) => {
            k.value = JSON.parse(k.value)
          })
        }
        state.services.push(value)
      }
    })
  },
  [FETCHING_ARTICLES_BY_SUPPLIER] (state, articles) {
    state.articlesBySupplier = []
    articles.forEach((value) => {
      if (!value.parent_id) {
        if (value.variants.length > 0) {
          value.variants.forEach((k) => {
            k.value = JSON.parse(k.value)
          })
        }
        state.articlesBySupplier.push(value)
      }
    })
  },
  [FETCHING_ARTICLE_NUMBER] (state, number) {
    state.articleNumber = number
  },
  [FETCHING_ARTICLES_MERGE] (state, articles) {
    const articlesMerge = articles.sort(util.compareValues('percent', 'desc')).slice(0, 5)
    state.articlesMerge = articlesMerge.map((value) => {
      return {
        avatar: value.path ? value.path : null,
        name: value.name,
        price: value.price,
        cost: value.cost,
        progress: value.percent
      }
    })
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [ARTICLE_CREATED] (state) {
    state.showNewModal = false
    state.showTransfer = false
    state.newArticle = {
      name: '',
      description: '',
      um: '',
      expire_date: null,
      price: 0.00,
      online_price: 0.00,
      online_sale: true,
      unit: 'unit',
      cost: 0.00,
      ref: '10001',
      barCode: '',
      variants: [],
      taxes: [],
      marks: [],
      variant_values: [],
      composite: false,
      service: false,
      track_inventory: true,
      category: null,
      color: '#1FBC9C',
      article_shops: [],
      composites: [],
      images: [],
      cost_id: null,
      price_id: null,
      online_price_id: null,
      remove_image: []
    }
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t(
        '$vuetify.messages.success_add', [this._vm.$language.t('$vuetify.articles.name')]
      )
    })
  },
  [ARTICLE_EDIT] (state, articleId) {
    state.editArticle = Object.assign({}, state.articles
      .filter(node => node.id === articleId)
      .shift()
    )
    state.editArticle.remove_image = []
  },
  [ARTICLE_REFOUND] (state, {
    sale,
    article
  }) {
    state.refound.sale = sale
    state.refound.article = article
  },
  [ARTICLE_UPDATED] (state) {
    state.showEditModal = false
    state.showTransfer = false
    state.editArticle = {
      id: '',
      name: '',
      description: '',
      um: '',
      expire_date: null,
      price: 0.00,
      online_price: 0.00,
      online_sale: true,
      unit: 'unit',
      cost: 0.00,
      ref: '10001',
      barCode: '',
      variants: [],
      taxes: [],
      marks: [],
      variant_values: [],
      composite: false,
      service: false,
      track_inventory: true,
      category: null,
      color: '#1FBC9C',
      article_shops: [],
      composites: [],
      images: [],
      cost_id: null,
      price_id: null,
      online_price_id: null,
      remove_image: []
    }
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t(
        '$vuetify.messages.success_up', [this._vm.$language.t('$vuetify.articles.name')]
      )
    })
  },
  [UPDATE_EDIT_ARTICLE] (state, editArticle) {
    state.editArticle = editArticle
    state.editArticle.remove_image = []
  },
  [SET_EDIT_ARTICLE] (state, profile) {
    state.editArticle.push(profile)
  },
  [ARTICLE_DELETE] (state) {
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t(
        '$vuetify.messages.success_del', [this._vm.$language.t('$vuetify.articles.name')]
      )
    })
  },
  [SET_ARTICLE_AVATAR] (state, avatar) {
    state.avatar = avatar
    state.saved = true
  },
  [UPDATE_TOTALS] (state, totals) {
    state.total_articles = totals.total_articles
    state.total_services = totals.total_services
    state.total_art_no_categories = totals.total_art_no_cat
  },
  [FAILED_ARTICLE] (state, error) {
    state.isActionInProgress = false
    state.isArticleTableLoading = false
    state.isTableLoading = false
    state.saved = false
    state.error = error
    this._vm.$Toast.fire({
      icon: 'error',
      title: this._vm.$language.t(
        '$vuetify.messages.failed_catch', [this._vm.$language.t('$vuetify.articles.name')]
      )
    })
  }
}

const getters = {
  getNumberArticle: (state) => {
    return state.articleNumber
  },
  totalArticles: (state) => {
    return state.total_articles
  },
  totalServices: (state) => {
    return state.total_services
  },
  totalArticlesNoCategories: (state) => {
    return state.total_articles_no_categories
  }
}

const actions = {
  updateDataTotalArticles ({ commit }, totals) {
    commit(UPDATE_TOTALS, totals)
  },
  toogleRefoundModal ({ commit }, showModal) {
    commit(SWITCH_ARTICLE_REFOUND_MODAL, showModal)
  },
  toogleTransferModal ({ commit }, showModal) {
    commit(SWITCH_TRANSFER_MODAL, showModal)
  },
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_ARTICLE_NEW_MODAL, showModal)
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_ARTICLE_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_ARTICLE_SHOW_MODAL, showModal)
  },
  toogleImportModal ({ commit }, showModal) {
    commit(SWITCH_ARTICLE_IMPORT_MODAL, showModal)
  },
  openTransferModal ({ commit }, articleId) {
    commit(ARTICLE_EDIT, articleId)
  },
  openEditModal ({ commit }, articleId) {
    commit(ARTICLE_EDIT, articleId)
  },
  openShowModal ({ commit }, articleId) {
    commit(SWITCH_ARTICLE_SHOW_MODAL, true)
    commit(ARTICLE_EDIT, articleId)
  },
  openRefoundModal ({ commit }, {
    sale,
    article
  }) {
    commit(ARTICLE_REFOUND, {
      sale,
      article
    })
    commit(SWITCH_ARTICLE_REFOUND_MODAL, true)
  },
  async getArticles ({ commit }) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .fetchAllArticles()
      .then(({ data }) => {
        commit(FETCHING_ARTICLES, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
      }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async getUnderInventory ({ commit }) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .getUnderInventory()
      .then(({ data }) => {
        commit(FETCHING_UNDER_INVENTORY, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
      }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async getExpireArticles ({ commit, dispatch }) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .fetchExpireArticles()
      .then(({ data }) => {
        commit(FETCHING_EXPIRE_ARTICLES, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
      }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async getSlowMoveArticles ({ commit, dispatch }, filter) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .fetchSlowMoveArticles(filter)
      .then(({ data }) => {
        commit(FETCHING_SLOW_MOVE_ARTICLES, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
      })
      .catch((error) => commit(FAILED_ARTICLE, error)
      )
  },
  async getServices ({ commit, dispatch }) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .fetchServices()
      .then(({ data }) => {
        commit(FETCHING_SERVICES, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
      }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async getArticlesBySupply ({
    commit,
    dispatch
  }, email) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .getArticlesBySupply(email)
      .then(({ data }) => {
        commit(FETCHING_ARTICLES_BY_SUPPLIER, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
      }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async getArticlesByCategory ({
    commit,
    dispatch
  }, data) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .fetchArticlesByCategory(data)
      .then(({ data }) => {
        commit(FETCHING_ARTICLES, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
      }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async updateCategory ({ commit, dispatch }, data) {
    commit(ENV_DATA_PROCESS, true)
    await apiArticle
      .sendUpdateCategory(data)
      .then(() => {
        commit(ENV_DATA_PROCESS, false)
      })
      .catch((error) => commit(FAILED_ARTICLE, error))
  },
  async changeShopInventory ({ commit, dispatch }, data) {
    commit(ENV_DATA_PROCESS, true)
    await apiArticle
      .sendShopInventory(data)
      .then(() => {
        commit(ENV_DATA_PROCESS, false)
      })
      .catch((error) => commit(FAILED_ARTICLE, error))
  },
  async importArticles ({
    commit,
    dispatch
  }, articlesData) {
    commit(ENV_DATA_PROCESS, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .importArticles(articlesData)
      .then(({ data }) => {
        commit(ENV_DATA_PROCESS, false)
        dispatch('article/toogleImportModal', false, { root: true })
        dispatch('article/getArticles', null, { root: true })
        this.dispatch('auth/updateAccess', data)
      }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async getArticlesMerge ({
    commit,
    dispatch
  }) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .fetchArticles()
      .then(({ data }) => {
        commit(FETCHING_ARTICLES_MERGE, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
      }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async refoundArticle ({
    commit,
    dispatch
  }, refound) {
    commit(ENV_DATA_PROCESS, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
      .refoundArticle(refound)
      .then(({ data }) => {
        commit(SWITCH_ARTICLE_REFOUND_MODAL, false)
        this.dispatch('auth/updateAccess', data)
      }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async createArticle ({
    commit,
    dispatch
  }, newArticle) {
    commit(ENV_DATA_PROCESS, true)

    await apiArticle
      .sendCreateRequest(newArticle)
      .then((data) => {
        commit(ARTICLE_CREATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('article/getArticles', null, { root: true })
        this.dispatch('auth/updateAccess', data)
      })
      .catch((error) => commit(FAILED_ARTICLE, error))
  },
  async getEditArticle ({ commit, dispatch }, data) {
    commit(ENV_DATA_PROCESS, true)
    await apiArticle.getEditArticle(data).then(({ data }) => {
      commit(UPDATE_EDIT_ARTICLE, data.data)
      commit(ENV_DATA_PROCESS, false)
    }).catch((error) => {
      commit(ENV_DATA_PROCESS, false)
      commit(FAILED_ARTICLE, error)
    })
  },
  async updateArticle ({
    commit,
    dispatch
  }, articleE) {
    commit(ENV_DATA_PROCESS, true)
    const request = articleE || state.editArticle

    // const request = profile || state.editUser
    await apiArticle
      .sendUpdateRequest(request)
      .then((response) => {
        commit(ARTICLE_UPDATED)
        commit(ENV_DATA_PROCESS, false)
      })
      .catch((error) => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_ARTICLE, error)
      })
  },
  async deleteArticle ({
    commit,
    dispatch
  }, articleId) {
    await apiArticle
      .sendDeleteRequest(articleId)
      .then((response) => {
        commit(ARTICLE_DELETE)
        this.dispatch('article/getArticles')
      })
      .catch((error) => commit(FAILED_ARTICLE, error))
  },
  async multiDelete ({
    commit,
    dispatch
  }, selected) {
    await apiArticle
      .sendMultiDelete(selected)
      .then((response) => {
        commit(ARTICLE_DELETE)
        this.dispatch('article/getArticles')
      })
      .catch((error) => commit(FAILED_ARTICLE, error))
  },
  async fetchArticleNumber ({
    commit,
    dispatch
  }) {
    await apiArticle
      .fetchArticleNumber()
      .then(({ data }) => {
        commit(FETCHING_ARTICLE_NUMBER, data.data)
        dispatch('auth/updateAccess', data.access, { root: true })
      }).catch((error) => commit(FAILED_ARTICLE, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
