import { render, staticRenderFns } from "./Variants.vue?vue&type=template&id=56d06e34&scoped=true&"
import script from "./Variants.vue?vue&type=script&lang=js&"
export * from "./Variants.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d06e34",
  null
  
)

export default component.exports