var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"450","persistent":""},model:{value:(_vm.toogleNewModal),callback:function ($$v) {_vm.toogleNewModal=$$v},expression:"toogleNewModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.titles.new", [ _vm.$vuetify.lang.t("$vuetify.menu.modifiers") ])))])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"my-10",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.name'),"rules":_vm.formRule.firstName,"required":""},model:{value:(_vm.newModifier.name),callback:function ($$v) {_vm.$set(_vm.newModifier, "name", $$v)},expression:"newModifier.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.shops,"label":_vm.$vuetify.lang.t('$vuetify.menu.shop'),"item-text":"name","loading":_vm.isShopLoading,"disabled":!!_vm.isShopLoading,"multiple":"","rules":_vm.formRule.required,"required":"","return-object":""},on:{"change":function($event){return _vm.setModifiers($event)}},model:{value:(_vm.newModifier.shops),callback:function ($$v) {_vm.$set(_vm.newModifier, "shops", $$v)},expression:"newModifier.shops"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-switch',{attrs:{"inset":"","label":`${
                  _vm.newModifier.percent
                    ? _vm.$vuetify.lang.t(
                      '$vuetify.tax.percent'
                    )
                    : _vm.$vuetify.lang.t(
                      '$vuetify.tax.permanent'
                    )
                }`},model:{value:(_vm.newModifier.percent),callback:function ($$v) {_vm.$set(_vm.newModifier, "percent", $$v)},expression:"newModifier.percent"}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.tax.value'),"rules":_vm.formRule.required,"required":"","properties":{
                clearable: true
              },"options":{
                length: 15,
                precision: 2,
                empty: 0.0
              }},model:{value:(_vm.newModifier.cant),callback:function ($$v) {_vm.$set(_vm.newModifier, "cant", $$v)},expression:"newModifier.cant"}})],1),( _vm.newModifier.cant && _vm.newModifier.cant !== 0.0 && _vm.newModifier.cant !== 0.0 &&
              _vm.newModifier.percent )?_c('v-col',{attrs:{"md":"12"}},[_c('i',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t( '$vuetify.tax.example', [_vm.newModifier.cant], [_vm.user.company.currency] )))])]):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isActionInProgress},on:{"click":function($event){return _vm.toogleNewModal(false)}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.actions.cancel"))+" ")],1),_c('v-btn',{attrs:{"disabled":!_vm.formValid || _vm.isActionInProgress || _vm.newModifier.shops.length === 0,"loading":_vm.isActionInProgress,"color":"primary"},on:{"click":_vm.handleSubmit}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.actions.save"))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }