<template>
  <v-card>
    <v-app-bar
      v-if="title !== ''"
      flat
      dense
      color="rgba(0, 0, 0, 0)"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="viewBackButton && routerGoBack !== '' "
            class="mx-2"
            fab
            small
            color="dark"
            @click="returnBack"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark>
              mdi-chevron-double-left
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $vuetify.lang.t('$vuetify.actions.back') }}</span>
      </v-tooltip>
      <h4>{{ title }}</h4>

      <v-spacer/>
      <v-tooltip
        v-if="viewTourButton"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="teal darken-2"
            dark
            v-bind="attrs"
            icon
            medium
            v-on="on"
            @click="tourButtonClicked"
          >
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>
        <span>{{ $vuetify.lang.t('$vuetify.guide') }}</span>
      </v-tooltip>
    </v-app-bar>
    <v-container
      v-if="viewShowFilter"
      fluid
    >
      <!-- SEARCH BAR -->
      <filter-header
        :id="`filter_header_`+id"
        :has-csv-export="hasCsvExport"
        :has-csv-import="hasCsvImport"
        :select-many-filters="selectManyFilters"
        :select-filters="selectFilters"
        :checkbox-filters="checkboxFilters"
        :filters-enabled-count="filtersEnabledCount"
        :has-filters="hasFilters"
        :loading="isLoading"
        :header-choices="headerChoices"
        :headers-choosen="headersChoosen"
        @onClickExport="onClickExport"
        @clearFilters="clearFilters"
        @onChangedFilters="onChangedFilters"
        @resetColumns="resetColumns"
        @searchValueChanged="searchValueChanged"
        @showFilterMenuChanged="showFilterMenuChanged"
        @headersChoosenChanged="headersChoosenChanged"
        @onClickImport="onClickImport"
      />
      <v-row v-if="viewShowFilterDate">
        <v-col
          class="py-0"
          cols="12"
          md="4"
        >
          <app-datetime-picker
            v-model="firstDate"
            :max-date="secondDate"
            :clearText="$vuetify.lang.t('$vuetify.actions.cancel')"
            :okText="$vuetify.lang.t('$vuetify.actions.accept')"
            :label="$vuetify.lang.t('$vuetify.plan.starts_at')"
          />
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          md="4"
        >
          <app-datetime-picker
            v-model="secondDate"
            :min-date="firstDate"
            :clearText="$vuetify.lang.t('$vuetify.actions.cancel')"
            :okText="$vuetify.lang.t('$vuetify.actions.accept')"
            :label="$vuetify.lang.t('$vuetify.plan.ends_at')"
          />
        </v-col>
        <v-col class="py-0" cols="12" md="4" v-if="viewShowFilterShop">
          <v-select
            v-model="localShop"
            chips
            :items="localShops"
            :label="$vuetify.lang.t('$vuetify.menu.shop')"
            item-text="name"
            item-value="id"
            required
            multiple
          />
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      v-model="selected"
      :id="id"
      :class="getClassStyle"
      v-bind="options"
      :loading="isLoading"
      :items="itemsFiltered || []"
      :headers="headersChoosenObjs || []"
      :search="searchValueDebounced"
      :hide-default-footer="hideFooter"
      :show-select="multiSelect"
      v-on="$listeners"
      @click:row="handleClick"
      @pagination="$emit('pagination')"
    >
      <template
        v-if="accessNewButton"
        v-slot:top
      >
        <v-toolbar flat>
          <v-spacer/>
          <v-btn v-if="selected.length > 0"
                 class="mb-2"
                 color="error"
                 @click="multiDeleteButtonClicked(true)"
          >
            <v-icon>mdi-delete</v-icon>
            {{ $vuetify.lang.t('$vuetify.actions.deleteAll') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            v-hotkey="keymap"
            class="mb-2"
            color="primary"
            @click="createButtonClicked(true)"
          >
            <v-icon>mdi-plus</v-icon>
            {{ newText }}
          </v-btn>
        </v-toolbar>
      </template>
      <!-- Pass on all named slots -->
      <slot
        v-for="slot in Object.keys($slots)"
        :slot="slot"
        :name="slot"
      />
      <!-- Pass on all scoped slots -->
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="viewFactureButton"
              class="mr-2"
              small
              v-bind="attrs"
              v-on="on"
              @click="$emit('print-facture',item.id)"
            >
              mdi-printer
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.show') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="viewShowButton"
              class="mr-2"
              small
              v-bind="attrs"
              v-on="on"
              @click="showButtonClicked(item.id)"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.show') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="showBillButton"
              class="mr-2"
              small
              v-bind="attrs"
              v-on="on"
              @click="billButtonClicked(item.id)"
            >
              mdi-cash-multiple
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.edit') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="accessEditButton"
              class="mr-2"
              color="warning"
              small
              v-bind="attrs"
              v-on="on"
              @click="editButtonClicked(item.id)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.edit') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="accessSuspendButton"
              class="mr-2"
              color="warning"
              small
              v-bind="attrs"
              v-on="on"
              @click="suspendButtonClicked(item.id)"
            >
              mdi-account-off
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.suspend') }}</span>
        </v-tooltip>
        <v-tooltip>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="activeAccountButton"
              class="mr-2"
              color="primary"
              small
              v-bind="attrs"
              v-on="on"
              @click="activeButtonClicked(item.id)"
            >
              mdi-account-check
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.active') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="accessCancelButton"
              class="mr-2"
              color="red"
              small
              v-bind="attrs"
              v-on="on"
              @click="editButtonClicked(item.id)"
            >
              mdi-account-remove
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.edit') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="viewDiscountButton"
              class="mr-2"
              color="success"
              small
              v-bind="attrs"
              v-on="on"
              @click="discountButtonClicked(item)"
            >
              mdi-currency-usd-off
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.access.access.manager_discount') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="viewModButton"
              class="mr-2"
              color="primary"
              small
              v-bind="attrs"
              v-on="on"
              @click="modButtonClicked(item)"
            >
              mdi-table-edit
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.access.access.manager_mod') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="accessTransportButton"
              class="mr-2"
              color="success"
              small
              v-bind="attrs"
              v-on="on"
              @click="transferButtonClicked(item.id)"
            >
              mdi-truck
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.transfer') }}</span>
        </v-tooltip>
        <v-tooltip top
                   v-if="accessDeleteButton && !viewDeleteButtonObject">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="error"
              small
              v-bind="attrs"
              v-on="on"
              @click="deleteButtonClicked(item.id?item.id: item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.delete') }}</span>
        </v-tooltip>
        <v-tooltip top
                   v-if="accessDeleteButton && viewDeleteButtonObject">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="error"
              small
              v-bind="attrs"
              v-on="on"
              @click="deleteButtonClicked(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.delete') }}</span>
        </v-tooltip>
        <v-btn
          v-if="viewPayButton"
          class="mr-2"
          color="primary"
          small
          @click="newPayClicked(item.id ? item.id: item)"
        >
          {{ $vuetify.lang.t('$vuetify.actions.pay') }}
          <v-icon>
            mdi-currency-usd
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import FilterHeader from './FilterHeader'
import FiltersHandler from './helpers/filter'
import { downloadAsJson } from './helpers/json-to-csv'
import { debounce } from './debounce'
import { BehaviorSubject, combineLatest, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { mapActions, mapState, mapGetters } from 'vuex'
import * as _ from 'lodash'
// import localStorage from '../../../config/localStorage'

const DEFAULT_ARRAY = []
export default {
  name: 'AppDataTable',
  components: { FilterHeader },
  inheritAttrs: false,
  props: {
    multiSelect: {
      type: Boolean,
      default: false
    },
    selectData: {
      type: Array,
      default: function () {
        return []
      }
    },
    localShops: {
      type: Array,
      default: function () {
        return []
      }
    },
    newText: {
      type: String,
      default: function () {
        return this.$vuetify.lang.t('$vuetify.actions.new')
      }
    },
    id: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    manager: {
      type: String,
      default: '',
      required: false
    },
    subtitle: {
      type: String,
      default: '',
      required: false
    },
    options: {
      type: [Object, Array],
      default: () => {
        return []
      }
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    items: {
      type: Array,
      default: DEFAULT_ARRAY
    },
    headers: {
      type: Array,
      default: DEFAULT_ARRAY
    },
    csvFilename: {
      type: String,
      default: ''
    },
    showBillButton: {
      type: Boolean,
      default: false
    },
    viewBackButton: {
      type: Boolean,
      default: false
    },
    routerGoBack: {
      type: String,
      default: ''
    },
    viewFactureButton: {
      type: Boolean,
      default: false
    },
    viewLetterButton: {
      type: Boolean,
      default: false
    },
    viewTourButton: {
      type: Boolean,
      default: false
    },
    viewNewButton: {
      type: Boolean,
      default: true
    },
    viewModButton: {
      type: Boolean,
      default: false
    },
    accessCancelButton: {
      type: Boolean,
      default: false
    },
    accessSuspendButton: {
      type: Boolean,
      default: false
    },
    activeAccountButton: {
      type: Boolean,
      default: false
    },
    viewDiscountButton: {
      type: Boolean,
      default: false
    },
    viewShowButton: {
      type: Boolean,
      default: false
    },
    viewPayButton: {
      type: Boolean,
      default: false
    },
    viewEditButton: {
      type: Boolean,
      default: true
    },
    viewDeleteButton: {
      type: Boolean,
      default: true
    },
    viewDeleteButtonObject: {
      type: Boolean,
      default: false
    },
    removeById: {
      type: Boolean,
      default: true
    },
    viewTransferButton: {
      type: Boolean,
      default: false
    },
    viewShowFilter: {
      type: Boolean,
      default: true
    },
    viewShowFilterDate: {
      type: Boolean,
      default: false
    },
    viewShowFilterShop: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    hasCsvImport: {
      type: Boolean,
      default: false
    },
    firstDateData: {
      type: Date,
      default: function () {
        return new Date()
      }
    },
    secondDateData: {
      type: Date,
      default: function () {
        return new Date()
      }
    }
  },
  data () {
    return {
      localShop: this.localShops,
      menu: false,
      selected: this.selectData,
      firstDate: this.firstDateData,
      secondDate: this.secondDateData,
      search: '',
      test: null,
      searchValue: '',
      searchValueDebounced: '',
      filterHandler: new FiltersHandler(),
      showFilterMenu: false,
      o$items: new BehaviorSubject(),
      o$headers: new BehaviorSubject(),
      o$destroyed: new Subject(),
      headersChoosen: [],
      selectFilters: [],
      selectManyFilters: [],
      headersAllMap: [],
      checkboxFilters: [],
      itemsFiltered: [],
      selectFiltersRegistered: {},
      selectManyFiltersRegistered: {},
      checkboxFiltersRegistered: {},
      accessNewButton: false,
      accessEditButton: false,
      accessTransportButton: false,
      accessOpen: false,
      accessDeleteButton: true
    }
  },
  computed: {
    keymap () {
      return {
        // 'esc+ctrl' is OK.
        // 'ctrl+alt+N': this.createButtonClicked()
      }
    },
    ...mapState('article', [
      'showImportModal'
    ]),
    ...mapGetters('auth', ['access_permit', 'isAdminIn']),
    ...mapGetters('auth', ['companySubscription']),
    getHaveSubscriptions () {
      return !!this.companySubscription
    },
    getSubscription () {
      return this.companySubscription
    },
    hasCsvExport () {
      const has = this.csvFilename
      return !!has
    },
    filtersEnabledCount () {
      const enabledSelect = this.selectFilters.filter(
        (f) => !_.isEmpty(f.model)
      )
      const enabledCheckbox = this.checkboxFilters.filter((f) => !!f.model)
      return enabledSelect.length || enabledCheckbox.length
    },
    hasFilters () {
      return !!this.allFilters.length
    },
    allFilters () {
      return [
        this.selectFilters,
        this.selectManyFilters,
        this.checkboxFilters
      ].reduce((acc, cur) => acc.concat(cur), [])
    },
    headerChoices: function () {
      return Object.values(this.headersAllMap)
    },
    headersChoosenObjs: function () {
      return this.headersChoosen
        .map((h) => this.headersAllMap[h])
        .filter((h) => !!h)
    }
  },
  watch: {
    localShops: function () {
      this.localShop = this.localShops
    },
    firstDate: function () {
      if (!this.viewShowFilterShop) {
        this.$emit('reload-data', [this.firstDate, this.secondDate])
      } else {
        this.$emit('reload-data', [this.firstDate, this.secondDate, this.localShop])
      }
    },
    secondDate: function () {
      if (!this.viewShowFilterShop) {
        this.$emit('reload-data', [this.firstDate, this.secondDate])
      } else {
        this.$emit('reload-data', [this.firstDate, this.secondDate, this.localShop])
      }
    },
    localShop: function () {
      this.$emit('reload-data', [this.firstDate, this.secondDate, this.localShop])
    },
    selectData: function () {
      this.selected = this.selectData
    },
    access_permit: function () {
      this.showButtons()
    },
    manager: function () {
      this.showButtons()
    },
    searchValue: debounce(function (newVal) {
      this.searchValueDebounced = newVal
    }, 300),
    headers: {
      immediate: true,
      handler (newVal) {
        this.o$headers.next(newVal)
      }
    },
    items: {
      immediate: true,
      handler (newVal) {
        this.o$items.next(newVal)
      }
    }
  },
  mounted () {
    this.secondDate = this.secondDateData
    this.firstDate = new Date(this.firstDateData.getTime())
    this.firstDate.setHours(0, 0, 0, 0)
    this.showButtons()
    combineLatest([this.o$items, this.o$headers])
      .pipe(filter(([items, headers]) => Array.isArray(items) && Array.isArray(headers)))
      .pipe(takeUntil(this.o$destroyed))
      .subscribe(([items, headers]) => {
        this._processHeaders(headers)
        this._processItems(items)
      })
  },
  created () {
    this.showButtons()
  },
  destroyed () {
    this.o$destroyed.next()
  },
  methods: {
    ...mapActions('article', ['importArticles', 'toogleImportModal']),
    showButtons () {
      console.log('showButtons')
      if (localStorage.getItem('typeConnection') === 'admin') {
        this.accessNewButton = this.viewNewButton
        this.accessEditButton = this.viewEditButton
        this.accessDeleteButton = this.viewDeleteButton
      } else {
        if (this.access_permit && this.manager !== '') {
          if (this.access_permit[this.manager] ? !Array.isArray(this.access_permit[this.manager].actions) : false) {
            this.accessNewButton = this.viewNewButton && this.access_permit[this.manager] ? this.access_permit[this.manager].actions.create : false
            this.accessEditButton = this.viewEditButton && this.access_permit[this.manager] ? this.access_permit[this.manager].actions.edit : false
            this.accessDeleteButton = this.viewDeleteButton && this.access_permit[this.manager] ? this.access_permit[this.manager].actions.delete : false
            this.accessTransportButton = this.viewTransferButton && this.access_permit[this.manager] ? ('transfer' in this.access_permit[this.manager].actions) ? this.access_permit[this.manager].actions.transfer : false : false
            if (this.manager === 'box') {
              this.accessOpen = this.access_permit[this.manager] ? this.access_permit[this.manager].actions.boxes_open : false
            }
          }
        }
      }
    },
    createButtonClicked () {
      if (localStorage.getItem('typeConnection') !== 'admin') {
        if (!this.getHaveSubscriptions) {
          this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.messages.no_subscription'))
        } else {
          if (this.getSubscription.is_caduced) {
            this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
          } else {
            this.$emit('create-row')
          }
        }
      } else {
        this.$emit('create-row')
      }
    },
    multiDeleteButtonClicked () {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.delete'),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.waring_delete_all'
            ),
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.cancel'
            ),
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$emit('multi-select', this.selected)
            }
          })
      }
    },
    tourButtonClicked () {
      this.$emit('init-tour')
    },
    handleClick (row) {
      this.$emit('rowClick', row)
    },
    refreshButtonClicked () {
      this.$emit('refresh-table')
    },
    billButtonClicked (clickedRowId) {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else { this.$emit('bill-row', clickedRowId) }
    },
    editButtonClicked (clickedRowId) {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else {
        this.$emit('edit-row', clickedRowId)
      }
    },
    suspendButtonClicked (clickedRowId) {
      this.$emit('suspend-row', clickedRowId)
    },
    activeButtonClicked (clickedRowId) {
      this.$emit('active-row', clickedRowId)
    },
    modButtonClicked (clickedRowId) {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else { this.$emit('manager-modify-row', clickedRowId) }
    },
    discountButtonClicked (clickedRowId) {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else { this.$emit('manager-discount-row', clickedRowId) }
    },
    openButtonClicked (clickedRowId) {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else { this.$emit('open-row', clickedRowId) }
    },
    showButtonClicked (clickedRowId) {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else { this.$emit('show-row', clickedRowId) }
    },
    deleteButtonClicked (clickedRowId) {
      console.log('deleteButtonClicked')
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else { this.$emit('delete-row', clickedRowId) }
    },
    newPayClicked (clickedRowId) {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else { this.$emit('new-pay', this.items.filter(sl => sl.id === clickedRowId)[0]) }
    },
    transferButtonClicked (clickedRowId) {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else { this.$emit('transfer-row', clickedRowId) }
    },
    getClassStyle () {
      return 'elevation-1'
    },
    onClickExport () {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else
      if (!this.getHaveSubscriptions) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.access.access.export'),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.export_subscription'
            ),
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.cancel'
            ),
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.subscribe'
            ),
            confirmButtonColor: 'primary'
          }).then(result => {
            if (result.isConfirmed) this.$router.push({ name: 'my_subscription' })
          })
      } else {
        const filtered = this.itemsFiltered
        const headerValues = this.headersChoosen
        const filenamePrefix = this.csvFilename || 'exported'
        downloadAsJson(filtered, headerValues, filenamePrefix)
      }
    },
    onClickImport () {
      if (localStorage.getItem('typeConnection') !== 'admin' && this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else if (!this.getHaveSubscriptions) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.access.access.export'),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.export_subscription'
            ),
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.cancel'
            ),
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.subscribe'
            ),
            confirmButtonColor: 'primary'
          }).then(result => {
            if (result.isConfirmed) this.$router.push({ name: 'my_subscription' })
          })
      } else {
        this.toogleImportModal(true)
      }
    },
    searchValueChanged (e) {
      this.searchValue = e
    },
    showFilterMenuChanged (e) {
      this.showFilterMenu = e
    },
    headersChoosenChanged (e) {
      this.headersChoosen = e
    },
    clearFilters () {
      this.showFilterMenu = false
      this.itemsFiltered = this.items
      this.allFilters.map((f) => (f.model = null))
    },
    onChangedFilters () {
      this._setFiltersToHandler()
      this._filterItems()
    },
    resetColumns () {
      this.headersChoosen = this.headers
        .filter((h) => !h.hide)
        .map((h) => h.value)
    },
    _setFiltersToHandler () {
      this.allFilters.map((f) => {
        this.filterHandler.updateFilterValue(f.name, f.model)
      })
    },
    _filterItems () {
      this.itemsFiltered = this.items.filter((item) =>
        this.filterHandler.runFilter(item)
      )
    },
    _processHeaders (newHeaders) {
      const handler = this.filterHandler

      function addFilter (fieldName, label, registered, filters, options, model, items) {
        if (registered[fieldName]) {
          return
        }
        registered[fieldName] = true
        const filter = {
          name: fieldName,
          model: [],
          label: label,
          items: []
        }
        if (model !== undefined) {
          filter.model = model
        }
        if (items !== undefined) {
          filter.items = items
        }
        filters.push(filter)
        handler.registerFilter(fieldName, options)
      }

      newHeaders
        .filter((h) => h.select_filter)
        .map((h) => {
          addFilter(
            h.value,
            `${this.$vuetify.lang.t('$vuetify.component.select_one')} ` + h.text,
            this.selectFiltersRegistered,
            this.selectFilters,
            { caseSensitive: h.case_sensitive }
          )
        })
      newHeaders
        .filter((h) => h.select_filter_many)
        .map((h) => {
          addFilter(
            h.value,
            `${this.$vuetify.lang.t('$vuetify.component.many_filter')} ` + h.text,
            this.selectManyFiltersRegistered,
            this.selectManyFilters,
            {
              caseSensitive: h.case_sensitive,
              isManyFilter: true
            }
          )
        })
      newHeaders
        .filter((h) => h.checkbox_filter)
        .map((h) => {
          addFilter(
            h.value,
            `${this.$vuetify.lang.t('$vuetify.component.select_one')} ` + h.text,
            this.checkboxFiltersRegistered,
            this.checkboxFilters,
            { isCheckbox: true },
            false,
            [true, false]
          )
        })
      newHeaders.map((h) => {
        this._setHeaderMap(h.value, h)
      })
      this.clearFilters()
      this.resetColumns()
    },
    _processItems (newItems) {
      newItems.map((item) => {
        this.selectManyFilters.map((f) => {
          const fieldValue = _.get(item, f.name)
          if (!Array.isArray(fieldValue)) {
            return
          }
          f.items = f.items.concat(fieldValue)
        })
        // Set select filters
        this.selectFilters.map((f) => {
          const fieldValue = _.get(item, f.name)
          f.items.push(fieldValue)
        })
      })
      // Order Items
      this.selectFilters.map((f) => {
        f.items = _.sortedUniq(_.sortBy(f.items))
      })
      this.selectManyFilters.map((f) => {
        f.items = _.sortedUniq(_.sortBy(f.items))
      })
      const firstRow = newItems[0]
      if (!firstRow) {
        return
      }
      Object.keys(firstRow).map((itemFieldName) => {
        this._setHeaderMap(itemFieldName, {
          value: itemFieldName,
          text: itemFieldName.toUpperCase()
        })
      })
    },
    _setHeaderMap (fieldName, headerObj) {
      if (this.headersAllMap[fieldName]) {
        return
      }
      this.headersAllMap[fieldName] = headerObj
      this.headersAllMap = { ...this.headersAllMap }
    },
    returnBack () {
      if (this.getSubscription.is_caduced) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
      } else {
        this.$router.push({
          name: this.routerGoBack
        })
      }
    },
    showSubscriptionMessage (text) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
          text: text,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.accept'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'my_subscription' })
          }
        })
    }
  }

}
</script>

<style scoped>

</style>
