<template>
  <v-dialog
    v-model="toogleShowStripePay"
    max-width="550"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          $vuetify.lang.t('$vuetify.titles.new', [
            $vuetify.lang.t('$vuetify.payment.name')
          ])
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            md="12"
            cols="12"
          >
            <div>
              <label>{{ $vuetify.lang.t('$vuetify.payment.card_number') }}</label>
              <div id="card" />
            </div>
            <v-alert
              v-if="stripeValidationError"
              type="warning"
            >
              {{ stripeValidationError }}
            </v-alert>
          </v-col>
          <app-loading v-if="loading" />
          <v-col
            v-else
            md="12"
            cols="12"
          >
            <v-textarea
              v-model="description"
              :label="$vuetify.lang.t('$vuetify.description')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!loading">
        <v-spacer />
        <v-btn
          :disabled="isActionInProgress"
          class="mb-2"
          @click="toogleShowStripePay(false)"
        >
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
        </v-btn>
        <v-btn
          :disabled="isActionInProgress"
          :loading="isActionInProgress"
          class="mb-2"
          color="primary"
          @click="addPayment"
        >
          <v-icon>mdi-check</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Vue from 'vue'

export default {
  name: 'PayStripe',
  props: {
    plan: {
      type: Object,
      default: function () {
        return {}
      }
    },
    user: {
      type: Object,
      default: function () {
        return {}
      }
    },
    order: {
      type: Object,
      default: function () {
        return {}
      }
    },
    period: {
      type: String,
      default: 'month'
    },
    periodId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      billing_details: {
        email: null,
        name: null,
        phone: null
      },
      errorPhone: null,
      countrySelect: null,
      formRule: this.$rules,
      stripeValidationError: null,
      loading: false,
      formValid: false,
      $stripe: {},
      description: ''
    }
  },
  computed: {
    ...mapState('pay', ['stripeData', 'payPlan', 'isActionInProgress'])
  },
  beforeDestroy () {
    this.cardNumber.destroy()
  },
  async created () {
    this.loading = true
    await this.getPayData({ method: 'stripe' })
    // Vue.use(StripePlugin, this.stripeData)
    try {
      this.$stripe = Stripe(this.stripeData.pk)
      const style = {
        base: {
          color: 'black',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
      this.formValid = true
      this.cardNumber = this.$stripe.elements().create('card', { style })
      this.cardNumber.mount('#card')
      this.cardNumber.on('change', this.setValidationError)
      this.loading = false
    } catch (error) {

    }
  },
  methods: {
    ...mapActions('pay', ['toogleShowStripePay', 'getPayData', 'sendPayPlan']),
    onInput (number, object) {
      const lang = this.$vuetify.lang
      if (object.valid) {
        this.billing_details.phone = number.replace(' ', '')
        this.errorPhone = null
      } else {
        this.errorPhone = lang.t('$vuetify.rule.bad_phone', [
          lang.t('$vuetify.phone')
        ])
      }
    },
    onCountry (event) {
      this.countrySelect = event
    },
    setValidationError (event) {
      this.stripeValidationError = event.error ? event.error.message : ''
    },
    async addPayment () {
      this.loading = true
      await this.$stripe.createToken(this.cardNumber).then(result => {
        if (result.error) {
          this.stripeValidationError = result.error.message
          this.loading = false
        } else {
          this.payPlan.token = result.token
          this.payPlan.plan = this.plan
          this.payPlan.user = this.user
          this.payPlan.method = 'stripe'
          this.payPlan.period = {
            value: this.period,
            text: this.$vuetify.lang.t('$vuetify.plan.' + this.period)
          }
          this.payPlan.description = this.description
          this.sendPayPlan(this.payPlan).then(() => {
            this.$emit('close-pay-stripe')
            this.loading = false
            window.location.reload()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped></style>
