<template>
  <v-container>
    <app-loading v-if="isTableLoading"/>
    <v-card v-else>
      <v-card-title>
        <v-list-item two-line>
          <v-list-item-title>
            {{ $vuetify.lang.t('$vuetify.menu.category_list') }}
          </v-list-item-title>
          <v-spacer/>
          <v-text-field
            v-model="search"
            :label="$vuetify.lang.t('$vuetify.actions.search')"
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
          <v-spacer/>
          <v-btn
            class="mb-2"
            color="primary"
            @click="addCategory(null)"
          >
            <v-icon>mdi-plus</v-icon>
            {{ $vuetify.lang.t('$vuetify.actions.newF') }}
          </v-btn>
        </v-list-item>
      </v-card-title>
      <v-card-text>
        <v-treeview
          activatable
          color="warning"
          open-on-click
          :items="getCategoriesLanguage"
          :search="search"
          :filter="filter"
          item-text="category"
        >
          <template v-slot:label="{ item }">
            <v-list-item two-line>
              <v-list-item-title>
                <span
                  v-if="item.id"
                  class="subtitle-2 font-weight-light"
                >
                  {{ UpperCaseFirstLetter(item.category) }}</span>
              </v-list-item-title>
              <v-list-item-icon>
                <v-spacer />
                <v-tooltip   v-if="showOptions" top style="margin-top: 15px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      small
                      color="withe"
                      v-bind="attrs"
                      v-on="on"
                      @click="addArticlesToCategory(item.id)"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.actions.add', [$vuetify.lang.t('$vuetify.menu.articles')]) }}</span>
                </v-tooltip>
                <v-tooltip v-if="showOptions" top style="margin-top: 15px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      small
                      color="withe"
                      v-bind="attrs"
                      v-on="on"
                      @click="showArticlesByCategory(item.id)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.actions.see', [$vuetify.lang.t('$vuetify.menu.articles')]) }}</span>
                </v-tooltip>
                <span class="mr-1" />
                <v-tooltip top style="margin-top: 15px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="addCategory(item)"
                    >
                      mdi-folder-plus
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.actions.add', [$vuetify.lang.t('$vuetify.menu.category')]) }}</span>
                </v-tooltip>
                <v-tooltip top v-if="item.company_id">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      small
                      color="warning"
                      v-bind="attrs"
                      v-on="on"
                      @click="editCategoryHandler(item.id)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.menu.sell_category') }}</span>
                </v-tooltip>
                <v-tooltip top v-if="item.company_id">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      small
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteCategoryHandler(item.id)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.menu.sell_category') }}</span>
                </v-tooltip>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-treeview>
      </v-card-text>
      <v-dialog v-model="showAddArticle" width="450px">
        <add-articles-to-category :category-id="categoryId" @closeModal="showAddArticle=false"/>
      </v-dialog>
      <new-category v-if="showNewModal" :parent-category="parentCategory"/>
      <edit-category v-if="showEditModal"/>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import categories from '../../../data/categories'
import AddArticlesToCategory from './AddArticlesToCategory'
import NewCategory from './NewCategory'
import EditCategory from './EditCategory'

export default {
  name: 'ListCategory',
  props: {
    showOptions: {
      type: Boolean,
      default: true
    }
  },
  components: { NewCategory, AddArticlesToCategory, EditCategory },
  data () {
    return {
      showAddArticle: false,
      categoryId: null,
      parentCategory: null,
      cat: [],
      search: ''
    }
  },
  computed: {
    ...mapState('category', [
      'activated',
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'isTableLoading'
    ]),
    ...mapGetters('category', ['allCategories', 'allActivated']),
    ...mapState('auth', ['company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.color'),
          value: 'color'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    getCategoriesLanguage () {
      const localCat = categories[this.$vuetify.lang.current]()
      let newLocal = []
      if (this.allActivated.length > 0) {
        newLocal = this.updateActiveCat(localCat, newLocal)
        this.allActivated.forEach((v) => {
          const cat = this.allCategories.filter(cat => parseInt(cat.id) === parseInt(v))[0]
          if (cat.company_id) {
            if (cat.parent_id) {
              this._find(newLocal, 'id', cat.parent_id, cat)
            } else {
              newLocal.push(cat)
            }
          }
        })
      }
      return newLocal
    }
  },
  async created () {
    await this.getCategories()
  },
  methods: {
    ...mapActions('category', ['toogleNewModal', 'openEditModal', 'openShowModal',
      'getCategories', 'deleteCategory']),
    updateActiveCat (collection, newLocal) {
      for (const o of collection) {
        const ele = {
          category: o.category,
          id: o.id,
          children: []
        }
        if (o.children && Array.isArray(o.children) && o.children.length > 0) {
          const _o = this.updateActiveCat(o.children, ele.children)
          if (!Array.isArray(_o)) {
            ele.children.push(_o)
          }
        }
        if (this.allActivated.filter((vl) => parseInt(vl) === parseInt(ele.id)).length > 0 || ele.children.length > 0) {
          if (!Array.isArray(ele)) {
            newLocal.push(ele)
          }
        }
      }
      return newLocal
    },
    _find (collection, key, value, child) {
      for (const o of collection) {
        for (const [k, v] of Object.entries(o)) {
          if (k === key && v === value.toString()) {
            if (!o.children && child) { o.children = [] }
            if (child)o.children.push(child)
          }
          if (Array.isArray(v)) {
            const _o = this._find(v, key, value, child)
            if (_o) {
              return _o
            }
          }
        }
      }
    },
    addArticlesToCategory (categoryId) {
      if (!this.getSubscription) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
            text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'my_subscription' })
            }
          })
      } else {
        this.categoryId = categoryId
        this.showAddArticle = true
      }
    },
    addCategory (category) {
      if (!this.getSubscription) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
            text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'my_subscription' })
            }
          })
      } else {
        this.parentCategory = category
        this.toogleNewModal(true)
      }
    },
    UpperCaseFirstLetter (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    showGraphicByCategory (id) {
      if (!this.getSubscription) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
            text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'my_subscription' })
            }
          })
      } else {
        this.$router.push({
          name: 'sell_category',
          params: {
            categoryId: id
          }
        })
      }
    },
    showArticlesByCategory (categoryId) {
      if (!this.getSubscription) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
            text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'my_subscription' })
            }
          })
      } else { this.$router.push({ name: 'product_by_category', params: { categoryId } }) }
    },
    editCategoryHandler ($event) {
      if (!this.getSubscription) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
            text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'my_subscription' })
            }
          })
      } else { this.openEditModal($event) }
    },
    deleteCategoryHandler (categoryId) {
      if (!this.getSubscription) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
            text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'my_subscription' })
            }
          })
      } else {
        this.allCategories.filter(cat => cat.id === categoryId)[0].cant_articles > 0
          ? this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.delete', [
                this.$vuetify.lang.t('$vuetify.menu.category')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_exist_articles'
              ),
              icon: 'info',
              showCancelButton: false,
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'red'
            }) : this.deleteCat(categoryId)
      }
    },
    deleteCat (categoryId) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.category')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deleteCategory(categoryId)
        })
    }
  }
}
</script>

<style scoped>

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}</style>
