<template>

    <v-dialog
        v-model="setShowHelp"
        max-width="90%"
        persistent
    >
        <v-card>

            <v-card-title class="indigo white--text text-h5">
                {{$vuetify.lang.t('$vuetify.help.text')}}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3" sm="12">
                        <v-card-text>
                            <v-scroll-y-transition mode="out-in">
                                <v-treeview
                                    style="width: 300px"
                                    :active.sync="active"
                                    :items="items"
                                    :open.sync="open"
                                    activatable
                                    color="warning"
                                    open-on-click
                                    return-object
                                    transition
                                >
                                    <template v-slot:prepend="{ item }" @click="selected">
                                        <v-icon>
                                            {{item.icon}}
                                        </v-icon>
                                    </template>
                                  <template v-slot:label="{ item }">
                                    <p>{{item.name}}</p>
                                  </template>
                                </v-treeview>
                            </v-scroll-y-transition>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="9" sm="12">
                        <v-card
                            v-if="!selected"
                            class="text-h6 grey--text text--lighten-1 font-weight-light"
                            style="align-self: center;"
                        >
                            <selected :selected="items[0]"/>
                        </v-card>
                        <v-card v-else
                                :key="selected.id"
                                class="pt-6 mx-auto"
                                flat>
                            <selected :selected="selected"/>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    class="mb-2"
                    @click="setShowHelp(false)"
                >
                    <v-icon>mdi-close</v-icon>
                    {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Selected from './Selected'

export default {
  name: 'HelpDoc',
  components: { Selected },
  data: () => ({
    active: [],
    avatar: null,
    open: [],
    users: []
  }),

  computed: {
    ...mapState('auth', ['userPin']),
    items () {
      return [
        {
          name: this.$vuetify.lang.t('$vuetify.help.index'),
          descriptions: [
            {
              text: this.$vuetify.lang.t('$vuetify.help.description.text'),
              steps: {
                0: {
                  text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.first'),
                  step: 1,
                  required: true
                },
                1: {
                  text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.two'),
                  step: 2,
                  required: true
                },
                2: {
                  text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.three'),
                  step: 3,
                  required: true
                },
                3: {
                  text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.four'),
                  step: 3.1,
                  required: true
                },
                4: {
                  text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.five'),
                  step: 4,
                  required: true
                },
                5: {
                  text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.six'),
                  step: 5,
                  required: false
                },
                6: {
                  text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.seven'),
                  step: 6,
                  required: false
                },
                7: {
                  text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.eight'),
                  step: 7,
                  required: false
                }
              }
            },
            {
              text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.text_one')
            },
            {
              text: this.$vuetify.lang.t('$vuetify.help.description.step_tax.text_two')
            }
          ],
          id: 0,
          children: [
            {
              name: this.$vuetify.lang.t('$vuetify.menu.tax_list'),
              icon: 'mdi-wallet',
              id: 'tax',
              descriptions: [{
                text: this.$vuetify.lang.t('$vuetify.help.children.descriptions.text')
              }],
              children: [
                {
                  id: 'tax_list',
                  name: this.$vuetify.lang.t('$vuetify.actions.list'),
                  icon: 'mdi-format-list-bulleted',
                  src: '',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.tax_list') }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.tax.list_tax.descriptions.text')
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.new'),
                  icon: 'mdi-plus',
                  src: '',
                  id: 'tax_new',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.tax_list') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_add'),
                      btn: 'add'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.tax.new_tax.descriptions.text')
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.edit'),
                  icon: 'mdi-pencil',
                  id: 'tax_edit',
                  src: '',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.tax_list') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_edit'),
                      btn: 'edit'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.tax.edit_tax.descriptions.text')
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.delete'),
                  icon: 'mdi-delete',
                  src: '/assets/videos/vending_list.webm',
                  id: 'tax_delete',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.tax_list') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_delete'),
                      btn: 'delete'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.tax.delete_tax.descriptions.text')
                  }]
                }
              ]
            },
            {
              name: this.$vuetify.lang.t('$vuetify.menu.discount'),
              icon: 'mdi-cash-minus',
              id: 'discount',
              descriptions: [{
                text: this.$vuetify.lang.t('$vuetify.help.children.descriptions.text')
              }],
              children: [
                {
                  id: 'discount_list',
                  name: this.$vuetify.lang.t('$vuetify.actions.list'),
                  icon: 'mdi-format-list-bulleted',
                  src: '',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.discount') }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.discount.list_discount.descriptions.text')
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.new'),
                  icon: 'mdi-plus',
                  src: '',
                  id: 'discount_new',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.discount') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_add'),
                      btn: 'add'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.discount.new_discount.descriptions.text')
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.edit'),
                  icon: 'mdi-pencil',
                  id: 'discount_edit',
                  src: '',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.discount') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_edit'),
                      btn: 'edit'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.discount.edit_discount.descriptions.text')
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.delete'),
                  icon: 'mdi-delete',
                  src: '/assets/videos/vending_list.webm',
                  id: 'discount_delete',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.discount') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_delete'),
                      btn: 'delete'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.discount.delete_discount.descriptions.text')
                  }]
                }
              ]
            },
            {
              name: this.$vuetify.lang.t('$vuetify.menu.articles'),
              icon: 'mdi-cash-minus',
              id: 'articles',
              descriptions: [{
                text: this.$vuetify.lang.t('$vuetify.help.children.articles.descriptions.text')
              }],
              children: [
                {
                  id: 'articles_list',
                  name: this.$vuetify.lang.t('$vuetify.actions.list'),
                  icon: 'mdi-format-list-bulleted',
                  src: '',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.help.children.articles.steps.text') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.articles') }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.articles.list_articles.descriptions.text')
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.new'),
                  icon: 'mdi-plus',
                  src: '/assets/videos/article_add.webm',
                  id: 'article_new',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.articles') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_add'),
                      btn: 'add'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.descriptions.text'),
                    steps: {
                      0: {
                        step: 1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.first')
                      },
                      1: {
                        step: 2,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.two')
                      },
                      2: {
                        step: 2.1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.three')
                      },
                      3: {
                        step: 3,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.four'),
                        btn: ['next']
                      },
                      4: {
                        step: 4,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.five'),
                        btn: ['add']
                      },
                      5: {
                        step: 4.1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.six')
                      },
                      6: {
                        step: '4.1.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.seven'),
                        btn: ['cancel', 'accept']
                      },
                      7: {
                        step: '4.2',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.eight')
                      },
                      8: {
                        step: '4.3',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.nine')
                      },
                      9: {
                        step: '4.3.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.ten'),
                        btn: ['cancel', 'accept']
                      },
                      10: {
                        step: '5',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.eleven'),
                        btn: ['next']
                      },
                      11: {
                        step: '6',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.twelve'),
                        btn: ['follow_inventory']
                      },
                      12: {
                        step: '6.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.thirteen')
                      },
                      13: {
                        step: '6.2',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.fourteen')
                      },
                      14: {
                        step: '7',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.fifteen'),
                        btn: ['next']
                      },
                      15: {
                        step: '8',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.sixteen')
                      },
                      16: {
                        step: 9,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.new_articles.steps.seventeen'),
                        btn: ['cancel', 'accept']
                      }
                    }
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.edit'),
                  icon: 'mdi-pencil',
                  id: 'article_edit',
                  src: '',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.discount') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_edit'),
                      btn: 'edit'
                    }
                  },
                  descriptions: [{
                    steps: {
                      0: {
                        step: 1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.first')
                      },
                      1: {
                        step: 2,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.two')
                      },
                      2: {
                        step: 2.1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.three')
                      },
                      3: {
                        step: 3,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.four'),
                        btn: ['next']
                      },
                      4: {
                        step: 4,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.six'),
                        btn: ['add']
                      },
                      5: {
                        step: 4.1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.seven')
                      },
                      6: {
                        step: '4.1.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.eight'),
                        btn: ['cancel', 'accept']
                      },
                      7: {
                        step: '4.2',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.nine')
                      },
                      8: {
                        step: '4.3',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.ten')
                      },
                      9: {
                        step: '4.3.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.eleven'),
                        btn: ['cancel', 'accept']
                      },
                      10: {
                        step: '5',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.twelve'),
                        btn: ['next']
                      },
                      12: {
                        step: '6',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.thirteen')
                      },
                      13: {
                        step: '6.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.fourteen'),
                        btn: ['follow_inventory']
                      },
                      14: {
                        step: '7',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.fifteen'),
                        btn: ['next']
                      },
                      15: {
                        step: '8',
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.sixteen')
                      },
                      16: {
                        step: 9,
                        text: this.$vuetify.lang.t('$vuetify.help.children.articles.edit_articles.descriptions.seventeen'),
                        btn: ['cancel', 'accept']
                      }
                    }
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.delete'),
                  icon: 'mdi-delete',
                  src: '/assets/videos/vending_list.webm',
                  id: 'article_delete',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.menu.setting') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.discount') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_delete'),
                      btn: 'delete'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.articles.delete_articles.descriptions.text')
                  }]
                }
              ]
            },
            {
              name: this.$vuetify.lang.t('$vuetify.menu.vending'),
              icon: 'mdi-currency-usd',
              src: '/assets/videos/vending_list.webm',
              id: 1,
              descriptions: [{
                text: this.$vuetify.lang.t('$vuetify.help.children.vending.descriptions.text')
              }],
              children: [
                {
                  id: 2,
                  name: this.$vuetify.lang.t('$vuetify.actions.list'),
                  icon: 'mdi-format-list-bulleted',
                  src: '/assets/videos/vending_list.webm',
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.steps.text') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.vending') }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.text')
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.new'),
                  icon: 'mdi-plus',
                  src: '/assets/videos/vending_add.webm',
                  id: 3,
                  steps: {
                    0: { text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.steps.text') },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.vending') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_add'),
                      btn: 'add'
                    }
                  },
                  descriptions: [{
                    steps: {
                      0: {
                        step: 1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.first')
                      },
                      1: {
                        step: 2,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.two')
                      },
                      2: {
                        step: 3,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.three')
                      },
                      3: {
                        step: 4,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.four')
                      },
                      4: {
                        step: 5,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.five'),
                        btn: ['cart', 'file-document', 'cart-off']
                      },
                      5: {
                        step: 5.1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.six'),
                        btn: ['cart']
                      },
                      6: {
                        step: 5.2,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.seven'),
                        btn: ['file-document']
                      },
                      7: {
                        step: '5.2.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.eight'),
                        btn: ['add']
                      },
                      8: {
                        step: '5.3',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.nine'),
                        btn: ['cart-off']
                      },
                      9: {
                        step: '6',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.ten'),
                        btn: ['process', 'accept']
                      },
                      10: {
                        step: '6.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.eleven'),
                        btn: ['process']
                      },
                      11: {
                        step: '6.2',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.children.descriptions.steps.twelve'),
                        btn: ['accept']
                      }
                    }
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.edit'),
                  icon: 'mdi-pencil',
                  id: 4,
                  src: '/assets/videos/vending_list.webm',
                  steps: {
                    0: { text: 'Menú lateral izquierdo' },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.vending') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_edit'),
                      btn: 'edit'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.descriptions.text'),
                    steps: {
                      0: {
                        step: 1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.first')
                      },
                      1: {
                        step: 2,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.two')
                      },
                      2: {
                        step: 3,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.three')
                      },
                      3: {
                        step: 4,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.four')
                      },
                      4: {
                        step: 5,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.five'),
                        btn: ['cart', 'file-document', 'cart-off']
                      },
                      5: {
                        step: 5.1,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.six'),
                        btn: ['cart']
                      },
                      6: {
                        step: 5.2,
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.seven'),
                        btn: ['file-document']
                      },
                      7: {
                        step: '5.2.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.eight'),
                        btn: ['add']
                      },
                      8: {
                        step: '5.3',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.nine'),
                        btn: ['cart-off']
                      },
                      9: {
                        step: '6',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.ten'),
                        btn: ['process', 'accept']
                      },
                      10: {
                        step: '6.1',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.eleven'),
                        btn: ['process']
                      },
                      11: {
                        step: '6.2',
                        text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_edit.steps.twelve'),
                        btn: ['accept']
                      }
                    }
                  }]
                },
                {
                  name: this.$vuetify.lang.t('$vuetify.actions.delete'),
                  icon: 'mdi-delete',
                  src: '/assets/videos/vending_list.webm',
                  id: 55,
                  steps: {
                    0: { text: 'Menú lateral izquierdo' },
                    1: { text: this.$vuetify.lang.t('$vuetify.menu.vending') },
                    2: {
                      text: this.$vuetify.lang.t('$vuetify.help.btn_delete'),
                      btn: 'delete'
                    }
                  },
                  descriptions: [{
                    text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_delete.descriptions.text')
                  }]
                }
                /* {
                                  name: this.$vuetify.lang.t('$vuetify.menu.refund'),
                                  icon: 'mdi-undo',
                                  src: '/assets/videos/vending_list.webm',
                                  descriptions: [{
                                    text: this.$vuetify.lang.t('$vuetify.help.children.vending.vending_refund.text')
                                  }]
                                } */
              ]
            }
          ]
        }
      ]
    },
    selected () {
      if (!this.active.length) return this.open.length > 0 ? this.open[this.open.length - 1] : undefined
      return this.active[0]
    },
    opened () {
      if (!this.open.length) return undefined
      return this.open[0]
    }
  },
  methods: {
    ...mapActions('auth', ['setShowHelp'])
  }
}
</script>

<style scoped>

</style>
