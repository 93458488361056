// import api from '@/config/api'
import api from '../config/api'
import localStorage from '../config/localStorage'

const dashboard = () => {
  return api.post('dashboard')
}
export default {
  dashboard
}
