import axios from 'axios'
import storage from './localStorage'
import CryptoJS from 'crypto-js'
import router from '../router'
const baseUrl = process.env.VUE_APP_API
axios.interceptors.response.use(res => {
  /* if (res.data) {
    const objJson = JSON.parse(atob(res.data))
    const encrypted = objJson[0]
    const salt = CryptoJS.enc.Hex.parse(objJson[2])
    const iv = CryptoJS.enc.Hex.parse(objJson[1])
    const keyD = objJson.length > 3 ? objJson[3] : storage.getPinToken()
    const key = CryptoJS.PBKDF2(keyD, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 })
    const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv })
    res.data = JSON.parse(atob(decrypted.toString(CryptoJS.enc.Utf8)))
  } */
  if (res.data.type_connection) { storage.saveTypeConnection(res.data.type_connection) }
  if (res.data.pin_code) { storage.savePinToken(res.data.pin_token) }
  if (res.data.server_time) { storage.saveServerTime(res.data.server_time) }
  return res
}, error => {
  if (error.response.status === 401) {
  } else { return Promise.reject(error) }
}
)

axios.interceptors.request.use(req => {
  req.headers.Authorization = storage.getToken()
  req.headers.TypeAccount = storage.getTypeConnection()
  req.headers.PinCode = storage.getPinToken()
  req.headers.Language = storage.getLanguage()
  return req
})

const post = (resource, params) => {
  if (resource === 'login' || resource === 'auth' || resource === 'register') {
    return axios.post(baseUrl + resource, params)
  } else if (storage.getTypeConnection() === 'admin') { return axios.post(baseUrl + 'admin/' + resource, params) }
  return axios.post(baseUrl + resource, params)
}
const get = (resource) => {
  if (storage.getTypeConnection() === 'admin') {
    return axios.get(baseUrl + 'admin/' + resource)
  }
  return axios.get(baseUrl + resource)
}
const put = (resource, params) => {
  if (storage.getTypeConnection() === 'admin' && resource !== 'register') {
    return axios.put(baseUrl + 'admin/' + resource, params)
  }
  return axios.put(baseUrl + resource, params)
}
const remove = (resource) => {
  if (storage.getTypeConnection() === 'admin') {
    return axios.delete(baseUrl + 'admin/' + resource)
  }
  return axios.delete(baseUrl + resource)
}
const importData = (resource, data) => {
  return axios.post(baseUrl + resource, data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}
export default {
  get,
  post,
  put,
  remove,
  importData
}
