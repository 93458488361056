import fiscalAccount from '../../api/fiscal-account'

const FETCHING_FISCAL_FISCAL_ACCOUNTS = 'FETCHING_FISCAL_FISCAL_ACCOUNTS'
const FISCAL_ACCOUNT_CREATED = 'FISCAL_ACCOUNT_CREATED'
const FISCAL_LOADING = 'FISCAL_LOADING'
const FAILED_FISCAL_ACCOUNT = 'FAILED_FISCAL_ACCOUNT'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'

const state = {
  loading: false,
  saved: false,
  fiscal: {
    id: '',
    rnc: '',
    init: '',
    cant_digits: 12,
    cant_at: null,
    prefix: null
  },
  isFiscalLoading: false,
  isActionInProgress: false,
  isTableLoading: false
}

const mutations = {
  [FETCHING_FISCAL_FISCAL_ACCOUNTS] (state, fiscal) {
    state.fiscal = fiscal
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t(
        '$vuetify.messages.success_up', [this._vm.$language.t('$vuetify.menu.fiscal_account')]
      )
    })
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [FISCAL_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
  },
  [FISCAL_ACCOUNT_CREATED] (state) {
    state.showNewFiscalModal = false
    state.fiscal = {
      id: '',
      rnc: '',
      init: '',
      cant_digits: 12,
      cant_at: null,
      prefix: null
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.menu.fiscal_account')
	  ])
    })
  },
  [FAILED_FISCAL_ACCOUNT] (state, error) {
    state.saved = false
    state.error = error
    state.isActionInProgress = false
    this._vm.$Toast.fire({
	  icon: 'error',
	  title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.fiscal_account')
	  ])
    })
  }
}

const getters = {}

const actions = {
  getFiscalAccount ({ commit }) {
    commit(FISCAL_LOADING, true)
    fiscalAccount
      .fetchAccount()
      .then(({ data }) => {
        commit(FETCHING_FISCAL_FISCAL_ACCOUNTS, data.data)
        commit(FISCAL_LOADING, true)
        this.dispatch('auth/updateAccess', data)
        return data
      })
      .catch(error => commit(FAILED_FISCAL_ACCOUNT, error))
  },
  async updateFiscalAccount ({ commit, dispatch }, fAccount) {
    commit(ENV_DATA_PROCESS, true)
    await fiscalAccount
      .sendUpdateRequest(fAccount)
      .then(({ data }) => {
        commit(FETCHING_FISCAL_FISCAL_ACCOUNTS, data.data)
        commit(ENV_DATA_PROCESS, false)
        this.dispatch('auth/updateAccess', data)
      })
      .catch(error => commit(FAILED_FISCAL_ACCOUNT, error))
  },
  async generateFiscalAccount ({ commit, dispatch }, sale) {
    await fiscalAccount
      .sendGenerateFiscalAccount(sale)
      .then(({ data }) => {
        commit(FETCHING_FISCAL_FISCAL_ACCOUNTS, data.data)
        commit(ENV_DATA_PROCESS, false)
        this.dispatch('sale/syncUpdateSale', data.data)
      })
      .catch(error => commit(FAILED_FISCAL_ACCOUNT, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
