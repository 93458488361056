import Vue from 'vue'
import VueLocalStorage from 'vue-localstorage'

Vue.use(VueLocalStorage)

const saveServerTime = (serverTime) => {
  return Vue.localStorage.set('info', btoa(serverTime.date))
}

const saveReferr = (affiliateInformation) => {
  return Vue.localStorage.set('affiliateInformation', btoa(affiliateInformation))
}

const saveToken = (authToken) => {
  return Vue.localStorage.set('authToken', authToken)
}

const removeToken = () => {
  return Vue.localStorage.remove('authToken')
}

const getToken = () => {
  return Vue.localStorage.get('authToken')
}

const getServerTime = () => {
  return atob(Vue.localStorage.get('info'))
}

const savePinToken = (authToken) => {
  return Vue.localStorage.set('PinToken', authToken)
}

const removePinToken = () => {
  return Vue.localStorage.remove('PinToken')
}

const getPinToken = () => {
  return Vue.localStorage.get('PinToken')
}

const setLanguage = (item) => {
  return Vue.localStorage.set('language', item)
}
const getLanguage = () => {
  return Vue.localStorage.get('language')
}

const setTheme = (item) => {
  return Vue.localStorage.set('theme', item)
}
const getTheme = () => {
  return Vue.localStorage.get('theme')
}

const saveTokenManager = (item) => {
  console.log('saveTokenManager')
  return Vue.localStorage.set('managerToken', item)
}
const removeTokenManager = () => {
  return Vue.localStorage.remove('managerToken')
}

const getTokenManager = () => {
  return Vue.localStorage.get('managerToken')
}

const saveTypeConnection = (item) => {
  return Vue.localStorage.set('typeConnection', item)
}
const removeTypeConnection = () => {
  return Vue.localStorage.remove('typeConnection')
}
const getTypeConnection = () => {
  return Vue.localStorage.get('typeConnection')
}

export default {
  saveServerTime,
  getServerTime,
  getToken,
  saveToken,
  removeToken,
  getPinToken,
  savePinToken,
  removePinToken,
  setLanguage,
  getLanguage,
  setTheme,
  getTheme,
  saveTokenManager,
  removeTokenManager,
  getTokenManager,
  saveTypeConnection,
  removeTypeConnection,
  getTypeConnection
}
