<template>
  <div class="base64-upload">
    <v-img
      :src="imageSrc"
      :style="imageStyle"
    />
    <input
      accept="image/*"
      type="file"
      :rules="localRules.maxSize"
      @change="onChange"
    >
  </div>
</template>

<script>
export default {
  name: 'AvatarPicker',
  props: {
    imageSrc: {
      type: String,
      default: ''

    },
    imageStyle: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      localRules: {
        maxSize: files => !files || !files.some(file => file.size > 1e6) || 'Image size should be less than 1 MB!'
      },
      src: this.imageSrc
    }
  },
  methods: {
    onChange (event) {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0]
        if (file.size > 1024 * 1024) {
          event.preventDefault()
          this.$Toast.fire({
            icon: 'error',
            title: this.$vuetify.lang.t('$vuetify.messages.max_size')
          })
        } else {
          const reader = new FileReader()
          reader.addEventListener('load', (e) => {
            this.src = e.target.result
            const [, base64] = this.src.split(',')
            this.$emit('input', {
              size: file.size,
              type: file.type,
              name: file.name,
              base64: base64
            })
          })
          reader.readAsDataURL(file)
        }
      }
    }
  }
}
</script>

<style scoped>
.base64-upload {
  position: relative;
}

img {
  width: 100%;
  height: 100%;
}

input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
</style>
