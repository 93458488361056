<template>
  <v-card>
    <app-loading v-if="loading"/>

    <v-card v-else color="basil">
      <print-facture v-if="showPrintModal && printer === 'ticket'" :print-data="paySelect" :subscription="getMySubscription"/>
      <print-facture-letter v-if="showPrintModal && printer === 'letter'"/>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
        flat
      >
        <v-tab v-if="getHaveSubscription">
          {{ $vuetify.lang.t('$vuetify.menu.my_subscription') }}
        </v-tab>
        <v-tab v-if="((getHaveSubscription && plans.length > 1) || !getHaveSubscription) && showPlans">
          {{ $vuetify.lang.t('$vuetify.menu.admin_plan') }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item  v-if="getHaveSubscription">
          <v-card
            color="basil"
            flat
          >
            <v-card-text  v-if="companySubscription && plans.length > 1">
              <div  align="end" justify="center">
                <v-tooltip bottom
                           v-if="!showPlans">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="showPlans=true"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.actions.see_other_plan') }}</span>
                </v-tooltip>
                <v-tooltip bottom
                           v-if="showPlans">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="showPlans=false"
                    >
                      mdi-eye-off
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.actions.no_see_other_plan') }}</span>
                </v-tooltip>
              </div>
            </v-card-text>
            <v-card-text  v-if="companySubscription"><pay-plan :plan="companySubscription.plan"/></v-card-text>
            <v-card-text class="headline font-weight-bold" v-if="companySubscription">
              <timer-counter-down v-if="companySubscription" :textLabel="companySubscription.is_test ? $vuetify.lang.t('$vuetify.plan.trial_period') + ':'
            : $vuetify.lang.t('$vuetify.plan.valid_until') + ':'"
                                  :atTime="companySubscription.is_test ? companySubscription.test_at : companySubscription.valid_at" :stTime="companySubscription.created_at"/>
              <app-data-table
                v-if="companySubscription"
                :items="getPays"
                :view-facture-button="true"
                :view-new-button="false"
                :viewDeleteButton="false"
                :viewFactureButton="true"
                :view-edit-button="false"
                :headers="getHeadersColumns">
                <template v-slot:item.createBy="{ item }">
                  <template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <b><v-icon
                          style="color: blue"
                          class="mr-2"
                          small
                          v-bind="attrs"
                          @click="openPrintModal('ticket', item.id )"
                          v-on="on"
                        >
                          mdi-printer
                        </v-icon></b>
                      </template>
                      <span>{{
                          $vuetify.lang.t(
                            "$vuetify.report.print_ticket"
                          )
                        }}</span>
                    </v-tooltip>
                  </template>
                  {{ item.createBy }}
                </template>
<!--                <template v-slot:item.period="{ item }">-->
<!--                  {{$vuetify.lang.t('$vuetify.plan.' + item.period)}}-->
<!--                </template>-->
                <template v-slot:item.period_text="{ item }">
                  {{ item.period_text }}
                </template>
                <template v-slot:item.planName="{ item }">
                  {{ item.planName }}
                </template>
                <template v-slot:item.cant="{ item }">
                  {{getMySubscription.plan.currency + ' ' + parseFloat(item.cant).toFixed(2) }}
                </template>
                <template v-slot:item.platform="{ item }">
                  <v-img v-if="item.platform === 'paypal'"
                    style="width: 128px; min-width: 128px;"
                    src="/assets/payments/paypal-2.png"
                  />
                </template>
              </app-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="((getHaveSubscription && plans.length > 1) || !getHaveSubscription) && showPlans">
          <v-card color="basil" flat>
            <subscription :plans="plans"  :is-modal="isModal"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-card>
</template>

<script>
// TODO: terminar impresion de factura para los pagos de subscripcion
import Subscription from './Subscription'
import PrintFacture from './print/PrintFacture'
import PrintFactureLetter from './print/PrintFactureLetter'
import { mapActions, mapGetters, mapState } from 'vuex'
import PayPlan from '../pay/PayPlan'
import AppLoading from '../../components/core/AppLoading'
export default {
  name: 'MySubscription',
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AppLoading,
    PayPlan,
    PrintFactureLetter,
    Subscription,
    PrintFacture
    // PrintFactureLetter
  },
  computed: {
    ...mapState('plan', ['plans']),
    ...mapGetters('auth', ['companySubscription']),
    ...mapState('pay', ['showPrintModal']),
    getPays () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.getMySubscription.id ? this.getMySubscription.pay_plans.reverse() : []
    },
    getHaveSubscription () {
      return !!this.companySubscription
    },
    getMySubscription () {
      return this.companySubscription
    },
    getHeadersColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'createBy',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.invoice_interval'),
          value: 'period_text',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.name'),
          value: 'planName',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.platform_payment_gateway'),
          value: 'platform',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.cant'),
          value: 'cant',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.valid_until'),
          value: 'valid_at',
          select_filter: false
        }
        // {
        //   text: this.$vuetify.lang.t('$vuetify.variants.cant'),
        //   value: 'price',
        //   select_filter: false
        // }
      ]
    }
  },
  data () {
    return {
      loading: false,
      showPlans: false,
      paySelect: null,
      showDialog: false,
      pay: null,
      printer: '',
      tab: null
    }
  },
  async mounted () {
    this.loading = true
    this.formValid = false
    await this.getPlansCountry().catch(() => {
      this.loading = false
    })
    if (this.companySubscription) {
      if (this.companySubscription.plan.type === 'unicorn') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showPlans = false
      } else {
        this.showPlans = true
      }
    } else { this.showPlans = true }
    this.loading = false
  },
  methods: {
    ...mapActions('pay', ['tooglePrintPayModal']),
    ...mapActions('plan', ['getPlansCountry']),
    openPrintModal (print, id) {
      this.printer = print
      this.paySelect = this.getMySubscription.pay_plans.filter((ele) => ele.id === id)[0]
      this.tooglePrintPayModal(true)
    }
  }
}
</script>
