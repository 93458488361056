import { render, staticRenderFns } from "./ArticleShop.vue?vue&type=template&id=ca00a5b2&scoped=true&"
import script from "./ArticleShop.vue?vue&type=script&lang=js&"
export * from "./ArticleShop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca00a5b2",
  null
  
)

export default component.exports