import api from '../config/api'

export default {
  fetchBuyOrders () {
    return api.get('buy-orders')
  },
  fetchSalesFilter (filter) {
    return api.post('buy-orders/filter-sale', filter)
  },
  fetchBuyOrdersData (type) {
    return api.post('buy-orders/data', type)
  },
  changeStateBuy (data) {
    return api.post('buy-orders/change-state', data)
  },
  fetchBuyOrdersReceived (filter) {
    return api.post('buy-orders/received', filter)
  },
  fetchBuyOrdersOnline (filter) {
    return api.post('buy-orders/online', filter)
  },
  sendCreateBuyOrderRequest (buyOrder) {
    return api.post('buy-orders', buyOrder)
  },
  sendUpdateRequest (buyOrder) {
    return api.put('buy-orders/' + buyOrder.id, buyOrder)
  },
  sendDeleteRequest (buyOrderId) {
    return api.remove('buy-orders/' + buyOrderId)
  },
  fetchBuyOrderNumber (idProvider) {
    return api.post('buy-orders/number/facture', idProvider)
  },
  getSupplyStates () {
    return api.post('supply-states')
  }
}
