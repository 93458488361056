import api from '../config/api'

export default {
  fetchMark () {
    return api.get('variant-cost')
  },
  sendCreateRequest (variantCost) {
    return api.post('variant-cost', variantCost)
  },
  sendUpdateOnlyCost (variantCost) {
    return api.post('variant-cost/' + variantCost.id, variantCost)
  },
  sendUpdateRequest (variantCost) {
    return api.put('variant-cost/' + variantCost.id, variantCost)
  },
  sendDeleteRequest (variantCostId) {
    return api.remove('variant-cost/' + variantCostId)
  }
}
