import admin from '../../api/admin'
import localStorage from '../../config/localStorage'
import router from '../../router'

const DASHBOARD_INFO = 'DASHBOARD_INFO'
const FAILED_CATCH = 'FAILED_CATCH'
const ACTION_PROGRESS = 'ACTION_PROGRESS'

const state = {
  dashboardInfo: null,
  isActionInProgress: false
}
const getters = {}

// mutations
const mutations = {
  [DASHBOARD_INFO] (state, info) {
    state.dashboardInfo = info
  },
  [ACTION_PROGRESS] (state, actionProgress) {
    state.isActionInProgress = actionProgress
  },
  [FAILED_CATCH] (state, error) {
    state.pending = false
    state.error = error
    state.isLoggedIn = false
    if (error.status === 401 && error.statusText === 'Unauthorized') {
      localStorage.removeToken()
      localStorage.removePinToken()
      router.push({ name: 'home' })
    } else {
      let msg = this._vm.$language.t('$vuetify.messages.login_failed')
      Object.keys(state.error.data.errors).forEach(v => {
        if (v !== 'message') {
          if (v === 'email') {
            if (state.error.data.errors[v][0] === 'The email has already been taken.') {
              msg = this._vm.$language.t('$vuetify.messages.register_exist_email')
            }
          }
        }
      })
      this._vm.$Toast.fire({
        icon: 'error',
        title: msg
      })
    }
  }
}

// actions
const actions = {
  async getDashboardInfo ({ commit }) {
    commit(ACTION_PROGRESS, true)
    await admin
      .dashboard()
      .then(({ data }) => {
        commit(DASHBOARD_INFO, data.data)
        commit(ACTION_PROGRESS, false)
      })
      .catch(({ response }) => {
        commit(FAILED_CATCH, response)
        commit(ACTION_PROGRESS, false)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
