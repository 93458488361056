import apiBuyOrders from '../../api/buy-order'
import moment from 'moment'
import { fullName } from '../../util'
import buy from '../../api/buy'

const FETCHING_BUY_ORDERS = 'FETCHING_BUY_ORDERS'
const FETCHING_BUY_ORDERS_DATA = 'FETCHING_BUY_ORDERS_DATA'
const FETCHING_BUY_ORDERS_RECEIVED = 'FETCHING_BUY_ORDERS_RECEIVED'
const FETCHING_BUY_ORDERS_BY_CATEGORIES = 'FETCHING_BUY_ORDERS_BY_CATEGORIES'
const FETCHING_BUY_ORDERS_BY_PAYMENT = 'FETCHING_BUY_ORDERS_BY_PAYMENT'
const FETCHING_BUY_ORDERS_BY_PRODUCT = 'FETCHING_BUY_ORDERS_BY_PRODUCT'
const FETCHING_BUY_ORDERS_BY_EMPLOYER = 'FETCHING_BUY_ORDERS_BY_EMPLOYER'
const FETCHING_BUY_ORDERS_BY_LIMIT = 'FETCHING_BUY_ORDERS_BY_LIMIT'
const FETCHING_BUY_ORDERS_NUMBER = 'FETCHING_BUY_ORDERS_NUMBER'
const FETCHING_BUY_ORDERS_STATES = 'FETCHING_BUY_ORDERS_STATES'
const FETCHING_BUY_ORDERS_TOTAL = 'FETCHING_BUY_ORDERS_TOTAL'
const FETCHING_ONLINE_ORDERS = 'FETCHING_ONLINE_ORDERS'
const SWITCH_LOAD_DATA = 'SWITCH_LOAD_DATA'
const SWITCH_BUY_ORDER_NEW_MODAL = 'SWITCH_BUY_ORDER_NEW_MODAL'
const SWITCH_BUY_ORDER_EDIT_MODAL = 'SWITCH_BUY_ORDER_EDIT_MODAL'
const SWITCH_BUY_ORDER_SHOW_MODAL = 'SWITCH_BUY_ORDER_SHOW_MODAL'
const BUY_ORDER_CREATED = 'BUY_ORDER_CREATED'
const BUY_ORDER_EDIT = 'BUY_ORDER_EDIT'
const BUY_ORDER_EDIT_RECEIVED = 'BUY_ORDER_EDIT_RECEIVED'
const BUY_ONLINE_ORDER_EDIT = 'BUY_ONLINE_ORDER_EDIT'
const BUY_ORDER_UPDATED = 'BUY_ORDER_UPDATED'
const BUY_ORDER_DELETE = 'BUY_ORDER_DELETE'
const BUY_ORDER_TABLE_LOADING = 'BUY_ORDER_TABLE_LOADING'
const FAILED_BUY_ORDER = 'FAILED_BUY_ORDER'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_BUY_ORDER = 'SET_EDIT_BUY_ORDER'
const SET_BUY_ORDER_AVATAR = 'SET_BUY_ORDER_AVATAR'
const CANCEL_MODAL = 'CANCEL_MODAL'

const state = {
  showNewModal: false,
  supplyStates: [],
  showEditModal: false,
  showShowModal: false,
  buyOrders: [],
  buyOnline: [],
  received: [],
  avatar: '',
  loadData: false,
  loading: false,
  saved: false,
  managerBuyOrders: false,
  typeOrder: 'emitted',
  newBuyOrder: {
    state: 'preform',
    type: 'in',
    sale: {
      no_facture: '',
      pays: [],
      online: false,
      state: 'preform',
      discounts: [],
      taxes: [],
      payments: [],
      sale_article_shops: [],
      shop: {},
      supplier: {},
      client: {}
    },
    client: {},
    provider: {}
  },
  editBuyOrder: {
    id: '',
    type: 'emitted',
    sale: {
      no_facture: '',
      pays: [],
      online: false,
      state: 'preform',
      discounts: [],
      taxes: [],
      payments: [],
      sale_article_shops: [],
      shop: {},
      supplier: {},
      client: {}
    },
    client: {},
    provider: {}
  },
  isBuyOrdersTableLoading: false,
  isActionInProgress: false,
  isTableLoading: false,
  buyOrdersByCategories: [],
  buyOrdersByPayments: [],
  buyOrdersByProducts: [],
  buyOrdersByEmployer: [],
  buyOrdersByLimit: [],
  buyOrderData: [],
  buyOrdersStatics: [],
  buyOrderNumber: ''
}

const mutations = {
  [SWITCH_BUY_ORDER_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_LOAD_DATA] (state, loadData) {
    state.loadData = loadData
  },
  [SWITCH_BUY_ORDER_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_BUY_ORDER_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [BUY_ORDER_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
  },
  [FETCHING_BUY_ORDERS] (state, buyOrders) {
    state.buyOrders = []
    state.buyOrders = buyOrders
    state.buyOrders.forEach((buyOrder) => {
      // buyOrder.sale.payments = buyOrder.sale.pays
      buyOrder.state.text = this._vm.$language.t('$vuetify.supply_state.state.' + buyOrder.state.name)
      buyOrder.nextState.forEach((state) => {
        state.text = this._vm.$language.t('$vuetify.supply_state.state.' + state.name)
      })
    })
  },
  [FETCHING_ONLINE_ORDERS] (state, buyOnline) {
    state.buyOnline = []
    state.buyOnline = buyOnline
    state.buyOnline.forEach((buyOrder) => {
      // buyOrder.sale.payments = buyOrder.sale.pays
      buyOrder.state.text = this._vm.$language.t('$vuetify.supply_state.state.' + buyOrder.state.name)
      buyOrder.nextState.forEach((state) => {
        state.text = this._vm.$language.t('$vuetify.supply_state.state.' + state.name)
      })
    })
  },
  [FETCHING_BUY_ORDERS_DATA] (state, data) {
    state.buyOrderData = data
  },
  [FETCHING_BUY_ORDERS_RECEIVED] (state, buyOrders) {
    state.received = buyOrders
    state.received.forEach((buyOrder) => {
      buyOrder.state.text = this._vm.$language.t('$vuetify.supply_state.state.' + buyOrder.state.name)
      buyOrder.nextState.forEach((state) => { state.text = this._vm.$language.t('$vuetify.supply_state.state.' + state.name) })
    })
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [CANCEL_MODAL] (state) {
    state.newBuyOrders = {
	  state: {},
      sale: {
        no_facture: '',
        online: false,
        box: null,
        state: 'open',
        pays: [],
        taxes: [],
        discounts: [],
        modifiers: [],
        payments: null,
        sale_article_shops: [],
        shop: null,
        client: null
      },
      client: {},
      provider: {},
      company: {}
    }
    state.saved = false
  },
  [BUY_ORDER_CREATED] (state) {
    state.showNewModal = false
    state.newBuyOrders = {
      state: {},
      sale: {
        no_facture: '',
        online: false,
        box: null,
        state: 'open',
        pays: [],
        taxes: [],
        discounts: [],
        modifiers: [],
        payments: null,
        sale_article_shops: [],
        shop: null,
        client: null
      },
      client: {},
      provider: {},
      company: {}
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.menu.order_productS')
	  ])
    })
  },
  [BUY_ORDER_EDIT] (state, buyOrderId) {
    state.editBuyOrder = Object.assign({}, state.buyOrders.filter(node => node.id === buyOrderId).shift())
  },
  [BUY_ONLINE_ORDER_EDIT] (state, buyOrderId) {
    state.editBuyOrder = Object.assign(
	  {},
	  state.buyOnline.filter(node => node.id === buyOrderId).shift()
    )
  },
  [BUY_ORDER_EDIT_RECEIVED] (state, buyOrderId) {
    state.editBuyOrder = Object.assign(
	  {},
	  state.received.filter(node => node.id === buyOrderId).shift()
    )
  },
  [BUY_ORDER_UPDATED] (state) {
    state.showEditModal = false
    state.editBuyOrder = {
      id: '',
      type: 'in',
      sale: {
        no_facture: '',
        pays: [],
        online: false,
        state: 'preform',
        discounts: [],
        taxes: [],
        payments: [],
        sale_article_shops: [],
        shop: {},
        supplier: {},
        client: {}
      },
      client: {},
      provider: {}
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_up', [
        this._vm.$language.t('$vuetify.menu.order_productS')
	  ])
    })
  },
  [SET_EDIT_BUY_ORDER] (state, profile) {
    state.editBuyOrder.push(profile)
  },
  [BUY_ORDER_DELETE] (state) {
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.menu.order_productS')
	  ])
    })
  },
  [SET_BUY_ORDER_AVATAR] (state, avatar) {
    state.avatar = avatar
    state.saved = true
  },
  [FAILED_BUY_ORDER] (state, error) {
    state.isActionInProgress = false
    state.isBuyOrdersTableLoading = false
    state.isTableLoading = false
    state.saved = false
    state.error = error
    this._vm.$Toast.fire({
	  icon: 'error',
	  title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.order_productS')
	  ])
    })
  },
  [FETCHING_BUY_ORDERS_BY_CATEGORIES] (state, buyOrderByCategory) {
    state.buyOrdersByCategories = buyOrderByCategory
  },
  [FETCHING_BUY_ORDERS_BY_PAYMENT] (state, buyOrdersByPayment) {
    state.buyOrdersByPayments = buyOrdersByPayment
  },
  [FETCHING_BUY_ORDERS_BY_PRODUCT] (state, buyOrdersByProduct) {
    state.buyOrdersByProducts = buyOrdersByProduct
  },
  [FETCHING_BUY_ORDERS_BY_EMPLOYER] (state, buyOrdersByEmployer) {
    state.buyOrdersByEmployer = buyOrdersByEmployer
  },
  [FETCHING_BUY_ORDERS_NUMBER] (state, buyOrderNumber) {
    state.buyOrderNumber = buyOrderNumber
  },
  [FETCHING_BUY_ORDERS_STATES] (state, supplyStates) {
    state.supplyStates = supplyStates
  },
  [FETCHING_BUY_ORDERS_BY_LIMIT] (state, buyOrdersByLimit) {
    buyOrdersByLimit.map(value => {
	  switch (value.state) {
	  case 'open':
          value.status = this._vm.$language.t(
		  '$vuetify.buyOrder.state.open'
          )
          value.color = 'green'
          break
	  case 'accepted':
          value.status = this._vm.$language.t(
		  '$vuetify.buyOrder.state.accepted'
          )
          value.color = 'blue'
          break
	  case 'cancelled':
          value.status = this._vm.$language.t(
		  '$vuetify.buyOrder.state.cancelled'
          )
          value.color = 'red'
          break
	  }
	  value.timeString = moment(value.created_at).fromNow()
	  const createdName = fullName(
        value.created.firstName,
        value.created.lastName
	  )
	  const clientName = value.client
        ? fullName(value.client.firstName, value.client.lastName)
        : ''
	  value.text =
		createdName +
		' ' +
		this._vm.$language.t('$vuetify.dashboard.timeLineText') +
		' ' +
		clientName
    })
    state.buyOrdersByLimit = buyOrdersByLimit
  },
  [FETCHING_BUY_ORDERS_TOTAL] (state, buyOrdersStatics) {
    state.buyOrdersStatics = buyOrdersStatics
  }
}

const getters = {
  getNumberFacture: state => {
    return state.buyOrderNumber
  },
  getBuyOrderData: state => {
    return state.buyOrderData
  }
}

const actions = {
  async multiDelete ({ commit, dispatch, state }, selected) {
    await apiBuyOrders
      .sendMultiDeleteRequest(selected)
      .then(data => {
        commit(BUY_ORDER_DELETE)
        dispatch('buyOrder/getBuyOrdersReceived', null, { root: true })
        dispatch('buyOrder/getBuyOrders', null, { root: true })
      })
      .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_BUY_ORDER_NEW_MODAL, showModal)
    if (!showModal) {
	  commit(CANCEL_MODAL)
    }
  },
  switchLoadData ({ commit }, loadData) {
    commit(SWITCH_LOAD_DATA, loadData)
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_BUY_ORDER_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_BUY_ORDER_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, buyOrderId) {
    commit(BUY_ORDER_EDIT, buyOrderId)
  },
  openEditOnlineSupply ({ commit }, buyOrderId) {
    commit(BUY_ONLINE_ORDER_EDIT, buyOrderId)
  },
  openEditReceivedModal ({ commit }, buyOrderId) {
    commit(BUY_ORDER_EDIT_RECEIVED, buyOrderId)
  },
  async changeStateBuy ({ commit, dispatch }, update) {
    commit(ENV_DATA_PROCESS, true)

    // const request = profile || state.editUser
    await apiBuyOrders
      .changeStateBuy(update)
      .then(data => {
        commit(BUY_ORDER_UPDATED)
        commit(ENV_DATA_PROCESS, false)
      })
      .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_BUY_ORDER, error)
      })
  },
  openShowModal ({ commit }, buyOrderId) {
    commit(SWITCH_BUY_ORDER_SHOW_MODAL, true)
    commit(BUY_ORDER_EDIT, buyOrderId)
  },
  async getBuyOrders ({ commit, dispatch }) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
	  .fetchBuyOrders()
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async getBuyOrdersFilter ({ commit, dispatch }, filter) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
      .fetchSalesFilter(filter)
      .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
      })
      .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async fetchBuyOrderData ({ commit, dispatch }, type) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
      .fetchBuyOrdersData(type)
      .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_DATA, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
      })
      .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async getBuyOrdersReceived ({ commit, dispatch }, filter) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
	  .fetchBuyOrdersReceived()
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_RECEIVED, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async getBuyOrdersOnline ({ commit, dispatch }, filter) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
	  .fetchBuyOrdersOnline()
	  .then(({ data }) => {
        commit(FETCHING_ONLINE_ORDERS, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async getBuyOrdersByCategories ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
	  .fetchBuyOrdersByCategory(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_BY_CATEGORIES, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async getBuyOrdersByPayment ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
	  .fetchBuyOrdersByPayment(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_BY_PAYMENT, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async getBuyOrdersByProduct ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
	  .fetchBuyOrdersByProduct(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_BY_PRODUCT, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async getBuyOrdersByEmployer ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
	  .fetchBuyOrdersByEmployer(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_BY_EMPLOYER, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async getBuyOrdersByLimit ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
	  .fetchBuyOrdersByLimit(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_BY_LIMIT, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => {
        commit(FAILED_BUY_ORDER, error)
	  })
  },
  async getBuyOrdersStatics ({
    commit,
    dispatch
  }) {
    commit(BUY_ORDER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiBuyOrders
	  .fetchBuyOrdersStatics()
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_TOTAL, data.data)
        commit(BUY_ORDER_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async createBuyOrders ({
    commit,
    dispatch
  }, newBuyOrders) {
    commit(ENV_DATA_PROCESS, true)
    await apiBuyOrders
	  .sendCreateRequest(newBuyOrders)
	  .then(data => {
        commit(BUY_ORDER_CREATED)
        commit(ENV_DATA_PROCESS, false)
        newBuyOrders.type === 'emitted' ? dispatch('buyOrder/getBuyOrdersFilter', null, { root: true }) : dispatch('buyOrder/getBuyOrdersReceived', null, { root: true })
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async createBuyOrder ({ commit, dispatch }, newBuyOrders) {
    commit(ENV_DATA_PROCESS, true)
    await apiBuyOrders
	  .sendCreateBuyOrderRequest(newBuyOrders)
	  .then(data => {
        commit(BUY_ORDER_CREATED)
        commit(ENV_DATA_PROCESS, false)
        newBuyOrders.type === 'emitted' ? dispatch('buyOrder/getBuyOrdersFilter', null, { root: true }) : dispatch('buyOrder/getBuyOrdersReceived', null, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async updateBuyOrders ({
    commit,
    dispatch
  }, buyOrderE) {
    commit(ENV_DATA_PROCESS, true)
    const request = buyOrderE || state.editBuyOrder

    // const request = profile || state.editUser
    await apiBuyOrders
	  .sendUpdateRequest(request)
	  .then(data => {
        commit(BUY_ORDER_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('buyOrder/getBuyOrdersFilter', null, { root: true })
        dispatch('buyOrder/getBuyOrdersReceived', null, { root: true })
        dispatch('buyOrder/getBuyOrdersOnline', null, { root: true })
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_BUY_ORDER, error)
	  })
  },
  async deleteBuyOrders ({
    commit,
    dispatch,
    state
  }, buyOrderId) {
    await apiBuyOrders
	  .sendDeleteRequest(buyOrderId)
	  .then(data => {
        commit(BUY_ORDER_DELETE)
        dispatch('buyOrder/getBuyOrdersFilter', null, { root: true })
        dispatch('buyOrder/getBuyOrdersReceived', null, { root: true })
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async fetchBuyOrdersNumber ({ commit, dispatch }, idProvider) {
    await apiBuyOrders
	  .fetchBuyOrderNumber(idProvider)
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_NUMBER, data.data)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  },
  async getSupplyStates ({ commit, dispatch }) {
    await apiBuyOrders
	  .getSupplyStates()
	  .then(({ data }) => {
        commit(FETCHING_BUY_ORDERS_STATES, data.data)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY_ORDER, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
