import api from '../config/api'

export default {
  fetchSales () {
    return api.get('sale')
  },
  fetchEditSale (id) {
    return api.get('sale/' + id)
  },
  getNewSaleData () {
    return api.post('sale/new-sale-data')
  },
  fetchOnlineSales () {
    return api.post('sale/online-sale')
  },
  fetchCreditSalesToPay (filter) {
    return api.post('sale/credits-sales', filter)
  },
  fetchSalesFilter (filter) {
    return api.post('sale/filter-sale', filter)
  },
  fetchSaleFilterRange (filter) {
    return api.post('sale/range/filter-sale', filter)
  },
  fetchSaleFilterDays (filter) {
    return api.post('sale/days/filter-sale', filter)
  },
  fetchSaleFilterMonths (filter) {
    return api.post('sale/months/filter-sale', filter)
  },
  fetchSaleFilterYears (filter) {
    return api.post('sale/years/filter-sale', filter)
  },
  fetchSalesAcceptedFilter (filter) {
    return api.post('sale-accepted/filter-sale', filter)
  },
  sendCreateRequest (sale) {
    return api.post('sale', sale)
  },
  changeStateSale (data) {
    return api.post('sale/change/state', data)
  },
  sendUpdateRequest (sale) {
    return api.put('sale/' + sale.id, sale)
  },
  sendDeleteRequest (saleId) {
    return api.remove('sale/' + saleId)
  },
  fetchSaleByCategory (filter) {
    return api.post('sale/category', filter)
  },
  getValidCant (filter) {
    return api.post('sale/valid-cant', filter)
  },
  sendMultiDeleteRequest (selected) {
    return api.post('sale/multi-delete', selected)
  },
  fetchSaleByPayment (filter) {
    return api.post('sale/payment', filter)
  },
  fetchSaleByTypeOrder (filter) {
    return api.post('sale/order', filter)
  },
  fetchSaleByMark (filter) {
    return api.post('sale/mark', filter)
  },
  fetchSaleByProduct (filter) {
    return api.post('sale/product', filter)
  },
  fetchSaleByEmployer (filter) {
    return api.post('sale/employer', filter)
  },
  fetchSaleSummary (filter) {
    return api.post('sale/summary', filter)
  },
  fetchSaleByLimit (filter) {
    return api.get('sale/by_limit/' + filter)
  },
  fetchSaleStatics (filter) {
    return api.post('sale/sales/static', filter)
  },
  fetchSaleNumber (info) {
    return api.post('sale/number/facture', info)
  }
}
