import api from '../config/api'

export default {
  fetchBills () {
    return api.get('bill')
  },
  getBillsFilter (filter) {
    return api.post('bills-filter', filter)
  },
  sendCreateRequest (bill) {
    return api.post('bill', bill)
  },
  sendSaveBillValue (bill) {
    return api.post('bill/value', bill)
  },
  sendUpdateRequest (bill) {
    return api.put('bill/' + bill.id, bill)
  },
  sendDeleteRequest (billId) {
    return api.remove('bill/' + billId)
  }
}
