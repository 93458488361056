<template>
  <v-app
    id="inspire"
    class="app dashboard"
  >
    <!-- Page Drawer -->
    <app-drawer v-if="!isPartnerIn"
      :show-drawer="showDrawer"
      class="app--drawer"
    />
    <!-- End Page Drawer -->
    <!-- Page Header -->
    <app-toolbar
      class="app--toolbar"
      @side-icon-click="handleDrawerVisiable"
      @show-help-dialog="handleShowHelp"
    />
    <!--End Page Header -->
    <v-main>
      <div class="page-wrapper">
        <help-doc v-if="showHelp"/>
        <fast-config v-if="showFastConfig"/>
        <router-view/>
      </div>
      <!-- App Footer -->
      <v-footer
        class="pa-3 app--footer"
        height="auto"
      >
        <span>&copy; {{ new Date().getFullYear() }} INNELI Team - BILLING MONEY APP</span>
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <app-fab />
  </v-app>
</template>

<script>
import AppDrawer from '../AppDrawer'
import AppToolbar from '../AppToolbar'
import AppFab from '../AppFab'
import { mapActions, mapGetters, mapState } from 'vuex'
// import Help from '../../views/help/Help'
import FastConfig from '../../views/FastConfig'
import HelpDoc from '../../views/help/HelpDoc'
// import MySubscriptionDefault from '../../views/subscriptions/MySubscription'

export default {
  name: 'LayoutDefault',
  components: {
    // MySubscriptionDefault,
    HelpDoc,
    FastConfig,
    // Help,
    AppDrawer,
    AppToolbar,
    AppFab
  },
  data () {
    return {
      showSelectedSubscriptions: false,
      showDrawer: true
    }
  },
  computed: {
    ...mapState('auth', ['showHelp']),
    ...mapState('settings', ['showFastConfig']),
    ...mapGetters('auth', ['isAdminIn', 'companySubscription', 'isPartnerIn']),
    ...mapState('plan', ['plans', 'showSelectSubscription', 'isActionInProgress']),
    showSubscription () {
      return !this.companySubscription
    }
  },
  watch: {
    '$route.name': function () {
      if (this.$route.name === 'vending_new' || this.$route.name === 'vending_edit' || this.$route.name === 'setting' ||
        this.$route.name === 'product_list' || this.$route.name === 'dashboard' || this.$route.name === 'service_list' ||
        this.$route.name === 'expire_list' || this.$route.name === 'vending' || this.$route.name === 'vending_acepted' ||
        this.$route.name === 'vending_process' || this.$route.name === 'vending_cancelled' || this.$route.name === 'product_under_inventory' ||
        this.$route.name === 'buy_add' || this.$route.name === 'buy_products' || this.$route.name === 'buy_products') {
        this.showDrawer = false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['setShowHelp']),
    handleDrawerVisiable () {
      this.showDrawer = !this.showDrawer
    },
    handleShowHelp () {
      this.setShowHelp(true)
    }
  }
}
</script>

<style lang="sass" scoped>
.page-wrapper
  min-height: calc(100vh - 112px - 48px)
</style>
