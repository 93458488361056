import beneficiary from '../../api/beneficiary'
import data from '../../data'
import partner from '../../api/partner'

const FETCHING_BENEFICIARIES = 'FETCHING_BENEFICIARIES'
const SWITCH_BENEFICIARY_NEW_MODAL = 'SWITCH_BENEFICIARY_NEW_MODAL'
const SWITCH_BENEFICIARY_EDIT_MODAL = 'SWITCH_BENEFICIARY_EDIT_MODAL'
const SWITCH_BENEFICIARY_SHOW_MODAL = 'SWITCH_BENEFICIARY_SHOW_MODAL'
const BENEFICIARY_CREATED = 'BENEFICIARY_CREATED'
const BENEFICIARY_EDIT = 'BENEFICIARY_EDIT'
const BENEFICIARY_UPDATED = 'BENEFICIARY_UPDATED'
const BENEFICIARY_DELETE = 'BENEFICIARY_DELETE'
const BENEFICIARY_TABLE_LOADING = 'BENEFICIARY_TABLE_LOADING'
const FAILED_BENEFICIARY = 'FAILED_BENEFICIARY'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_BENEFICIARY = 'SET_EDIT_BENEFICIARY'
const SET_BENEFICIARY_AVATAR = 'SET_BENEFICIARY_AVATAR'
const CANCEL_MODAL = 'CANCEL_MODAL'

const state = {
  showNewModal: false,
  showEditModal: false,
  showShowModal: false,
  beneficiaries: [],
  avatar: '',
  loading: false,
  saved: false,
  newBeneficiary: {
    first_name: '',
    last_name: '',
    email: '',
    rnc: '',
    city: '',
    phone: '',
    avatar: '',
    country: '',
    province: '',
    barCode: '',
    description: '',
    limit_affiliate_beneficiary: 1000,
    limit_affiliate: 100,
    isPartner: false
  },
  editBeneficiary: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    rnc: '',
    city: '',
    phone: '',
    avatar: '',
    country: '',
    province: '',
    barCode: '',
    description: '',
    isPartner: false
  },
  isBeneficiaryTableLoading: false,
  isActionInProgress: false,
  isTableLoading: false
}

const mutations = {
  [SWITCH_BENEFICIARY_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_BENEFICIARY_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_BENEFICIARY_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [BENEFICIARY_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
  },
  [FETCHING_BENEFICIARIES] (state, beneficiaries) {
    beneficiaries.map((value) => {
	  Object.keys(data.countries).map((key) => {
        if (key === value.country) {
		  value.nameCountry = data.countries[key].name + '(' + data.countries[key].native + ')'
		  value.countryFlag = data.countries[key].emoji
        }
	  })
    })

    state.beneficiaries = beneficiaries
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [CANCEL_MODAL] (state) {
    state.newBeneficiary = {
	  first_name: '',
	  last_name: '',
      email: '',
      rnc: '',
	  city: '',
	  phone: '',
	  avatar: '',
	  country: '',
	  province: '',
	  barCode: '',
	  description: '',
      isPartner: false
    }
    state.saved = true
  },
  [BENEFICIARY_CREATED] (state) {
    state.showNewModal = false
    state.newBeneficiary = {
	  first_name: '',
	  last_name: '',
      email: '',
      rnc: '',
	  city: '',
	  phone: '',
	  avatar: '',
	  country: '',
	  province: '',
	  barCode: '',
	  description: '',
      isPartner: false
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t(
        '$vuetify.messages.success_add', [this._vm.$language.t('$vuetify.menu.admin_beneficiary')]
	  )
    })
  },
  [BENEFICIARY_EDIT] (state, beneficiaryId) {
    state.editBeneficiary = Object.assign({}, state.beneficiaries
	  .filter(node => node.id === beneficiaryId)
	  .shift()
    )
  },
  [BENEFICIARY_UPDATED] (state) {
    state.showEditModal = false
    state.editBeneficiary = {
	  id: '',
	  first_name: '',
	  last_name: '',
      email: '',
      rnc: '',
	  city: '',
	  phone: '',
	  avatar: '',
	  country: '',
	  province: '',
	  barCode: '',
	  description: ''
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t(
        '$vuetify.messages.success_up', [this._vm.$language.t('$vuetify.menu.admin_beneficiary')]
	  )
    })
  },
  [SET_EDIT_BENEFICIARY] (state, profile) {
    state.editBeneficiary.push(profile)
  },
  [BENEFICIARY_DELETE] (state) {
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t(
        '$vuetify.messages.success_del', [this._vm.$language.t('$vuetify.menu.admin_beneficiary')]
	  )
    })
  },
  [SET_BENEFICIARY_AVATAR] (state, avatar) {
    state.avatar = avatar
    state.saved = true
  },
  [FAILED_BENEFICIARY] (state, error) {
    state.saved = false
    state.error = error
    state.isBeneficiaryTableLoading = false
    state.isActionInProgress = false
    state.isTableLoading = false
    let msg = this._vm.$language.t(
      '$vuetify.messages.failed_catch', [this._vm.$language.t('$vuetify.menu.admin_beneficiary')]
    )
    if (error.status !== 401) {
      Object.keys(state.error.data.errors).forEach(v => {
        if (v !== 'message') {
          if (v === 'email') {
            if (state.error.data.errors[v][0] === 'The email has already been taken.'
            ) {
              msg = this._vm.$language.t('$vuetify.messages.register_exist_email'
              )
            }
          }
        }
      })
    }
    this._vm.$Toast.fire({
      icon: 'error',
      title: msg
    })
  }
}

const getters = {}

const actions = {
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_BENEFICIARY_NEW_MODAL, showModal)
    if (!showModal) {
	  commit(CANCEL_MODAL)
    }
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_BENEFICIARY_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_BENEFICIARY_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, beneficiaryId) {
    commit(SWITCH_BENEFICIARY_EDIT_MODAL, true)
    commit(BENEFICIARY_EDIT, beneficiaryId)
  },
  openShowModal ({ commit }, beneficiaryId) {
    commit(SWITCH_BENEFICIARY_SHOW_MODAL, true)
    commit(BENEFICIARY_EDIT, beneficiaryId)
  },
  async getBeneficiariesAdmin ({ commit }) {
    commit(BENEFICIARY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await beneficiary.adminFetchBeneficiary()
	  .then(({ data }) => {
        commit(FETCHING_BENEFICIARIES, data.data)
        commit(BENEFICIARY_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
	  }).catch(({ response }) => commit(FAILED_BENEFICIARY, response))
  },
  async getPlansByBeneficiary ({ commit }) {
    commit(BENEFICIARY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await beneficiary.adminFetchBeneficiary()
	  .then(({ data }) => {
        commit(FETCHING_BENEFICIARIES, data.data)
        commit(BENEFICIARY_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
	  }).catch(({ response }) => commit(FAILED_BENEFICIARY, response))
  },
  async createBeneficiary ({ commit, dispatch }, newBeneficiary) {
    commit(ENV_DATA_PROCESS, true)

    await beneficiary
	  .sendCreateRequest(newBeneficiary)
	  .then((data) => {
        commit(BENEFICIARY_CREATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('beneficiary/getBeneficiariesAdmin', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(({ response }) => commit(FAILED_BENEFICIARY, response))
  },
  async updateBeneficiary ({ commit, dispatch }, editBeneficiary) {
    await beneficiary
	  .sendUpdateRequest(editBeneficiary)
	  .then((data) => {
        commit(BENEFICIARY_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('beneficiary/getBeneficiariesAdmin', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(({ response }) => commit(FAILED_BENEFICIARY, response))
  },
  async deleteBeneficiary ({
    commit,
    dispatch
  }, beneficiaryId) {
    await beneficiary
	  .sendDeleteRequest(beneficiaryId)
	  .then((data) => {
        commit(BENEFICIARY_DELETE)
        dispatch('beneficiary/getBeneficiariesAdmin', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(({ response }) => commit(FAILED_BENEFICIARY, response))
  },

  async updateAvatar ({
    commit,
    dispatch
  }, file) {
    const image = `data:${file.file.type};base64,${file.file.base64}`
    const sendData = {
	  id: file.id,
	  image: image
    }
    await beneficiary.updateAvatar(sendData).then((data) => {
	  commit(SET_BENEFICIARY_AVATAR, file.file.base64)
	  dispatch('auth/getBeneficiaryData', null, { root: true })
	  this.dispatch('auth/updateAccess', data.access)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
