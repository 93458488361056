<template>
  <v-container>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
      >
        <new-mark v-if="showNewModal" />
        <edit-mark v-if="showEditModal" />
        <app-data-table
          :title="
            $vuetify.lang.t('$vuetify.menu.marks_list')
          "
          :select-data="selectData"
          :headers="getTableColumns"
          csv-filename="MarksProducts"
          :items="marks"
          :manager="'mark'"
          @create-row="toogleNewModal(true)"
          @edit-row="editMarkHandler($event)"
          @delete-row="deleteMarkHandler($event)"
        >
          <template v-slot:item.color="{ item }">
            <v-avatar
              style="margin-left: 0px"
              :color="item.color"
            />
          </template>
          <template v-slot:item.avatar="{ item }">

            <v-avatar
              style="margin-left: 0px"
              :color="(item.color && item.avatar )? item.color : ''"
            >
              <v-img
                v-if="item.avatar"
                :src="item.avatar.original_url"
              />
            </v-avatar>
          </template>
        </app-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NewMark from './NewMark'
import EditMark from './EditMark'

export default {
  name: 'ListMark',

  components: {
    EditMark,
    NewMark
  },
  data () {
    return {
      search: '',
      selectData: [],
      loadingData: false,
      menu: false,
      vBindOption: {
        itemKey: 'name',
        singleExpand: false,
        showExpand: true
      }
    }
  },
  computed: {
    ...mapState('mark', [
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'marks',
      'isTableLoading'
    ]),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.marks.avatar'),
          value: 'avatar'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  async created () {
    await this.getMarks()
  },
  methods: {
    ...mapActions('mark', [
      'toogleNewModal',
      'openEditModal',
      'openShowModal',
      'getMarks',
      'deleteMark',
      'getMarksFilter'
    ]),
    editMarkHandler ($event) {
      this.openEditModal($event)
    },
    deleteMarkHandler (markId) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.marks')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deleteMark(markId)
        })
    }
  }
}
</script>

<style scoped></style>
