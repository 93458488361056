import partner from '../../api/partner'
import localStorage from '../../config/localStorage'

const FETCHING_PARTNERS = 'FETCHING_PARTNERS'
const FETCHING_PARTNER = 'FETCHING_PARTNER'
const SWITCH_PARTNER_NEW_MODAL = 'SWITCH_PARTNER_NEW_MODAL'
const SWITCH_PARTNER_EDIT_MODAL = 'SWITCH_PARTNER_EDIT_MODAL'
const SWITCH_PARTNER_SHOW_MODAL = 'SWITCH_PARTNER_SHOW_MODAL'
const PARTNER_CREATED = 'PARTNER_CREATED'
const PARTNER_EDIT = 'PARTNER_EDIT'
const PARTNER_UPDATED = 'PARTNER_UPDATED'
const PARTNER_DELETE = 'PARTNER_DELETE'
const PARTNER_TABLE_LOADING = 'PARTNER_TABLE_LOADING'
const FAILED_PARTNER = 'FAILED_PARTNER'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_PARTNER = 'SET_EDIT_PARTNER'
const SET_PARTNER_AVATAR = 'SET_PARTNER_AVATAR'
const CANCEL_MODAL = 'CANCEL_MODAL'

const state = {
  showNewModal: false,
  showEditModal: false,
  showShowModal: false,
  partners: [],
  avatar: '',
  loading: false,
  saved: false,
  newPartner: {
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    avatar: '',
    pinCode: '',
    phone: '',
    position: [],
    shops: []
  },
  editPartner: {
    id: '',
    avatar: '/assets/avatar/avatar-undefined.jpg',
    email: '',
    phone: null,
    state: 'solicited',
    description_inactive: '',
    name: '',
    last_name: '',
    address: '',
    date_born: '',
    level_student: 'bachiller',
    sex: '',
    city: '',
    country: ''
  },
  isPartnerTableLoading: false,
  isActionInProgress: false,
  isTableLoading: false
}

const mutations = {
  [SWITCH_PARTNER_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_PARTNER_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_PARTNER_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [PARTNER_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
  },
  [FETCHING_PARTNERS] (state, partners) {
    state.partners = partners
  },
  [FETCHING_PARTNER] (state, partner) {
    state.partnerLogin = partner
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [CANCEL_MODAL] (state) {
    state.newPartner = {
	  firstName: '',
	  lastName: '',
	  email: '',
	  country: '',
	  phone: '',
	  password: '',
	  avatar: '',
	  position: [],
	  position_id: '',
	  shops: []
    }
    state.saved = false
  },
  [PARTNER_CREATED] (state) {
    state.showNewModal = false
    state.newPartner = {
	  firstName: '',
	  lastName: '',
	  email: '',
	  country: '',
	  phone: '',
	  password: '',
	  avatar: '',
	  position: [],
	  position_id: '',
	  shops: []
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.menu.affiliate')
	  ])
    })
  },
  [PARTNER_EDIT] (state, partnerId) {
    state.editPartner = Object.assign(
	  {},
	  state.partners.filter(node => node.id === partnerId).shift()
    )
  },
  [PARTNER_UPDATED] (state, data) {
    state.showEditModal = false
    state.editPartner = {
	  id: '',
	  firstName: '',
	  lastName: '',
	  email: '',
	  password: '',
	  phone: '',
	  country: '',
	  avatar: '',
	  position: [],
	  positions_id: '',
	  shops: []
    }
    state.partner = data
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_up', [
        this._vm.$language.t('$vuetify.menu.affiliate')
	  ])
    })
  },
  [SET_EDIT_PARTNER] (state, profile) {
    state.editPartner.push(profile)
  },
  [PARTNER_DELETE] (state) {
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.menu.affiliate')
	  ])
    })
  },
  [SET_PARTNER_AVATAR] (state, avatar) {
    state.avatar = avatar
    state.saved = true
  },
  [FAILED_PARTNER] (state, error) {
    state.saved = false
    state.error = error
    state.isPartnerTableLoading = false
    state.isActionInProgress = false
    state.isTableLoading = false
    this._vm.$Toast.fire({
	  icon: 'error',
	  title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.affiliate')
	  ])
    })
  }
}
const getters = {}
const actions = {
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_PARTNER_NEW_MODAL, showModal)
    if (!showModal) {
	  commit(CANCEL_MODAL)
    }
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_PARTNER_EDIT_MODAL, showModal)
  },
  updateSyncAccount (data) {
    partner.updatePartnerData(data)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_PARTNER_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit, dispatch, state }, partnerId) {
    commit(SWITCH_PARTNER_EDIT_MODAL, true)
    commit(PARTNER_EDIT, partnerId)
    dispatch('auth/setPartnerData', state.editPartner, { root: true })
  },
  openShowModal ({ commit }, partnerId) {
    commit(SWITCH_PARTNER_SHOW_MODAL, true)
    commit(PARTNER_EDIT, partnerId)
  },
  async getPartners ({ commit }, data) {
    commit(PARTNER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await partner
	  .fetchPartners(data)
	  .then(({ data }) => {
        commit(FETCHING_PARTNERS, data.data)
        commit(PARTNER_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data.access)
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_PARTNER, error)
	  })
  },
  async getPartnerLogin ({ commit }) {
    commit(PARTNER_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await partner
	  .fetchPartner()
	  .then(({ data }) => {
        commit(FETCHING_PARTNER, data.data)
        commit(PARTNER_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_PARTNER, error)
	  })
  },
  async createPartner ({ commit, dispatch }, newPartner) {
    commit(ENV_DATA_PROCESS, true)

    await partner
	  .sendCreateRequest(newPartner)
	  .then(data => {
        commit(PARTNER_CREATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('partner/getPartners', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_PARTNER, error)
	  })
  },
  async updatePartner ({ commit, dispatch }, profile) {
    commit(ENV_DATA_PROCESS, true)
    await partner
	  .sendUpdateRequest(profile)
	  .then(data => {
        commit(PARTNER_UPDATED, data.data)
        commit(ENV_DATA_PROCESS, false)
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_PARTNER, error)
	  })
  },
  async partnerChangeState ({ commit, dispatch }, data) {
    commit(ENV_DATA_PROCESS, true)
    await partner
	  .partnerChangeState(data)
	  .then(data => {
        commit(PARTNER_UPDATED, data.data)
        commit(ENV_DATA_PROCESS, false)
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_PARTNER, error)
	  })
  },
  async deletePartner ({ commit, dispatch }, partnerId) {
    await partner
	  .sendDeleteRequest(partnerId)
	  .then(data => {
        commit(PARTNER_DELETE)
        dispatch('user/getPartners', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_PARTNER, error))
  },
  async updateAvatar ({ commit, dispatch }, file) {
    const image = `data:${file.file.type};base64,${file.file.base64}`
    await partner.updateAvatar(file.id, {
      // id: file.id,
      image: image
    }).then(data => {
	  commit(SET_PARTNER_AVATAR, file.file.base64)
      if (localStorage.getTypeConnection() === 'partner') {
        this.dispatch('auth/updatePartnerAvatar', data.data)
      }
	  this.dispatch('auth/updateAccess', data.access)
    })
    if (localStorage.getTypeConnection() !== 'partner') {
      dispatch('auth/getPartnerData', null, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
