<template>
  <v-container>
    <app-loading v-if="loading"/>
    <app-data-table v-else
                    :title=getTitle
                    :headers="getColumnHeader"
                    :options="vBindOption"
                    :viewShowFilterDate="typeData === 'expire'"
                    :second-date-data="new Date(new Date().getTime() + 31 * 24 * 60 * 60 * 1000)"
                    @reload-data="reloadData($event)"
                    :viewNewButton="typeData !== 'expire'"
                    @create-row="createArticleHandler"
                    @edit-row="editArticleHandler($event)"
                    @multi-select="multiDeleteSaleHandler($event)"
                    @delete-row="deleteArticleHandler($event)"
                    :manager="typeData === 'service' ? 'service': 'article'"
                    :items="localData"
    >
      <template v-slot:item.name="{ item }">
        <v-tooltip v-if="item.description" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              pill
              style="padding-left: 0px; margin: 1px"
            >
              <v-avatar
                style="margin-left: 0px"
                :color="(item.color && item.images.length === 0 )? item.color : ''"
              >
                <v-img
                  v-if="item.images.length > 0"
                  :src="item.images[0].original_url"
                />
              </v-avatar>
              <v-edit-dialog
                :return-value.sync="item.name"
                large
                persistent
                :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                @save="updateArticleHandler(item, 'name')"
              >
                <div>
                  {{ item.name }}
                </div>
                <template v-slot:input>
                  <div class="mt-4 title">
                    {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                  </div>
                  <v-text-field
                    v-model="item.name"
                    :label="$vuetify.lang.t('$vuetify.firstName')"
                    required
                    :rules="formRule.required"
                  />
                </template>
              </v-edit-dialog>
            </v-chip>
          </template>
          <p>{{ item.description }}</p>
        </v-tooltip>
        <v-chip
          v-else
          pill
          style="padding-left: 0px; margin: 1px"
        >
          <v-edit-dialog
            :return-value.sync="item.name"
            large
            persistent
            :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
            :save-text="$vuetify.lang.t('$vuetify.actions.save')"
            @save="updateArticleHandler(item, 'name')"
          >
            <div>
              <v-avatar
                style="margin-left: 0px"
                :color="(item.color && item.images.length === 0 )? item.color : ''"
              >
                <v-img
                  v-if="item.images.length > 0"
                  :src="item.images[0].original_url"
                />
              </v-avatar>
              <b style="margin-left: 5px">{{ item.name }}</b>
            </div>
            <template v-slot:input>
              <div class="mt-4 title">
                {{ $vuetify.lang.t("$vuetify.actions.edit") }}
              </div>
              <v-text-field
                v-model="item.name"
                :label="$vuetify.lang.t('$vuetify.firstName')"
                required
                :rules="formRule.required"
              />
            </template>
          </v-edit-dialog>
        </v-chip>
      </template>
      <template v-slot:item.taxes="{ item }">
        <template v-if="item.variant_values.length === 0 && item.taxes.length > 0">
          <template v-for="(tax, i) of item.taxes">
            <v-tooltip bottom :key="i">
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on">
                  <span>{{ tax.name_complete }} </span>
                </v-chip>
              </template>
              <i>{{ $vuetify.lang.t(tax.included ? '$vuetify.tax.include_tax' : '$vuetify.tax.added_tax') }}</i>
            </v-tooltip>
          </template>
        </template>
        <template v-else>
          ---
        </template>
      </template>
      <template v-slot:item.category_text="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                      {{ item.category_text }}
                  </span>
          </template>
          <span v-if="item.category_id">{{ item.category_text }}</span>
        </v-tooltip>
        <!--v-progress-linear v-else
          indeterminate
          color="yellow darken-2"
        ></v-progress-linear-->
      </template>
      <template v-slot:item.cost="{ item }">
        <template v-if="item.variant_values.length === 0">
          <v-edit-dialog
            v-if="access_permit.article.actions.edit"
            :return-value.sync="item.cost"
            large
            persistent
            :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
            :save-text="$vuetify.lang.t('$vuetify.actions.save')"
            @save="updateArticleHandler(item, 'cost')"
          >
            <div v-if="parseFloat(item.cost).toFixed(2) === '0.00'" style="color: #ef700e">
              <v-icon
                v-if="access_permit.article.actions.edit"
                class="mr-2"
                color="warning"
                small
              >
                mdi-pencil
              </v-icon>
              {{ `${parseFloat(item.cost).toFixed(2)}` + ' ' + getCurrency }}
            </div>
            <div v-else>
              <v-icon
                v-if="access_permit.article.actions.edit"
                class="mr-2"
                color="warning"
                small
              >
                mdi-pencil
              </v-icon>
              {{ `${parseFloat(item.cost).toFixed(2)}` + ' ' + getCurrency }}
            </div>
            <template v-slot:input>
              <div class="mt-4 title">
                {{ $vuetify.lang.t("$vuetify.actions.edit") }}
              </div>
              <v-text-field-money
                v-model="item.cost"
                :label="$vuetify.lang.t('$vuetify.actions.edit')"
                required
                :properties="{
                prefix: getCurrency,
                clearable: true
              }"
                :options="{
                length: 15,
                precision: 2,
                empty: 0.0
              }"
              />
            </template>
          </v-edit-dialog>
          <span v-else>{{ `${parseFloat(item.cost).toFixed(2)}` + ' ' + getCurrency }}</span>
        </template>
        <template v-else>
          {{ '---' }}
        </template>
      </template>
      <template v-slot:item.price="{ item }">
        <template v-if="item.variant_values.length === 0">
          {{ `${item.price + " " + getCurrency}` }}
        </template>
        <template v-else>
          {{ '---' }}
        </template>
      </template>
      <template v-slot:item.inventory="{ item }">
        {{ parseFloat(item.inventory).toFixed(2) }} {{ item.um ? item.um.name : '' }}
      </template>
      <template v-slot:item.percent="{ item }">
        <template v-if="item.variant_values.length === 0">
          {{ item.percent }}
        </template>
        <template v-else>
          {{ '---' }}
        </template>
      </template>
      <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
        <v-btn
          color="primary"
          fab
          x-small
          dark
          @click="expand(!isExpanded)"
        >
          <v-icon v-if="isExpanded">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 0 0 0 0"
        >
          <div v-if="item.variant_values.length > 0">
            <h4 class="text-center" style="margin-top: 10px;">{{ $vuetify.lang.t('$vuetify.panel.variant') }}</h4>
            <variants :article="item" :show-header="false"
                      @showEditVariantModal="showEditVariantModal"/>
            <!--list-variants :article="item"
                           @loadDataRequest="loadDataRequest"
                           @showEditVariantModal="showEditVariantModal"
                           @updateArticle="updateArticleHandler"
            /-->
          </div>
          <div v-else>
            <h4 class="text-center" style="margin-top: 10px;">{{ $vuetify.lang.t('$vuetify.articles.inventory') }}</h4>
            <!--            <list-article-shop :article="item"/>-->
            <article-shop :article="item" :shop-header="false" style="margin-top: 10px"
                          @calcInventory="calcInventory(item)"/>
          </div>
          <composite-list v-if="item.composite" :article="item" :show-header="false"/>
        </td>
      </template>
    </app-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import localCategories from '../../../data/categories'
import CompositeList from './manager-article/CompositeList'
import ArticleShop from './manager-article/ArticleShop'
import Variants from './manager-article/Variants'

export default {
  name: 'ListArticle',
  components: {
    Variants,
    ArticleShop,
    CompositeList
  },
  props: {
    typeData: {
      type: String,
      default: 'article'
    }
  },
  data () {
    return {
      localData: [],
      formRule: this.$rules,
      showEditArticle: false,
      articleSelected: null,
      newStock: 0.00,
      articleShopSelected: null,
      showStockModel: false,
      loading: false,
      vBindOption: {
        singleExpand: false,
        showExpand: true
      }
    }
  },
  watch: {
    typeData: function () {
      this.loadDataRequest()
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapGetters('auth', ['isManagerIn', 'user', 'access_permit']),
    ...mapState('article', ['articles', 'isActionInProgress', 'services', 'expireArticles', 'underInventory']),
    ...mapGetters('category', ['allCategories']),
    getTitle () {
      if (this.typeData === 'article') {
        return this.$vuetify.lang.t('$vuetify.titles.list', [this.$vuetify.lang.t('$vuetify.menu.articles')])
      } else if (this.typeData === 'service') {
        return this.$vuetify.lang.t('$vuetify.titles.list', [this.$vuetify.lang.t('$vuetify.menu.services')])
      } else if (this.typeData === 'expire') {
        return this.$vuetify.lang.t('$vuetify.menu.expire_list')
      } else if (this.typeData === 'under-inventory') {
        return this.$vuetify.lang.t('$vuetify.titles.list', [this.$vuetify.lang.t('$vuetify.menu.articles')])
      }
      return ''
    },
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getColumnHeader () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          width: '10%',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.category'),
          value: 'category_text',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.articles.inventory'),
          value: 'inventory',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.articles.taxes'),
          value: 'taxes',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    },
    getLocalCategories () {
      return localCategories[this.$vuetify.lang.current]()
    }
  },
  async created () {
    await this.loadDataRequest()
  },
  methods: {
    ...mapActions('article', ['getArticles', 'changeShopInventory', 'openEditModal', 'deleteArticle',
      'updateArticle', 'getServices', 'getExpireArticles', 'getUnderInventory']),
    ...mapActions('category', ['getCategories']),
    reloadData (filter) {
      // this.filter = filter
      // this.filter[2] = this.state
      // this.loadInitData()
    },
    calcInventory (item) {
      let cant = 0
      if (item.variant_values.length === 0) {
        item.article_shops.forEach((art) => {
          cant += parseFloat(art.inventory)
        })
      }
      item.inventory = cant
    },
    async loadDataRequest () {
      this.loading = true
      if (this.typeData === 'article') {
        await this.getArticles()
      } else if (this.typeData === 'service') {
        await this.getServices()
      } else if (this.typeData === 'expire') {
        await this.getExpireArticles()
      } else {
        await this.getUnderInventory()
      }
      await this.updateCategoriesText()
      this.loading = false
    },
    async updateCategoriesText () {
      this.localData = this.typeData === 'service' ? this.services
        : this.typeData === 'article' ? this.articles : this.typeData === 'expire' ? this.expireArticles : this.underInventory
      if (this.localData.length > 0) {
        await this.getCategories().then(() => {
          this.localData.map(v => {
            v.category_text = this.getCategoryName(v.category_id)
          })
        })
      }
    },
    deleteArticleHandler (articleId) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.articles')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.deleteArticle(articleId).then(() => {
              this.loadDataRequest()
            })
          }
        })
    },
    updateArticleHandler (article, action) {
      if (action === 'cost') {
        article.cost_id = null
      }
      this.updateArticle(article).then(() => {
        this.loadDataRequest()
      })
    },
    showEditVariantModal (article) {
      this.$store.state.article.managerArticle = true
      this.$router.push({ name: 'product_variant_edit', params: { hash: btoa(article.id) } })
    },
    cancelChangeDialog () {
      this.articleShopSelected = null
      this.showStockModel = false
    },
    changeStock (articleShop) {
      this.articleShopSelected = Object.assign({}, articleShop)
      this.showStockModel = true
    },
    async saveStock () {
      await this.changeShopInventory(this.articleShopSelected).then(() => {
        window.location.reload()
      })
    },
    createArticleHandler () {
      this.$store.state.article.managerArticle = false
      this.$store.state.article.newArticle.track_inventory = true
      this.$emit('show-new-article')
      if (this.$route.name !== 'product_add') { this.$router.push({ name: 'product_add' }) }
    },
    editArticleHandler ($event) {
      this.$store.state.article.managerArticle = true
      this.openEditModal($event)
      this.$router.push({ name: 'product_edit', params: { hash: btoa($event) } })
    },
    getCategoryName (id) {
      if (id) {
        if (this.allCategories.length > 0) {
          const categ = this.allCategories.filter(a => parseInt(a.id) === parseInt(id))[0]
          if (categ.company_id) {
            return categ.category
          } else {
            const cat = this._find(this.getLocalCategories, 'id', id).category
            return cat.charAt(0).toUpperCase() + cat.slice(1)
          }
        }
      } else {
        return this.$vuetify.lang.t('$vuetify.noDefined')
      }
    },
    _find (collection, key, value) {
      for (const o of collection) {
        for (const [k, v] of Object.entries(o)) {
          if (k === key && v.toString() === value.toString()) {
            return o
          }
          if (Array.isArray(v)) {
            const _o = this._find(v, key, value)
            if (_o) {
              return _o
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
