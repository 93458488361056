<template>
  <v-card>
    <v-card-title>
      <h4>{{ plan.name }}</h4>
    </v-card-title>
    <v-card-text>
      <template v-if="plan.description">
        <p><b>{{ $vuetify.lang.t('$vuetify.description') }}:</b></p>
        <p>{{ plan.description }}</p>
      </template>
    <v-row align="center"
           justify="center"
           class="v-app-ad v-sheet v-sheet--outlined theme--light rounded"
           v-if="loadingPayPal">
      <v-col md="12" cols="12"  align="center" justify="center">
        <v-spacer/>
        <v-icon @click="loadingPayPal=false">
          mdi-close-circle
        </v-icon>
      </v-col>
      <v-col cols="12" md="4">
        <pay-pal-pay-sdk
          v-if="showShowPayPalModal"
          :order="order"
          :plan="plan"
          :period="period"
          :periodId="periodId"
          :user="userData"
          @changeLoad="changeLoad"
        />
      </v-col>
    </v-row>
      <v-row v-if="!plan.is_free && !plan.is_coupon && !loadingPayPal">
        <v-col
          v-if="plan.facture_period.month !== '0.00'"
          :md="isModal? 12: 6"
          cols="12"
        >
          <v-row>
            <v-col
              md="5"
              cols="12"
            >
              <p>
                {{ $vuetify.lang.t('$vuetify.plan.month') }}: {{ plan.facture_period.month + ' ' + plan.currency }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <template v-if="getHaveSubscription">
                <v-btn
                  v-if="(plan.type === 'basic') || (!userData.myPartner && plan.type === 'unicorn')"
                  style="width: 128px; min-width: 128px;"
                  @click="showStripePayHandler(plan, 'month', plan.facture_period.id)"
                >
                  <v-img
                    src="/assets/payments/powered-by-stripe.png"
                    style="width: 128px; min-width: 128px;"
                  />
                </v-btn>
                <v-btn
                  style="width: 128px; min-width: 128px;"
                  @click="showPayPalPay(plan, 'month', plan.facture_period.id)">
                  <v-img
                    style="width: 128px; min-width: 128px;"
                    src="/assets/payments/paypal-2.png"
                  />
                </v-btn>
              </template>
              <v-btn
                v-else
                :disabled="getHaveSubscription ? plan.id === getMySubscription.id : false"
                style="width: 128px; min-width: 128px;"
                @click="activeTestSubscription(plan, 'month', plan.facture_period.id)"
              >
                {{ $vuetify.lang.t('$vuetify.actions.active') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="plan.facture_period.trimester !== '0.00'"
          :md="isModal? 12: 6"
          cols="12"
        >
          <v-row>
            <v-col
              md="5"
              cols="12"
            >
              <p>
                {{ $vuetify.lang.t('$vuetify.plan.trimester') }}: {{ plan.facture_period.trimester + ' ' + plan.currency }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <template v-if="getHaveSubscription">
                <v-btn
                  v-if="(plan.type === 'basic') || (!userData.myPartner && plan.type === 'unicorn')"
                  style="width: 128px; min-width: 128px;"
                  @click="showStripePayHandler(plan, 'trimester', plan.facture_period.id)"
                >
                  <v-img
                    src="/assets/payments/powered-by-stripe.png"
                    style="width: 128px; min-width: 128px;"
                  />
                </v-btn>
                <v-btn
                  v-if="plan.type === 'unicorn' && showPlatform(plan, 'paypal')"
                  style="width: 128px; min-width: 128px;"
                  @click="showPayPalPay(plan, 'trimester', plan.facture_period.id)">
                  <v-img
                    style="width: 128px; min-width: 128px;"
                    src="/assets/payments/paypal-2.png"
                  />
                </v-btn>
              </template>
              <v-btn
                v-else
                :disabled="getHaveSubscription ? plan.id === getMySubscription.id : false"
                style="width: 128px; min-width: 128px;"
                @click="activeTestSubscription(plan, 'trimester', plan.facture_period.id)"
              >
                {{ $vuetify.lang.t('$vuetify.actions.active') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="plan.facture_period.semester !== '0.00'"
          :md="isModal? 12: 6"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <p>
                {{ $vuetify.lang.t('$vuetify.plan.semester') }}: {{ plan.facture_period.semester + ' ' + plan.currency }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <template v-if="getHaveSubscription">
                <v-btn
                  v-if="(plan.type === 'basic') || (!userData.myPartner && plan.type === 'unicorn')"
                  style="width: 128px; min-width: 128px;"
                  @click="showStripePayHandler(plan, 'semester', plan.facture_period.id)"
                >
                  <v-img
                    src="/assets/payments/powered-by-stripe.png"
                    style="width: 128px; min-width: 128px;"
                  />
                </v-btn>
                <v-btn
                  v-if="showPlatform(plan, 'paypal')"
                  style="width: 128px; min-width: 128px;"
                  @click="showPayPalPay(plan, 'semester', plan.facture_period.id)">
                  <v-img
                    style="width: 128px; min-width: 128px;"
                    src="/assets/payments/paypal-2.png"
                  />
                </v-btn>
              </template>
              <v-btn
                v-else
                :disabled="getHaveSubscription ? plan.id === getMySubscription.id : false"
                style="width: 128px; min-width: 128px;"
                @click="activeTestSubscription(plan, 'semester', plan.facture_period.id)"
              >
                {{ $vuetify.lang.t('$vuetify.actions.active') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="plan.facture_period.annually !== '0.00'"
          :md="isModal? 12: 6"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <p>
                {{ $vuetify.lang.t('$vuetify.plan.annually') }}: {{ plan.facture_period.annually + ' ' + plan.currency }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >

              <template v-if="getHaveSubscription">
                <v-btn
                  v-if="(plan.type === 'basic') || (!userData.myPartner && plan.type === 'unicorn')"
                  style="width: 128px; min-width: 128px;"
                  @click="showStripePayHandler(plan, 'annually', plan.facture_period.id)"
                >
                  <v-img
                    src="/assets/payments/powered-by-stripe.png"
                    style="width: 128px; min-width: 128px;"
                  />
                </v-btn>
                <v-btn
                  style="width: 128px; min-width: 128px;"
                  @click="showPayPalPay(plan, 'annually', plan.facture_period.id)">
                  <v-img
                    style="width: 128px; min-width: 128px;"
                    src="/assets/payments/paypal-2.png"
                  />
                </v-btn>
              </template>
              <v-btn
                v-else
                :disabled="getHaveSubscription ? plan.id === getMySubscription.id : false"
                style="width: 128px; min-width: 128px;"
                @click="activeTestSubscription(plan, 'annually', plan.facture_period.id)"
              >
                {{ $vuetify.lang.t('$vuetify.actions.active') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="plan.facture_period.years_2 !== '0.00'"
          :md="isModal? 12: 6"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <p>
                {{ $vuetify.lang.t('$vuetify.plan.years_2') }}: {{ plan.facture_period.years_2 + ' ' + plan.currency }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <template v-if="getHaveSubscription">
                <v-btn
                  v-if="(plan.type === 'basic') || (!userData.myPartner && plan.type === 'unicorn')"
                  style="width: 128px; min-width: 128px;"
                  @click="showStripePayHandler(plan, 'years_2', plan.facture_period.id)"
                >
                  <v-img
                    src="/assets/payments/powered-by-stripe.png"
                    style="width: 128px; min-width: 128px;"
                  />
                </v-btn>
                <v-btn
                  v-if="showPlatform(plan, 'paypal')"
                  style="width: 128px; min-width: 128px;"
                  @click="showPayPalPay(plan, 'years_2', plan.facture_period.id)">
                  <v-img
                    style="width: 128px; min-width: 128px;"
                    src="/assets/payments/paypal-2.png"
                  />
                </v-btn>
              </template>
              <v-btn
                v-else
                :disabled="getHaveSubscription ? plan.id === getMySubscription.id : false"
                style="width: 128px; min-width: 128px;"
                @click="activeTestSubscription(plan, 'years_2', plan.facture_period.id)"
              >
                {{ $vuetify.lang.t('$vuetify.actions.active') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="plan.facture_period.years_3 !== '0.00'"
          :md="isModal? 12: 6"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <p>
                {{ $vuetify.lang.t('$vuetify.plan.years_3') }}:  {{ plan.facture_period.years_3 + ' ' + plan.currency }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <template v-if="getHaveSubscription">
                <v-btn
                  v-if="(plan.type === 'basic') || (!userData.myPartner && plan.type === 'unicorn')"
                  style="width: 128px; min-width: 128px;"
                  @click="showStripePayHandler(plan, 'years_3', plan.facture_period.id)"
                >
                  <v-img
                    src="/assets/payments/powered-by-stripe.png"
                    style="width: 128px; min-width: 128px;"
                  />
                </v-btn>
                <v-btn
                  v-if="showPlatform(plan, 'paypal')"
                  style="width: 128px; min-width: 128px;"
                  @click="showPayPalPay(plan, 'years_3', plan.facture_period.id)">
                  <v-img
                    style="width: 128px; min-width: 128px;"
                    src="/assets/payments/paypal-2.png"
                  />
                </v-btn>
              </template>
              <v-btn
                v-else
                :disabled="getHaveSubscription ? plan.id === getMySubscription.id : false"
                style="width: 128px; min-width: 128px;"
                @click="activeTestSubscription(plan, 'years_3', plan.facture_period.id)"
              >
                {{ $vuetify.lang.t('$vuetify.actions.active') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="plan.facture_period.years_4 !== '0.00'"
          :md="isModal? 12: 6"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <p>
                {{ $vuetify.lang.t('$vuetify.plan.years_4') }}: {{ plan.facture_period.years_4 + ' ' + plan.currency }}
              </p>>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <template v-if="getHaveSubscription">
                <v-btn
                  v-if="(plan.type === 'basic') || (!userData.myPartner && plan.type === 'unicorn')"
                  style="width: 128px; min-width: 128px;"
                  @click="showStripePayHandler(plan, 'years_4', plan.facture_period.id)"
                >
                  <v-img
                    src="/assets/payments/powered-by-stripe.png"
                    style="width: 128px; min-width: 128px;"
                  />
                </v-btn>
                <v-btn
                  v-if="showPlatform(plan, 'paypal')"
                  style="width: 128px; min-width: 128px;"
                  @click="showPayPalPay(plan, 'years_4', plan.facture_period.id)">
                  <v-img
                    style="width: 128px; min-width: 128px;"
                    src="/assets/payments/paypal-2.png"
                  />
                </v-btn>
              </template>
              <v-btn
                v-else
                :disabled="getHaveSubscription ? plan.id === getMySubscription.id : false"
                style="width: 128px; min-width: 128px;"
                @click="activeTestSubscription(plan, 'years_4', plan.facture_period.id)"
              >
                {{ $vuetify.lang.t('$vuetify.actions.active') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          md="6"
          cols="12"
          v-if="plan.facture_period.years_5 !== '0.00'"
        >
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <p>
                {{ $vuetify.lang.t('$vuetify.plan.years_5') }}: {{ plan.facture_period.years_5 + ' ' + plan.currency }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <template v-if="getHaveSubscription">
                <v-btn
                  v-if="(plan.type === 'basic') || (!userData.myPartner && plan.type === 'unicorn')"
                  style="width: 128px; min-width: 128px;"
                  @click="showStripePayHandler(plan, 'years_5', plan.facture_period.id)"
                >
                  <v-img
                    src="/assets/payments/powered-by-stripe.png"
                    style="width: 128px; min-width: 128px;"
                  />
                </v-btn>
                <v-btn
                  v-if="showPlatform(plan, 'paypal')"
                  style="width: 128px; min-width: 128px;"
                  @click="showPayPalPay(plan, 'years_5', plan.facture_period.id)">
                  <v-img
                    style="width: 128px; min-width: 128px;"
                    src="/assets/payments/paypal-2.png"
                  />
                </v-btn>
              </template>
              <v-btn
                v-else
                :disabled="getHaveSubscription ? plan.id === getMySubscription.id : false"
                style="width: 128px; min-width: 128px;"
                @click="activeTestSubscription(plan, 'years_5', plan.facture_period.id)"
              >
                {{ $vuetify.lang.t('$vuetify.actions.active') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="plan.is_coupon && !plan.is_free"
        style="width: 128px; min-width: 128px;"
        @click="$emit('showModalCouponModal', plan)"
      >
        {{ $vuetify.lang.t('$vuetify.plan.coupon') }}
      </v-btn>
      <v-btn
        :disabled="getHaveSubscription ? plan.id === getMySubscription.id : false"
        v-if="!plan.is_coupon && plan.is_free"
        style="width: 128px; min-width: 128px;"
        @click="$emit('handlerActiveFreePlan', plan)"
      >
        {{ $vuetify.lang.t('$vuetify.actions.active') }}
      </v-btn>
    </v-card-actions>
    <pay-stripe
      v-if="showStripeModal"
      :plan="plan"
      :user="userData"
      :order="order"
      :period="period"
      :periodId="periodId"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PayPalPaySdk from './PayPalPay-sdk'
import PayStripe from './PayStripe'
import localStorage from '../../config/localStorage'

export default {
  name: 'PayPlan',
  components: { PayStripe, PayPalPaySdk },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    plan: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      facture_period: [
        { text: 'month', value: 1 },
        { text: 'trimester', value: 3 },
        { text: 'semester', value: 6 },
        { text: 'annually', value: 12 },
        { text: 'years_2', value: 24 },
        { text: 'years_3', value: 36 },
        { text: 'years_4', value: 48 },
        { text: 'years_5', value: 60 }
      ],
      newValitAt: null,
      platform: false,
      order: null,
      loadingPayPal: false,
      planSelected: {},
      period: 'month',
      periodId: null
    }
  },
  computed: {
    ...mapState('auth', ['userData']),
    ...mapState('pay', ['showStripeModal', 'showShowPayPalModal', 'showStripeSectionModal', 'session', 'stripeData']),
    ...mapGetters('auth', ['companySubscription']),
    getHaveSubscription () {
      return !!this.companySubscription
    },
    getMySubscription () {
      return this.companySubscription
    }
  },
  methods: {
    ...mapActions('plan', ['activeMyTestSubscription', 'changePlanSubcription']),
    ...mapActions('auth', ['getUserData']),
    ...mapActions('pay', ['toogleShowStripePay', 'toogleShowPayPalModal', 'toogleShowStripeSectionModal', 'makeSession', 'getPayData']),
    changeLoad (value) {
    },
    showPlatform (plan, platform) {
      if (plan.platform_payment_gateway) {
        return JSON.parse(plan.platform_payment_gateway).filter(ps => ps === platform).length > 0 && (this.userData.myPartner && this.plan.type === 'unicorn')
      }
      return false
    },
    showStripePayHandler (plan, period, periodId) {
      let pay = true
      if (this.getMySubscription.plan.id === this.plan.id) {
        this.planSelected = plan
        this.toogleShowStripePay(true)
        return
      } else if (this.getMySubscription.plan.type === 'unicorn') {
        const t = Date.parse(new Date(this.getMySubscription.valid_at)) - Date.parse(this.getMySubscription.created_at)
        if (Math.floor(t / (1000 * 60 * 60 * 24)) > 1) {
          pay = false
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.change_plan'),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_no_change_plan'
              ),
              icon: 'info',
              showCancelButton: false,
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'primary'
            })
        }
        return
      }
      if (pay) {
        this.period = period
        this.periodId = periodId
        const payIntent = this.calcDifference()
        if (!payIntent.subscription) {
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.change_plan'),
              text: payIntent.status === 'menor_subscription' ? this.$vuetify.lang.t(
                '$vuetify.messages.menor_subscription',
                [this.getMySubscription.plan.name],
                [this.$vuetify.lang.t('$vuetify.plan.' + this.period)],
                [this.newValitAt.format('YYYY-MM-DD H:m:s')],
                [this.order.amount.value + ' ' + this.plan.currency]
              ) : payIntent.status === 'iqual_subscription' ? this.$vuetify.lang.t(
                '$vuetify.messages.iqual_subscription',
                [this.getMySubscription.plan.name],
                [this.$vuetify.lang.t('$vuetify.plan.' + this.period)]
              ) : this.$vuetify.lang.t(
                '$vuetify.messages.mayor_subscription',
                [this.getMySubscription.plan.name],
                [this.$vuetify.lang.t('$vuetify.plan.' + this.period)],
                [this.newValitAt.format('YYYY-MM-DD H:m:s')]
              ),
              icon: 'info',
              showCancelButton: true,
              cancelButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.cancel'
              ),
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.active'
              ),
              confirmButtonColor: 'primary'
            })
            .then(result => {
              if (result.value) {
                if (payIntent.status === 'mayor_subscription' || payIntent.status === 'iqual_subscription') {
                  this.changePlanSubcription({
                    validAt: date,
                    order: this.order,
                    subscription: this.getMySubscription,
                    plan: this.plan
                  }).then(() => {
                    window.location.reload()
                  })
                }
              } else if (payIntent.status === 'menor_subscription') {
                this.toogleShowStripePay(true)
              }
            })
        }
      }
    },
    calcDifference () {
      const payIntent = {
        status: 'subscription',
        subscription: false
      }
      let price = this.plan.facture_period[this.period]
      if (this.getMySubscription.plan.id !== this.plan.id) {
        const today = this.$moment(localStorage.getServerTime())
        const validAt = this.$moment(this.getMySubscription.valid_at)
        if (validAt.diff(today, 'days') > 2) {
          const montDiff = validAt.diff(today, 'months')
          const moneyNoConsume = this.getMySubscription.plan.facture_period.month * montDiff
          if (parseFloat(moneyNoConsume).toFixed(2) === parseFloat(this.plan.facture_period[this.period]).toFixed(2)) {
            payIntent.status = 'iqual_subscription'
          } else {
            const unicornDayPrice = parseFloat(this.plan.facture_period.month / 30).toFixed(2)
            const newDaysDifference = moneyNoConsume / unicornDayPrice
            if (moneyNoConsume >= this.plan.facture_period[this.period]) {
              this.newValitAt = today.add(newDaysDifference, 'days').format('YYYY-MM-DD H:m:s')
              payIntent.status = 'mayor_subscription'
            } else {
              this.newValitAt = today.add(this.facture_period.filter(fp => fp.text === this.period)[0].value, 'months')
              payIntent.status = 'menor_subscription'
              price = this.plan.facture_period[this.period] - moneyNoConsume
            }
          }
        }
      } else {
        payIntent.subscription = true
      }
      this.order =
        {
          amount: {
            currency_code: this.plan.currency,
            value: parseFloat(price).toFixed(2)
          }
        }
      if (this.userData.myPartner && this.plan.type === 'unicorn' && payIntent.platform &&
        parseFloat(price).toFixed(2) >= parseFloat(this.plan.facture_period.month).toFixed(2)) {
        const percent = plan.partner_percent ? plan.partner_percent : 19.45
        if (this.userData.myPartner.paypal_merchant_id) {
          this.order.payment_instruction = {
            disbursement_mode: 'INSTANT',
            platform_fees: [
              {
                amount: {
                  currency_code: this.plan.currency,
                  value: parseFloat(percent * price / 100).toFixed(2)
                },
                payee: {
                  merchant_id: this.userData.myPartner.paypal_merchant_id
                }
              }
            ]
          }
        }
      }
      return payIntent
    },
    async showPayPalPay (plan, period, periodId) {
      await this.getUserData()
      this.planSelected = plan
      this.period = period
      this.periodId = periodId
      this.loadingPayPal = true
      const price = plan.facture_period[this.period]
      let pay = true
      if (this.getMySubscription.plan.id === this.plan.id) {
        this.order =
          {
            amount: {
              currency_code: this.plan.currency,
              value: parseFloat(price).toFixed(2)
            }
          }
        if (this.userData.myPartner && this.plan.type === 'unicorn') {
          const percent = plan.partner_percent ? plan.partner_percent : 19.45
          if (this.userData.myPartner.paypal_merchant_id) {
            this.order.payment_instruction = {
              disbursement_mode: 'INSTANT',
              platform_fees: [
                {
                  amount: {
                    currency_code: this.plan.currency,
                    value: parseFloat(percent * price / 100).toFixed(2)
                  },
                  payee: {
                    merchant_id: this.userData.myPartner.paypal_merchant_id
                  }
                }
              ]
            }
          }
        }
        this.toogleShowPayPalModal(true)
        pay = false
      } else if (pay) {
        const payIntent = this.calcDifference()
        if (!payIntent.subscription) {
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.change_plan'),
              text: payIntent.status === 'menor_subscription' ? this.$vuetify.lang.t(
                '$vuetify.messages.menor_subscription',
                [this.getMySubscription.plan.name],
                [this.$vuetify.lang.t('$vuetify.plan.' + this.period)],
                [this.newValitAt.format('YYYY-MM-DD H:m:s')],
                [this.order.amount.value + ' ' + this.plan.currency]
              ) : payIntent.status === 'iqual_subscription' ? this.$vuetify.lang.t(
                '$vuetify.messages.iqual_subscription',
                [this.getMySubscription.plan.name],
                [this.$vuetify.lang.t('$vuetify.plan.' + this.period)]
              ) : this.$vuetify.lang.t(
                '$vuetify.messages.mayor_subscription',
                [this.getMySubscription.plan.name],
                [this.$vuetify.lang.t('$vuetify.plan.' + this.period)],
                [this.newValitAt.format('YYYY-MM-DD H:m:s')]
              ),
              icon: 'info',
              showCancelButton: true,
              cancelButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.cancel'
              ),
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.active'
              ),
              confirmButtonColor: 'primary'
            })
            .then(result => {
              if (result.value) {
                if (payIntent.status === 'mayor_subscription' || payIntent.status === 'igual_subscription') {
                  this.changePlanSubcription({
                    validAt: date,
                    order: this.order,
                    subscription: this.getMySubscription,
                    plan: this.plan
                  }).then(() => {
                    window.location.reload()
                  })
                } else if (payIntent.status === 'menor_subscription') {
                  this.toogleShowPayPalModal(true)
                }
              }
            })
        }
      }
    },
    activeTestSubscription (plan, period, periodId) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.actions.active'),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.active_subscription', [plan.name], [plan.test_day]
          ),
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.active'
          ),
          confirmButtonColor: 'primary'
        })
        .then(result => {
          if (result.value) {
            this.activeMyTestSubscription({ plan: plan, period: period, period_id: periodId })
              .then(() => {
                window.location.reload()
              })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
