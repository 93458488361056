import api from '../config/api'

export default {
  fetchAll () {
    return api.get('plan')
  },
  getPlansCountry () {
    return api.post('plan/byCountry')
  },
  fetchGeneral () {
    return api.post('plan/general')
  },
  changePlanSubcription (data) {
    return api.post('plan/change-subscription', data)
  },
  sendCreateRequest (plan) {
    return api.post('plan', plan)
  },
  sendUpdateRequest (plan) {
    return api.put('plan/' + plan.id, plan)
  },
  sendPaySubscription (data) {
    return api.post('pay/subscription', data)
  },
  sendCouponActive (data) {
    return api.post('plan/coupon-active', data)
  },
  sendFreePlan (data) {
    return api.post('plan/free-active', data)
  },
  activeTestSubscription (data) {
    return api.post('plan/test-active', data)
  },
  sendDeleteRequest (planId) {
    return api.remove('plan/' + planId)
  },
  getRestrictions (data) {
    return api.post('restriction', data)
  }
}
