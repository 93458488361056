<template>
    <v-card>
        <v-card-title>
            {{ $vuetify.lang.t('$vuetify.actions.add', [$vuetify.lang.t('$vuetify.menu.articles')]) }}
        </v-card-title>
        <v-card-text>
            <v-data-table
                v-if="articles.length > 0"
                v-model="selected"
                :headers="getHeaders"
                :items="articles"
                :single-select="false"
                item-key="ref"
                show-select
                class="elevation-1"
            >
                <template v-slot:item.shops="{ item }">
                    <v-chip
                        v-for="(shop, i) of item.shops"
                        :key="i"
                    >
                        {{ shop.name }}
                    </v-chip>
                </template>
            </v-data-table>
            <p v-else>{{ $vuetify.lang.t('$vuetify.sale.no_article') }}</p>
        </v-card-text>
        <v-card-actions>
            <v-spacer />

            <v-btn
                class="mb-2"
                :disabled="isActionInProgress"
                @click="$emit('closeModal')"
            >
                <v-icon>mdi-close</v-icon>
                {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
            </v-btn>
            <v-btn
                class="mb-2"
                color="primary"
                :disabled="selected.length === 0 || isActionInProgress"
                :loading="isActionInProgress"
                @click="sendAddArticleHandler"
            >
                <v-icon>mdi-check</v-icon>
                {{ $vuetify.lang.t('$vuetify.actions.save') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddArticlesToCategory',
  props: {
    categoryId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selected: []
    }
  },
  computed: {
    ...mapState('article', ['articles']),
    ...mapState('category', ['isActionInProgress']),
    getHeaders () {
      return [
        // {
        //   text: this.$vuetify.lang.t('$vuetify.articles.ref'),
        //   value: 'ref',
        //   select_filter: true
        // },
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shops',
          select_filter: true
        }
      ]
    }
  },
  created () {
    this.getArticlesByCategory()
  },
  methods: {
    ...mapActions('article', ['getArticlesByCategory', 'updateCategory']),
    async sendAddArticleHandler () {
      await this.updateCategory({
        category_id: this.categoryId,
        articles: this.selected
      })
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>

</style>
