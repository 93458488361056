import delivery from '../../api/delivery'

const FETCHING_DELIVERIES = 'FETCHING_DELIVERIESS'
const SWITCH_DELIVERIES_NEW_MODAL = 'SWITCH_DELIVERIES_NEW_MODAL'
const SWITCH_DELIVERIES_EDIT_MODAL = 'SWITCH_DELIVERIES_EDIT_MODAL'
const SWITCH_DELIVERIES_SHOW_MODAL = 'SWITCH_DELIVERIES_SHOW_MODAL'
const DELIVERIES_CREATED = 'DELIVERIES_CREATED'
const DELIVERIES_EDIT = 'DELIVERIES_EDIT'
const DELIVERIES_UPDATED = 'DELIVERIES_UPDATED'
const DELIVERIES_DELETE = 'DELIVERIES_DELETE'
const DELIVERIES_TABLE_LOADING = 'DELIVERIES_TABLE_LOADING'
const FAILED_DELIVERIES = 'FAILED_DELIVERIES'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_DELIVERIES = 'SET_EDIT_DELIVERIES'
const CANCEL_MODAL = 'CANCEL_MODAL'

const state = {
  showNewModal: false,
  showEditModal: false,
  showShowModal: false,
  deliveries: [],
  loading: false,
  saved: false,
  newDelivery: {
    name: '',
    valuesId: null,
    cant: '',
    type: '',
    existing: false,
    percent: 'true'
  },
  deliveriesAdded: {},
  editDelivery: {
    id: '',
    valuesId: null,
    name: '',
    cant: '',
    type: '',
    existing: false,
    percent: true
  },
  isDeliveryLoading: false,
  isActionInProgress: false,
  isTableLoading: false
}

const mutations = {
  [SWITCH_DELIVERIES_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_DELIVERIES_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_DELIVERIES_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [DELIVERIES_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
    state.isDeliveryLoading = isLoading
  },
  [FETCHING_DELIVERIES] (state, deliveries) {
    state.deliveries = deliveries
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [CANCEL_MODAL] (state) {
    state.newDelivery = {
      name: '',
      valuesId: null,
      cant: '',
      type: '',
      existing: false,
      percent: true
    }
    state.saved = false
  },
  [DELIVERIES_CREATED] (state, newDeliveries) {
    state.showNewModal = false
    state.newDelivery = {
      name: '',
      valuesId: null,
      cant: '',
      type: '',
      existing: false,
      percent: true
    }
    state.deliveriesAdded = newDeliveries
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.menu.delivery')
      ])
    })
  },
  [DELIVERIES_EDIT] (state, deliveryId) {
    state.editDelivery = Object.assign(
      {},
      state.deliveries.filter(node => node.id === deliveryId).shift()
    )
  },
  [DELIVERIES_UPDATED] (state) {
    state.showEditModal = false
    state.editDelivery = {
      id: '',
      valuesId: null,
      name: '',
      cant: '',
      type: '',
      existing: false,
      percent: true
    }
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_up', [
        this._vm.$language.t('$vuetify.menu.delivery')
      ])
    })
  },
  [SET_EDIT_DELIVERIES] (state, profile) {
    state.editDelivery.push(profile)
  },
  [DELIVERIES_DELETE] (state, error) {
    state.saved = true
    state.error = error
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.menu.delivery')
      ])
    })
  },
  [FAILED_DELIVERIES] (state, error) {
    state.saved = false
    state.error = error
    state.isActionInProgress = false
    this._vm.$Toast.fire({
      icon: 'error',
      title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.delivery')
      ])
    })
  }
}

const getters = {
  allDeliveries: state => state.deliveries,
  getdeliveriesAdded: state => state.deliveriesAdded
}

const actions = {
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_DELIVERIES_NEW_MODAL, showModal)
    if (!showModal) {
      commit(CANCEL_MODAL)
    }
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_DELIVERIES_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_DELIVERIES_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, deliveryId) {
    commit(SWITCH_DELIVERIES_EDIT_MODAL, true)
    commit(DELIVERIES_EDIT, deliveryId)
  },
  openShowModal ({ commit }, deliveryId) {
    commit(SWITCH_DELIVERIES_SHOW_MODAL, true)
    commit(DELIVERIES_EDIT, deliveryId)
  },
  async getDeliveries ({ commit }) {
    commit(DELIVERIES_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await delivery
      .fetchDeliveries()
      .then(({ data }) => {
        commit(FETCHING_DELIVERIES, data.data)
        commit(DELIVERIES_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
      })
      .catch(error => commit(FAILED_DELIVERIES, error))
  },
  async createDelivery ({
    commit,
    dispatch
  }, newDelivery) {
    commit(ENV_DATA_PROCESS, true)

    await delivery
      .sendCreateRequest(newDelivery)
      .then(({ data }) => {
        commit(DELIVERIES_CREATED, data.data)
        commit(ENV_DATA_PROCESS, false)
        dispatch('delivery/getDeliveries', null, { root: true })
        this.dispatch('auth/updateAccess', data)
      })
      .catch(error => commit(FAILED_DELIVERIES, error))
  },
  async updateDelivery ({
    commit,
    dispatch
  }, editDelivery) {
    commit(ENV_DATA_PROCESS, true)
    await delivery
      .sendUpdateRequest(editDelivery)
      .then(data => {
        commit(DELIVERIES_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('delivery/getDeliveries', null, { root: true })
        this.dispatch('auth/updateAccess', data)
      })
      .catch(error => commit(FAILED_DELIVERIES, error))
  },
  async deleteDelivery ({
    commit,
    dispatch
  }, deliveryId) {
    await delivery
      .sendDeleteRequest(deliveryId)
      .then(data => {
        commit(DELIVERIES_DELETE)
        dispatch('delivery/getDeliveries', null, { root: true })
        this.dispatch('auth/updateAccess', data)
      })
      .catch(error => commit(FAILED_DELIVERIES, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
