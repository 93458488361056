import api from '../config/api'

export default {
  fetchPerquisites () {
    return api.get('perquisite')
  },
  sendCreateRequest (perquisite) {
    return api.post('perquisite', perquisite)
  },
  sendUpdateRequest (perquisite) {
    return api.put('perquisite/' + perquisite.id, perquisite)
  },
  sendDeleteRequest (perquisiteId) {
    return api.remove('perquisite/' + perquisiteId)
  }
}
