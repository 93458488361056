import api from '../config/api'

export default {
  fetchTables () {
    return api.get('tables')
  },
  fetchTable (idTable) {
    return api.get('tables/' + idTable)
  },
  sendCreateRequest (tables) {
    return api.post('tables', tables)
  },
  fetchOpenClose (boxId) {
    return api.get('tables/' + boxId)
  },
  sendUpdateRequest (tables) {
    return api.put('tables/' + tables.id, tables)
  },
  sendDeleteRequest (tablesId) {
    return api.remove('tables/' + tablesId)
  },
  sendOpenCloseTable (box) {
    return api.post('tables/sendOpenCloseTable', box)
  },
  fetchOperations (box) {
    return api.post('tables/getOperations', box)
  }
}
