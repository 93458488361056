import mark from '../../api/mark'

const FETCHING_MARK = 'FETCHING_MARK'
const SWITCH_MARK_NEW_MODAL = 'SWITCH_MARK_NEW_MODAL'
const SWITCH_MARK_EDIT_MODAL = 'SWITCH_MARK_EDIT_MODAL'
const SWITCH_MARK_SHOW_MODAL = 'SWITCH_MARK_SHOW_MODAL'
const MARK_CREATED = 'MARK_CREATED'
const MARK_EDIT = 'MARK_EDIT'
const MARK_UPDATED = 'MARK_UPDATED'
const MARK_DELETE = 'MARK_DELETE'
const MARK_TABLE_LOADING = 'MARK_TABLE_LOADING'
const FAILED_MARK = 'FAILED_MARK'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_MARK = 'SET_EDIT_MARK'
const CANCEL_MODAL = 'CANCEL_MODAL'

const state = {
  showNewModal: false,
  showEditModal: false,
  showShowModal: false,
  marks: [],
  loading: false,
  saved: false,
  newMark: {
    name: '',
    description: '',
    color: '',
    avatar: ''
  },
  markAdded: {},
  editMark: {
    name: '',
    description: '',
    color: '',
    avatar: ''
  },
  isMarksLoading: false,
  isActionInProgress: false,
  isTableLoading: false
}
const mutations = {
  [SWITCH_MARK_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_MARK_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_MARK_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [MARK_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
    state.isMarksLoading = isLoading
  },
  [FETCHING_MARK] (state, marks) {
    state.marks = marks
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [CANCEL_MODAL] (state) {
    state.newMark = {
      name: '',
      description: '',
      color: '',
      avatar: ''
    }
    state.saved = false
  },
  [MARK_CREATED] (state, newMark) {
    state.showNewModal = false
    state.newMark = {
      name: '',
      description: '',
      color: '',
      avatar: ''
    }
    state.markAdded = newMark
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.menu.marks_list')
      ])
    })
  },
  [MARK_EDIT] (state, markId) {
    state.editMark = Object.assign({},
      state.marks.filter(node => node.id === markId).shift()
    )
  },
  [MARK_UPDATED] (state) {
    state.showEditModal = false
    state.newMark = {
      name: '',
      description: '',
      color: '',
      avatar: ''
    }
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_up', [
        this._vm.$language.t('$vuetify.menu.marks_list')
      ])
    })
  },
  [SET_EDIT_MARK] (state, profile) {
    state.editMark.push(profile)
  },
  [MARK_DELETE] (state, error) {
    state.saved = true
    state.error = error
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.menu.marks_list')
      ])
    })
  },
  [FAILED_MARK] (state, error) {
    state.saved = false
    state.error = error
    state.isActionInProgress = false
    this._vm.$Toast.fire({
      icon: 'error',
      title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.marks_list')
      ])
    })
  }
}

const getters = {
  allMark: state => state.marks,
  getmarkAdded: state => state.markAdded
}

const actions = {

  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_MARK_NEW_MODAL, showModal)
    if (!showModal) {
      commit(CANCEL_MODAL)
    }
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_MARK_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_MARK_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, markId) {
    commit(SWITCH_MARK_EDIT_MODAL, true)
    commit(MARK_EDIT, markId)
  },
  openShowModal ({ commit }, markId) {
    commit(SWITCH_MARK_SHOW_MODAL, true)
    commit(MARK_EDIT, MarkId)
  },
  async getMarks ({ commit }) {
    commit(MARK_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await mark
      .fetchMark()
      .then(({ data }) => {
        commit(FETCHING_MARK, data.data)
        commit(MARK_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
      })
      .catch(error => commit(FAILED_MARK, error))
  },
  async getMarksFilter ({ commit }) {
    commit(MARK_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await mark
      .fetchMark()
      .then(({ data }) => {
        commit(FETCHING_MARK, data.data)
        commit(MARK_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
      })
      .catch(error => commit(FAILED_MARK, error))
  },
  async createMark ({
    commit,
    dispatch
  }, newMark) {
    commit(ENV_DATA_PROCESS, true)
    await mark
      .sendCreateRequest(newMark)
      .then(({ data }) => {
        commit(MARK_CREATED, data.data)
        commit(ENV_DATA_PROCESS, false)
        dispatch('mark/getMarks', null, { root: true })
      })
      .catch(error => commit(FAILED_MARK, error))
  },
  async updateMarks ({
    commit,
    dispatch
  }, editMark) {
    commit(ENV_DATA_PROCESS, true)
    await mark
	  .sendUpdateRequest(editMark)
	  .then(data => {
        commit(MARK_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('mark/getMarks', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_MARK, error))
  },
  async deleteMark ({
    commit,
    dispatch
  }, markId) {
    await mark
	  .sendDeleteRequest(markId)
	  .then(data => {
        commit(MARK_DELETE)
        dispatch('mark/getMarks', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_MARK, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
