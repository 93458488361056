var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.toogleNewModal),callback:function ($$v) {_vm.toogleNewModal=$$v},expression:"toogleNewModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.titles.new', [ _vm.$vuetify.lang.t('$vuetify.menu.employee') ])))])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"my-10",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"align-self":"start","cols":"2"}},[_c('avatar-picker',{staticClass:"profile mx-auto d-block",attrs:{"image-src":_vm.getAvatar,"image-style":{
                'border-radius': '50%',
                height: '80px',
                width: '80px'
              }},on:{"input":function($event){return _vm.onChangeImage($event)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.firstName'),"rules":_vm.formRule.firstName,"required":""},model:{value:(_vm.newUser.first_name),callback:function ($$v) {_vm.$set(_vm.newUser, "first_name", $$v)},expression:"newUser.first_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.lastName'),"required":""},model:{value:(_vm.newUser.last_name),callback:function ($$v) {_vm.$set(_vm.newUser, "last_name", $$v)},expression:"newUser.last_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.email'),"autocomplete":"off"},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('vue-tel-input-vuetify',_vm._b({attrs:{"error-messages":_vm.errorPhone,"label":_vm.$vuetify.lang.t('$vuetify.phone'),"placeholder":_vm.$vuetify.lang.t('$vuetify.phone_holder'),"prefix":_vm.countrySelect
                  ? `+` + _vm.countrySelect.dialCode
                  : ``,"select-label":_vm.$vuetify.lang.t('$vuetify.country'),"required":""},on:{"input":_vm.onInput,"keypress":_vm.numbers,"country-changed":_vm.onCountry},scopedSlots:_vm._u([{key:"message",fn:function({ key, message }){return [_vm._t("label",null,null,{ key, message }),_vm._v(" "+_vm._s(message)+" ")]}}],null,true),model:{value:(_vm.newUser.phone),callback:function ($$v) {_vm.$set(_vm.newUser, "phone", $$v)},expression:"newUser.phone"}},'vue-tel-input-vuetify',_vm.bindProps,false))],1),(_vm.alertMessage!=='')?_c('v-col',{attrs:{"col":"12","md":"12"}},[_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.pinCode'),"options":{
                inputMask: '#-#-#-#-#-#',
                outputMask: '######',
                empty: null,
                alphanumeric: false
              },"properties":{
                min: 6,
                max: 6,
                clearable: true,
                required: true,
                rules: _vm.formRule.pinCode
              }},model:{value:(_vm.newUser.pinCode),callback:function ($$v) {_vm.$set(_vm.newUser, "pinCode", $$v)},expression:"newUser.pinCode"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"disabled":!!_vm.isAccessLoading,"items":_vm.roles.filter(role => role.name !== 'CEO'),"label":_vm.$vuetify.lang.t('$vuetify.menu.access'),"loading":_vm.isAccessLoading,"rules":_vm.formRule.access,"item-text":"name","required":"","return-object":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$store.dispatch(
                          'role/toogleNewModal',
                          true
                        )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( '$vuetify.titles.newAction' )))])])]},proxy:true}]),model:{value:(_vm.newUser.position),callback:function ($$v) {_vm.$set(_vm.newUser, "position", $$v)},expression:"newUser.position"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"disabled":!!_vm.isShopLoading,"items":_vm.shops,"label":_vm.$vuetify.lang.t('$vuetify.menu.shop'),"loading":_vm.isShopLoading,"item-text":"name","multiple":"","required":"","rules":_vm.formRule.country,"return-object":""},model:{value:(_vm.newUser.shops),callback:function ($$v) {_vm.$set(_vm.newUser, "shops", $$v)},expression:"newUser.shops"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":_vm.isActionInProgress},on:{"click":function($event){return _vm.toogleNewModal(false)}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.cancel'))+" ")],1),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":!_vm.formValid || _vm.isActionInProgress || _vm.newUser.shops.length === 0 || !_vm.valid,"loading":_vm.isActionInProgress,"color":"primary"},on:{"click":_vm.createNewUser}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.save'))+" ")],1)],1),(_vm.$store.state.role.showNewModal)?_c('new-access'):_vm._e(),(_vm.$store.state.shop.showNewModal)?_c('new-shop'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }