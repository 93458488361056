<template><div /></template>

<script>
export default {
  name: 'ProductList'
}
</script>

<style scoped>

</style>
