<template>
    <v-row justify="center">
        <v-dialog
            v-model="toogleFastConfig"
            fullscreen
            persistent
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="toogleFastConfig(false)"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.access.access.fast_config') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <user-setting :showRequired="true"/>

            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import UserSetting from './setting/commerce/UserSetting'

export default {
  name: 'FastConfig',
  components: {
    UserSetting
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapGetters('tax', ['allTaxes']),
    ...mapState('bank', ['banks']),
    ...mapGetters('discount', ['allDiscounts'])
  },
  methods: {
    ...mapActions('settings', ['toogleFastConfig'])
  }
}
</script>

<style scoped>

</style>
