<template>
  <v-dialog
    v-model="tooglePrintPayModal"
    max-width="100mm"
    persistent
  >
    <v-card>
        <v-card-text>
            <div
                id="ticket"
                class="ticket"
            >
<!--                <img-->
<!--                    v-if="user.company.logo"-->
<!--                    style="margin-top: 15px"-->
<!--                    class="profile mx-auto d-block"-->
<!--                    :src="user.company.logo"-->
<!--                    alt="LOGO"-->
<!--                >-->
                <p class="centrado">
                    {{ $vuetify.lang.t("$vuetify.sale.ticket") }}<br>
                    <b>{{ $vuetify.lang.t("$vuetify.created_by") }}</b>: {{ printData.createBy }}<br>
                    <b>{{ $vuetify.lang.t("$vuetify.plan.period") + ": " }}</b>
                  {{$vuetify.lang.t('$vuetify.plan.' + printData.period)}}<br>
                    <b>{{ $vuetify.lang.t('$vuetify.plan.name') + ': ' }}</b>
                    {{ printData.planName }}<br>
                    <b>{{ $vuetify.lang.t('$vuetify.variants.cant') + ': ' }}</b>
                  {{parseFloat(printData.cant).toFixed(2) + ' ' + subscription.plan.currency}}<br>
                    <b>{{ $vuetify.lang.t('$vuetify.plan.valid_until') + ': ' }}</b>
                    {{ printData.valid_at }}<br>
                    <b>{{  }}</b>
                </p>
            </div>
        </v-card-text>
      <v-card-actions>
        <v-btn
          class="mb-2"
          @click="cancelModal"
        >
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="mb-2"
          color="primary"
          @click="printFacture"
        >
          <v-icon>mdi-printer</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.print') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import printJS from 'print-js'

export default {
  name: 'PrintFacture',
  props: {
    printData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    subscription: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    ...mapActions('pay', ['tooglePrintPayModal']),
    printFacture () {
      printJS({
        printable: 'ticket',
        type: 'html',
        // targetStyles: ['*'],
        style: '.profile { width: 100px; height: 100px; border-radius: 50%; }' +
          '* { font-size: 12px; font-family: "DejaVu Sans", serif;}' +
          ' .profile { width: 100px; height: 100px; border-radius: 50%; }' +
          ' .centrado { text-align: center; align-content: center; }' +
          ' td, th, tr, table { border-collapse: collapse; margin: 15 auto;}' +
          'td.producto, th.producto { width: 80px; max-width: 80px; }' +
          'td.cantidad, th.cantidad { margin-left: 0px; width: 40px; max-width: 40px; word-break: break-all;}' +
          'td.precio, th.precio { width: 50px; max-width: 50px; word-break: break-all;}' +
          '#ticket { padding-top: 5px; align-content: center; width: 75mm; max-width: 75mm;}' +
          'img { max-width: inherit; width: inherit;}',
        scanStyles: false,
        header: '',
        onPrintDialogClose: () => {
          this.tooglePrintPayModal(false)
          if (this.go) {
            this.$router.push({ name: 'vending' })
          }
        },
        onError: () => {
          this.tooglePrintPayModal(false)
          if (this.go) {
            this.$router.push({ name: 'vending' })
          }
        }
      })
    },
    cancelModal () {
      this.tooglePrintPayModal(false)
    }
  }
}
</script>

<style scoped>
.profile {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
* {
    font-size: 12px;
    font-family: 'DejaVu Sans', serif;
}
td,
th,
tr,
table {
  border-collapse: collapse;
  margin: 15 auto;
}

td.producto,
th.producto {
    width: 80px;
    max-width: 80px;
}

td.cantidad,
th.cantidad {
    margin-left: 0px;
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

td.precio,
th.precio {
    width: 50px;
    max-width: 50px;
    word-break: break-all;
}

.centrado {
    text-align: center;
    align-content: center;
}

#ticket {
    padding-top: 5px;
    align-content: center;
    width: 75mm;
    max-width: 75mm;
}

img {
    max-width: inherit;
    width: inherit;
}
@media print {
    @page { margin: 0; }
    body { margin: 1.6cm; }
    .profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    * {
        font-size: 12px;
        font-family: 'DejaVu Sans', serif;
    }
    td,
    th,
    tr,
    table { border-collapse: collapse;
        margin: 15 auto;
    }
    td.producto,
    th.producto {
        width: 80px;
        max-width: 80px;
    }
    td.cantidad,
    th.cantidad {
        margin-left: 0px;
        width: 40px;
        max-width: 40px;
        word-break: break-all;
    }

    td.precio,
    th.precio {
        width: 50px;
        max-width: 50px;
        word-break: break-all;
    }

    .centrado {
        text-align: center;
        align-content: center;
    }

    #ticket {
        padding-top: 5px;
        align-content: center;
        width: 75mm;
        max-width: 75mm;
    }

    img {
        max-width: inherit;
        width: inherit;
    }
}

</style>
