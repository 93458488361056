import api from '../config/api'

export default {
  fetchMark () {
    return api.get('mark')
  },
  sendCreateRequest (mark) {
    return api.post('mark', mark)
  },
  sendUpdateRequest (mark) {
    return api.put('mark/' + mark.id, mark)
  },
  sendDeleteRequest (markId) {
    return api.remove('mark/' + markId)
  }
}
