import auth from '../../api/auth'
import localStorage from '../../config/localStorage'
import router from '../../router'

const SET_USER_DATA = 'SET_USER_DATA'
const SET_SESSION_DATA = 'SET_SESSION_DATA'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const PIN_SUCCESS = 'PIN_SUCCESS'
const LOGIN_FAILED = 'LOGIN_FAILED'
const PIN_FAILED = 'PIN_FAILED'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const LOGOUT = 'LOGOUT'
const LOGIN = 'LOGIN'
const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
const SET_RESET = 'SET_RESET'
const IN_PROCESS_RESET = 'IN_PROCESS_RESET'
const IS_MANAGER = 'IS_MANAGER'
const IS_TYPE_CONNECTION = 'IS_TYPE_CONNECTION'
const FAILED_CATCH = 'FAILED_CATCH'
const FAILED_VERIFIED = 'FAILED_VERIFIED'
const UPDATE_ACCESS = 'UPDATE_ACCESS'
const UPDATE_PARTNER = 'UPDATE_PARTNER'
const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
const AFFILIATE_UPDATE = 'AFFILIATE_UPDATE'
const SET_SHOW_HELP = 'SET_SHOW_HELP'

const state = {
  isLoggedIn: !!localStorage.getToken(),
  isManager: !!localStorage.getTokenManager(),
  showConfig: true,
  isPartner: localStorage.getTypeConnection() === 'partner',
  isBeneficiary: localStorage.getTypeConnection() === 'beneficiary',
  company_subscription: null,
  isAdmin: false,
  fiscal_config: null,
  userData: [],
  showHelp: false,
  userPin: {},
  pending: false,
  pinSuccess: false,
  loadingReset: false,
  successForgot: false,
  successReset: false,
  affiliateId: null,
  sessionData: null,
  access: [],
  menu: [],
  cantBox: 0,
  pinChange: 0,
  onlineRequest: 0,
  isSystemUpdated: 1,
  underInventory: 0,
  expire: 0,
  partner: {
    avatar: '/assets/avatar/avatar-undefined.jpg',
    email: '',
    phone: null,
    state: 'solicited',
    description_inactive: '',
    name: '',
    last_name: '',
    address: '',
    municipality: '',
    province: '',
    date_born: '',
    level_student: 'bachiller',
    sex: '',
    city: '',
    country: ''
  },
  error: {},
  notifications: [],
  fromModel: {
    email: '',
    password: ''
  },
  formRegister: {
    affiliate_id: null,
    shopName: '',
    username: '',
    email: '',
    password: '',
    phone: '',
    password_confirmation: '',
    sector: '',
    name: '',
    last_name: '',
    address: '',
    date_born: '',
    level_student: 'bachiller',
    state: 'solicited',
    sex: '',
    city: '',
    country: ''
  },
  formReset: {
    email: '',
    password: '',
    password_confirmation: ''
  },
  socialIcons: [
    {
      text: 'Google',
      icon: 'mdi-google'
    },
    {
      text: 'Facebook',
      icon: 'mdi-facebook'
    },
    {
      text: 'Twitter',
      icon: 'mdi-twitter'
    }
  ]
}

// getters
const getters = {
  user: state => state.userData,
  companySubscription: state => state.company_subscription,
  userPin: state => state.userPin,
  access_permit: state => state.access,
  fiscalConfig: state => state.fiscal_config,
  pinChanged: state => state.pinChange,
  onlineRequest: state => state.onlineRequest,
  getMenu: state => state.menu,
  getNotifications: state => state.notifications,
  isLoggedIn: state => state.isLoggedIn,
  isManagerIn: state => state.isManager,
  isPartnerIn: state => state.isPartner,
  isAdminIn: state => state.isAdmin,
  isBeneficiaryIn: state => state.isBeneficiary,
  pinSuccess: state => state.pinSuccess
}

// mutations
const mutations = {
  [IS_MANAGER] (state, isManager) {
    state.isManager = isManager
  },
  [IS_TYPE_CONNECTION] (state, typeConnection) {
    state.isPartner = typeConnection === 'partner'
    state.isBeneficiary = typeConnection === 'beneficiary'
  },
  [SET_USER_DATA] (state, user) {
    state.userData = user
    if (user) {
      state.partner = user.partner ? user.partner : {
        avatar: '/assets/avatar/avatar-undefined.jpg',
        phone: null,
        state: 'solicited',
        description_inactive: '',
        name: '',
        last_name: '',
        address: '',
        date_born: '',
        level_student: 'bachiller',
        sex: '',
        city: '',
        country: ''
      }
      if (localStorage.getTypeConnection() === 'partner') {
        state.partner = user
        state.userData.isPartner = true
      }
    }
  },
  [SET_SESSION_DATA] (state, data) {
    state.sessionData = data
  },
  [IN_PROCESS_RESET] (state, process) {
    state.loadingReset = process
  },
  [UPDATE_ACCESS] (state, access) {
    if (access) {
      state.company_subscription = access.subscription
      state.access = []
      state.access = access.access_permit ? JSON.parse(access.access_permit) : {}
      state.fiscal_config = access.fiscalConfig ? access.fiscalConfig : null
      state.isAdmin = localStorage.getTypeConnection() === 'admin'
      state.userPin = access.userPin ? access.userPin : null
      state.showConfig = access.showConfig ? access.showConfig : false
      state.cantBox = access.cantBox ? access.cantBox : 0
      state.onlineRequest = access.onlineRequest ? access.onlineRequest : 0
      state.isSystemUpdated = access.isSystemUpdated ? access.isSystemUpdated : 1
      state.underInventory = access.underInventory ? access.underInventory : 0
      state.expire = access.expire ? access.expire : 0
      if (!access.pinChanged) { state.pinChange = true }
      state.menu = []
      state.menu = access.menu ? JSON.parse(access.menu) : null
    }
  },
  [UPDATE_PARTNER] (state, partner) {
    state.partner = partner
  },
  [UPDATE_NOTIFICATION] (state, notifications) {
    if (notifications !== undefined) {
      state.notifications = []
      state.notifications = notifications
    }
  },
  [LOGIN] (state, pending) {
    state.pending = pending
  },
  [LOGIN_SUCCESS] (state) {
    state.isLoggedIn = true
    state.pending = false
  },
  [PIN_SUCCESS] (state, userPin) {
    state.pinSuccess = true
    state.userPin = userPin
    state.pending = false
  },
  [LOGOUT] (state) {
    state.isLoggedIn = false
    state.isManagerIn = false
  },
  [FORGOT_PASSWORD] (state, status) {
    state.successForgot = status
    if (status) {
      this._vm.$Toast.fire({
        icon: 'success',
        title: this._vm.$language.t('$vuetify.messages.check_mail'),
        timer: 5000
      })
      router.push({ name: 'login' })
    } else {
      this._vm.$Toast.fire({
        icon: 'error',
        title: this._vm.$language.t('$vuetify.messages.invalid_check_mail'),
        timer: 5000
      })
    }
  },
  [LOGIN_FAILED] (state) {
    state.isLoggedIn = false
    state.pending = false
    this._vm.$Toast.fire({
      icon: 'error',
      title: this._vm.$language.t('$vuetify.messages.login_failed')
    })
  },
  [PIN_FAILED] (state) {
    state.pinSuccess = false
    state.isManager = false
    this._vm.$Toast.fire({
      icon: 'error',
      title: this._vm.$language.t('$vuetify.messages.pin_failed')
    })
  },
  [ENV_DATA_PROCESS] (state, pending) {
    state.pending = pending
  },
  [SET_RESET] (state, reset) {
    state.formReset = {
      username: '',
      email: '',
      password: '',
      password_confirmation: ''
    }
    state.successReset = reset
    if (reset) {
      this._vm.$Toast.fire({
        icon: 'success',
        title: this._vm.$language.t(
          '$vuetify.messages.password_success'
        )
      })
    } else {
      this._vm.$Toast.fire({
        icon: 'error',
        title: 'Invalid Token.'
      })
    }
    router.push({ name: 'login' })
  },
  [FAILED_CATCH] (state, error) {
    state.pending = false
    state.error = error
    state.isLoggedIn = false
    if (error.status === 401 && error.statusText === 'Unauthorized') {
      localStorage.removeToken()
      localStorage.removePinToken()
      router.push({ name: 'home' })
    } else {
      let msg = this._vm.$language.t('$vuetify.messages.login_failed')
      Object.keys(state.error.data.errors).forEach(v => {
        if (v !== 'message') {
          if (v === 'email') {
            if (state.error.data.errors[v][0] === 'The email has already been taken.') {
              msg = this._vm.$language.t('$vuetify.messages.register_exist_email')
            }
          }
        }
      })
      this._vm.$Toast.fire({
        icon: 'error',
        title: msg
      })
    }
  },
  [FAILED_VERIFIED] (state) {
    state.isLoggedIn = false
    state.pending = false
    state.pending = false
    state.isLoggedIn = false
    localStorage.removeToken()
    localStorage.removePinToken()
    this._vm.$Toast.fire({
      icon: 'error',
      title: this._vm.$language.t('$vuetify.messages.fail_verified')
    })
  },
  [AFFILIATE_UPDATE] (state, affiliateId) {
    state.affiliateId = affiliateId
    this._vm.$cookies.set('referral', affiliateId)
  },
  [SET_SHOW_HELP] (state, show) {
    state.showHelp = show
  }
}

// actions
const actions = {
  async updateMenuConfig ({ commit }, menu) {
    await auth
      .updateMenuConfig(menu)
  },
  setShowHelp ({ commit }, show) {
    commit(SET_SHOW_HELP, show)
  },
  async getUserData ({ commit }) {
    await auth
      .getUserData()
      .then(({ data }) => {
        if (data.data) {
          commit(SET_USER_DATA, data.data)
          this.dispatch('auth/updateAccess', data)
        } else {
          // commit(SET_USER_DATA, null)
          // localStorage.removeToken()
          // localStorage.removePinToken()
          // localStorage.removeTokenManager()
          // commit(LOGOUT)
          // this.$router.push('home')
        }
      })
      .catch(({ response }) => {
        // commit(FAILED_CATCH, response)
        // localStorage.removeToken()
        // localStorage.removePinToken()
      })
  },
  async callNewAccount ({ commit }, type) {
    await auth
      .callNewAccount(type)
      .then(({ data }) => {
        commit(SET_SESSION_DATA, data.data)
        this.dispatch('auth/updateAccess', data)
      })
      .catch(({ response }) => {
        commit(FAILED_CATCH, response)
      })
  },
  async solicitedPartner ({ commit }, type) {
    await auth
      .solicitedPartner(type)
      .then(({ data }) => {
        commit(SET_USER_DATA, data.data)
        this.dispatch('auth/updateAccess', data)
      })
      .catch(({ response }) => {
        commit(FAILED_CATCH, response)
      })
  },
  async sendLoginRequest ({ commit }, login) {
    commit(LOGIN, true)
    return await auth
      .loginRequest(login)
      .then(({ data }) => {
        if (!data.user.email_verified_at) {
          commit(FAILED_VERIFIED)
        } else {
          commit(LOGIN, false)
          commit(LOGIN_SUCCESS)
          localStorage.saveToken(
            data.token_type + ' ' + data.access_token
          )
          localStorage.savePinToken(data.pin_code)
        }
      })
      .catch(({ response }) => {
        commit(LOGIN_FAILED)
        commit(FAILED_CATCH, response)
      })
  },
  async sendLoginPincode ({ commit, dispatch }, login) {
    commit(LOGIN)
    return await auth
      .loginPincodeRequest(login)
      .then(({ data }) => {
        commit(PIN_SUCCESS, data.data)
        commit(UPDATE_ACCESS, data.access)
        commit(UPDATE_NOTIFICATION, data.notifications)
        localStorage.savePinToken(data.data.pin_code)
        if (data.success && data.data.isManager) {
          commit(IS_MANAGER, true)
          localStorage.saveTokenManager(data.data.access_token)
        } else {
          commit(IS_MANAGER, false)
          localStorage.removeTokenManager()
        }
        return data
      })
      .catch(({ response }) => {
        commit(PIN_FAILED)
        commit(IS_MANAGER, false)
        // commit(FAILED_CATCH, response)
        localStorage.removeTokenManager()
      })
  },
  async sendRegisterRequest ({ commit, dispatch }, register) {
    return await auth
      .registerRequest(register)
      .then(({ data }) => {
        commit(LOGIN_SUCCESS)
        localStorage.saveToken(
          data.token_type + ' ' + data.access_token
        )
        localStorage.savePinToken(data.pin_code)
        dispatch('getUserData')
        router.push('/hi')
      })
      .catch(({ response }) => {
        commit(FAILED_CATCH, response)
      })
  },
  async sendRegisterPartner ({ commit, dispatch }, register) {
    return await auth
      .registerPartner(register)
      .then(({ data }) => {
        commit(LOGIN_SUCCESS)
        localStorage.saveToken(
          data.token_type + ' ' + data.access_token
        )
        localStorage.savePinToken(data.pin_code)
        dispatch('getUserData')
        router.push('/hi')
      })
      .catch(({ response }) => {
        commit(FAILED_CATCH, response)
      })
  },
  async sendLogoutRequest ({ commit }) {
    commit(LOGOUT)
    await auth
      .logoutRequest()
      .then(() => {
        localStorage.removeToken()
        localStorage.removePinToken()
        localStorage.removeTokenManager()
        commit(SET_USER_DATA, null)
      })
  },
  async sendVerifyResendRequest ({ commit }) {
    return await auth.verifyResendRequest().catch(({ response }) => {
      commit(FAILED_CATCH, response)
    })
  },
  async sendVerifyRequest ({ dispatch }, hash) {
    localStorage.savePinToken(hash.hash)
    localStorage.saveTypeConnection(hash.type)
    return await auth
      .verifyRequest(hash)
  },
  async sendEmailForgot ({ commit }, data) {
    return await auth
      .verifyMailForgot(data)
      .then(response => {
        if (response.status === 200 && response.data.success) {
          commit(FORGOT_PASSWORD, true)
        } else {
          commit(FORGOT_PASSWORD, false)
        }
      })
      .catch(response => {
        response.unauthorized = true
        commit(FAILED_CATCH, response)
      })
  },
  async sendResetPassword ({ commit }, newData) {
    commit(IN_PROCESS_RESET, true)

    return await auth
      .resetPassword(newData.token, newData)
      .then(response => {
        if (response.status === 200 && response.data.success) {
          commit(IN_PROCESS_RESET, false)
          commit(SET_RESET, true)
        } else {
          commit(IN_PROCESS_RESET, false)
          commit(SET_RESET, false)
        }
      })
  },
  async sendChangePassword ({ commit }, newData) {
    commit(IN_PROCESS_RESET, true)
    return await auth
      .sendChangePassword(newData)
      .then(response => {
        if (response.status === 200 && response.data.success) {
          // commit(IN_PROCESS_RESET, false)
          // commit(SET_RESET, true)
        } else {
          // commit(IN_PROCESS_RESET, false)
          // commit(SET_RESET, false)
        }
      })
  },
  async changePinCode ({ commit }, newData) {
    commit(IN_PROCESS_RESET, true)
    return await auth
      .changePinCode(newData)
      .then(response => {
        if (response.status === 200 && response.data.success) {
          window.location.reload()
        }
      })
  },
  async updateAccess ({ commit }, data) {
    if (data) {
      commit(UPDATE_ACCESS, data.access)
      if (data.isSystemUpdated === false) {
        // this.dispatch('company/sendSystemCompaniesUpdated')
      }
      commit(UPDATE_NOTIFICATION, data.notifications)
      commit(IS_TYPE_CONNECTION, data.type_connection)
      this.dispatch('plan/getMySubscription', [!!data.subscription, data.subscription, data.server_time])
    }
  },
  async setPartnerData ({ commit }, data) {
    commit(UPDATE_PARTNER, data)
  },
  async readNotification ({ commit }, idNotification) {
    await auth
      .readNotification(idNotification)
      .then(({ data }) => {
        this.dispatch('auth/updateAccess', data)
      })
      .catch(({ response }) => {
        commit(FAILED_CATCH, response)
        localStorage.removeToken()
        localStorage.removePinToken()
      })
  },
  async readAllNotifications ({ commit }, notifications) {
    await auth
      .readAllNotification(notifications)
      .then(({ data }) => {
        this.dispatch('auth/updateAccess', data)
      })
      .catch(({ response }) => {
        commit(FAILED_CATCH, response)
        localStorage.removeToken()
        localStorage.removePinToken()
      })
  },
  async sendAffiliateRequest ({ commit }, affiliateId) {
    await auth
      .affiliateRequest(affiliateId)
      .then(({ data }) => {
        commit(AFFILIATE_UPDATE, data.affiliate_id)
      })
      .catch(({ response }) => {
        if (!response || response.status !== 404) return console.log('Something went wrong')

        console.log('Affiliate does not exist. Register for our referral program here: https://inneli.dev/affiliate')
      })
  },
  async updatePartnerAvatar ({ commit }, data) {
    commit(PARTNER_UPDATE_AVATAR, data)
  },
  async sendContact ({ commit }, data) {
    await auth.sendContact(data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
