import data from '../../data'

const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
const FETCH_PAYMENTS = 'FETCH_PAYMENTS'
const PAYMENTS_LOADING = 'PAYMENTS_LOADING'
const COUNTRIES_LOADING = 'COUNTRIES_LOADING'
const FAILED = 'FAILED'
const CHANGE_SCREEN = 'CHANGE_SCREEN'

const state = {
  smallScreen: true,
  arrayCountry: data.getCountryToSelect(),
  arrayUM: data.getUM(),
  arraySector: data.getSector(),
  arrayCurrency: data.getCurrencyToSelect(),
  payments: data.getPaymentToSelect(),
  isCountryLoading: false,
  isPaymentsLoading: false,
  currencies: data.getCurrencies(),
  plans_type: data.getPlansType(),
  platform_type: data.getPlatformType(),
  invoicePeriod: data.getInvoicePeriod()
}

const mutations = {
  [FETCH_COUNTRIES] (state, arrayCurrency) {
    state.arrayCurrency = arrayCurrency
  },
  [FETCH_PAYMENTS] (state, payments) {
    state.payments = payments
  },
  [COUNTRIES_LOADING] (state, isLoading) {
    state.isCountryLoading = isLoading
  },
  [PAYMENTS_LOADING] (state, isLoading) {
    state.isPaymentsLoading = isLoading
  },
  [FAILED] (state, error) {
    state.error = error
  },
  [CHANGE_SCREEN] (state, size) {
    state.smallScren = size < 700
  }
}

const getters = {
  smallScreen: state => state.smallScren,
  arrayCurrency: state => state.arrayCurrency,
  arrayCountry: state => state.arrayCountry,
  arraySector: state => state.arraySector,
  payments: state => state.payments,
  currencies: state => state.currencies,
  plansType: state => state.plans_type,
  platformType: state => state.platform_type,
  invoicePeriod: state => state.invoicePeriod
}

const actions = {
  resizeScreen ({ commit }, size) {
    commit(CHANGE_SCREEN, size)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
