<template>
  <div>
    <v-card-subtitle>
      <h4>{{ $vuetify.lang.t('$vuetify.actions.config', [$vuetify.lang.t('$vuetify.menu.fiscal_account')]) }}</h4>
    </v-card-subtitle>
    <app-loading v-if="loading"/>
    <v-form
        v-else
      ref="form"
      v-model="formValid"
      style="padding: 0"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="fiscal.rnc"
            :label="$vuetify.lang.t('$vuetify.fiscal.rnc')"
            :rules="formRule.firstName"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="fiscal.init"
            :label="$vuetify.lang.t('$vuetify.fiscal.first_facture')"
            :hint="$vuetify.lang.t('$vuetify.tips.fiscal_init')"
            persistent-hint
            :rules="formRule.required"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="fiscal.cant_at"
            :label="$vuetify.lang.t('$vuetify.fiscal.cant_at')"
            :rules="formRule.required"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="fiscal.cant_digits"
            :label="$vuetify.lang.t('$vuetify.fiscal.cant_digits')"
            :rules="formRule.required"
          />
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <v-combobox
            v-model="prefixs"
            :items="prefixItems"
            multiple
            :label="$vuetify.lang.t('$vuetify.fiscal.prefix')"
            chips
            :hint="$vuetify.lang.t('$vuetify.tips.fiscal_prefix')"
            persistent-hint
            deletable-chips
            class="tag-input"
          />
        </v-col>

        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="fiscal.address"
            :counter="120"
            maxlength="120"
            :label="$vuetify.lang.t('$vuetify.address')"
          />
        </v-col>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="showCancel"
            class="mb-2"
            @click="$emit('cancelAccountFiscal')"
          >
            <v-icon>mdi-close</v-icon>
            {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
          </v-btn>
          <v-btn
            class="mb-2"
            color="primary"
            @click="updateFiscalAccountHandler"
          >
            <v-icon>mdi-check</v-icon>
            {{ $vuetify.lang.t("$vuetify.actions.accept") }}
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AccountFiscal',
  props: {
    showCancel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      prefixItems: [],
      prefixs: [],
      newPrefix: '',
      formValid: false,
      loading: false,
      formRule: this.$rules
    }
  },
  computed: {
    ...mapState('fiscal', ['fiscal'])
  },
  watch: {
    'fiscal.cant_at': function () {
      this.fiscal.cant_at = Math.round(this.fiscal.cant_at, 2)
    },
    'fiscal.cant_digits': function () {
      this.fiscal.cant_digits = Math.round(this.fiscal.cant_digits, 2)
    },
    'fiscal.prefix': function (newV, oldV) {
      if (this.isJSON(this.fiscal.prefix)) { this.prefixItems = JSON.parse(this.fiscal.prefix) } else if (Array.isArray(this.fiscal.prefix)) {
        this.prefixItems = this.fiscal.prefix
      } else { this.prefixItems = [] }
      this.prefixs = this.prefixItems
    }
  },
  async mounted () {
    this.loading = true
    await this.getFiscalAccount()
    this.loading = false
  },
  methods: {
    ...mapActions('fiscal', ['getFiscalAccount', 'updateFiscalAccount']),
    isJSON (element) {
      try {
        const testIfJson = JSON.parse(element)
        if (typeof testIfJson === 'object') {
          return true
        } else {
          return false
        }
      } catch {
        return false
      }
    },
    updateFiscalAccountHandler () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.fiscal.prefix = this.prefixs
        this.updateFiscalAccount(this.fiscal).then(() => {
          this.loading = false
        })
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
