<template>
  <v-dialog
    v-model="toogleNewModal"
    max-width="500"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          $vuetify.lang.t('$vuetify.titles.newF', [
            $vuetify.lang.t('$vuetify.menu.marks')
          ])
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="formValid"
          class="my-10"
          lazy-validation
        >
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newMark.name"
                :label="$vuetify.lang.t('$vuetify.name_of', [$vuetify.lang.t('$vuetify.menu.marks')])"
                :rules="formRule.firstName"
                required
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="newMark.description"
                :label="$vuetify.lang.t('$vuetify.marks.description')"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-radio-group
                v-model="representation"
                row
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-radio
                      :label="$vuetify.lang.t('$vuetify.representation.color_shape')"
                      value="color"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-radio
                      :label="$vuetify.lang.t('$vuetify.representation.image')"
                      value="image"
                    />
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
            <v-row>
              <v-col
                v-show="representation===`image`"
                cols="12"
                md="12"
              >
                <div
                  id="multiple-image"
                  style="display: flex; justify-content: center;"
                >
                  <avatar-picker
                    :image-src="getAvatar"
                    :image-style="{
                    'border-radius': '50%',
                    height: '5em',
                    width: '5em'
                  }"
                    class="profile mx-auto d-block"
                    @input="onChangeImage($event)"
                  />
                </div>
              </v-col>
              <v-col
                v-show="representation===`color`"
                cols="12"
                md="9"
              >
                <app-color-picker
                  :value="color || ``"
                  @input="inputColor"
                />
              </v-col>
            </v-row>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="isActionInProgress"
          class="mb-2"
          @click="toogleNewModal(false)"
        >
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
        </v-btn>
        <v-btn
          :disabled="!formValid || isActionInProgress"
          :loading="isActionInProgress"
          class="mb-2"
          color="primary"
          @click="createNewMarkHandler"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'NewMark',
  data () {
    return {
      avatar: [],
      color: '#1FBC9C',
      formValid: false,
      hidePinCode1: true,
      hidePinCode2: true,
      errorPhone: null,
      formRule: this.$rules,
      representation: 'color'
    }
  },
  computed: {
    ...mapState('mark', [
      'newMark',
      'saved',
      'isActionInProgress'
    ]),
    ...mapGetters('auth', ['user']),
    getAvatar () {
      return `${
        this.newMark.avatar ? this.newMark.avatar : '/assets/avatar/avatar-undefined.jpg'
      }`
    }
  },
  created () {
    this.formValid = false
  },
  methods: {
    ...mapActions('mark', ['createMark', 'toogleNewModal']),
    onChangeImage (file) {
      this.newMark.avatar = `data:${file.type};base64,${file.base64}`
    },
    async createNewMarkHandler () {
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.createMark(this.newMark).then(() => {
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    },
    inputColor (color) {
      this.newMark.color = color
      this.newMark.avatar = null
    }
  }
}
</script>

<style scoped></style>
