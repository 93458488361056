<template>
  <v-container>
    <v-dialog v-model="localPinChanged" v-if="!isAdminIn"
              max-width="400" persistent>
      <v-card>
        <v-card-subtitle>
          <h3>{{ $vuetify.lang.t('$vuetify.actions.change') }} {{ $vuetify.lang.t('$vuetify.menu.pinlogin') }}</h3>
        </v-card-subtitle>
        <v-card-text>
          <v-text-field v-model="newPinCode" type="number" :label="$vuetify.lang.t('$vuetify.menu.pinlogin')"/>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :disabled="newPinCode.toString().length !== 6"
                 @click="changePinCode({'pin':newPinCode})">
            {{ $vuetify.lang.t('$vuetify.actions.change') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changePassword"
              max-width="400"
    >
      <v-card>
        <v-card-subtitle>
          <h3>{{ $vuetify.lang.t('$vuetify.reset_password') }}</h3>
        </v-card-subtitle>
        <v-card-text>
          <v-form v-model="validForm">
            <v-text-field
              v-model="modelPassword.newPassword"
              :append-icon="
                  hidePassword1 ? 'mdi-eye' : 'mdi-eye-off'
                "
              :label="$vuetify.lang.t('$vuetify.new_password')"
              :rules="formRule.password"
              :type="hidePassword1 ? 'password' : 'text'"
              autocomplete="off"
              name="password"
              required
              @click:append="hidePassword1 = !hidePassword1"
            />
            <v-text-field
              v-model="modelPassword.passwordConfirmation"
              :append-icon="
                  hidePassword2 ? 'mdi-eye' : 'mdi-eye-off'
                "
              :label="
                  $vuetify.lang.t('$vuetify.confirm_password')
                "
              :rules="passwordConfirmationRule"
              :type="hidePassword2 ? 'password' : 'text'"
              autocomplete="off"
              name="password_confirmation"
              required
              @click:append="hidePassword2 = !hidePassword2"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="changePassword=false">
            {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" :disabled="!validForm || loading" @click="changePasswordSave">
            {{ $vuetify.lang.t('$vuetify.actions.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon
        v-if="showNavIcon"
        id="navIcon"
        @click=" handleDrawerToggle"
      />
      <v-icon
        v-if="!smallScreen"
        id="homeIcon"
        @click="handleHomePage"
      >
        mdi-home
      </v-icon>
      <v-spacer/>
      <v-toolbar-items>
        <v-tooltip v-if="isManagerIn && !isAdminIn && isLoggedIn && !isPartnerIn"
                   bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              id="mdi_fast_config"
              v-bind="attrs"
              :class="getShowFastConfig ? 'mr-3 parpadea text' : 'mr-3 text'"
              v-on="on"
              @click="toogleFastConfig(true)"
              v-text="'mdi-timer-sand'"
            />
<!--            <v-icon-->
<!--              v-else-->
<!--              id="mdi_fast_config"-->
<!--              v-bind="attrs"-->
<!--              class="mr-3 text"-->
<!--              v-on="on"-->
<!--              @click="toogleFastConfig(true)"-->
<!--              v-text="'mdi-timer-sand'"-->
<!--            />-->
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.access.access.fast_config') }}</span>
        </v-tooltip>
        <v-menu absolute
          style="color: #f1f0f0"
          offset-y
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              slot="activator"
              icon
              text
              v-on="on"
            >
              <template
                v-if="getNotifications.length > 0 && $route.name !== 'welcome'"
              >
                <v-badge
                  :content="getNotifications.length"
                  :value="getNotifications.length"
                  color="pink"
                  overlap
                >
                  <v-icon>mdi-bell</v-icon>
                </v-badge>
              </template>
              <template v-else-if="$route.name !== 'welcome'">
                <v-icon>mdi-bell</v-icon>
              </template>
            </v-btn>
          </template>
        </v-menu>
        <v-tooltip
          bottom v-if="isAdminIn"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="showLockIcon"
              id="mdi_lock"
              v-bind="attrs"
              class="mr-3"
              v-on="on"
              @click="sendNewUPdate"
              v-text="'mdi-update'"
            />
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.send_to_update') }}</span>
        </v-tooltip>
        <v-tooltip v-if="isManagerIn && !isAdminIn && isLoggedIn && !isPartnerIn"
                   bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="!smallScreen"
              id="mdi_plan"
              v-bind="attrs"
              class="mr-3"
              v-on="on"
              @click="showSubscription"
              v-text="'mdi-clipboard-text'"
            />
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.menu.admin_subscription') }}</span>
        </v-tooltip>
        <v-tooltip
          bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="mr-3"
              v-on="on"
              v-if="!isMobile"
              id="mdi_fullscreen"
              icon
              @click="handleFullScreen()"
            >
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.full_screen') + ' (Ctrl + Alt + F)' }}</span>
        </v-tooltip>
        <v-tooltip v-if="!isAdminIn && !isPartnerIn"
                   bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-hotkey="keymap"
              v-if="showSalesIcon"
              id="mdi_currency_usd"
              class="mr-3"
              v-on="on"
              v-bind="attrs"
              @click="handleSales"
              v-text="'mdi-currency-usd'"
            />
          </template>
          <span>{{
              $vuetify.lang.t('$vuetify.titles.newF', [
                $vuetify.lang.t('$vuetify.sale.sale'),
              ]) + ' (Ctrl + Alt + S)'
            }}</span>
        </v-tooltip>
        <v-menu
          v-if="showMenuLang"
          class="elelvation-1"
          offset-y
          origin="center center"
          transition="scale-transition"
        />
        <v-select
          id="menuLang"
          v-model="$vuetify.lang.current"
          :items="availableLanguages"
          class="mt-3"
          item-text="text"
          item-value="value"
          menu-props="auto"
          style="width:6em"
          @change="handleChangeLocale($event)"
        />
        <v-menu
          v-if="showMenuUser"
          offset-y
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              id="menuUser"
              slot="activator"
              icon
              large
              text
              v-on="on"
            >
              <v-avatar size="30px">
                <v-icon>mdi-account-circle</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list
            v-if="(isAdminIn && isLoggedIn ) && (!isManagerIn && !isPartnerIn)"
            class="pa-0"
          >
            <v-list-item
              v-for="(item, index) in adminMenus"
              :key="index"
              :disabled="item.disabled"
              :href="item.href"
              :target="item.target"
              :to="!item.href ? { name: item.name } : null"
              rel="noopener"
              ripple="ripple"
              @click="item.click"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    item.title
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            v-if="(!isManagerIn && !isAdminIn) && (isPartnerIn && isLoggedIn)"
            class="pa-0"
          >
            <v-list-item
              v-for="(item, index) in partnerMenu"
              :key="index"
              :disabled="item.disabled"
              :target="item.target"
              rel="noopener"
              ripple="ripple"
              @click="item.click"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    item.title
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            v-if="(!isManagerIn && !isAdminIn) && (isBeneficiaryIn && isLoggedIn)"
            class="pa-0"
          >
            <v-list-item
              v-for="(item, index) in beneficiaryMenu"
              :key="index"
              :disabled="item.disabled"
              :target="item.target"
              rel="noopener"
              ripple="ripple"
              @click="item.click"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    item.title
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            v-else-if="isManagerIn && isLoggedIn && !isAdminIn && !isPartnerIn"
            class="pa-0"
          >
            <v-list-item
              v-for="(item, index) in profileMenus"
              :key="index"
              :disabled="item.disabled"
              @click="item.click"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    item.title
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            v-else-if="!isManagerIn && !isAdminIn && isLoggedIn && !isPartnerIn"
            class="pa-0"
          >
            <v-list-item
              v-for="(item, index) in employeeMenus"
              :key="index"
              :disabled="item.disabled"
              :href="item.href"
              :target="item.target"
              :to="!item.href ? { name: item.name } : null"
              rel="noopener"
              ripple="ripple"
              @click="item.click"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    item.title
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="showLockIcon"
              id="mdi_help"
              v-bind="attrs"
              class="mr-3"
              v-on="on"
              @click="handleShowHelp"
              v-text="'mdi-help'"
            />
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.help.text') }}</span>
        </v-tooltip>
      </v-toolbar-items>
      <v-toolbar
        slot="extension"
        color="white"
        dense
        light
        tag="div"
      >
        <v-icon v-if="hasHistory" @click="$router.go(-1)">
          mdi-arrow-left-bold-circle
        </v-icon>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-3"
        />
        <v-spacer/>
        <v-menu
          v-if="companySubscription && !companySubscription.is_free"
          offset-y
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              id="menuTimer"
              slot="activator"
              icon
              large
              text
              v-on="on"
            >
              <v-avatar size="30px">
                <v-icon>mdi-clock</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <time-toolbar
              class="pa-3"
              :isTest="(companySubscription.is_test)===1"
              :textLabel="companySubscription.is_test ? $vuetify.lang.t('$vuetify.plan.rest_time') + ':'
            : $vuetify.lang.t('$vuetify.plan.valid_until') + ':'"
              :atTime="companySubscription.is_test ? companySubscription.test_at : companySubscription.valid_at"
              :stTime="companySubscription.created_at"/>
          </v-card>
        </v-menu>
        <router-link :to="{name:'my_subscription'}"
                     v-if="!companySubscription  && isManagerIn && $route.name !== 'welcome'" class="parpadea text">
          {{ $vuetify.lang.t('$vuetify.actions.subscribe') }}
        </router-link>
      </v-toolbar>
    </v-app-bar>
  </v-container>
</template>
<script>
import Util from '../util'

// import ListNotifications from '../views/notifications/ListNotifications'
import { mapActions, mapGetters, mapState } from 'vuex'
import localStorage from '../config/localStorage'

export default {
  name: 'AppToolbar',
  // components: { ListNotifications },
  props: {
    showNavIcon: {
      type: Boolean,
      default: true
    },
    showLockIcon: {
      type: Boolean,
      default: true
    },
    showSalesIcon: {
      type: Boolean,
      default: true
    },
    showBuyIcon: {
      type: Boolean,
      default: true
    },
    showMenuLang: {
      type: Boolean,
      default: true
    },
    showMenuUser: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showModal: false,
      localPinChanged: false,
      newPinCode: '',
      validForm: false,
      loading: false,
      modelPassword: {
        actualPassword: '',
        newPassword: '',
        passwordConfirmation: ''
      },
      formRule: this.$rules,
      changePassword: false,
      hidePassword1: true,
      hidePassword2: true,
      hidePassword3: true,
      showSale: false,
      showBuy: false,
      isMobile: false,
      passwordConfirmationRule: [
        v =>
          !!v ||
          this.$vuetify.lang.t('$vuetify.rule.required', [
            this.$vuetify.lang.t('$vuetify.confirm_password')
          ]),
        v =>
          (!!v && v) === this.modelPassword.newPassword ||
          this.$vuetify.lang.t(
            '$vuetify.rule.match',
            [this.$vuetify.lang.t('$vuetify.password')],
            [this.$vuetify.lang.t('$vuetify.confirm_password')]
          )
      ]
    }
  },
  computed: {
    keymap () {
      return {
        // 'esc+ctrl' is OK.
        'ctrl+alt+S': this.handleSales,
        // 'ctrl+alt+B': this.handleBuy,
        'ctrl+alt+F': this.handleFullScreen
      }
    },
    ...mapGetters('auth', ['companySubscription', 'isLoggedIn', 'isBeneficiaryIn', 'isPartnerIn', 'user', 'access_permit', 'isManagerIn', 'getNotifications', 'pinChanged', 'companySubscription']),
    ...mapGetters('statics', ['smallScreen']),
    ...mapState('auth', ['cantBox', 'pinChange', 'showConfig']),
    getHaveSubscriptions () {
      return !!this.companySubscription
    },
    getSubscription () {
      return this.companySubscription
    },
    getShowFastConfig () {
      return !!this.showConfig
    },
    isAdminIn () {
      return localStorage.getTypeConnection() === 'admin'
    },
    toolbarColor () {
      return this.$vuetify.options.extra.mainNav
    },
    availableLanguages () {
      const { locales } = this.$vuetify.lang
      return Object.keys(locales).map((lang) => {
        return {
          text: locales[lang].label,
          value: lang
        }
      })
    },
    localeText () {
      const find = this.availableLanguages.find(
        (item) => item.value === this.$vuetify.lang.current
      )
      return find.text
    },
    breadcrumbs () {
      const { matched } = this.$route
      return matched.map((route, index) => {
        const to = index === matched.length - 1
          ? this.$route.path
          : route.path || route.redirect
        const text = this.$vuetify.lang.t(
          '$vuetify.menu.' + route.meta.title
        )
        return {
          text: text,
          to: to,
          exact: true,
          disabled: false
        }
      })
    },
    profileMenus () {
      const dataMenu = []
      const self = this
      if (this.smallScreen && this.showBuyIcon) {
        dataMenu.push(
          {
            icon: 'mdi-home',
            href: '#',
            title: this.$vuetify.lang.t('$vuetify.menu.home_home'),
            click: this.handleHomePage
          }
        )
        if (this.isManagerIn && !this.isAdminIn && this.isLoggedIn && !this.isPartnerIn) {
          dataMenu.push(
            {
              icon: 'mdi-clipboard-text',
              href: '#',
              title: this.$vuetify.lang.t('$vuetify.menu.admin_subscription'),
              click: this.showSubscription
            }
          )
          dataMenu.push(
            {
              icon: 'mdi-lock',
              href: '#',
              title: this.$vuetify.lang.t('$vuetify.have_pin'),
              click: this.handlePinLogin
            }
          )
        }
        // dataMenu.push(
        //   {
        //     icon: 'mdi-cart',
        //     href: '#',
        //     title: this.$vuetify.lang.t('$vuetify.titles.newF', [this.$vuetify.lang.t('$vuetify.supply.name')]),
        //     click: this.handleBuy
        //   }
        // )
      }
      dataMenu.push({
        icon: 'mdi-face',
        href: '#',
        title: this.$vuetify.lang.t('$vuetify.menu.profile'),
        click: this.handleProfile
      }, {
        icon: 'mdi-lock',
        href: '#',
        title: this.$vuetify.lang.t('$vuetify.have_pin'),
        click: this.handlePinLogin
      },
      {
        icon: 'mdi-key-change',
        href: '#',
        title: this.$vuetify.lang.t('$vuetify.reset_password'),
        click: this.changePasswordHandle
      },
      {
        icon: 'mdi-logout',
        href: '#',
        title: this.$vuetify.lang.t('$vuetify.menu.logout'),
        click: this.handleLogout
      })
      return dataMenu
    },
    employeeMenus () {
      return [
        {
          icon: 'mdi-face',
          href: '#',
          title: this.$vuetify.lang.t('$vuetify.menu.profile'),
          click: this.handleProfile
        }/*, {
          icon: 'mdi-timer',
          href: '#',
          title: this.$vuetify.lang.t('$vuetify.menu.turnOn'),
          click: this.handleProfileEmployee
        } */
      ]
    },
    adminMenus () {
      return [
        {
          icon: 'mdi-logout',
          href: '#',
          title: this.$vuetify.lang.t('$vuetify.menu.logout'),
          click: this.handleLogout
        }
      ]
    },
    partnerMenu () {
      return [
        {
          icon: 'mdi-face',
          title: this.$vuetify.lang.t('$vuetify.menu.profile'),
          click: this.handleProfilePartner
        },
        {
          icon: 'mdi-logout',
          title: this.$vuetify.lang.t('$vuetify.menu.logout'),
          click: this.handleLogout
        }
      ]
    },
    beneficiaryMenu () {
      return [
        {
          icon: 'mdi-face',
          title: this.$vuetify.lang.t('$vuetify.menu.profile'),
          click: this.handleProfileBenficiary
        },
        {
          icon: 'mdi-logout',
          title: this.$vuetify.lang.t('$vuetify.menu.logout'),
          click: this.handleLogout
        }
      ]
    }
  },
  watch: {
    access_permit: function () {
      if (localStorage.getTypeConnection() !== 'admin') {
        this.showSale = this.access_permit.sales.actions.create ? this.access_permit.sales.actions.create : this.showSalesIcon
      }
    },
    pinChange: function () {
      if (localStorage.getTypeConnection() !== 'admin') {
        this.localPinChanged = this.pinChanged
      }
    }
  },
  created () {
    if (localStorage.getTypeConnection() !== 'partner' && localStorage.getTypeConnection() !== 'beneficiary' && localStorage.getTypeConnection() !== 'admin') {
      this.getUserData().then((v) => {
        this.showSale = this.access_permit.sales.actions.create
          ? this.access_permit.sales.actions.create : this.showSalesIcon
      })
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    ...mapActions('auth', ['sendLogoutRequest', 'getUserData', 'sendChangePassword', 'changePinCode']),
    ...mapActions('settings', ['toogleFastConfig']),
    ...mapActions('company', ['sendNewUpdateToCompanies']),
    sendNewUPdate () {
      this.sendNewUpdateToCompanies()
    },
    changePasswordSave () {
      this.sendChangePassword(this.modelPassword).then(() => {
        this.changePassword = false
      })
    },
    showFastConfig () {
      this.toogleFastConfig(true)
    },
    hasHistory () {
      return window.history.length > 2
    },
    handleShowHelp () {
      this.$emit('show-help-dialog')
    },
    handleDrawerToggle () {
      this.$emit('side-icon-click')
    },
    handleHomePage () {
      this.$router.push({ name: 'home' })
    },
    onResize () {
      this.isMobile = window.innerWidth < 700
    },
    showSubscription () {
      this.$router.push({ name: 'my_subscription' })
    },
    handleFullScreen () {
      Util.toggleFullScreen()
    },
    handleLogout () {
      this.sendLogoutRequest().then(() => {
        this.$router.push('/auth/login')
      })
    },
    handleChangeLocale (value) {
      this.$vuetify.lang.current = value
      localStorage.setLanguage(value)
      window.location.reload()
    },
    handleProfile () {
      this.$router.push({ name: 'profile' })
    },
    changePasswordHandle () {
      this.changePassword = true
    },
    handleProfileAdmin () {
      this.$router.push({ name: 'admin_profile' })
    },
    handleProfilePartner () {
      this.$router.push({ name: 'partner_profile' })
    },
    handleProfileBenficiary () {
      this.$router.push({ name: 'beneficiary_profile' })
    },
    handlePinLogin () {
      localStorage.removeTokenManager()
      this.$router.push({ name: 'pinlogin', params: { email: this.user.email } })
    },
    handleSales () {
      if (!this.getHaveSubscriptions) {
        this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.messages.no_subscription'))
      } else {
        if (this.getSubscription.is_caduced) {
          this.showSubscriptionMessage(this.$vuetify.lang.t('$vuetify.actions.is_caduced'))
        } else {
          if (this.cantBox > 0) {
            this.$store.state.sale.managerSale = false
            this.$store.dispatch('sale/restoreNewSale')
            this.$router.push({ name: 'vending_new' })
          } else {
            this.$Swal
              .fire({
                title: this.$vuetify.lang.t('$vuetify.titles.new', [
                  this.$vuetify.lang.t('$vuetify.sale.sale')
                ]),
                text: this.$vuetify.lang.t(
                  '$vuetify.messages.warning_no_boxes'
                ),
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: this.$vuetify.lang.t(
                  '$vuetify.actions.accept'
                ),
                confirmButtonColor: 'primary'
              })
              .then(() => {
                this.$router.push({ name: 'boxes' })
              })
          }
        }
      }
    },
    handleBuy () {
      this.$store.state.buy.managerBuy = false
      this.$router.push({ name: 'supply_add' }).catch(() => {
      })
    },
    showSubscriptionMessage (text) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
          text: text,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.accept'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'my_subscription' })
          }
        })
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
<style>
.text {
  font-size: 14px;
  font-family: helvetica;
  font-weight: bold;
  color: #71d90b;
  text-transform: uppercase;
}

.parpadea {

  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}
</style>
