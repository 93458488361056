<template>
  <div>
    <v-card-subtitle
        :style="times[0].alert ? 'color:red': 'color: forestgreen'"
                     :class="times[0]===0 && times[1]===0 && times[2]===0 && times[3]===0 ?'parpadea text':''">
      {{textLabel}} {{times[0].time + ' ' + times[0].text }}, {{ times[1].time + ' ' + times[1].text}},
      {{times[2].time + ' ' + times[2].text}}
      <v-tooltip bottom v-if="times[0].time < 3 || isTest">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="times[0].alert || isTest ? 'red': 'forestgreen'"
            id="mdi_lock"
            v-bind="attrs"
            class="mr-3"
            v-on="on"
            @click="goToActiveSubscription"
            v-text="'mdi-account-check'"
          />
        </template>
        <span>{{ $vuetify.lang.t('$vuetify.actions.active') }}</span>
      </v-tooltip></v-card-subtitle>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'TimeToolbar',
  props: {
    isTest: {
      type: Boolean,
      default: false
    },
    textLabel: {
      type: String,
      default: ''
    },
    atTime: {
      type: String,
      default: new Date().toString()
    },
    stTime: {
      type: String,
      default: new Date().toString()
    }
  },
  data: function () {
    return {
      startTime: this.stTime,
      times: [
        { id: 0, text: this.$vuetify.lang.t('$vuetify.date_text.days'), time: 1, alert: false },
        { id: 1, text: this.$vuetify.lang.t('$vuetify.date_text.hours'), time: 1, alert: false },
        { id: 2, text: this.$vuetify.lang.t('$vuetify.date_text.minutes'), time: 1, alert: false },
        { id: 3, text: this.$vuetify.lang.t('$vuetify.date_text.seconds'), time: 1, alert: false }
      ],
      progress: 100,
      // isActive: false,
      timeInterval: undefined
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen'])
  },
  methods: {
    goToActiveSubscription () {
      this.$router.push({ name: 'my_subscription' })
    },
    updateTimer: function () {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining()
        this.updateProgressBar()
      } else {
        clearTimeout(this.timeInterval)
        // this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
        this.progress = 0
      }
    },
    getTimeRemaining: function () {
      const t = Date.parse(new Date(this.atTime)) - Date.parse(new Date())
      if (t >= 0) {
        this.times[3].time = Math.floor(t / 1000 % 60) // seconds
        this.times[2].time = Math.floor(t / 1000 / 60 % 60) // minutes
        this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24) // hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)) // days
      } else {
        this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0
        this.progress = 0
      }
      if (this.times[0].time < 3) {
        this.times[3].alert = true
        this.times[2].alert = true
        this.times[1].alert = true
        this.times[0].alert = true
      }
    },
    updateProgressBar: function () {
      const startTime = Date.parse(new Date(this.stTime))
      const currentTime = Date.parse(new Date())
      const endTime = Date.parse(new Date(this.atTime))
      const interval = parseFloat(
        (currentTime - startTime) / (endTime - startTime) * 100
      ).toFixed(2)
      this.progress = 100 - interval
    }
  },
  created: function () {
    this.updateTimer()
    this.timeInterval = setInterval(this.updateTimer, 1000)
  }
}
</script>

<style scoped>
</style>

<style>
.text {
  font-size:14px;
  font-family:helvetica;
  font-weight:bold;
  color:#71d90b;
  text-transform:uppercase;
}
.parpadea {

  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
</style>
