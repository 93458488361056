import variantCost from '../../api/variantCost'

const FETCHING_VARIANT_COST = 'FETCHING_VARIANT_COST'
const SWITCH_VARIANT_COST_NEW_MODAL = 'SWITCH_VARIANT_COST_NEW_MODAL'
const SWITCH_VARIANT_COST_EDIT_MODAL = 'SWITCH_VARIANT_COST_EDIT_MODAL'
const SWITCH_VARIANT_COST_SHOW_MODAL = 'SWITCH_VARIANT_COST_SHOW_MODAL'
const VARIANT_COST_CREATED = 'VARIANT_COST_CREATED'
const VARIANT_COST_EDIT = 'VARIANT_COST_EDIT'
const VARIANT_COST_UPDATED = 'VARIANT_COST_UPDATED'
const VARIANT_COST_DELETE = 'VARIANT_COST_DELETE'
const VARIANT_COST_TABLE_LOADING = 'VARIANT_COST_TABLE_LOADING'
const FAILED_VARIANT_COST = 'FAILED_VARIANT_COST'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_VARIANT_COST = 'SET_EDIT_VARIANT_COST'
const CANCEL_MODAL = 'CANCEL_MODAL'

const state = {
  showNewModal: false,
  showEditModal: false,
  showShowModal: false,
  variantCosts: [],
  loading: false,
  saved: false,
  newVariantCost: {
    stock: 1,
    under_inventory: 1,
    cost: 0.00,
    cost_id: null
  },
  variantCostAdded: {},
  editVariantCost: {
    id: '',
    stock: 1,
    under_inventory: 1,
    cost: 0.00,
    cost_id: null
  },
  isVariantCostsLoading: false,
  isActionInProgress: false,
  isTableLoading: false
}
const mutations = {
  [SWITCH_VARIANT_COST_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_VARIANT_COST_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_VARIANT_COST_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [VARIANT_COST_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
    state.isVariantCostsLoading = isLoading
  },
  [FETCHING_VARIANT_COST] (state, variantCosts) {
    state.variantCosts = variantCosts
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [CANCEL_MODAL] (state) {
    state.newVariantCost = {
      stock: 1,
      under_inventory: 1,
      cost: 0.00
    }
    state.saved = false
  },
  [VARIANT_COST_CREATED] (state, newVariantCost) {
    state.showNewModal = false
    state.newVariantCost = {
      stock: 1,
      under_inventory: 1,
      cost: 0.00
    }
    state.variantCostAdded = newVariantCost
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.menu.variant_cost')
      ])
    })
  },
  [VARIANT_COST_EDIT] (state, variantCostId) {
    state.editVariantCost = Object.assign({},
      state.variantCosts.filter(node => node.id === variantCostId).shift()
    )
  },
  [VARIANT_COST_UPDATED] (state) {
    state.showEditModal = false
    state.newVariantCost = {
      stock: 1,
      under_inventory: 1,
      cost: 0.00
    }
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_up', [
        this._vm.$language.t('$vuetify.menu.variant_cost')
      ])
    })
  },
  [SET_EDIT_VARIANT_COST] (state, profile) {
    state.editVariantCost.push(profile)
  },
  [VARIANT_COST_DELETE] (state, error) {
    state.saved = true
    state.error = error
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.menu.variant_cost')
      ])
    })
  },
  [FAILED_VARIANT_COST] (state, error) {
    state.saved = false
    state.error = error
    state.isActionInProgress = false
    this._vm.$Toast.fire({
      icon: 'error',
      title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.variant_cost')
      ])
    })
  }
}

const getters = {
  allVariantCost: state => state.variantCosts,
  getVariantCostAdded: state => state.variantCostAdded
}

const actions = {

  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_VARIANT_COST_NEW_MODAL, showModal)
    if (!showModal) {
      commit(CANCEL_MODAL)
    }
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_VARIANT_COST_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_VARIANT_COST_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, variantCostId) {
    commit(SWITCH_VARIANT_COST_EDIT_MODAL, true)
    commit(VARIANT_COST_EDIT, variantCostId)
  },
  openShowModal ({ commit }, variantCostId) {
    commit(SWITCH_VARIANT_COST_SHOW_MODAL, true)
    commit(VARIANT_COST_EDIT, VariantCostId)
  },
  async getVariantCosts ({ commit }) {
    commit(VARIANT_COST_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await variantCost
      .fetchVariantCost()
      .then(({ data }) => {
        commit(FETCHING_VARIANT_COST, data.data)
        commit(VARIANT_COST_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
      })
      .catch(error => commit(FAILED_VARIANT_COST, error))
  },
  async getVariantCostsFilter ({ commit }) {
    commit(VARIANT_COST_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await variantCost
      .fetchVariantCost()
      .then(({ data }) => {
        commit(FETCHING_VARIANT_COST, data.data)
        commit(VARIANT_COST_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
      })
      .catch(error => commit(FAILED_VARIANT_COST, error))
  },
  async createVariantCost ({
    commit,
    dispatch
  }, newVariantCost) {
    commit(ENV_DATA_PROCESS, true)
    await variantCost
      .sendCreateRequest(newVariantCost)
      .then(({ data }) => {
        commit(VARIANT_COST_CREATED, data.data)
        return data.data
      })
      .catch(error => commit(FAILED_VARIANT_COST, error))
  },
  async updateVariantCosts ({
    commit,
    dispatch
  }, editVariantCost) {
    commit(ENV_DATA_PROCESS, true)
    await variantCost
	  .sendUpdateRequest(editVariantCost)
	  .then(data => {
        commit(VARIANT_COST_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_VARIANT_COST, error))
  },
  async updateOnlyCost ({
    commit,
    dispatch
  }, editVariantCost) {
    commit(ENV_DATA_PROCESS, true)
    await variantCost
	  .sendUpdateOnlyCost(editVariantCost)
	  .then(data => {
        commit(VARIANT_COST_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_VARIANT_COST, error))
  },
  async deleteVariantCost ({
    commit,
    dispatch
  }, variantCostId) {
    await variantCost
	  .sendDeleteRequest(variantCostId)
	  .then(data => {
        commit(VARIANT_COST_DELETE)
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_VARIANT_COST, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
