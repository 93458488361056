import api from '../config/api'

export default {
  fetchCompanies () {
    return api.get('company/active')
  },
  getCompaniesSuspend ($data) {
    return api.get('all-company/suspend')
  },
  fetchCompaniesByEmail (email) {
    return api.get('company/email/' + email)
  },
  sendCreateRequest (company) {
    return api.post('company', company)
  },
  sendUpdateRequest (company) {
    return api.put('company/' + company.id, company)
  },
  sendSuspendRequest (data) {
    return api.post('company/suspend', data)
  },
  sendDeleteRequest (companyId) {
    return api.remove('company/' + companyId)
  },
  sendNewUpdateToCompanies () {
    return api.post('company/update-system')
  },
  sendSystemCompaniesUpdated () {
    return api.post('company/system-updated')
  },
  updateLogo (avatar) {
    return api.post('company/logo/' + avatar.id, avatar)
  }
}
