import Vue from 'vue'
import VueRouter from 'vue-router'
import { protectedRoute, publicRoute } from './config'
import NProgress from 'nprogress'
import localStorage from '../config/localStorage'
import 'nprogress/nprogress.css'
import auth from '../store/modules/auth'

const routes = publicRoute.concat(protectedRoute)

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router gards
router.beforeEach((to, from, next) => {
  if (to.name === 'home') {
    importStyles()
  }
  NProgress.start()
  // auth route is authenticated
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (localStorage.getToken() && auth.getters.isLoggedIn) {
      next()
    } else {
      next({ name: 'home' })
    }
  }
  next()
})

router.afterEach((to, from) => {
  if (to.name === 'dashboard' && from.name === 'home') {
    document.querySelectorAll('.nicepage-style').forEach(el => el.remove())
    document.querySelectorAll('.nicepage-script').forEach(el => el.remove())
  }
  NProgress.done()
})

function importStyles () {
  const scripts = [
    '../../assets/css/nicepage/nicepage.css',
    '../../assets/css/nicepage/Billing-Money-App.css'
  ]
  scripts.forEach(script => {
    const tag = document.createElement('link')
    tag.setAttribute('href', script)
    tag.setAttribute('rel', 'stylesheet')
    tag.setAttribute('media', 'screen')
    tag.setAttribute('class', 'nicepage-style')
    document.head.appendChild(tag)
  })
}

export default router
