import api from '../config/api'

export default {
  fetchBuys () {
    return api.get('buy')
  },
  getBuysFilters (filter) {
    return api.post('buy/filter-sale', filter)
  },
  getNewBuyData (filter) {
    return api.post('buy/buy-data', filter)
  },
  sendCreateRequest (buy) {
    return api.post('buy', buy)
  },
  fetchCreditBuysToPay (filter) {
    return api.post('buy/credits-sales', filter)
  },
  changeStateBuy (data) {
    return api.post('buy/change/state', data)
  },
  sendUpdateRequest (buy) {
    return api.put('buy/' + buy.id, buy)
  },
  sendDeleteRequest (buyId) {
    return api.post('buy/delete/' + buyId.id, buyId)
  },
  fetchBuyByCategory (filter) {
    return api.post('buy/category', filter)
  },
  fetchBuyByPayment (filter) {
    return api.post('buy/payment', filter)
  },
  fetchBuyByProduct (filter) {
    return api.post('buy/product', filter)
  },
  fetchBuyByEmployer (filter) {
    return api.post('buy/employer', filter)
  },
  fetchBuyByLimit (filter) {
    return api.get('buy/by_limit/' + filter)
  },
  fetchBuyStatics () {
    return api.get('buy/buys/static')
  },
  fetchBuyNumber () {
    return api.post('buy/number/facture')
  }
}
