import en from 'vuetify/es5/locale/en'

export default {
  label: 'English',
  // page loginf
  login: 'Start section',
  name_of: 'Name of {0}',
  unlimited: 'Unlimited',
  money_limit: 'Money Limit to Facture',
  welcome_login: 'Login to your Billing Money App account',
  reset_password: 'Change password',
  find_password: 'Submit your password',
  forgot: 'Forgot your password?',
  have_pin: 'Lock Screen',
  full_screen: 'Full Screen',
  value_of: 'Value of {0}',
  generated_by_sale: 'Generated by Sale',
  pinTile: 'Pin Code',
  date: 'Date',
  created_by: 'Created by',
  forgot_btn: 'Submit',
  hint_forgot: 'Enter your email and instructions will be sent to you',
  username: 'User',
  name: 'Name',
  no_defined: 'Undefined',
  provider: 'Provider',
  email: 'Mail',
  holder_email: 'Company email address',
  firstName: 'Name',
  lastName: 'Surname',
  bornDate: 'Born Date',
  password: 'Password',
  actual_password: 'Current Password',
  new_password: 'New Password',
  pinCode: 'Code',
  confirm: 'Confirmation',
  register: 'Register',
  no_account: 'Don\'t have an account?',
  noDefined: 'Undefined',
  aboutMe: 'About me',
  activeAccount: 'Hello, your account has been created, but it has not been activated yet. Please access the registered email, you have been sent a message for activation. ',
  confirm_password: 'Confirm Password',
  confirm_pinCode: 'Confirm Code',
  company: 'Company',
  first_name: 'Name (s)',
  last_name: 'Surname',
  description: 'Description',
  address: 'Address',
  shop_address: 'Shop Address',
  slogan: 'Slogan',
  footer: 'Firm',
  city: 'City/State',
  country: 'Country',
  currency: 'Currency',
  change: 'Exchange Rate',
  province: 'Province',
  municipality: 'Municipality',
  barCode: 'Bar Code',
  position: 'Cargo',
  postal_code: 'Postal Code',
  about_me: 'About me',
  phone: 'Phone',
  wait: 'Please wait ...',
  phone_holder: 'Enter your phone number',
  price: 'Price',
  onlinePrice: 'Online Price',
  cost: 'Cost',
  color: 'Color',
  main: 'Main',
  file: 'File',
  system: 'System',
  import_csv: 'Importing file',
  state: 'State',
  to: 'A',
  fromData: 'Realize By',
  guide: 'Click to start guide',
  referralLink: 'Referral link',
  slogan_bill: 'THE SUCCESS OF THE COMPANY IN YOUR HANDS',
  all: 'All',
  any: 'Any',
  // menu
  menu: {
    home_home: 'Home',
    perquisite: 'Perquisite',
    delivery: 'Delivery',
    profitableness: 'Profitableness',
    variant_cost: 'Variant Costs',
    product_under_inventory: 'Low Stock',
    buy: 'Buy',
    buys: 'Buys',
    menu: 'Menu',
    product_by_category: 'See Sales Summary by Category',
    accounting_account: 'Accounting Accounts',
    partners: 'Partners',
    summary: 'Summary',
    tables: 'Tables',
    table: 'Table',
    tables_list: 'Table List',
    type_order: 'Type de Order',
    active_category_list: 'Active Categories',
    my_subscription: 'Mi Subscription',
    about: 'About',
    contact: 'Contact',
    prices: 'Prices',
    accounts: 'Accounts',
    account: 'Account',
    pasarela_config: 'Gateways Config',
    fiscal_account: 'Fiscal Account',
    manager_fiscal: 'Fiscal Account',
    admin_plan: 'Plan',
    admin_subscription: 'Subscriptions',
    admin_beneficiary: 'Beneficiary',
    beneficiary: 'Beneficiary',
    sub_file: 'Sub file Account',
    account_move: 'Move',
    account_moves: 'Moves',
    account_bills: 'Others Bills',
    account_bill: 'Bill',
    account_expense: 'Expenses',
    account_expenses: 'Expenses',
    account_income: 'Incomes',
    bank: 'Bank',
    setting: 'Configuration',
    shop_online: 'Online Shop(BETA)',
    supply_online: 'Online supplies',
    online_config: 'Shop Online Config(BETA)',
    config_add: 'Shop Online Configuration(BETA)',
    home: 'Home',
    shops: 'Shops',
    shop: 'Store',
    marks: 'Mark',
    marks_list: 'List of Marks',
    manager_shop: 'Manager Store',
    manager_access: 'Manage Access Permissions',
    dashboard: 'Dashboard',
    admin_dashboard: 'Dashboard',
    access_denied: 'Access denied',
    verify: 'Verify Mail',
    welcome: 'Welcome',
    profile: 'Profile',
    logout: 'Exit',
    user_list: 'Users',
    employer_list: 'Employees',
    user: 'Employees',
    employee: 'Employee',
    employees: 'Employees',
    client_employer: 'Clients & Employees',
    client_employer_admin: 'Clients & Employees',
    access: 'Access permissions',
    keys: 'Keys for permission',
    access_new: 'New Access Permission',
    access_list: 'Permission List',
    client: 'Client',
    client_list: 'Clients',
    clients_list: 'Clients',
    articles: 'Inventory',
    services: 'Services',
    products: 'Products',
    article: 'Article',
    detail: 'Detail',
    service: 'Service',
    product: 'Product',
    category: 'Category',
    expense_category: 'Expense Category',
    expense_category_list: 'List of Expense Category',
    exchange_rate: 'Foreign currency',
    exchange_rate_list: 'List of Foreign currency',
    category_list: 'List of Categories',
    plan_list: 'List of Plans',
    accounting_category_list: 'List of Categories of Accounting Account ',
    bank_list: 'Bank List',
    boxes_list: 'List of Boxes',
    product_list: 'List of Products',
    service_list: 'List of Services',
    expire_list: 'Expire articles',
    slow_move: 'Slow Move',
    modifiers_list: 'List of Modifiers',
    modifiers: 'Modifier',
    discounts_list: 'List of Discounts',
    pay: 'Payment Methods',
    supplier: 'Supplier',
    suppliers: 'Suppliers',
    company_subscription: 'Companies and Subscriptions ',
    supplier_list: 'List of Suppliers',
    resume: 'Summary',
    account_classify: 'Account File',
    accountings: 'Accounting Accounts',
    account_to_pay: 'Accounts to Pay',
    account_pay_list: 'Accounts to Pay',
    accounts_to_receivable: 'Accounts Receivable',
    account_receivable: 'Accounts Receivable',
    account_receivable_list: 'Accounts Receivable',
    accounting: 'Accounting Account',
    sell_product: 'Sale by Products',
    sell_mark: 'Sale by Products',
    sell_product_margin: 'Products with greater benefits',
    sell_category: 'Sale by Categories',
    sell_user: 'Sale by Employee',
    sell_types_payment: 'Sale by Type of Payment',
    sell_types_order: 'Sale by Type of Order',
    pinlogin: 'Pin Code',
    refund: 'Refunds',
    history_refund: 'Refunds History',
    boxes: 'Boxes',
    boxes_operations: 'Box Operations',
    tables_operations: 'Table Operations',
    box: 'Box',
    vending: 'Sales',
    vending_history: 'History of all Sales',
    vending_acepted: 'History Sales Accepted',
    vending_cancelled: 'History Sales Cancelled',
    vending_process: 'History Sales In Process',
    vending_online: 'Sales Online',
    vending_new: 'New Sale',
    vending_edit: 'Edit Sale',
    turnOn: 'Register Shift',
    product_add: 'New Article',
    product_edit: 'Edit Article',
    assistance: 'Assistance card',
    buy_products: 'Inventory',
    history_buy_products: 'Inventory update history',
    buy_product: 'Purchases',
    buy_productS: 'Purchase',
    buy_add: 'New Purchase',
    buy_edit: 'Edit Purchase',
    supply_product: 'Order Request',
    supply_productS: 'Orders Request',
    supply_emit: 'Emitted Supply',
    supply_cancelled: 'Cancelled Supply/Orders',
    supply_process: 'Orders in process',
    supply_received: 'Received Order',
    supply_add: 'Orders Request',
    supply_edit: 'Edit Purchase',
    order_product: 'Orders',
    history_order_product: 'Orders History',
    order_productS: 'Order',
    order_emit: 'Order Emited',
    order_emit_title: 'Orders to suppliers out or system',
    order_received: 'Orders received',
    order_add: 'Create order',
    emit_order: 'Emit order',
    received_order: 'Received order',
    order_edit: 'Edit Order',
    finance: 'Input',
    type_of_order: 'List of Type of Order',
    tax_list: 'Tax',
    discount: 'Discount',
    discounts: 'Discounts',
    coin: 'Coin',
    affiliate: 'Partners',
    affiliate_solicited: 'Solicited',
    affiliate_blocked: 'Blocked',
    affiliate_approved: 'Approved',
    affiliate_denied: 'Denied'
  },
  // settings
  settings: {
    title: 'Theme Setting',
    color: 'Color options',
    lang: 'Language',
    sidebar: 'Sidebar option'
  },
  // rules
  rule: {
    required: '{0} is required',
    min: 'The minimum quantity is {0} characters',
    max: 'The maximum number is {0} characters',
    minCant: 'The minimum quantity is {0}',
    maxCant: 'The maximum quantity is {0}',
    between: 'The value of {0} must be between {1} and {2}',
    length: 'This field needs {0} characters',
    match: 'Parameters {0} and {1} are not equal',
    bad_email: '{0} must be valid',
    shop_name: 'Just admin letters, numbers and character "_".',
    bad_numeric: '{0} must be numeric value',
    required_element: 'This field is required',
    bad_phone: '{0} must be valid',
    select: 'Select',
    pin: {
      len: 'Quantity is {0} digits',
      min: 'The minimum quantity is {0}',
      max: 'The maximum amount is {0}'
    },
    general_required: 'This field is required'
  },
  // profile
  profile: {
    user: 'User',
    edit_profile: 'Edit Profile',
    sub_profile: 'Complete your profile',
    btn_edit: 'Update',
    company: 'Company',
    manager: 'Owner'
  },
  // error
  messages: {
    variants_values: 'A variant is used when a product can have different characteristics.\n' +
      '        Example a Pants product can have a "Color" Variant, and its values: "Red" and "Black".\n' +
      '        In turn, it can have the variant "Size", with the values: "L" and "M", which will generate the combinations: "Pants - Red/L",\n' +
      '        "Pants - Red/M", "Pants - Black/L", "Pants - Black/M". Valid to clarify that each one will be related to all' +
      ' the stores in the system by default. If you wish, you can remove them as you like.',
    variant_cost: 'A cost variant, it is used for when a product has been purchased at different values, it can be reflected without having to affect the already ' +
      'previously saved as well as their operations',
    order_definition: 'You can create orders keeping them in the PREFORM status, at the time you define it ' +
      'a payment method, then it will begin to be recognized as a purchase in PROCESS or ACCEPTED depending on the status' +
      'that you select',
    no_client: 'To issue a Tax Receipt, you must associate a\n' +
      ' customer for sale:\n' +
      '1. If you do not have the client registered in the system, please\n' +
      'please insert it, don\'t forget to save the RNC of it for\n' +
      ' be able to issue the invoice.\n' +
      '2. If you already have the customer registered, edit the sale \n' +
      'and associate it, the desired client by selecting it in then\n' +
      ' section Additional Data in Edit Sale.\n' +
      'Always remember to have configured your\n Fiscal Invoice in Settings.',
    no_rnc: 'Please set your rnc in\n Settings-> Fiscal Invoice',
    no_client_rnc: 'Please, to generate a Fiscal receipt\n for this client, you must define your RNC',
    no_total_pay: 'To issue a Tax Receipt you cannot have\n accounts payable',
    invalid_cant: 'Sorry, you are using the basic version of our system and you have exhausted the billing limit for the subscribed period. \n' +
      'To continue operating, you must switch to our unlimited UNICORN version.\n' +
      'Sorry for any inconvenience caused.\n Thank you.',
    rentability_text_1: 'For the performance of a business, several things are taken into account, ' +
      'including its direct costs (cost of merchandise purchased), indirect costs, expenses, as well as other ' +
      'expenses reflected in the system. Likewise, what is paid in sales, taxes, and other income is related, as well ' +
      'as what is paid in credit accounts, or what has been paid to you through them.',
    rentability_text_2: 'His Accounting File plays a fundamental role at this point, since he has all these relationships in his accounting accounts.\n' +
      'The objective of this section is to show you approximately how the strengthening of your business is balanced by using ' +
      'the platform and the data reflected in it.',
    resume_rentability_text_range: 'During the period {0}:',
    resume_rentability_text_days: 'A days {0}:',
    resume_rentability_text_months: 'A months {0}:',
    resume_rentability_text_years: 'A months {0}:',
    resume_rentability_text_1: 'you incurred a total expense of: {0} {6},\n' +
      'In general, you reported a cost in purchasing products or merchandise of: {2} {6}, and as well as an indirect bills: of {3} {6},\n' +
      'During this time, he reported expenditures for a concept of  {7} {6} and paid a total of {8} {6}, for payments on credit.',
    resume_rentability_text_2: 'In this same time interval, you obtaining as Net Sales a total of {0} {3}. Likewise, a total of: {1} {3}' +
      'was entered for other concepts, and recovering {2} {3} for accounts receivable on credit.',
    resume_rentability_text_3: 'All this generates as profit or profitability a total of: {1} {2}.',
    resume_rentability_text_4: 'As additional data, we inform you that you have pending payment a total of {1} and receivable {2}, if you wish to take this into account, ' +
      'then your future benefit will be {3}.',
    resume_rentability_text_5: 'NOTE: THIS CALCULATION IS NUTRITED FROM THE INFORMATION INSERTED IN THE SYSTEM, TO MAKE IT MORE ACCURATE, PLEASE ALWAYS KEEP THE SYSTEM UPDATED',
    indirect_cost_description: 'You can add the indirect costs that a certain Purchase/Sale has, ' +
      '(ex: Transportation, Fuel, Lodging, Food, etc.). This information will be useful to you at the time of ' +
      "reduce expenses and have a more accurate summary of the business's feasibility or profitability",
    indirect_cost_description2: 'To do this select the "New" button, this action will show a window select the Expense incurred, ' +
      'as well as the value it had, if you make a mistake, you can delete it or edit the value directly.',
    active_article_shop: 'Do you want active "{0}" in shop: "{1}"',
    warning_no_image: 'This element no content images',
    refund_detail: 'Move generated by refund in sale with invoice: {0}, in article article: {1}',
    variant_cost_detail: 'Movement generated by updating product inventory: {0}, with reference: {1}',
    sale_detail: 'Move generated by sale with invoice: {0}',
    pay_detail: 'Move generated by the payment of the agreed credit in the sale with invoice: {0}',
    sale_tax_detail: 'Move generated by payment to Tax: {0}, in sale with invoice: {1}',
    sale_article_tax_detail: 'Move generated by payment to Tax: {0}, in article {1} sold with invoice: {1}',
    buy_detail: 'Move generated by buy with invoice: {0}',
    info_change_rate_1: 'The equivalent, according to the established exchange rate({0}), is from {1}, rounded to two places, {2}, but due to rounding off:',
    info_change_rate_2: 'Rounded value: {0} x exchange rate {1} = {2}, value q is less than the amount to be charged' +
      '({3}), this will cause a loss of: {4}',
    info_change_rate_3: 'The system recommends charging {0} and returning {1}',
    max_size: 'Images max size is 1M',
    wait: {
      loadingArticle: 'Waiting Articles'
    },
    merchant_id_paypal: 'This field is required to make payments through the PayPal gateway',
    no_shop_to_config: 'You have already configured the stores that you have registered in the system, please create a new one, or edit the ones you already have.',
    delete_mark_variant: 'This Variant cannot be deleted. If you don\'t want it, don\'t select Brand in Step 1',
    paypal_currency_example_exclange: 'If you have an item that costs 100 {0}, applying your exchange rate of 1 {2} equals {3} {0} in your online store will cost {1} {2}',
    paypal_currency_note: 'If the selected currency contains (1) (2) or (3), please note PayPal\'s clarifications',
    paypal_currency_note_1: '(1) This currency does not support decimals. If you pass a decimal amount, an error occurs.',
    paypal_currency_note_2: '(2) This currency is supported as a payment currency and a currency balance for in-country ' +
      'PayPal accounts only. If the receiver of funds is not from Brazil, then PayPal converts funds into the primary holding currency of the ' +
      "account with the applicable currency conversion rate. The currency conversion rate includes PayPal's applicable spread or fee.",
    paypal_currency_note_3: '(3) This currency is supported as a payment currency and a currency balance for in-country PayPal accounts only.',
    warning_delete_all: 'You have selected all of these items to be removed. Are you sure you want to delete them? This action will be irreversible.',
    warning_basic_plan: 'Sorry, this function cannot be executed on the "Basic" plan. Please switch to the "Unicorn" plan.',
    warning_basic_users: 'Sorry, this function cannot be executed on the "Basic" plan. Please switch to the "Unicorn" plan.',
    fail_verified: 'We send you a registered verification email, please check it.',
    active_categories: 'Activate and / or deactivate the categories that will be used in the system. ' +
      'Note that once deactivated it will not be seen by users, and with it the articles containing.',
    export_subscription: "You can't export/import elements if no have suscription in any plan",
    active_subscription: 'Thank you very much for subscribing to our plan {0}, you will have {1} trial days. ' +
      'In that time interval you can test the application. ',
    equal_subscription: 'Thank you very much for changing your plan, the money you have left to consume in your subscription plan({0}),' +
      ' is equivalent to the period {1}, in the selected plan',
    mayor_subscription: 'Thank you very much for changing your plan, the money you have left to consume in your subscription plan({0}),' +
      'is greater than the period {1}, in the selected plan, your subscription will change to the selected plan and period, and will be valid until {2}',
    minor_subscription: 'Thank you very much for changing your plan, the money you have left to consume in your subscription plan({0}),' +
      ' is less than the period {1}, in the selected plan, your subscription will change plan and selected period, and will be valid until {2}, for this you must pay {3}',
    no_subscription: "Sorry, you won't be able to do this until you subscribe to a plan. " +
      'You no have to pay immediately, do it now so that you can test our system and make your management more comfortable.' +
      ' We offer you trial days once you active your subscription.',
    subscription_updated: 'Subscription updated',
    empty_elements: 'No exist elements.',
    no_delete_default: 'You can\'t execute this function. This element is by default.',
    refused: 'Failed: Connection refused.',
    success_avatar: 'The image was saved successfully.',
    warning_requested_provider: 'If your provider is not registered, you want us to send you an invitation email?',
    warning_delete: 'This action cannot be reversed!',
    warning_search_days: 'You should select at less 2 days to compare and make graph',
    restart_articles: 'Remember we delete expenses or incomes asociated to this element, and accounts to receivable or to pay with credit process' +
      'do you want restart articles inventory as this element never existed?',
    warning_delete_ceo: 'This position is not deleted',
    warning_ceo_name: 'CEO is a name that you can\'\t use. Is reserved by System',
    warning_change_cant_pay: 'The amount paid is less than the amount to be charged, the system will automatically ' +
      'change the amount to be charged for the same value as the amount paid.',
    warning_no_change_plan: 'You cannot switch to this plan until your subscription expires, or is at least one day away',
    warning_safe_articles_online: 'You want to save these changes regardless of whether or not you save the online store settings',
    warning_no_boxes: 'Please create one box.',
    warning_no_articles: 'Please create one article.',
    warning_state: 'Please select a state',
    warning_supplier_no_article: 'This supplier no have articles to sale.',
    warning_exist_articles: "You can't delete this Category. Please delete articles first!",
    warning_exist_subscriptions: 'You can\'t edit/delete this Plan. Please delete subscriptions first!',
    warning_zero_price: 'The price must by grater than 0.50!',
    warning_zero_active_subscribers_limit: 'This plan have coupon associated, please define the limit of subscriptions.',
    warning_digital_box: 'This Box is necessary, the system does not accept its edition or deletion management',
    warning_exist: 'Exist a variant with this name!',
    warning_exist_cost: 'this element have this cost, please change a value or cancel action',
    warning_excess_money: 'The defined amount to be charged is greater than or equal to the total price. Decrease the amounts already defined.',
    warning_preform: 'You will create a Preform (PRE-FACTURE)' +
      'This will not be considered as a real sale until you define the Payment Method in the Additional Data Section.',
    error_delete_shop: 'There must be at least one store.',
    error_delete_cost: 'There must be at least one cost.',
    error_associate: 'An error occurred while trying to sync with Sripe, please try again later',
    error_no_logo: 'You should select a logo for your shop.',
    error_name: 'You should select a name for your shop.',
    error_delete_manager: 'This user cannot be deleted.',
    error_edit_manager: 'This user cannot be edited. Use the profile to edit it.',
    success_profile: 'The data has been updated.',
    success_system: 'System updated successfully.',
    success_add: '{0} has been created successfully.',
    success_up: '{0} has been updated successfully.',
    success_del: '{0} has been successfully removed.',
    failed_catch: 'Error occurred while processing data from {0}.',
    exist_pinCode: 'This access code is in use.',
    failed_catch_exist_name: 'Error, please change the name.',
    check_mail: 'An email has been sent with the details to change password.',
    invalid_check_mail: 'This email does not correspond to the type of account selected, please verify it\n.',
    password_success: 'The password was updated successfully.',
    warning_create: 'Cannot create this element. First you must create {0}.',
    sure_delete: 'Are you sure to delete this item?',
    dont_add: 'This element is already added.',
    pin_failed: 'You don\'t have access. The PIN code is not valid.',
    login_failed: 'Invalid email or password.',
    register_exist_email: 'This email is already in use.',
    login_failed_exist_email: 'This email is not registered.',
    warning_no_category: 'You need select a Category.',
    warning_composite: 'If the element is composite, you must define the elements that compose it.',
    warning_price: 'You cannot add that item. If the price is higher than the cost, it will not give you profit.',
    warning_cant_article: "You can't do this action. You must add at least one item.",
    warning_no_facture: "You can't do this action. You must add No. Facture in Additional data section.",
    warning_no_article: "You can't do this action. You must add at least one article in the system.",
    warning_no_article_services: "You can't do this action. You must add at least one article in the system. You can't supply services",
    warning_no_article_cat: "You can't do this action. Your articles no have Categories, you need add at one less.",
    warning_no_clients_supplier: "You can't do this action. You need have a Supplier registered in system.",
    warning_supply_state: "You can't do this action. Supplier init the process, please Cancel the supply.",
    warning_supply_state_cancelled: "You can't do this action. Supply has been cancelled, is not important edited.",
    warning_supply_delete: "You can't do this action. You need Cancel this supply first, and then you can delete.",
    warning_exist_refunds: 'You cannot edit this Sale. This sale have refunds, please change state to Cancel o create a new Sale.',
    warning_exist_article_deleted: 'This Sale contains deleted items, it is not recommended to edit it, but to delete it and create a new one.',
    warning_no_box: 'You cannot add this sale. You must add or select a box.',
    warning_difference_price: 'There is a difference between the total price and the one defined in the payments of {0}. \n Please fix the difference.',
    warning_no_client_credit: 'You have selected credit as a payment method, you must select which client you are going to make such a payment to',
    warning_no_supplier_credit: 'You have selected credit as a payment method, you must select which supplier you are going to make such a payment',
    warning_tax_cost: 'You are paying more tax than the cost of the product itself.',
    warning_value_tax: 'The tax value cannot be null or zero.',
    warning_refund_Cant: 'You have refunded {0} items, you can only refund between 0.00 and {1}.',
    warning_refund_Money: 'You have refunded $' + '{0}, you can only refund between $ 0.00 and $' + '{1}.',
    warning_refund_all: 'Unable to refund. You have refunded all money or items.',
    warning_article_service: 'By creating an Article, you will be able to supply it and put value on it in the store or warehouse, in the case of the Service, the supply would be impossible.',
    warning_partner_beneficiary: 'If you select this option, the beneficiary will obtain profits as if he were a partner for the referrals that he obtains and pays for the UNICORN version.',
    info_exchange_rate: 'The exchange rate is with respect to 1.',
    info_enable_email: 'Allow store mail to be displayed',
    info_enable_phone: 'Allow store phone to be displayed',
    info_enable_address: 'Allow physical store address to be displayed',
    info_enable_defaultCurrency: 'Allow that in addition to the payment currency configured in the gateway, the local currency configured for your country is displayed.',
    idle_title: 'Session expired.',
    idle_info: 'You have left this browser idle for 10 minutes.',
    idle_counter: 'The session closes in {0} seconds.',
    warning_ref: 'Reference {0} is already in use.',
    warning_barCode: 'Barcode {0} is used by {1} and {2}.',
    info_import_category: 'If this file contains Categories, they will be imported to the system, but if you have already created any before importing,' +
      'and the names match, then the old ones will be kept, attaching the new items.',
    info_import_shop: 'If this file contains items related to stores, they will be imported into the system with the country where you were registered,' +
      'but if you have already created one before importing, and the names match, then the old one will be kept, linking the new articles to it. If you want ' +
      'change the country, after importing it, you can go to the Settings menu, Stores section and Edit it in the list.',
    info_import_ref: 'If you have already created Articles and the References match those of the imported Articles, you will be rewritten to the new articles.',
    observation: 'Observation.',
    massage_print_facture: 'Thank you for visiting, us come back soom.:::::::'
  },
  /// hints messages
  hints: {
    round: 'System round automatically to {0} decimals, result will be: {1}',
    gateway_system: 'The system recognizes payments through gateways as "Digital Transfer"',
    permit_invite: 'Permit that clients buy if nor registers',
    online_price_zero: 'If the item has Online Price equal to zero (0.00) it will not be shown for online shop',
    online_stock_zero: 'If the item has zero inventory, it will not be displayed in the online store',
    online_basic_limit_zero: 'This Plan is of the Basic type, you may have to set a maximum amount to be billed for this country',
    invoice_interval: 'Billing Money has defined these billing periods, to which a price is defined. If the "Free" field is selected as active, then even if the prices have values, they will be saved as "0.00',
    facture_period: 'Every period should the plan be charged',
    press_enter: 'Press ENTER to add to the list',
    rnc: 'RNC or RUC or ID. This is your identification as contributor',
    select_country: 'Select one or many countries, if no select any one, this will be applied all country',
    active_subscribers_limit: 'If this field is empty, no exist limit of users',
    prorate_day: 'Days that user will by using plan if no pay next subscription',
    invoice_period: 'Days than to wait pay of plan after executed',
    test_day: 'Number of days that the application can be populated without having to pay for the plan to which it is subscribed',
    format_date: 'Year/Month/Day Format',
    partner_percent: 'Percent to pay at partner',
    partner_permanent: 'Fixed amount to be paid to partner',
    account_archive: 'Accounting file: they are the files used to record all the commercial activities to which a company is dedicated. Therefore, they function as proof of all the financial movements that the organization has during a certain period of time.',
    account_actives: 'Assets: are all assets or rights owned by a person or company and that are likely to be converted into money, benefits or economic returns.',
    account_expenses: 'The Expenses: They are outflows of financial resources in order to meet a payment, it can also be considered the outflow of money caused by other reasons, such as making an investment.',
    account_incomes: 'Income: are money inflows due to the activities provided by the company such as services and goods.Income: are money inflows due to the activities provided by the company such as services and goods.',
    account_passives: 'Liabilities: are the debts and obligations with which the company finances its activity. Example: loans, balances pending payment to suppliers, debts for taxes, social security, among others.'
  },
  actions: {
    is_caduced: 'Your subscription has expired o no have, please renew it, o create.',
    update: 'Update',
    update_data: 'Update {0}',
    no_active: 'This element is not enabled, select it to activate its use',
    load_image: 'Load Images',
    send_to_update: 'Generate new update to companies',
    goTo: 'Go to {}',
    save_continue: 'Save & Clear Data',
    pay: 'Pay',
    readAll: 'Read All',
    suspend: 'Temporarily Suspend',
    subscribe: 'Subscribe',
    add: 'Add {0}',
    active: 'Active',
    active_el: 'Active {0}',
    actions: 'Actions',
    insert: 'Insert {0}',
    config: 'Config {0}',
    refund: 'Refund',
    accept: 'Accept',
    open: 'Open',
    open_to: 'Open To',
    close_by: 'Close By',
    search: 'Search',
    new: 'New',
    created: 'Create {0}',
    newF: 'New',
    edit: 'Edit',
    show: 'Show',
    show_p: 'Show {0}',
    hidden: 'Hidden',
    hidden_p: 'Hidden {0}',
    delete: 'Delete',
    deleteAll: 'Delete All',
    list: 'List',
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close',
    change: 'Change',
    print: 'Print',
    apply: 'Apply',
    transfer: 'Transfer',
    next: 'Next',
    back: 'Back',
    yes: 'Yes',
    no: 'No',
    eye: 'See',
    see: 'See {0}',
    see_other_plan: 'See others plans',
    no_see_other_plan: 'No see others plans',
    send: 'Send',
    minus: 'Minus',
    plus: 'Plus',
    solicit: 'Solicit'
  },
  titles: {
    extra_bills: 'Bills out Sale/Buy',
    extra_sale_bills: 'Bills in sales(no costs)',
    change_plan: 'Change Plan',
    solicited: 'Solicit of Partners',
    solicit: 'Solicit {0}',
    list: 'List of {0}',
    new: 'New {0}',
    newF: 'New {0}',
    edit: 'Edit {0}',
    show: 'Show {0}',
    delete: 'Delete {0}',
    no_action: 'Can\'t {0}',
    newAction: 'Create new',
    importData: 'Import'
  },
  access: {
    key: 'Key',
    name: 'Position or Specialty',
    accessPin: 'Access with Code',
    accessEmail: 'Access with Mail',
    description: 'Description',
    keys: {
      atm: 'Cashier',
      waiter: 'Waiter',
      supervisor: 'Supervisor',
      seller: 'Seller',
      super_manager: 'CEO Administrator'
    },
    access: {
      dashboard: 'Home',
      just_yours: 'Only those of the user',
      manager_help: 'Define what actions you can execute',
      manager_article: 'Manage Articles',
      sales: 'Management Sale',
      box: 'Management Box',
      refund: 'Management Refund',
      tables: 'Management Table',
      article: 'Management Article',
      service: 'Management Service',
      category: 'Management Category',
      modifier: 'Management Modifier',
      sell_by: 'Management Sell By',
      type_of_order: 'Management Type of Order',
      buy: 'Management Buy',
      supplier: 'Management Supplier',
      order: 'Management Order',
      supply: 'Management Supply ',
      account_classify: 'Management Account Classify',
      accounting_account: 'Management Accounting Account',
      bank: 'Management Bank',
      summary: 'Management Summary',
      client: 'Management Clients',
      employer: 'Management Employers',
      access: 'Management Access Permit',
      shop_online: 'Management Online Shop',
      expense_category: 'Management Expense Category',
      subscription: 'Management Subscription',
      fiscal_account: 'Management Fiscal Account',
      shop: 'Management Shop',
      tax: 'Management Tax',
      discount: 'Management Discount',
      exchange_rate: 'Management Exchange Rate',
      assistence: 'Management Assistance',
      mark: 'Management Mark',
      list: 'List',
      create: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      import: 'Import',
      export: 'Export',
      active: 'Active',
      boxes_open: 'Open Box',
      boxes_close: 'Close Box',
      boxes_detail: 'Detail Box',
      transport: 'Move Items',
      manager_vending: 'Manage Sales',
      manager_refunds: 'Manage Refunds',
      manager_accounting: 'Manager Accounting Account',
      manager_category: 'Manage Categories',
      manager_tables: 'Manage Tables',
      manager_account_classify: 'Account File',
      manager_accounting_account: 'Manage Accounting Accounts',
      manager_marks: 'Manage Marks',
      manager_service: 'Manage Service',
      manager_mod: 'Manage Modifiers',
      manager_supplier: 'Manage Suppliers',
      manager_supply: 'Manage Supply',
      manager_order: 'Manage Order',
      manager_buy: 'Manage Purchases',
      manager_fiscal: 'Manage Fiscal Account',
      manager_subscription: 'Manage Subscription',
      manager_sumary: 'See Sales Summary',
      manager_summary: 'See Sales Summary',
      manager_sell: 'See Sales Summary',
      manager_shop_online: 'Manager Shop Online',
      sell_by_product: 'View Sales Summary by Items',
      sell_by_category: 'See Sales Summary by Category',
      sell_by_employer: 'See Sales Summary by Employees',
      sell_by_payments: 'See Sales Summary by Payment Methods',
      manager_employer: 'Manage Employees',
      manager_assistence: 'Manage Attendance Card',
      manager_tax: 'Manage Tax',
      manager_discount: 'Manage Discount',
      manager_boxes: 'Manage Boxes',
      manager_factures: 'Manage Facture',
      manager_client: 'Manage Clients',
      config: 'Configuration',
      fast_config: 'Fast Config',
      manager_shop: 'Manage Store',
      warning_manager_shop: '1 Shop',
      warning_manager_facture: '300 Factures at month',
      warning_manager_boxes: '2 Box',
      warning_manager_users: '3 Users',
      manager_access: 'Manage Access Permissions',
      manager_payment: 'Manage Payment Methods',
      manager_expense_category: 'Manage Expense Category',
      manager_exchange_rate: 'Manage Foreign Currency',
      manager_type_of_order: 'Manage Order Types'
    }
  },
  assistance: {
    entry: 'Entry',
    exit: 'Exit',
    entry_hour: 'Entry Time',
    exit_hour: 'Exit Time',
    total_hours: 'Total Hours'
  },
  component: {
    group_by: 'Group By',
    range: 'Range',
    select: 'Select columns',
    others: 'Others',
    filter: 'Filter',
    fieldColor: 'Choose a color',
    select_all: 'Select All',
    unselect_all: 'Unselect All',
    select_account: 'Select Account',
    select_one: 'Select',
    active_select_filter: 'Active selection filters',
    active_many_filter: 'Select active filters (out of many)',
    many_filter: 'Select (from many)',
    active_switch_filter: 'Active switch filters',
    download_csv: 'Download as CSV file',
    upload_csv: 'Import CSV file',
    choose_columns: 'Choose columns',
    reset_columns: 'Reset columns',
    no_filters: 'No filters enabled',
    clear_filters: 'Clear filters',
    filter_data: 'Filter data',
    filter_disabled: 'Filter disabled',
    loading: 'Loading',
    images: {
      name: 'Images',
      dragText: 'Drag the image (multiple)',
      browseText: '(or) Select',
      primaryText: 'Default',
      markIsPrimaryText: 'Set as default',
      popupText: 'This image will show as default',
      dropText: 'Drop your file here ...'
    },
    logo: 'Logo',
    image: 'Image'
  },
  tips: {
    delivery: 'You can configure all delivery charges, according to their values, having a list of them.' +
      'With these data, when making a sale, you can add that value, and it will be reflected in the invoice to the customer.',
    perquisite: 'You can configure all perquisite charges, according to their values, having a list of them.' +
      'With these data, when making a sale, you can add that value, and it will be reflected in the invoice to the customer.',
    tables_operations: 'Here you can observe all the operations carried out in a table',
    copy_link: 'Click to copy link',
    boxes_operations: 'Here you can observe all the operations carried out in a box',
    buy_edit: 'You can edit a purchase order using this interface',
    product_edit: 'If you want to edit a product, using this interface, you can do so',
    product_variant_edit: 'If you want to edit a variant of a product, you can do it through this interface',
    account_move_by_category: 'If you want to see the accounting movements, you can access this interface to have the details',
    account_delete: 'Delete account permanently.',
    referrals_on: 'Activate referral system',
    referrals_off: 'Disable referral system',
    dashboard: 'System start, here you can observe all the sales behavior of the day, as well as a summary of' +
      ' some factors of importance for an immediate meeting, or notion of daily behavior. This function is updated daily',
    vending_new: 'From here you can directly access to make a sale, without having to go through so many munu or options,' +
      ' Just select the product, taxes, discounts, payment method and make a sale instantly ',
    vending: 'By clicking on this option you can see the list of all the sales made on the day, but you can also filter them by date range. ' +
      'It has a link to create a new sale, and in the list you can also edit and/or delete them. You can make refunds directly, and ' +
      'issue fiscal invoice, simple invoice (Ticket) or invoice in letter format',
    vending_accepted: 'By clicking on this option you can see the list of all the sales made in the ACCEPTED status of the day, but you can also filter them by date range. ' +
      'It has a link to create a new sale, and in the list you can also edit and/or delete them. You can make refunds directly, and ' +
      'issue fiscal invoice, simple invoice (Ticket) or invoice in letter format',
    vending_process: 'By clicking on this option you can see the list of all the sales made in the IN PROCESS status of the day, but you can also filter them by date range. ' +
      'It has a link to create a new sale, and in the list you can also edit and/or delete them. You can make refunds directly, and ' +
      'issue fiscal invoice, simple invoice (Ticket) or invoice in letter format. SALES IN PROCESS ARE SALES THAT ARE NOT YET ACCEPTED OR CLOSED, THESE DO NOT INFLUENCE THE ACCOUNTS,' +
      'BUT IF THEY REMAIN IN THE INVENTORY',
    vending_cancelled: 'By clicking on this option you can see the list of all sales made in the CANCELED status of the day, but you can also filter them by date range. ' +
      'It has a link to create a new sale, and in the list you can also edit and/or delete them. You can make refunds directly, and ' +
      'issue fiscal invoice, simple invoice (Ticket) or invoice in letter format. CANCELED SALES ARE SALES THAT DECIDED TO CANCEL FOR A SPECIFIC REASON, THESE MAY OR MAY NOT INFLUENCE THE ACCOUNTS IF THE USER WISHES SO,' +
      'BUT IF THEY REMAIN IN THE INVENTORY',
    make_refund: 'Although from the sales interface, refunds can be made, if you want you can access directly and abstract from everything ' +
      'the information provided by said view, focusing only on what is required to make a Refund to a customer',
    refund: 'Using this component, you can see the history of all refunds made on the day, likewise, you can filter it by a range of days if you wish',
    boxes: 'To be able to carry out a sales process, you must have additional boxes, the system provides you with a comfortable interface that ' +
      'allows you to add boxes in a simple way, using this interface, you can see the registered boxes, grouped by the stores they belong to, as well as open or close them.' +
      'From this interface, the system provides you with an automatic tool to balance it, based on the record of all the transactions reflected in it.',
    table: 'If in your business you provide services to tables, then Billing Money provides you with that field too, with us' +
      'The processes are reflected by the tables, as well as the box for which a sale was made, you can also save the table where it was made',
    type_of_order: 'With this component you can define different modes or forms if you want to specify in your purchase processes, eg: ' +
      'To take away, Delivery, To deliver, Eat on the premises, etc.',
    manager_shop: 'When you register on our platform, we automatically generate a store for you so you can start selling immediately,' +
      'But if your business is larger and groups several stores or points of sale, you can add them using this interface',
    product_add: 'Products are the basics for the sales system, with this flexible and comprehensive component you can define all the features' +
      'for your articles and/or services that you will offer to the client or public. (NOTE: Billing Money defines Products as its list of items and services)',
    product_list: 'From this interface you can see all the items you have in inventory',
    service_list: 'From this interface you can see all the services you have in inventory',
    product_under_inventory: 'Billing Money aims for you to always be aware of your inventory, for them, when you define a product, ' +
      'There is an option that defines notify low inventory. Using it, the system notifies you in this interface about them, or the products that' +
      'are at zero in inventory',
    expire_list: 'Billing Money\'s objective is that you are always alert about your inventory, for this, when you create a product, ' +
    'There is an option that defines expiration date. Using it, the system notifies you in this interface about the products one month before expiration, and those that are already one month expired',
    buy_add: 'If you want to keep your accounting part updated, the system offers you the function so that you reflect your purchases, just as you make them,' +
      ' storing all the necessary information for reports, summaries and statistics',
    buy_products: 'Using this interface, you see the history of purchases made in the day, although you can also extend them by a date range in your filter',
    supply_process: 'Orders in process are the purchases reflected in the system, but that have not been finalized or closed',
    supply_emit: 'The issued orders are purchase requests that are still in negotiation, or purchase preforms',
    supply_cancelled: 'Canceled Orders/Orders are purchases that for some reason were not completed and it was decided to cancel',
    supplier_list: 'To make your processes automatic, the system recommends that you reflect your suppliers in it. With a simple list, ' +
      'you will always take into account your SUPPLIERS and ACCOUNTS PAYABLE to them',
    category_list: 'Billing Money provides you with an extensive list of categories, but if they don\'t fit your business you can add new categories and edit them if necessary',
    active_category_list: 'Having a long list of categories, you may not use all of them, so from here you can activate or deactivate them according to your needs',
    marks_list: 'Using this interface, you can define the brands of the products you offer, to be more specific and detailed in their characteristics',
    modifiers_list: 'Modifiers will be a plus that your products will have, but they are not required at the time of sale. eg Their product is a cheese pizza,' +
      'the modifiers would be all the additions it will have: Mushroom, Pineapple Ham, etc. A price is defined for each modifier, and it is added to the value of the product at the time of sale.',
    bank_list: 'To carry out a more organized accounting, the system allows you to keep all the banks virtually, ' +
      'reflecting the activities or accounting movements associated with them. By default one is generated',
    account_classify: 'From this interface you will be able to see the accounting system reflected through the operations you have performed or configured.' +
      'By default Billing Money generates a basic structure, which you can add to your needs',
    accounting_account: 'Accounts are operating instruments where assets (accounts receivable) are recorded, ' +
      'the liabilities (accounts payable) and the capital of a company (cash flow). So these reflect the increases and ' +
      'the decline that an institution has.',
    profitableness: 'Making use of all the information provided by accounting movements, whether they are money inflows or outflows, ' +
      'Billing Money gives you accurate information or a summary of the profitability of your business, always based on the information entered.',
    account_income: 'All the income that you reflect in the system, you can see from this interface',
    account_expenses: 'All the expenses that you reflect in the system, you can see from this interface',
    account_bills: 'Regardless of expenses for purchases, you incur other expenses, it can be Electricity, Internet, Water, Gas, etc.' +
      'These are reflected and help you tremendously in your bottom line',
    account_pay_list: 'If your business allows accounts receivable, from this interface, you can have a list of all customers who incur it.' +
      'You can also update the payments that it is making',
    account_receivable_list: 'If your business allows accounts payable, from this interface, you can have a list of all the vendors that you incur it with.' +
      'You can also update the payments you are making to it',
    tax_list: 'With this component you define the Taxes you must pay, as well as their value',
    discount: 'With this component you define the Discounts that will be made, as well as their value',
    exchange_rate: 'If your business charges or pays in different currencies, this component will help your business as it allows you to list them and set your own exchange rate',
    sell_product_margin: 'Gives you a graph, table and summary on the products sold that give you the highest profit margin',
    sell_product: 'Gives a graph, table and summary of the best selling products',
    sell_mark: 'Gives a graph, table and summary of the best selling Brands',
    sell_types_payment: 'Gives a graph, a table and a summary of the payment method that is most traded',
    sell_user: 'Gives a graph, table and summary on the employee who sells the most',
    summary: 'Complete summary taking into account the product with the highest profit margin, the best seller, the brands, payment methods and the employee who sells the most',
    employer_list: 'List of all employees, as well as the access permissions they have',
    manager_access: 'List of access permissions assigned or to be assigned to employees',
    shop_online: 'Listing and management of online stores',
    clients_list: 'List and information of clients',
    partners: 'You can work from anywhere, at any time with no schedules or obligations other than attracting customers to the application. ' +
      'To be a Partner of our application, you only have to fill out a form and wait to be approved, remember that this does not require an investment,' +
      'However you will earn a commission. Our company will also offer marketing preparation courses, and other topics for you to keep your affiliates' +
      ' within our system and you can earn your part every month.',
    setting: 'A general system configuration, modules grouped for a basic configuration and start selling quickly',
    fiscal_init: 'First invoice that will be listed, that is, if you have issued 47, you must enter 48, in this way the next fiscal invoice issued will be with that number',
    fiscal_prefix: 'If your tax invoices have prefixes included, just type them and press ENTER, avoid the "-" character ' +
      'the system will put it by default to separate the prefix from the number'
  },
  articles: {
    price_product: 'Price(Product)',
    total_price_product: 'Total Price(Product)',
    remove_inventory: 'Inventory deleted',
    name: 'Article',
    due_date: 'Due Date',
    names: 'Articles',
    price: 'Price',
    cost: 'Cost',
    percent: 'Profit %',
    sell_by: 'Sold by',
    unit: 'Unit',
    p_v: 'Weight/Volume',
    ref: 'Reference',
    inventory: 'Inventory',
    new_inventory: 'New Inventory',
    total_inventory: 'Total Inventory',
    composite: 'Composite article',
    track_inventory: 'Track inventory',
    itbis: 'ITBIS (18%)',
    tax: 'Tax',
    tax_by_sale: 'Total Taxes by Sale',
    tax_by_product: 'Taxes by Products',
    taxes: 'Taxes',
    lay: 'Law tip (10%)',
    walking: 'Walking',
    composite_text: 'Composite articles contain a certain number of other articles',
    online_text: 'Articles will be inserted and sale online or not',
    onlineSale: 'Online Sale',
    onlineSale_text: 'This article will by sale in virtual shop'
  },
  panel: {
    basic: 'Basic',
    inventory: 'Inventory',
    variant: 'Variant',
    shop: 'Shop'
  },
  variants: {
    variant: 'Variant',
    name: 'Name',
    price: 'Price',
    cost: 'Cost',
    ref: 'Reference',
    barCode: 'Bar Code',
    options: 'Options',
    cant: 'Quantity',
    total_cost: 'Total Cost',
    direct_cost: 'Direct Cost',
    direct_costs: 'Direct Costs',
    indirect_cost: 'Indirect Cost',
    indirect_costs: 'Indirect Costs',
    total_price: 'Total Price',
    total_cant: 'Total Quantity'
  },
  shop_article: {
    bajo_inventory: 'Under Invenroty',
    under_inventory: 'Low Stock',
    stock: 'In Stock',
    enabled: 'Available',
    person_sale: 'Person Sale'
  },
  representation: {
    representation: 'Representation',
    color_shape: 'Color',
    image: 'Image'
  },
  buy_product: {
    supply: 'Supplier'
  },
  payment: {
    name: 'Payment Method',
    counted: 'Counted',
    cash: 'Cash',
    bank: 'Bank',
    credit_card: 'Credit Card',
    card: 'Card',
    check: 'Check',
    credit: 'Credit',
    deposit: 'Deposit',
    wire_transfer: 'Bank Transfer',
    digital_transfer: 'Digital Transfer',
    other: 'Other',
    pay_before: 'Pay before',
    pay_delay: 'Pay for delay',
    cant_pay: 'Pay',
    cant_charge: 'Charge',
    cant_back: 'Return',
    check_number: 'Check Number',
    card_number: 'Card Number',
    card_expiry: 'Card Expiry',
    card_cvc: 'CVC'
  },
  supplier: {
    name: 'Provider',
    identity: 'ID / Document',
    phone: 'Phone',
    email: 'Mail',
    country: 'Country',
    address: 'Address',
    contract: 'Contract Number',
    expense: 'Expense Category',
    note: 'Note'
  },
  tax: {
    name: 'Tax',
    nameGeneral: 'General tax',
    value: 'Value',
    rate: 'Rate',
    percent: 'Percent',
    permanent: 'Fixed',
    noFacture: 'No. Bill',
    type: 'Type',
    include_tax: 'Included in the price',
    added_tax: 'Added to price',
    option_tax: 'Apply to all existing articles',
    example: 'Example: For a price of 100 {1}, the {0}% would be: {0} {1}',
    to_pay_tax: 'To pay for Tax',
    total_pay_tax: 'Total for Tax',
    outstanding: 'Outstanding',
    to_pay_discount: 'To discount',
    total_pay_discount: 'Total Discount'
  },
  delivery: {
    name: 'Delivery',
    nameGeneral: 'General Delivery',
    value: 'Value',
    rate: 'Rate',
    percent: 'Percent',
    permanent: 'Fixed',
    noFacture: 'No. Bill',
    type: 'Type',
    include_tax: 'Included in the price',
    added_delivery: 'Added to price',
    option_delivery: 'Apply to all existing articles',
    example: 'Example: For a price of 100 {1}, the {0}% would be: {0} {1}',
    to_pay_delivery: 'To pay for Delivery',
    total_pay_delivery: 'Total for Delivery',
    outstanding: 'Outstanding',
    to_pay_discount: 'To discount',
    total_pay_discount: 'Total Discount'
  },
  perquisite: {
    name: 'Perquisite',
    nameGeneral: 'General Perquisite',
    value: 'Value',
    rate: 'Rate',
    percent: 'Percent',
    permanent: 'Fixed',
    noFacture: 'No. Bill',
    type: 'Type',
    include_tax: 'Included in the price',
    added_perquisite: 'Added to price',
    option_perquisite: 'Apply to all existing articles',
    example: 'Example: For a price of 100 {1}, the {0}% would be: {0} {1}',
    to_pay_perquisite: 'To pay for Perquisite',
    total_pay_perquisite: 'Total for Perquisite',
    outstanding: 'Outstanding',
    to_pay_discount: 'To discount',
    total_pay_discount: 'Total Discount'
  },
  marks: {
    description: 'Description',
    color: 'Color',
    avatar: 'Avatar',
    marks_edit: 'Edit Marks',
    marks_delete: 'Delete Marks',
    marks_show: 'Show Marks'
  },
  pay: {
    counted: 'Counted',
    credit: 'Credit',
    pay: 'Payment',
    pays: 'Payments',
    extra_data: 'Additional data',
    total_pay: 'Total paid',
    total_to_pay: 'Total to collect',
    sub_total: 'Sub Total',
    total: 'Total'
  },
  supply: {
    supplier_shop: 'Supplier Shop',
    supply_shop: 'My Shop',
    name: 'Purchase',
    emit: 'Supply Emit',
    received: 'Supply Received'
  },
  order: {
    name: 'Order',
    emit: 'Order Emit',
    received: 'Order Received'
  },
  sale: {
    update_price: 'Actual Price',
    sale_price: 'Sale Price',
    sale: 'Sale',
    online_text: 'This sale will be online, and reported by Digital Box',
    selectArticle: 'Please select the article',
    no_article: 'There are no existing items in this store without categories, please add items first',
    selectShop: 'You must select a store',
    discountGeneral: 'General discount',
    clear_cart: 'Clear Cart',
    ticket: 'TICKET SALE',
    fiscal: 'TAX RECEIPT',
    ticket_buy: 'TICKET BUY',
    facture: 'Invoice',
    cart: 'Cart',
    add_cart: 'Add to Cart',
    pay_detail: 'Pay detail',
    cant_money: 'Cant of Money',
    cant_Article: 'Cant of Product',
    state: {
      solicited: 'Solicited',
      approved: 'Approved',
      denied: 'Denied',
      blocked: 'Blocked',
      open: 'In process',
      preform: 'Preform',
      accepted: 'Accepted',
      cancelled: 'Canceled',
      close: 'Closed'
    },
    emptyArticle: 'This shop has no items. Please stock the store '
  },
  box_state: {
    open: 'Open',
    close: 'Close'
  },
  tables_close: 'Table Free',
  tables_open: 'Table in Use',
  report: {
    contact_us: 'Contact Us',
    cant: 'Cant',
    print_fiscal: 'Print Fiscal',
    print_ticket: 'Print Ticket',
    print_letter: 'Print Letter',
    breakdown: 'Breakdown',
    sale_footer: '¡THANK YOU FOR SHOPPING IN OUR STORE!',
    top5Category: 'The 5 Categories that sell the most',
    top5Articles: 'Articles that sell the most',
    top5Payment: 'The Payment Methods that are sold the most',
    top5Employers: 'The Employers that are sold the most',
    netPrice: 'Net Price',
    total_sale: 'Sale Price',
    final_price: 'Final Price',
    grossPrice: 'Gross Price',
    cant_operations: 'Cant Operations',
    cant_sale: 'Cant Sale',
    grossSale: 'Gross sale',
    discountsSale: 'Sales Discounts',
    graphics: 'Graphic',
    noTop5: 'There are no sales to display information in the selected interval',
    barGraphics: 'Bar Graph',
    margin: 'Margin'
  },
  sector: {
    name: 'Sector',
    arts: 'Art',
    rental: 'Rental',
    food: 'Food',
    drink: 'Drinks',
    health: 'Health',
    agricultural: 'Agricultural',
    industrial: 'Industrial',
    hostelry: 'Hospitality',
    home: 'Home',
    shops: 'Shops',
    ironmongery: 'Hardware store',
    restaurant: 'Restaurants',
    tourism: 'Tourism',
    construction: 'Construction',
    service: 'Services',
    technologies: 'Technologies',
    consultancy: 'Consulting',
    education: 'Education',
    entertainment: 'Entertainment',
    automotive: 'Automotive',
    transport: 'Transport',
    real_state: 'Real estate',
    others: 'Others'

  },
  dashboard: {
    sales: 'Sales',
    salesSub: 'Sales increase',
    expenses: 'Expenses',
    expensesSub: 'Total expenses',
    costs: 'Costs',
    costsSub: 'Total costs',
    revenue: 'Income',
    revenueSub: 'Increase in revenue',
    orders: 'Orders',
    ordersSub: 'Increase of orders',
    info: 'Information',
    access: 'Accesses',
    mergeTable: 'Profit margin',
    timeLine: 'Last invoices',
    timeLineText: 'has created new sale for customer'
  },
  boxes: {
    difference: 'Difference',
    init: ' Initial Balance',
    current: 'CurrentBalance',
    future: 'Future Balance',
    final: 'Final Balance'
  },
  tour: {
    buttonSkip: 'Skip',
    buttonPrevious: 'Previous',
    buttonNext: 'Next',
    buttonStop: 'End',
    initGuide: 'Click to start guide'
  },
  steps: {
    step_5: 'Best sellers'
  },
  tourMsg: {
    step1: '<strong>BILLING MONEY APP</strong>!<br> Welcome to our system',
    step2: '<strong>Menu</strong>!<br> Here we find the main functionalities by modules',
    step3: '<strong> Configuration </strong><br> General <a href="/setting" style="color:#4fc08d">configuration</a> elements ',
    step4: '<strong>Management of Articles</strong>!<br> ' +
      '<a href="/articles/product.list" style="color:#4fc08d">Products</a><br>' +
      '<a href="/articles/category.list" style="color:#4fc08d">Categories</a>',
    step5: '<strong> Sales Management </strong>! <br>' +
      '<a href="/sales/vending.list" style="color:#4fc08d">Sales </a><br>' +
      '<a href="/sales/refund.list" style="color:#4fc08d">Refunds </a><br>' +
      '<a href="/sales/boxes.list" style="color:#4fc08d">Boxes </a>',
    step6: '<strong> Employee management </strong>! <br>' +
      '<a href="/users/employer.list" style="color:#4fc08d">Employee</a> list  <br>' +
      '<a href="/users/assistance.list" style="color:#4fc08d">Attendance</a> cards',
    step7: '<strong> Management of <a href="/clients/client.list" style="color:#4fc08d"> clients </a> </strong>!',
    step8: '<strong> Managing my finances </strong>! <br>' +
      '<a href="/finance/supplier.list" style="color:#4fc08d">Suppliers </a> <br>' +
      '<a href="/finance/buy_list" style="color:#4fc08d">Purchasing management </a>',
    step9: '<strong>Summary</strong>!<br>Graphical summary of my sales',
    step10: '<strong> Nav Icon </strong>! <br> Open and close the general menu',
    step11: '<strong> Lock Icon </strong>! <br> Lock screen or app',
    step12: '<strong> Full Screen Icon </strong>! <br> Open the application in full screen',
    step13: '<strong> Sales </strong>! <br> Direct access to product sales',
    step14: '<strong> Purchases </strong>! <br> Direct access to product purchases',
    step15: '<strong> Language selector </strong>! <br> Language selector for display',
    step16: '<strong> User Icon </strong>! <br>' +
      '<a href="/user/profile" style="color:#4fc08d"> Profile </a> <br>' +
      'Sing Out',
    step17: '<strong> Theme Setting </strong>! <br> Select application colors, languages and styles',
    step18: '<strong> General statistics </strong>! <br> Main company indicators'
  },
  um: {
    name: 'Unit of measurement',
    distance: 'Distance',
    u: 'Unit',
    pq: 'Package',
    mm: 'Millimeter',
    cm: 'Centimeter',
    pp: 'Inch',
    m: 'Meter',
    footer: 'Foot',
    yard: 'Yard',
    km: 'Kilometer',
    mll: 'Mile',
    temp: 'Temperature',
    c: 'Celsius',
    k: 'Kelvin',
    f: 'Faraday',
    time: 'Time',
    s: 'Second',
    mt: 'Minute',
    h: 'Hora',
    d: 'Día',
    ms: 'Mass',
    mg: 'Milligram',
    g: 'Gram',
    kg: 'Kilogram',
    ton: 'Toned',
    lb: 'Libra',
    oz: 'Pound',
    velocity: 'Velocity',
    m_s: 'Metro/Segundo',
    km_h: 'Kilometer/Hors',
    ms2: 'Acceleration',
    vol: 'Volume',
    gl: 'Gallon',
    l: 'Liter',
    m3: 'Metro cubic',
    m2: 'Metro square',
    digital: 'Digital',
    bit: 'Bit',
    byte: 'Byte',
    mega: 'Mega',
    tr: 'Tera',
    px: 'Pixel',
    mPx: 'MegaPixel',
    other: 'Others',
    mhz: 'Microhertz',
    hz: 'Hertz',
    hom: 'Ohmio',
    volt: 'Volt',
    amp: 'Ampere',
    joule: 'Joule'
  },
  helpSales: {
    listStep1: '<strong> Add button </strong>! <br> Create new sale',
    listStep2: '<strong> Filters </strong>! <br> Set search filters and download data',
    listStep3: '<strong> List </strong>! <br> List of all sales created.'
  },
  helpSaleManager: {
    selectShop: 'Select Shop',
    selectBox: 'Select the Box where you sell. If the box is closed, then the system will open it with $0.00',
    addBox: 'Add a new Box',
    selectArticle: 'Select all articles that you will sell'
  },
  supply_state: {
    state: {
      name: 'State',
      requested: 'Requested',
      accepted: 'Accepted',
      process: 'Process',
      ship: 'Ship',
      received: 'Received',
      cancelled: 'Cancelled'
    }
  },
  online: {
    online: 'Online',
    delivery: 'Send Cost',
    template: 'Template',
    templates: {
      template_1: 'Template 1',
      template_2: 'Template 2',
      template_3: 'Template 3'
    },
    merchant_id: 'Merchant ID',
    paypal_email: 'Registered mail in Paypal',
    enable_email: 'Allow email',
    enable_phone: 'Allow phone',
    enable_address: 'Allow address',
    enable_defaultCurrency: 'Allow default currency',
    showing: 'Showing',
    of: 'of',
    permit_invite: 'Permit invited',
    credits_pay: 'Pay Credentials',
    paypal_credit: 'PayPal Credentials',
    paypal_client_id: 'CLIENT ID',
    paypal_secret: 'PAYPAL SECRET'
  },
  notifications: {
    supplier_is_register: 'Your Supllier {0} is register in BILLING MONEY APP. You can make Orders using the system',
    solicited_supply: 'Client {0} has registered a supply'
  },
  bank: {
    count_number: 'Count Number',
    type_operation: 'Type of Operation Banking'
  },
  partners: {
    title: 'Solicit of partner',
    referrals: 'Referral count',
    referralsSub: 'Linked referral count',
    referrer: 'Referenced',
    referrerSub: 'Referenced by company',
    not_specified: 'Not Specified',
    referralsList: 'Referrals List',
    activate: 'Activate your Referral Program',
    solicit: 'Solicit the activation of referral',
    activate_description: 'If you invite a person and this person pays for a membership in "UNICORN" version, you get proceeds. ' +
      'Billing Money App pay you a commissions using runway.',
    solicit_description: 'Billing Money App holds back all right to activate or no his account of referred, ' +
      ' in dependence of the needs of company have in that place ( Place, Age etc.), ' +
      'and the conditions that you present in application form, this process can come to several days delay',
    activate_activation: 'Please associate your account with ours, complete the information requested by the gateway in order to transfer the payments.',
    confirm_copied: 'Your referral link has been copied: ',
    error_copied: 'The text could not be copied',
    wait_result: 'Billing Money App is proccesing the data to accept your solicit, this proccess maybe delay days,' +
      ' let\'s wait dispense our delay, we will try to speed up his process as soon as possible. Let\'s have the results in story we will notify him' +
      'to the mail that you registered in our system. Thank you for joining us!!! Soon we will send you the result of his request, and when you be approved you will can ' +
      ' beginning to invoice of immediate way. Welcome to Billing Money Family, where we all won',
    online_stripe_associate: 'Please associate your account with ours, complete the information requested by the gateway in order to transfer the payments.',
    existing_account: 'Associate to Stripe account',
    existing_account_paypal: 'Associate to PayPal account',
    no_paypal_asociate: 'Please edit your configuration and in Step 3(Gateways), insert the data required to pay by PayPal',
    no_articles_online: 'Please add products for sale online, edit their settings, and in step 4: Inventory Management, activate new products, which have inventory greater than zero, as well as their price online',
    is_not_online: 'If you show this icon, your store is not online, since you must first synchronize your account to make payments.',
    is_online: 'If you show this icon, your store is correctly configured and online'
  },
  accounting_category: {
    buy_bills: 'Buy Bills',
    name: 'Accounting Category',
    bills: 'Bills',
    others_incomes: 'Others Incomes',
    expenses_buy: 'Investments in Purchase',
    other_expenses: 'Others Expenses',
    others_bills: 'Others Bills',
    sale_bills: 'Bills in Sale/Buy',
    expenses_credit: 'Expenses by Credit Accounts',
    accounting_accounts: 'Accounting Accounts',
    actives: 'Actives',
    passives: 'Passives',
    incomes: 'Incomes',
    income_tax: 'Incomes to Taxes',
    income_credit: 'Incomes by Credit Accounts',
    income_tax_credit: 'Incomes to Tax by Credit Accounts',
    income: 'Income',
    expenses: 'Expenses',
    expense: 'Expense',
    amount: 'Amount',
    code: 'Code',
    nature: 'Nature',
    creditor: 'Creditor',
    debtor: 'Debtor',
    debit: 'Debit',
    assets: 'Assets(Credit)',
    description_nature: {
      creditor: 'By their nature, they increase their balance when registering a credit (credit, credit).\n' +
        'On the other hand, your balance is reduced by making a debit record (credit, credit) in them\n',
      debtor: 'By its nature, your balance decreases when you register a credit (credit, credit).\n' +
        'On the other hand, your balance is increased by making a debit record (credit, credit) in them.\n'
    }
  },
  fiscal: {
    receiver: 'Receiver',
    sender: 'Sender',
    rnc: 'RNC or ID',
    cant_digits: 'Number of Digits',
    cant_at: 'Generate up to',
    first_facture: 'First Facture',
    prefix: 'Prefix of Facture'
  },
  plan: {
    name: 'Plan',
    period: 'Period',
    general: 'General',
    countries: 'Countries',
    is_active: 'Active',
    is_free: 'Free',
    use_coupon: 'Use Coupon',
    coupon: 'Coupon',
    signup_fee: 'Sign up fee',
    invoice_period: 'Invoice period(Days)',
    test_day: 'Test days',
    platform_payment_gateway: 'Platform Payment Gateway',
    partner_payment_gateway: 'Partner Payment Gateway',
    partner_percent: 'Percent to partner',
    partner_permanent: 'Permanent amount to business partner',
    invoice_interval: 'Invoice interval',
    trial_period: 'Trial period',
    rest_time: 'Rest Time',
    trial_interval: 'Trial interval',
    resettable_interval: 'Resettable interval',
    active_subscribers_limit: 'Limit of users',
    prorate_day: 'Prorate day',
    subscriber: 'Subscriber',
    benefited: 'Beneficiary',
    blessed: 'Blessed',
    referral_limit: 'Referral Limit',
    benefit_limit: 'Benefit Limit',
    valid_until: 'Valid until',
    subscriptions: 'Subscriptions',
    month: 'Month',
    trimester: 'Trimester(3 Month)',
    semester: 'Semester(6 Month)',
    annually: 'Annually(12 Month)',
    years_2: '2 Years(24 Month)',
    years_3: '3 Years(36 Month)',
    years_4: '4 Years(48 Month)',
    years_5: '5 Years(60 Month)',
    week: 'Week',
    time_limit: 'Establish time limit by subscriptions',
    starts_at: 'Starts at',
    ends_at: 'Ends at',
    country: 'Country/Countries',
    day: 'Day',
    type: {
      free: 'Free',
      basic: 'Basic',
      personalized: 'Personalized',
      unicorn: 'Unicorn'
    }
  },
  gateway: {
    stripe: 'Stripe',
    stripe_secret: 'STRIPE_SECRET',
    stripe_key: 'STRIPE_KEY',
    paypal: 'PayPal'
  },

  sex: {
    text: 'Sex',
    f: 'Feminine',
    o: 'Other',
    m: 'Masculine'
  },
  level_student: {
    text: 'Level student',
    primary: 'Primary',
    preparatory: 'Preparatory',
    university: 'University',
    master: 'Master',
    doctor: 'Doctor',
    other: 'Other'
  },
  type: {
    type_account: 'Type Account',
    merchant: 'Merchant',
    partner: 'Partner',
    beneficiary: 'Beneficiary'
  },
  texts: {
    last_week: 'Last week',
    suspend: 'If you suspend this account, users will automatically not be able to access it until it is enabled again, or the time defined below',
    sale_exchange_rate: 'Price is {0} {1}',
    home_text: 'A solution for your business, for your company. ' +
      'A system that grows every day to please the client and make their life more comfortable',
    download_app: 'Download our mobile aplication for you device in the main stores',
    contact_text: 'Our main objective is to please the client by making their business more prosperous with the options that we present to them.' +
      'We are always available for every question or concern you have.',
    sale_characteristic: 'BILLING MONEY APP te permite hacer las ventas a tu gusto, y las que se realicen en la tienda online, serán monitoreadas constantemente. ' +
      'Si un empleado realiza un reembolso, te queda constancia porque BILLING MONEY APP almacena esa información',
    employer_characteristic: 'puedes adicionar tus empleados, y desde la misma plataforma ' +
      'saber cuál vendió qué producto. Te ofrecemos un gráfico de resumen de ' +
      'venta de empleado para que te nutras de la información',
    boxes_characteristic: 'BILLING MONEY APP permite realizar ventas por cajas para controlar mejor ' +
      'los ingresos. Permite abrir, cerrar y cuadrar una cajar de forma rápida y sencilla. Además de emitir una notificación cuando no se cierra y' +
      ' el cuadre no es exacto.',
    choice_plan_text: 'Choice your preference plan',
    basic_plan_description: 'Choice your preferenc plan',
    facture_characteristic: 'With BILLING MONEY APP you can issue invoices in different formats. We allow you to manage your tax invoices from the same platform',
    free_characteristics1: 'You can use all the basic functionalities for free for a period of 7 days',
    free_characteristics2: 'The company reserves the right to give this version of the system to specific people who make a notable contribution to the application.',
    free_characteristics3: 'Depending on the agreements and economic conditions in certain countries, this version can be obtained for use for a specified period of time.',
    basic_characteristics1: 'There is no registration limit in any of the functionalities except for those exposed in the next point.',
    basic_characteristics2: 'It does not allow issuing tax invoices, or adding more roles than those established (2)',
    basic_characteristics3: 'Depending on the agreements and economic conditions, certain countries can obtain this version for use for an indefinite period of time.',
    unicorn_characteristics1: 'There is no registration limit in any of the functionalities.',
    unicorn_characteristics2: 'Management and issuance of tax invoices, add as many roles as you deem appropriate, automatic accounting management.',
    unicorn_characteristics3: 'The company reserves the right to give this version of the system to specific people who make a notable contribution to the application.',
    personalized_characteristics: 'We can customize the application, design and create functions according to your needs. We are flexible, and we are always willing to find the solutions that your company requires to achieve financial success.',
    note_beneficiaries: 'The company is open to make personalized plans, you can contact our team and we can establish better communication.',
    partner_text: 'Billing Money gives its partners up to a commission on the payment made by the brought customer if the buys the Unicorn version',
    partner_characteristic: 'You can work from anywhere, at any time without schedules or obligations other than attracting clients to the application. ' +
      'To be a Partner of our application, you just have to fill out a form and wait to be approved, think that this does not require an investment,' +
      'however you will earn a commission. Our company will also offer marketing preparation courses, and other topics for you to keep your affiliates' +
      'within our system and you can earn your share every month.',
    partner_characteristic2: 'We seek responsibility, and creativity so that its benefits grow. Just by investing some of your time and sharing on social networks, ' +
      'Your income will increase exponentially, we offer you the opportunity, you just have to decide and you are already part of the team. '
  },
  date_text: {
    seconds: 'Seconds',
    minutes: 'Minutes',
    init: 'Init',
    finish: 'Finish',
    hours: 'Hours',
    last_24H: 'Last 24 hours',
    days: 'Days',
    months: 'Months',
    years: 'Years'
  },
  categories: {
    1: 'food and drink > food > soups and broths',
    2: 'food and drink > food > nuts and seeds',
    3: 'food and drink > food > snacks and crisps',
    4: 'food and drink > food > meat substitutes',
    5: 'food and drink > food > spices and seasonings',
    6: 'food and drink > food > pasta and grains',
    7: 'food and drink > food > prepared food and meal kits',
    8: 'food and drink > food > chocolate and confectionery',
    9: 'food and drink > food > dips and spreads',
    10: 'food and drink > food > meat and seafood',
    11: 'food and drink > food > bakery and bread',
    12: 'food and drink > food > condiments and sauces',
    13: 'food and drink > food > dairy eggs and cheese',
    14: 'food and drink > food > frozen food',
    15: 'food and drink > food > baking ingredients',
    16: 'food and drink > food > fruits and vegetables',
    17: 'food and drink > food > breakfast and cereal',
    18: 'food and drink > food',
    19: 'food and drink > beverages > sports and energy drinks',
    20: 'food and drink > beverages > juice',
    21: 'food and drink > beverages > powdered beverage mixes',
    22: 'food and drink > beverages > soda',
    23: 'food and drink > beverages > water',
    24: 'food and drink > beverages > tea and coffee',
    25: 'food and drink > beverages > alcoholic beverages',
    26: 'food and drink > beverages > milk',
    27: 'food and drink > beverages',
    28: 'food and drink',
    29: 'toys & games > outdoor toys',
    30: 'toys & games > stuffed animals & plush toys',
    31: 'toys & games > games > role-playing games',
    32: 'toys & games > games > chess',
    33: 'toys & games > games > miniature & war games',
    34: 'toys & games > games > dice games',
    35: 'toys & games > games > tile games',
    36: 'toys & games > games > card games',
    37: 'toys & games > games > dexterity games',
    38: 'toys & games > games > board games',
    39: 'toys & games > games',
    40: 'toys & games > puzzles',
    41: 'toys & games > riding toys',
    42: 'toys & games > pretend play',
    43: 'toys & games > toy vehicles',
    44: 'toys & games > remote control toys',
    45: 'toys & games > educational toys',
    46: 'toys & games > dolls & dolls houses',
    47: 'toys & games > building toys',
    48: 'toys & games > action figures',
    49: 'toys & games > electronic toys',
    50: 'toys & games',
    51: 'diy > home heating and cooling',
    52: 'diy > building supplies & hardware',
    53: 'diy > plumbing & fixtures',
    54: 'diy > tools > ladders & scaffolding',
    55: 'diy > tools > painting tools',
    56: 'diy > tools > power tools',
    57: 'diy > tools > measuring tools & sensors',
    58: 'diy > tools > air tools',
    59: 'diy > tools > hand tools',
    60: 'diy > tools',
    61: 'diy',
    62: 'sporting goods > sports equipment > ice & roller hockey',
    63: 'sporting goods > sports equipment > rugby',
    64: 'sporting goods > sports equipment > tennis & racket sports',
    65: 'sporting goods > sports equipment > volleyball',
    66: 'sporting goods > sports equipment > golf',
    67: 'sporting goods > sports equipment > football',
    68: 'sporting goods > sports equipment > american football',
    69: 'sporting goods > sports equipment > athletics',
    70: 'sporting goods > sports equipment > cricket',
    71: 'sporting goods > sports equipment > hockey & lacrosse',
    72: 'sporting goods > sports equipment > boxing & martial arts',
    73: 'sporting goods > sports equipment > dance and gymnastics',
    74: 'sporting goods > sports equipment > baseball & softball',
    75: 'sporting goods > sports equipment > basketball',
    76: 'sporting goods > sports equipment',
    77: 'sporting goods > exercise & fitness > yoga & pilates',
    78: 'sporting goods > exercise & fitness > cardio equipment',
    79: 'sporting goods > exercise & fitness > weight-lifting equipment',
    80: 'sporting goods > exercise & fitness',
    81: 'sporting goods > indoor games',
    82: 'sporting goods > outdoor recreation > fishing',
    83: 'sporting goods > outdoor recreation > water sports & boating',
    84: 'sporting goods > outdoor recreation > skateboarding',
    85: 'sporting goods > outdoor recreation > outdoor games',
    86: 'sporting goods > outdoor recreation > cycling',
    87: 'sporting goods > outdoor recreation > winter sports',
    88: 'sporting goods > outdoor recreation > climbing',
    89: 'sporting goods > outdoor recreation > inline & roller skating',
    90: 'sporting goods > outdoor recreation > camping & hiking',
    91: 'sporting goods > outdoor recreation',
    92: 'sporting goods',
    93: 'pet supplies > pet carriers & containment',
    94: 'pet supplies > pet grooming supplies',
    95: 'pet supplies > small animal supplies',
    96: 'pet supplies > pet feeding & watering supplies',
    97: 'pet supplies > reptile & amphibian supplies',
    98: 'pet supplies > pet collars harnesses & leads',
    99: 'pet supplies > pet steps & ramps',
    100: 'pet supplies > bird supplies',
    101: 'pet supplies > pet care & health',
    102: 'pet supplies > pet beds & bedding',
    103: 'pet supplies > fish supplies',
    104: 'pet supplies > cat supplies',
    105: 'pet supplies > dog supplies',
    106: 'pet supplies',
    107: 'patio & garden > watering equipment',
    108: 'patio & garden > ponds & water features',
    109: 'patio & garden > outdoor power equipment',
    110: 'patio & garden > outdoor lighting',
    111: 'patio & garden > barbecues and outdoor cooking',
    112: 'patio & garden > plants seeds & bulbs',
    113: 'patio & garden > outdoor furniture',
    114: 'patio & garden > pools & spas',
    115: 'patio & garden > plant care soil & accessories',
    116: 'patio & garden > garden decor',
    117: 'patio & garden > garden hand tools & equipment',
    118: 'patio & garden',
    119: 'office supplies > writing supplies',
    120: 'office supplies > presentation & display boards',
    121: 'office supplies > staplers & punches',
    122: 'office supplies > projectors',
    123: 'office supplies > tape & adhesives',
    124: 'office supplies > filing & storage',
    125: 'office supplies > clips & fasteners',
    126: 'office supplies > labels',
    127: 'office supplies > calendars & planners',
    128: 'office supplies > binders & accessories',
    129: 'office supplies > office equipment & machines',
    130: 'office supplies > postal supplies',
    131: 'office supplies > paper products',
    132: 'office supplies',
    133: 'musical instruments > pro audio equipment',
    134: 'musical instruments > music accessories',
    135: 'musical instruments > guitars and basses',
    136: 'musical instruments > brass instruments',
    137: 'musical instruments > drums & percussion instruments',
    138: 'musical instruments > pianos & keyboard instruments',
    139: 'musical instruments > woodwind instruments',
    140: 'musical instruments > string instruments',
    141: 'musical instruments',
    142: 'media > newspapers',
    143: 'media > magazines & catalogues',
    144: 'media > books',
    145: 'media > films & tv shows',
    146: 'media > music',
    147: 'media',
    148: 'travel & luggage > garment bags',
    149: 'travel & luggage > travel accessories',
    150: 'travel & luggage > handbags and wallets > wallets',
    151: 'travel & luggage > handbags and wallets > satchels',
    152: 'travel & luggage > handbags and wallets > cross-body bags',
    153: 'travel & luggage > handbags and wallets > totes',
    154: 'travel & luggage > handbags and wallets > shoulder bags',
    155: 'travel & luggage > handbags and wallets > clutches',
    156: 'travel & luggage > handbags and wallets > belt bags & waist packs',
    157: 'travel & luggage > handbags and wallets',
    158: 'travel & luggage > suitcases',
    159: 'travel & luggage > duffel bags & gym bags',
    160: 'travel & luggage > luggage sets',
    161: 'travel & luggage > briefcases & laptop bags',
    162: 'travel & luggage > backpacks',
    163: 'travel & luggage',
    164: 'arts & crafts > craft supplies',
    165: 'arts & crafts > sewing',
    166: 'arts & crafts > needlecrafts & yarn',
    167: 'arts & crafts > beading & jewellery-making',
    168: 'arts & crafts > art supplies',
    169: 'arts & crafts',
    170: 'vehicle parts and accessories > trailer parts & accessories',
    171: 'vehicle parts and accessories > powersports parts & accessories',
    172: 'vehicle parts and accessories > motorhome & camper parts & accessories',
    173: 'vehicle parts and accessories > car parts & accessories',
    174: 'vehicle parts and accessories > boat parts & accessories',
    175: 'vehicle parts and accessories > motorcycle parts & accessories',
    176: 'vehicle parts and accessories > automotive tools',
    177: 'vehicle parts and accessories',
    178: 'antiques & collectables > collectable sports memorabilia',
    179: 'antiques & collectables > collectable home goods',
    180: 'antiques & collectables > collectable electronics',
    181: 'antiques & collectables > collectable coins and paper money',
    182: 'antiques & collectables > collectable stamps',
    183: 'antiques & collectables > collectable furniture',
    184: 'antiques & collectables > collectable appliances',
    185: 'antiques & collectables > collectable toys',
    186: 'antiques & collectables',
    187: 'baby products > baby feeding',
    188: 'baby products > baby transport > baby carriers',
    189: 'baby products > baby transport > pushchairs & accessories',
    190: 'baby products > baby transport > car seats & accessories',
    191: 'baby products > baby transport',
    192: 'baby products > baby health and safety',
    193: 'baby products > baby bathing',
    194: 'baby products > toys > activity centres & entertainers',
    195: 'baby products > toys > baby toys',
    196: 'baby products > toys',
    197: 'baby products > nappies and potty training',
    198: 'baby products > nursery > nursery bedding',
    199: 'baby products > nursery > nursery decor',
    200: 'baby products > nursery > nursery furniture',
    201: 'baby products > nursery',
    202: 'baby products',
    203: 'electronics > portable audio & video',
    204: 'electronics > home audio & video',
    205: 'electronics > home security & automation',
    206: 'electronics > mobile phones and smart watches > mobile phones',
    207: 'electronics > mobile phones and smart watches > mobile phone and smart watch accessories',
    208: 'electronics > mobile phones and smart watches > smart watches',
    209: 'electronics > mobile phones and smart watches',
    210: 'electronics > projectors',
    211: 'electronics > cameras > binoculars and telescopes',
    212: 'electronics > cameras > film cameras',
    213: 'electronics > cameras > camera accessories',
    214: 'electronics > cameras > camera drones',
    215: 'electronics > cameras > digital cameras',
    216: 'electronics > cameras > video cameras',
    217: 'electronics > cameras',
    218: 'electronics > tvs and monitors > tvs',
    219: 'electronics > tvs and monitors > computer monitors',
    220: 'electronics > tvs and monitors',
    221: 'electronics > software',
    222: 'electronics > printers & scanners',
    223: 'electronics > video game consoles and video games > video game accessories',
    224: 'electronics > video game consoles and video games > video game consoles',
    225: 'electronics > video game consoles and video games > video games',
    226: 'electronics > video game consoles and video games',
    227: 'electronics > computers and tablets > tablets & e-book readers',
    228: 'electronics > computers and tablets > networking & servers',
    229: 'electronics > computers and tablets > computer components and hardware',
    230: 'electronics > computers and tablets > laptops',
    231: 'electronics > computers and tablets > desktop computers',
    232: 'electronics > computers and tablets',
    233: 'electronics > accessories > computer peripherals',
    234: 'electronics > accessories > batteries and power supplies',
    235: 'electronics > accessories > blank media',
    236: 'electronics > accessories > cables chargers and adapters',
    237: 'electronics > accessories',
    238: 'electronics',
    239: 'health & beauty > health > oral care',
    240: 'health & beauty > health > first aid',
    241: 'health & beauty > health > feminine care',
    242: 'health & beauty > health > vision care',
    243: 'health & beauty > health > massage',
    244: 'health & beauty > health > shaving & hair removal',
    245: 'health & beauty > health',
    246: 'health & beauty > beauty > skincare > tanning oil & lotion',
    247: 'health & beauty > beauty > skincare > lip balms & treatments',
    248: 'health & beauty > beauty > skincare > masks & peels',
    249: 'health & beauty > beauty > skincare > exfoliators & scrubs',
    250: 'health & beauty > beauty > skincare > facial lotion & moisturiser',
    251: 'health & beauty > beauty > skincare > pore strips',
    252: 'health & beauty > beauty > skincare > night treatments',
    253: 'health & beauty > beauty > skincare > sun cream',
    254: 'health & beauty > beauty > skincare > skin cleansing brushes & systems',
    255: 'health & beauty > beauty > skincare > acne & blemish treatments',
    256: 'health & beauty > beauty > skincare > cleansers & toners',
    257: 'health & beauty > beauty > skincare',
    258: 'health & beauty > beauty > nail care > pumice stones',
    259: 'health & beauty > beauty > nail care > nail polish & art',
    260: 'health & beauty > beauty > nail care > nail tools',
    261: 'health & beauty > beauty > nail care > artificial nails & accessories',
    262: 'health & beauty > beauty > nail care',
    263: 'health & beauty > beauty > make-up > make-up tools & accessories',
    264: 'health & beauty > beauty > make-up > lip make-up > lip plumpers',
    265: 'health & beauty > beauty > make-up > lip make-up > lip gloss',
    266: 'health & beauty > beauty > make-up > lip make-up > lip liners',
    267: 'health & beauty > beauty > make-up > lip make-up > lip stains',
    268: 'health & beauty > beauty > make-up > lip make-up > lipstick',
    269: 'health & beauty > beauty > make-up > lip make-up',
    270: 'health & beauty > beauty > make-up > make-up sets & kits',
    271: 'health & beauty > beauty > make-up > face make-up > foundations',
    272: 'health & beauty > beauty > make-up > face make-up > face powders',
    273: 'health & beauty > beauty > make-up > face make-up > blushers',
    274: 'health & beauty > beauty > make-up > face make-up > bronzers & highlighters',
    275: 'health & beauty > beauty > make-up > face make-up > face primers',
    276: 'health & beauty > beauty > make-up > face make-up > concealers',
    277: 'health & beauty > beauty > make-up > face make-up > bb cc & alphabet creams',
    278: 'health & beauty > beauty > make-up > face make-up',
    279: 'health & beauty > beauty > make-up > eye make-up > eyebrow products',
    280: 'health & beauty > beauty > make-up > eye make-up > eyeliners',
    281: 'health & beauty > beauty > make-up > eye make-up > mascara',
    282: 'health & beauty > beauty > make-up > eye make-up > eye shadows',
    283: 'health & beauty > beauty > make-up > eye make-up > false eyelashes & adhesives',
    284: 'health & beauty > beauty > make-up > eye make-up > eye primers',
    285: 'health & beauty > beauty > make-up > eye make-up',
    286: 'health & beauty > beauty > make-up',
    287: 'health & beauty > beauty > haircare & styling > shampoos & conditioners',
    288: 'health & beauty > beauty > haircare & styling > rollers & curlers',
    289: 'health & beauty > beauty > haircare & styling > straighteners & curling tongs',
    290: 'health & beauty > beauty > haircare & styling > styling products',
    291: 'health & beauty > beauty > haircare & styling > hair oils & protectors',
    292: 'health & beauty > beauty > haircare & styling > hair ties & styling accessories',
    293: 'health & beauty > beauty > haircare & styling > hair-cutting scissors & shears',
    294: 'health & beauty > beauty > haircare & styling > hairdryers',
    295: 'health & beauty > beauty > haircare & styling > hair extensions & wigs',
    296: 'health & beauty > beauty > haircare & styling > brushes & combs',
    297: 'health & beauty > beauty > haircare & styling > hair colouring & dyes',
    298: 'health & beauty > beauty > haircare & styling',
    299: 'health & beauty > beauty > fragrances > mens fragrances',
    300: 'health & beauty > beauty > fragrances > womens fragrances',
    301: 'health & beauty > beauty > fragrances > childrens fragrances',
    302: 'health & beauty > beauty > fragrances',
    303: 'health & beauty > beauty > bath & body > bubble baths',
    304: 'health & beauty > beauty > bath & body > bath salts',
    305: 'health & beauty > beauty > bath & body > body lotions & moisturisers',
    306: 'health & beauty > beauty > bath & body > body washes & soaps',
    307: 'health & beauty > beauty > bath & body > sponges & brushes',
    308: 'health & beauty > beauty > bath & body > scrubs & exfoliants',
    309: 'health & beauty > beauty > bath & body > bath oils',
    310: 'health & beauty > beauty > bath & body > bath bombs',
    311: 'health & beauty > beauty > bath & body',
    312: 'health & beauty > beauty',
    313: 'health & beauty',
    314: 'jewellery & watches > watches > watch accessories',
    315: 'jewellery & watches > watches > childrens watches',
    316: 'jewellery & watches > watches > mens watches',
    317: 'jewellery & watches > watches > womens watches',
    318: 'jewellery & watches > watches',
    319: 'jewellery & watches > jewellery > rings',
    320: 'jewellery & watches > jewellery > necklaces',
    321: 'jewellery & watches > jewellery > jewellery sets',
    322: 'jewellery & watches > jewellery > lapel pins & brooches',
    323: 'jewellery & watches > jewellery > anklets',
    324: 'jewellery & watches > jewellery > charms & pendants',
    325: 'jewellery & watches > jewellery > earrings',
    326: 'jewellery & watches > jewellery > body jewellery',
    327: 'jewellery & watches > jewellery > bracelets',
    328: 'jewellery & watches > jewellery',
    329: 'jewellery & watches',
    330: 'home > cleaning supplies > cleaning products and chemicals',
    331: 'home > cleaning supplies > vacuums and floor cleaners',
    332: 'home > cleaning supplies > laundry supplies',
    333: 'home > cleaning supplies',
    334: 'home > large appliances > cookers & ovens',
    335: 'home > large appliances > fridges & freezers',
    336: 'home > large appliances > washers & dryers',
    337: 'home > large appliances > microwave ovens',
    338: 'home > large appliances > dishwashers',
    339: 'home > large appliances',
    340: 'home > bedding > quilts',
    341: 'home > bedding > mattress pads & protectors',
    342: 'home > bedding > duvet covers',
    343: 'home > bedding > blankets & throws',
    344: 'home > bedding > bedspreads quilts & coverlets',
    345: 'home > bedding > valances',
    346: 'home > bedding > bed sheets & pillowcases',
    347: 'home > bedding > bedding sets & collections',
    348: 'home > bedding > bed pillows & positioners',
    349: 'home > bedding',
    350: 'home > furniture > living room furniture',
    351: 'home > furniture > office furniture',
    352: 'home > furniture > dining room furniture',
    353: 'home > furniture > bedroom furniture',
    354: 'home > furniture',
    355: 'home > home goods > storage & organisation',
    356: 'home > home goods > lamps & lighting',
    357: 'home > home goods > kitchen & dining > small kitchen appliances',
    358: 'home > home goods > kitchen & dining > tableware',
    359: 'home > home goods > kitchen & dining > dinnerware',
    360: 'home > home goods > kitchen & dining > kitchen tools & utensils',
    361: 'home > home goods > kitchen & dining > bakeware',
    362: 'home > home goods > kitchen & dining > drinkware',
    363: 'home > home goods > kitchen & dining > cookware',
    364: 'home > home goods > kitchen & dining > barware',
    365: 'home > home goods > kitchen & dining',
    366: 'home > home goods > bath > towels and bath linens',
    367: 'home > home goods > bath > bath accessories',
    368: 'home > home goods > bath',
    369: 'home > home goods > home decor > festive & seasonal decor',
    370: 'home > home goods > home decor > rugs & carpets',
    371: 'home > home goods > home decor > window treatments and hardware',
    372: 'home > home goods > home decor > home fragrances',
    373: 'home > home goods > home decor > clocks',
    374: 'home > home goods > home decor > decorative accents',
    375: 'home > home goods > home decor',
    376: 'home > home goods',
    377: 'home',
    378: 'clothing & accessories > clothing accessories > womens accessories > belts',
    379: 'clothing & accessories > clothing accessories > womens accessories > gloves & mittens',
    380: 'clothing & accessories > clothing accessories > womens accessories > sunglasses',
    381: 'clothing & accessories > clothing accessories > womens accessories > scarves & wraps',
    382: 'clothing & accessories > clothing accessories > womens accessories > hats',
    383: 'clothing & accessories > clothing accessories > womens accessories',
    384: 'clothing & accessories > clothing accessories > mens accessories > ties',
    385: 'clothing & accessories > clothing accessories > mens accessories > belts',
    386: 'clothing & accessories > clothing accessories > mens accessories > hats',
    387: 'clothing & accessories > clothing accessories > mens accessories > braces',
    388: 'clothing & accessories > clothing accessories > mens accessories > sunglasses',
    389: 'clothing & accessories > clothing accessories > mens accessories > gloves & mittens',
    390: 'clothing & accessories > clothing accessories > mens accessories > scarves',
    391: 'clothing & accessories > clothing accessories > mens accessories',
    392: 'clothing & accessories > clothing accessories > girls accessories > hats',
    393: 'clothing & accessories > clothing accessories > girls accessories > gloves & mittens',
    394: 'clothing & accessories > clothing accessories > girls accessories > belts',
    395: 'clothing & accessories > clothing accessories > girls accessories > scarves',
    396: 'clothing & accessories > clothing accessories > girls accessories > sunglasses',
    397: 'clothing & accessories > clothing accessories > girls accessories',
    398: 'clothing & accessories > clothing accessories > boys accessories > ties',
    399: 'clothing & accessories > clothing accessories > boys accessories > braces',
    400: 'clothing & accessories > clothing accessories > boys accessories > wallets',
    401: 'clothing & accessories > clothing accessories > boys accessories > gloves & mittens',
    402: 'clothing & accessories > clothing accessories > boys accessories > hats',
    403: 'clothing & accessories > clothing accessories > boys accessories > scarves',
    404: 'clothing & accessories > clothing accessories > boys accessories > belts',
    405: 'clothing & accessories > clothing accessories > boys accessories > sunglasses',
    406: 'clothing & accessories > clothing accessories > boys accessories',
    407: 'clothing & accessories > clothing accessories',
    408: 'clothing & accessories > shoes and footwear > womens shoes > boots',
    409: 'clothing & accessories > shoes and footwear > womens shoes > heels',
    410: 'clothing & accessories > shoes and footwear > womens shoes > fashion trainers',
    411: 'clothing & accessories > shoes and footwear > womens shoes > slippers',
    412: 'clothing & accessories > shoes and footwear > womens shoes > sandals',
    413: 'clothing & accessories > shoes and footwear > womens shoes > flats',
    414: 'clothing & accessories > shoes and footwear > womens shoes > athletic shoes',
    415: 'clothing & accessories > shoes and footwear > womens shoes',
    416: 'clothing & accessories > shoes and footwear > mens shoes > sandals',
    417: 'clothing & accessories > shoes and footwear > mens shoes > casual and dress shoes',
    418: 'clothing & accessories > shoes and footwear > mens shoes > boots',
    419: 'clothing & accessories > shoes and footwear > mens shoes > athletic shoes',
    420: 'clothing & accessories > shoes and footwear > mens shoes > slippers',
    421: 'clothing & accessories > shoes and footwear > mens shoes > fashion trainers',
    422: 'clothing & accessories > shoes and footwear > mens shoes',
    423: 'clothing & accessories > shoes and footwear > kids shoes > baby boy shoes',
    424: 'clothing & accessories > shoes and footwear > baby shoes > baby boy shoes',
    425: 'clothing & accessories > shoes and footwear > kids shoes > girls baby shoes',
    426: 'clothing & accessories > shoes and footwear > baby shoes > girls baby shoes',
    427: 'clothing & accessories > shoes and footwear > kids shoes',
    428: 'clothing & accessories > shoes and footwear > baby shoes',
    429: 'clothing & accessories > shoes and footwear',
    430: 'clothing & accessories > clothing > womens clothing > tops > tops & t-shirts',
    431: 'clothing & accessories > clothing > womens clothing > tights socks & hosiery > socks',
    432: 'clothing & accessories > clothing > womens clothing > tights socks & hosiery > leg warmers',
    433: 'clothing & accessories > clothing > womens clothing > tights socks & hosiery > sheers & tights',
    434: 'clothing & accessories > clothing > womens clothing > tights socks & hosiery > stockings & thigh-highs',
    435: 'clothing & accessories > clothing > womens clothing > tights socks & hosiery',
    436: 'clothing & accessories > clothing > womens clothing > sweatshirts & hoodies',
    437: 'clothing & accessories > clothing > womens clothing > swimwear',
    438: 'clothing & accessories > clothing > womens clothing > tops > suits & blazers',
    439: 'clothing & accessories > clothing > womens clothing > shorts',
    440: 'clothing & accessories > clothing > womens clothing > tops > jumpers',
    441: 'clothing & accessories > clothing > womens clothing > trousers',
    442: 'clothing & accessories > clothing > womens clothing > bottoms > skirts',
    443: 'clothing & accessories > clothing > womens clothing > dresses',
    444: 'clothing & accessories > clothing > womens clothing > lingerie & sleepwear > sleepwear',
    445: 'clothing & accessories > clothing > womens clothing > lingerie & sleepwear > underwear',
    446: 'clothing & accessories > clothing > womens clothing > lingerie & sleepwear > slips',
    447: 'clothing & accessories > clothing > womens clothing > lingerie & sleepwear > bras',
    448: 'clothing & accessories > clothing > womens clothing > lingerie & sleepwear > shapewear',
    449: 'clothing & accessories > clothing > womens clothing > lingerie & sleepwear',
    450: 'clothing & accessories > clothing > womens clothing > bottoms > jeans',
    451: 'clothing & accessories > clothing > womens clothing > jumpsuits & playsuits',
    452: 'clothing & accessories > clothing > womens clothing > leggings',
    453: 'clothing & accessories > clothing > womens clothing > activewear',
    454: 'clothing & accessories > clothing > womens clothing > coats & jackets',
    455: 'clothing & accessories > clothing > womens clothing',
    456: 'clothing & accessories > clothing > mens clothing > bottoms > underwear',
    457: 'clothing & accessories > clothing > mens clothing > bottoms > swimwear',
    458: 'clothing & accessories > clothing > mens clothing > sweatshirts & hoodies',
    459: 'clothing & accessories > clothing > mens clothing > t-shirts',
    460: 'clothing & accessories > clothing > mens clothing > sleepwear',
    461: 'clothing & accessories > clothing > mens clothing > suits',
    462: 'clothing & accessories > clothing > mens clothing > dress shirts',
    463: 'clothing & accessories > clothing > mens clothing > jeans',
    464: 'clothing & accessories > clothing > mens clothing > socks',
    465: 'clothing & accessories > clothing > mens clothing > shorts',
    466: 'clothing & accessories > clothing > mens clothing > tops > jumpers',
    467: 'clothing & accessories > clothing > mens clothing > trousers',
    468: 'clothing & accessories > clothing > mens clothing > activewear',
    469: 'clothing & accessories > clothing > mens clothing > blazers & sport jackets',
    470: 'clothing & accessories > clothing > mens clothing > coats & jackets',
    471: 'clothing & accessories > clothing > mens clothing > casual shirts',
    472: 'clothing & accessories > clothing > mens clothing',
    473: 'clothing & accessories > clothing > kids clothing > girls clothing > swimwear',
    474: 'clothing & accessories > clothing > kids clothing > girls clothing > underwear',
    475: 'clothing & accessories > clothing > kids clothing > girls clothing > tops & t-shirts',
    476: 'clothing & accessories > clothing > kids clothing > girls clothing > sweatshirts & hoodies',
    477: 'clothing & accessories > clothing > kids clothing > girls clothing > skirts',
    478: 'clothing & accessories > clothing > kids clothing > girls clothing > socks & tights',
    479: 'clothing & accessories > clothing > kids clothing > girls clothing > shorts',
    480: 'clothing & accessories > clothing > kids clothing > girls clothing > sleepwear',
    481: 'clothing & accessories > clothing > kids clothing > girls clothing > trousers',
    482: 'clothing & accessories > clothing > kids clothing > girls clothing > jumpers',
    483: 'clothing & accessories > clothing > kids clothing > girls clothing > outerwear',
    484: 'clothing & accessories > clothing > kids clothing > girls clothing > jeans',
    485: 'clothing & accessories > clothing > kids clothing > girls clothing > jumpsuits & playsuits',
    486: 'clothing & accessories > clothing > kids clothing > girls clothing > leggings',
    487: 'clothing & accessories > clothing > kids clothing > girls clothing > outfits & sets',
    488: 'clothing & accessories > clothing > kids clothing > girls clothing > dresses',
    489: 'clothing & accessories > clothing > kids clothing > girls clothing',
    490: 'clothing & accessories > clothing > kids clothing > boys clothing > socks',
    491: 'clothing & accessories > clothing > kids clothing > boys clothing > suits',
    492: 'clothing & accessories > clothing > kids clothing > boys clothing > underwear',
    493: 'clothing & accessories > clothing > kids clothing > boys clothing > sweatshirts & hoodies',
    494: 'clothing & accessories > clothing > kids clothing > boys clothing > jumpers',
    495: 'clothing & accessories > clothing > kids clothing > boys clothing > swimwear',
    496: 'clothing & accessories > clothing > kids clothing > boys clothing > tops & t-shirts',
    497: 'clothing & accessories > clothing > kids clothing > boys clothing > sleepwear',
    498: 'clothing & accessories > clothing > kids clothing > boys clothing > outfits & sets',
    499: 'clothing & accessories > clothing > kids clothing > boys clothing > trousers',
    500: 'clothing & accessories > clothing > kids clothing > boys clothing > shorts',
    501: 'clothing & accessories > clothing > kids clothing > boys clothing > jeans',
    502: 'clothing & accessories > clothing > kids clothing > boys clothing > outerwear',
    503: 'clothing & accessories > clothing > kids clothing > boys clothing',
    504: 'clothing & accessories > clothing > kids clothing',
    505: 'clothing & accessories > clothing > baby clothing > baby girls clothing > tops',
    506: 'clothing & accessories > clothing > baby clothing > baby girls clothing > swimwear',
    507: 'clothing & accessories > clothing > baby clothing > baby girls clothing > dresses',
    508: 'clothing & accessories > clothing > baby clothing > baby girls clothing > sleepwear',
    509: 'clothing & accessories > clothing > baby clothing > baby girls clothing > outerwear',
    510: 'clothing & accessories > clothing > baby clothing > baby girls clothing > bottoms',
    511: 'clothing & accessories > clothing > baby clothing > baby girls clothing > jumpers',
    512: 'clothing & accessories > clothing > baby clothing > baby girls clothing > outfits & sets',
    513: 'clothing & accessories > clothing > baby clothing > baby girls clothing > socks',
    514: 'clothing & accessories > clothing > baby clothing > baby girls clothing > bodysuits',
    515: 'clothing & accessories > clothing > baby clothing > baby girls clothing',
    516: 'clothing & accessories > clothing > baby clothing > baby boys clothing > suits',
    517: 'clothing & accessories > clothing > baby clothing > baby boys clothing > swimwear',
    518: 'clothing & accessories > clothing > baby clothing > baby boys clothing > jumpers',
    519: 'clothing & accessories > clothing > baby clothing > baby boys clothing > socks',
    520: 'clothing & accessories > clothing > baby clothing > baby boys clothing > tops',
    521: 'clothing & accessories > clothing > baby clothing > baby boys clothing > bodysuits',
    522: 'clothing & accessories > clothing > baby clothing > baby boys clothing > sleepwear',
    523: 'clothing & accessories > clothing > baby clothing > baby boys clothing > bottoms',
    524: 'clothing & accessories > clothing > baby clothing > baby boys clothing > outerwear',
    525: 'clothing & accessories > clothing > baby clothing > baby boys clothing > outfits & sets',
    526: 'clothing & accessories > clothing > baby clothing > baby boys clothing',
    527: 'clothing & accessories > clothing > baby clothing',
    528: 'clothing & accessories > clothing',
    529: 'clothing & accessories',
    530: 'other',
    535: 'clothing & accessories > clothing > mens clothing > tops',
    536: 'clothing & accessories > clothing > mens clothing > tops > activewear tops',
    537: 'clothing & accessories > clothing > mens clothing > tops > sleepwear tops',
    538: 'clothing & accessories > clothing > mens clothing > bottoms',
    539: 'clothing & accessories > clothing > mens clothing > bottoms > activewear bottoms',
    540: 'clothing & accessories > clothing > mens clothing > bottoms > sleepwear bottoms',
    541: 'clothing & accessories > clothing > womens clothing > tops',
    542: 'clothing & accessories > clothing > womens clothing > tops > activewear tops',
    543: 'clothing & accessories > clothing > womens clothing > tops > swimwear tops',
    544: 'clothing & accessories > clothing > womens clothing > bottoms',
    545: 'clothing & accessories > clothing > womens clothing > bottoms > swimwear bottoms',
    546: 'clothing & accessories > clothing > mens clothing > swimwear'
  },
  functions: {
    text: 'Functions',
    follow: 'Follow us',
    say: 'Good communication allows us to help you build a better business',
    politics: 'Privacy policies',
    suport: 'We provide personalized Support service guided by our specialists, we are at your disposal 24 hours, just so that your business is always on the rise.' +
      'We care about every comment, idea or suggestion, you are important to us, and we are here to listen to you.',
    functions: {
      facture_pos: {
        title: 'Issuance of Invoices',
        text: 'We offer billing in different formats and sizes. With our system you can create Simplified Invoice or Ticket, Fiscal Invoice and Proforma Invoice.'
      },
      pos: {
        title: 'POS Sale',
        text: 'With Billing Money App you can use the functions on different mobile and desktop devices, such as Cell Phones, Tablet, Computer, Terminal, optimizing in a census, efficient and fast way, all the operations of administration, management and control of your business.'
      },
      cloude_store: {
        title: 'Cloud storage',
        text: 'We offer you access to your business from anywhere in the world, we use external servers, with enormous data and file storage capacity, excellent security system, data recovery, and fast data processing.'
      },
      shop: {
        title: 'Online Shop',
        text: 'BILLING MONEY APP platform provides you with a free online store for each store that ' +
          'register in the system, so you can sell your products all over the world.'
      },
      counted: {
        title: 'Accounting Management',
        text: 'We keep you up to date on accounting management through notifications and messages, providing you with graphs and summaries.\n'
      },
      articles: {
        title: 'Inventory management',
        text: 'You can manage your inventory, from the simplest to the most complex, being able to register both services and items.'
      },
      functions: {
        title: 'More than 100 functions',
        text: 'Our application has more than 120 functions so that you can have management and administrative control of your company or commercial activity.'
      },
      multi_user: {
        title: 'Multiple Users',
        text: 'With Billing Money App you can manage multiple employees, and have control over your business and income behavior.'
      },
      facture: {
        title: 'Issuance of Invoices and Tax Invoices',
        text: 'Our system provides you with an easy and fast way to issue an invoice, both in the form of a Ticket and in Letter Format. ' +
          'If you configure the Tax Invoice section, you can still issue them for your client.'
      },
      sales: {
        title: 'Pos and Online Sales Management',
        text: 'Using a flexible interface and extensive functions you manage your POS sales automatically and securely.'
      },
      buy: {
        title: 'Purchasing Management',
        text: 'You can update your product inventory, reflecting your purchases, in this way the margins will be calculated, as well as the statistical parameters, for future diagnoses that will allow them to reduce the risks and increase the chances of success.'
      },
      report: {
        title: 'Issuance of Reports and Notifications',
        text: 'Once started in the business process, you have functions to issue summaries and reports on the behavior of sales, for a better understanding of it.'
      }

    },
    about_as: {
      title: 'About As',
      text: 'We are an innovative team, whose main objective is to satisfy the client, offering a high quality product, and a unique degree of commitment. We have a creative, intuitive, easy-to-use solution and a price according to your budget.  N ' +
        'Billing Money App is a powerful and flexible application that is focused on online billing, with more than 120 functions that make your business more productive.' +
        'We focus on providing a tool that grows your business and allows you to plan your investments based on historical behavior. ' +
        'We automate most of your processes so that with just one click you save time, and you can invest it in other functions. ' +
        'Easy to use system, with a simple and friendly interface ' +
        'Minimalist design, focused on users without practice or little relation to technology ' +
        'Flexibility in all functions, and total control of the information by the director or manager of the registered company ' +
        'Immediate and personalized support ' +
        'Information security and compartmentalisation '
    },
    characteristics: {
      why_as: 'What we offer',
      answer: 'We have spent time with an in-depth market study, to come up with a solution that allows us to solve various problems of the market.' +
        'The experience acquired during that time, allowed the creation of an online billing system, with the best features and functionalities,' +
        'focused on reducing time and automating services and / or processes, to improve your profits and business performance. ' +
        'We have achieved a wide range of functions for you to work more comfortably.'
    },
    about: {
      one: 'Easy to use system, with a simple and friendly interface',
      two: 'Minimalist design, focused on users without practice or little relation to technology',
      three: 'Flexibility in all functions, and total control of the information by the director or manager of the registered company',
      four: 'Immediate and personalized support',
      five: 'Information security and compartmentalisation'
    },
    descriptions: {
      manager_article: 'The system allows a wide and comprehensive management of articles and services. ' +
        'You can manage both different prices and costs in stores, as well as variants of the same product.' +
        'You will add images or colors to identify and / or recognize them',
      manager_vending: 'The system allows a form of pos sale, with an interactive and extensive management of the elements (articles and / or services) to be sold.',
      manager_factures: 'With Billing Money App you can generate your invoices with a simple click, they will always be available for your purchase and sale operations,' +
        ' as well as so that you can print according to your needs. We use Ticket and Letter formats so you can print as you see fit',
      manager_shop: 'At the time of registration, the Billing Money App auto-generates a store with the same company name, then you can edit it if you wish.' +
        'All the articles and / or services that you register will be associated with the stores that you have in the system',
      manager_refunds: 'Once a sale is made, you can make refunds of both money and products, and the system will automatically update the cost,' +
        'and profit margin of the product, as well as the sale made, always stating the process carried out as well as a description and' +
        'who performed the operation (see Permission Management)',
      manager_boxes: 'Each store that you have (including the one created by default), a box is generated to speed up your time, you can add more.' +
        'The boxes will be opened to the employees, and they will be squared automatically when they are closed, having only to verify that it matches, since all the operations are registered and associated with one. In case of not matching, you will be notified in the system and via email',
      manager_bank: 'For a better interaction with accounting management, Billing Money App generates a default bank when registering, you can associate accounts and the system will' +
        'in charge of keeping you updated on the status of the account regarding the operations you carry out in the system',
      manager_category: 'The items to be sold (articles and / or services) are associated with categories, which can be managed, with no quantity limit.',
      manager_supplier: 'You can manage your suppliers in the system, registering them. If your provider is registered in our application, you can request ' +
        'an order (See Order Management) through it, making the process unique and continuous, so that both can update it and not having to go personally.',
      manager_buy: 'By registering your purchases in Billing Money App, your inventory will be updated automatically, without need or effort, as well as the registration for the accounting part' +
        'always allowing an automatic synchronization of the system.',
      supply_emit: 'Billing Money App allows you to have a record of the orders that you issue through the system, as well as the status in which they are, since if your provider is' +
        'Registered in our application, he will be able to update the status (Accepted, In process, On the way) and upon receiving your order you will be able to update it to Received',
      supply_received: 'Just as you can issue orders, you can also receive them in case you are a supplier to someone in the system, having a record of the orders that are made through the system,' +
        'having the facility to update the status of the order to your client (Accepted, In process, On the way), so that he is aware of how his process is going',
      account_classify: 'Our system creates an Accounting File by default with the record of Expenditures, Income, Liabilities and Assets as well as accounts by default to manage it, if you configure' +
        'Our system can constantly update you on the status of your accounts.',
      accountings: 'Accounting Accounts can be edited, as well as adding new ones according to the denomination they have in your country, allowing you to make accounting movements manually.',
      manager_employer: 'If you have several employees, Billing Money App allows you to register them and helps you control their entry and exit times, using the attendance cards' +
        '(See Assistance Card) and access permissions (See Access Permissions).',
      manager_mod: 'If you want more variables tours items, with Biling Money App you cant make modifiers then',
      manager_client: 'You can have a record of your regular customers in order to know them and keep them loyal to your business, since Billing Money App allows you to register them easily and quickly.',
      affiliate: 'With Billing Money App everyone wins, and our desire is always that you increase your profits, that is why we have created a way in our company so that your income can be increased.' +
        'How? Simple, you just have to request to join our community of Partners through which the company paysun     a comisión por cada afiliado que unas al sistema. ' +
        'If your application is accepted, we will give you a link to share it either on your networks, with family and friends, in the community, and if people subscribe to the unicorn version you' +
        'We rewarded with it for it.',
      shop_online: 'Billing Money provides you with an Online Store for each physical store you have, in a simple and intuitive way you can define the payment gateways for which you want to charge' +
        'as well as the products you decide to sell in them. Do not charge us anything for this service, it is TOTALLY FREE, so you can sell your products all over the world and increase your profits quickly. ',
      fiscal_account: 'Billing Money allows you to issue tax invoices so that it is faster for you and you can invest your time in other things, you only need to fill out a minimum form and you can generate your Invoices' +
        'Prosecutors when you want it',
      access: 'If you have multiple workers and their functions are separate, the Billing Money App helps you with that too. We have a simple and fast way for you ' +
        'limit the functions that a given employee will have within the system, defining what each one can or cannot do, as well as what they can or cannot see in the system.',
      exchange_rate: 'If you operate with multiple currencies, then Billing Money App adapts to this. You can register the currencies you want to work with as well as the Exchange Rates (defined by you), so that when you generate an invoice ' +
        'It will be specified until the currency in which your client paid and the profits that this report for you',
      tax_list: 'If you are one of those who handle several taxes, and the relationship and calculation of these becomes tedious, you are using the perfect application to eradicate your problem. ' +
        'We allow you to manage your taxes, and thus you can add them to both sales, purchases and items with the minimum of time spent.',
      discounts: 'If you are one of those who handle several discounts, and the relationship and calculation of these becomes tedious, you are using the perfect application to eradicate your problem. ' +
        'We allow you to manage your discounts, and so you can add them to both sales, purchases and items with the minimum of time spent.'
    }
  },
  text_voz: {
    welcome: 'Welcome to Billing Money App. Your company in your hands'
  },
  help: {
    text: 'Help',
    index: 'Index',
    step: 'Steps',
    btn_add: 'Add Button',
    btn_edit: 'Edit button',
    btn_delete: 'Delete button',
    description: {
      text: 'Billing Money App is a billing system that will allow you to comfortably manage ' +
        'your sales system.' +
        'To proceed correctly with the application, we recommend that you follow these steps:',
      step_tax: {
        first: 'Create the Taxes that you must use for the sale and the products. See Tax section',
        two: 'Create the Discounts that you must use for the sale and the products. See Discounts section',
        three: 'Check the Categories you want to use. To do this you can go to Settings->Activate Categories',
        four: 'Billing Money App offers you a list of categories to which you can add new ones.',
        five: 'Add the items you want. See Articles section',
        six: 'Add the Clients if you want to control them as such from the beginning. ' +
          'This way, when making a sale to them, you can send them the invoice by mail if they require it. ',
        seven: 'Register your providers.',
        eight: 'If you wish to issue Fiscal Invoices, complete the information requested in the Fiscal Invoice section in Configuration',
        text_one: 'Billing Money App gives you the option to increase your capital by becoming a Partner of our company.' +
          ' For them you can complete the information requested and once accepted by the Company, you will earn a commission ' +
          'for every customer you bring to our platform and use the Unicorn version',
        text_two: 'This system gives you the option to maintain contact with your supplier to place orders through him, if he is registered here.' +
          'You only have to enter your data and you will be able to reflect it in your purchases and orders'

      }
    },
    children: {
      descriptions: {
        text: 'Billin Money App gives you the possibility to manage your own Taxes in a simple and interactive way.' +
          ' You can Add, Edit and Delete them. This step must be done before managing the articles already ' +
          'that these are closely related, and depend on that prior configuration.'
      },
      tax: {
        list_tax: {
          descriptions: {
            text: 'Tax Management allows the list from a simple interface, as well as Add, Edit and Delete them.' +
              'From this view you will be able to see all the defined taxes'
          }
        },
        new_tax: {
          descriptions: {
            text: 'Once in the Add interface you must define the name of the tax, the value it will have. In the Rate field define if it is percentage or fixed, ' +
              'then the field appears to select the Type: "Included in the price" or "Added in the price". This value to select is of vital importance since it will define the value of the price of the product at the' +
              ' Time to charge a customer. The view also has an option to add to all existing articles. Once the pertinent options have been defined, proceed to Accept on the button with the same name.' +
              'If you don`t want to save the changes, press the Cancel button'
          }
        },
        edit_tax: {
          descriptions: {
            text: 'In the list of taxes, select the element you want to edit. ' +
              'Once in the Edit interface you can redefine the name of the tax, as well as the value it will have. ' +
              'In the Rate field define if it is percentage or fixed, ' +
              'then the field appears to select the Type: "Included in the price" or "Added in the price", which will recalculate in all the items that contain this tax.' +
              'This value to select is of vital importance since it will define the value of the price of the product at the' +
              ' Time to charge a customer. The view also has an option to add to all existing articles. Once the pertinent options have been defined, proceed to Accept on the button with the same name.' +
              'If you don`t want to save the changes, press the Cancel button'
          }
        },
        delete_tax: {
          descriptions: {
            text: 'The procedure to Eliminate a Tax is simple. You only have to, from the list of Taxes, click on the button Delete from the table.' +
              'A confirmation banner will automatically be shown to confirm the Delete option. In case you no longer want to proceed with this action,' +
              ' press the Cancel button. If you still want to delete, then proceed to press the OK button.'
          }
        }

      },
      discount: {
        list_discount: {
          descriptions: {
            text: 'The Discount Management allows the list from a simple interface, as well as Add, Edit and Delete them.' +
              'From this view you will be able to see all the defined discounts'
          }
        },
        new_discount: {
          descriptions: {
            text: 'Once in the Add interface you must define the name of the discount, the value it will have. In the Rate field define if it is percentage or fixed, ' +
              'then the field appears to select the Type: "Included in the price" or "Added in the price". This value to select is of vital importance since it will define the value of the price of the product at the' +
              ' Time to charge a customer. The view also has an option to add to all existing articles. Once the pertinent options have been defined, proceed to Accept on the button with the same name.' +
              'If you don`t want to save the changes, press the Cancel button'
          }
        },
        edit_discount: {
          descriptions: {
            text: 'In the Discounts list, select the item you want to edit. ' +
              'Once in the Edit interface you can redefine the name of the Discount, as well as the value it will have. ' +
              'In the Rate field define if it is percentage or fixed, ' +
              'then the field appears to select the Type: "Included in the price" or "Added in the price", which will recalculate in all the items that contain this tax.' +
              'This value to select is of vital importance since it will define the value of the price of the product at the' +
              ' Time to charge a customer. The view also has an option to add to all existing articles. Once the pertinent options have been defined, proceed to Accept on the button with the same name.' +
              'If you don`t want to save the changes, press the Cancel button'
          }
        },
        delete_discount: {
          descriptions: {
            text: 'The procedure to Delete a Discount is simple. All you have to do is click on the Delete from table button from the Discounts list.' +
              'A confirmation banner will automatically be shown to confirm the Delete option. In case you no longer want to proceed with this action,' +
              ' press the Cancel button. If you still want to delete, then proceed to press the OK button.'
          }
        }
      },
      articles: {
        descriptions: {
          text: 'Item management is a basic function for the proper functioning of your system.' +
            ' Billing Money App gives you the possibility that you can create items and/or services, with a wide ' +
            'variety of features.'
        },
        steps: {
          text: 'Left Side Menu'
        },
        list_articles: {
          descriptions: {
            text: 'The Articles list allows you to have full control over their management, such as Adding, Editing and Deleting them.' +
              'From this view you will be able to see all the definite articles'
          }
        },
        new_articles: {
          descriptions: {
            text: 'Once in the Add interface you will be shown a series of steps. First are the basic features, ' +
              'where you must define the name of the article, The unit of measure, etc. An important point of this step is to define if the article is composite.' +
              'If it is, you will define which items it is made up of. You will define the store(s) in which it will be sold as well as in the next step, ' +
              'you can specify how to represent it either by one or more images or a color if you prefer'
          },
          steps: {
            first: 'Add the name of the new article. Select the Unit of Measure, Barcode, edit if you want the reference. ' +
              'Define the Cost and Price, as well as the Category to which it belongs and the Tax if any. as well as its Description',
            two: 'Define if the item is Composite.',
            three: 'Select the articles that compose it',
            four: 'Press the Next button',
            five: 'If the article is not Composite, you can define its variants in Step 2: "Variant"',
            six: 'Define the name of the variant and the values it will have. (Example: Name->Colors, Options->Red, Black, White)',
            seven: 'If you don`t want to add the Variant you can press the Cancel button, otherwise press Accept',
            eight: 'You can Edit the representation of the Variant conventions from the table that shows them, selecting in the Image column.',
            nine: 'You can select an Image or color to represent the Variant',
            ten: 'If you don`t want to add the Image you can press the Cancel button, otherwise press OK',
            eleven: 'Press the Next button',
            twelve: 'This step is important since you will define if the system assumes this element as an Article ' +
              'or as a Service when activating the Follow Inventory option',
            thirteen: 'You will also define if the product and/or service will be sold online or only in person, as well as the prices for both cases.' +
              'If you defined variants, these will be the ones that will show in the table. This display will be done for each store you have, to define ' +
              'where the same will be sold',
            fourteen: 'In case you define the following inventory to the table, two new columns will be added, which will define the quantity in Stock and the ' +
              'Quantity Under Stock to notify you when this happens',
            fifteen: 'Press the Next button',
            sixteen: 'The Representation step is the last one, there, as in the variant, you define how to represent the element, either with a color or with an image',
            seventeen: 'If you dont want to save the changes you can press the Cancel button, otherwise press OK'
          }
        },
        edit_articles: {
          descriptions: {
            first: 'Edit the name of the new article. Change the Unit of Measure, Barcode, edit if you want the reference. ' +
              'Redefine the Cost and Price, as well as the Category to which it belongs and the Tax if any. and/or the Description thereof',
            two: 'If it has been defined as compound, you can redefine the articles that compose it.',
            three: 'Change the articles that compose it',
            four: 'Press the Next button',
            six: 'If the article is not Composite you can redefine variants of it in Step 2: "Variant"',
            seven: 'Define the name of the variant and the values ​​it will have. (Example: Name->Colors, Options->Red, Black, White)',
            eight: 'If you don`t want to add the Variant you can press the Cancel button, otherwise press Accept',
            nine: 'You can Edit the representation of the Variant conventions from the table that shows them, selecting in the Image column.',
            ten: 'You can select an Image or color to represent the Variant',
            eleven: 'If you don`t want to add the Image you can press the Cancel button, otherwise press OK',
            twelve: 'Press the Next button',
            thirteen: 'You will define if the product and/or service will be sold online or only in person, as well as the prices for both cases.' +
              'If you defined variants, these will be the ones that will show in the table. This display will be done for each store you have, to define ' +
              'where the same will be sold',
            fourteen: 'In case you define the following inventory to the table, two new columns will be added, which will define the quantity in Stock and the ' +
              'Quantity Under Stock to notify you when this happens',
            fifteen: 'Press the Next button',
            sixteen: 'The Representation step is the last one, there, as in the variant, you define how to represent the element, either with a color or with an image',
            seventeen: 'If you don`t want to save the changes you can press the Cancel button, otherwise press OK'
          }
        },
        delete_articles: {
          descriptions: {
            text: 'The procedure to Delete a Discount is simple. All you have to do is click on the Delete from table button from the Discounts list.' +
              'A confirmation banner will automatically be shown to confirm the Delete option. In case you no longer want to proceed with this action,' +
              ' press the Cancel button. If you still want to delete, then proceed to press the OK button.'

          }
        }
      },
      vending: {
        descriptions: {
          text: 'Sales are one of the main functions of the system, for them it is required to have previously managed taxes, discounts, and items' +
            'to be able to proceed quickly and without having to cancel them due to lack of data.'
        },
        children: {
          steps: {
            text: 'Left Side Menu'
          },
          descriptions: {
            text: 'The sale list allows you to see all the sales made, as well as the person who made it.' +
              ' This interface provides the user with various functionalities such as Add, Edit Delete, Refunds, Print Ticket, ' +
              'Print Invoice in Letter Form in addition to the ' +
              'procedure details such as price, payment breakdown, refund details, etc. This interface is designed to ' +
              'that you can control everything related to Sales from a single view',
            steps: {
              first: 'Select the Store where the items to sell are located',
              two: 'Items can be filtered in the window by Barcode or Name',
              three: 'You can Search them specifically by the Category to which they belong',
              four: 'Once the items are listed, select in the Cart you have to add it to the sale',
              five: 'Once at least one item has been selected, the Items, Invoice, Clean Cart functions will be displayed',
              six: 'The Items function allows the item to be sold to be edited by adding discounts and modifiers. this will automatically recalculate the new price.',
              seven: 'The Invoice function allows the selection of the client, as well as editing the Invoice number if necessary, adding Taxes and/or General Discounts to the sale',
              eight: 'Inside this view, there is an interface to add the form of payment which controls that the calculated amount coincides with the amount defined as paid by the specified method',
              nine: 'Clear Cart removes all items added to make the sale',
              ten: 'Once the payment method has been defined as well as the amount to be paid for each one, you can proceed to Accept or define it as In Process',
              eleven: 'In process a sale is defined that is open, or that the payment method(s) is not defined',
              twelve: 'Accepted is established when the price coincides with the amount to pay defined in the Invoice section'
            }
          }
        },
        vending_edit: {
          descriptions: {
            text: 'The procedure of Editing a sale can be from the simplest to the most complex.' +
              'Once inside the interface, you can filter in the search bar, either by name or by barcode' +
              'of the product or service that you will provide to your customer.' +
              'As soon as you select the cart on the item or service image, a set of features will be displayed.'
          },
          steps: {
            first: 'Edit the Store where the items to sell are located',
            two: 'Items can be filtered in the window by Barcode or Name',
            three: 'You can Search them specifically by the Category to which they belong',
            four: 'Once the items are listed, select in the Cart you have to add it to the sale',
            five: 'Once at least one item has been selected, the Items, Invoice, Clean Cart functions will be displayed',
            six: 'The Items function allows the item to be sold to be edited by adding discounts and modifiers. this will automatically recalculate the new price.',
            seven: 'The Invoice function allows the selection of the client, as well as editing the Invoice number if necessary, adding Taxes and/or General Discounts to the sale',
            eight: 'Inside this view, there is an interface to add the form of payment which controls that the calculated amount coincides with the amount defined as paid by the specified method',
            nine: 'Clear Cart removes all items added to make the sale',
            ten: 'Once the payment method has been defined as well as the amount to be paid for each one, you can proceed to Accept or define it as In Process',
            eleven: 'In process a sale is defined that is open, or that the payment method(s) is not defined',
            twelve: 'Accepted is established when the price coincides with the amount to pay defined in the Invoice section'
          }

        },
        vending_delete: {
          descriptions: {
            text: 'The procedure of Editing a sale can be from the simplest to the most complex.' +
              'Once inside the interface, you can filter in the search bar, either by name or by barcode' +
              'of the product or service that you will provide to your customer.' +
              'As soon as you select the cart on the item or service image, a set of features will be displayed.'
          }
        },
        vending_refund: {
          text: 'processing'
        }
      }
    }
  },
  subscription_state: {
    test: 'Test',
    active: 'Active',
    canceled_change: 'Plan changed',
    suspend: 'Suspend'
  },
  ...en
}
