import api from '../config/api'

export default {
  fetchAllArticles () {
    return api.get('article')
  },
  fetchArticles () {
    return api.post('article/articles')
  },
  fetchExpireArticles () {
    return api.post('article/expire')
  },
  getUnderInventory () {
    return api.post('article/under-inventory')
  },
  fetchSlowMoveArticles (filter) {
    return api.post('article/slowMoveArticles', filter)
  },
  fetchServices () {
    return api.post('article/services')
  },
  fetchArticlesByCategory (filter) {
    return api.post('article/byCategory', filter)
  },
  getArticlesBySupply (email) {
    return api.post('article/bySupplier', email)
  },
  sendCreateRequest (article) {
    return api.post('article', article)
  },
  getEditArticle (data) {
    return api.get('article/' + data.id)
  },
  sendUpdateRequest (article) {
    return api.put('article/' + article.id, article)
  },
  sendDeleteRequest (articleId) {
    return api.remove('article/' + articleId)
  },
  sendUpdateCategory (data) {
    return api.post('article/update-category', data)
  },
  sendShopInventory (data) {
    return api.post('article/update-stock', data)
  },
  sendMultiDelete (data) {
    return api.post('article/multi-delete', data)
  },
  importArticles (dataFile) {
    return api.importData('article/import', dataFile)
  },
  refoundArticle (dataFile) {
    return api.post('article/refound', dataFile)
  },
  fetchArticleNumber () {
    return api.get('article/number/get')
  },
  fetchArticlesShops () {
    return api.get('article_shop')
  },
  findAllToSale (email) {
    return api.post('article_shop/findAllToSale', email)
  },
  findAllToSaleOnline (email) {
    return api.post('article_shop/findAllToSaleOnline', email)
  },
  getOnlineArticleSale (shop) {
    return api.post('article_shop/findOnline', shop)
  },
  updateArticlesShopOnline (shop) {
    return api.post('article_shop/update-online-data', shop)
  },
  findAllToBuy (email) {
    return api.post('article_shop/findAllToBuy', email)
  },
  enabledArticleShop (artShopId) {
    return api.get('article_shop/' + artShopId)
  },
  sendUpdateArtShopRequest (article) {
    return api.put('article_shop/' + article.id, article)
  }
}
