<template>
  <div class="col-sm">
    <div class="card text-center">
      <div class="card-block" :style="time.alert ? 'color:red': 'color: forestgreen'">
        <h6 class="card-title">{{time.time}}</h6>
      </div>
      <div class="card-footer" :style="time.alert ? 'color:red': 'color: forestgreen'">
        <h6> {{time.text}}</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Time-Item',
  props: ['time']
}
</script>

<style scoped>
.card-title{
  padding: 1em;
}
.card {
  border-width: 1px;
  -webkit-box-shadow: 10px 10px 5px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 5px rgba(0, 0, 0, 0.75);
  padding-bottom: ;
  margin-bottom: 2em;
}
</style>
