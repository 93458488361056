<template>
  <v-container>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
      >
        <new-modifiers v-if="showNewModal" />
        <edit-modifiers v-if="showEditModal" />
        <app-data-table
          :title="$vuetify.lang.t('$vuetify.menu.modifiers_list')"
          csv-filename="Modifiers"
          :headers="getTableColumns"
          :is-loading="isTableLoading"
          :items="modifiers"
          :manager="'modifier'"
          :sort-by="['name']"
          :sort-desc="[false, true]"
          multi-sort
          @create-row="toogleNewModal(true)"
          @edit-row="openEditModal($event)"
          @delete-row="deleteModifiersHandler($event)"
        >
          <template v-slot:[`item.cant`]="{ item }">
            <template v-if="item.cant">
              <span v-if="item.percent === 'true' || item.percent === '1'"> {{ parseFloat(item.cant).toFixed(2) + '%' }}</span>
              <span v-else> {{parseFloat(item.cant).toFixed(2)}} {{ user ? user.company.currency : '' }}</span>
            </template>
            <template v-else>
              <span v-if="item.percent === 'true' || item.percent === '1'"> {{ '0.00' + '%' }}</span>
              <span v-else> {{'0.00'}} {{ user ? user.company.currency : '' }}</span>
            </template>
          </template>
          <template v-slot:item.shops="{ item }">
            <v-chip
              v-for="(shop, i) of item.shops"
              :key="i"
            >
              {{ shop.name }}
            </v-chip>
          </template>
        </app-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewModifiers from './NewModifiers'
import EditModifiers from './EditModifiers'

export default {
  name: 'ListModifiers',
  components: { NewModifiers, EditModifiers },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState('modifiers', [
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'modifiers',
      'isTableLoading'
    ]),
    ...mapGetters('auth', ['user']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shops',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.tax.value'),
          value: 'cant'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  mounted () {
    this.getModifiers()
  },
  methods: {
    ...mapActions('modifiers', [
      'toogleNewModal',
      'openEditModal',
      'openShowModal',
      'getModifiers',
      'deleteModifiers'
    ]),
    deleteModifiersHandler (modifierId) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.modifiers')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deleteModifiers(modifierId)
        })
    }
  }
}
</script>

<style scoped></style>
