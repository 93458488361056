<template>
  <v-autocomplete
    v-model="localModel"
    :label="selectLabel"
    :items="arrayCountry"
    :multiple="multiple"
    :hint="hint"
    :flat="flat"
    clearable
    item-text="name"
    item-value="iso2"
    return-object
    @change="updateData"
  >
    <template v-slot:selection="data">
      <v-chip
        class="vue-tel-input-vuetify"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click:close="remove(data.item)"
      >
        {{ data.item.name.split(' (')[0] }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <span>{{ data.item.name }}</span>
    </template>
  </v-autocomplete>
</template>
<script>

import utils from 'vue-tel-input-vuetify/lib/utils'
import { mapState } from 'vuex'

function getDefault (key) {
  const value = utils.options[key]
  if (typeof value === 'undefined') {
    return utils.options[key]
  }
  return value
}
export default {
  name: 'SelectCountry',
  props: {
    outlined: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: true
    },
    onlyCountries: {
      type: Array,
      default: () => getDefault('onlyCountries')
    },
    ignoredCountries: {
      type: Array,
      default: () => getDefault('ignoredCountries')
    },
    allCountries: {
      type: Array,
      default: () => getDefault('allCountries')
    },
    selectLabel: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    model: {
      type: [Array, Object],
      default: function () {
        return this.multiple ? [] : {}
      }
    }
  },
  data () {
    return {
      localModel: this.multiple ? [] : {},
      activeCountry: { iso2: '' }
    }
  },
  computed: {
    ...mapState('statics', ['arrayCountry', 'arraySector', 'smallScreen']),
    parsedMode () {
      if (this.mode) {
        if (!['international', 'national'].includes(this.mode)) {
        } else {
          return this.mode
        }
      }
      if (!this.phone || this.phone[0] !== '+') {
        return 'national'
      }
      return 'international'
    },
    filteredCountries () {
      // List countries after filtered
      if (this.onlyCountries.length) {
        return this.getCountries(this.onlyCountries)
      }
      if (this.ignoredCountries.length) {
        return this.allCountries.filter(
          ({ iso2 }) => !this.ignoredCountries.includes(iso2.toUpperCase()) &&
                      !this.ignoredCountries.includes(iso2.toLowerCase())
        )
      }
      return this.allCountries
    },
    sortedCountries () {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.getCountries(
        this.preferredCountries
      ).map(country => ({ ...country, preferred: true }))
      return [...preferredCountries, ...this.filteredCountries]
    }
  },
  watch: {
    model: function () {
      this.localModel = this.model
    },
    localModel: function () {
      if (this.multiple) {
        if (this.localModel.length === 0) {
          this.$emit('emptyModel')
        } else {
          this.$emit('noEmptyModel')
        }
      } else {
        if (this.localModel === null) {
          this.$emit('emptyModel')
        } else {
          this.$emit('noEmptyModel')
        }
      }
    }
  },
  created () {
    this.localModel = this.model
  },
  methods: {
    /**
       * Get the list of countries from the list of iso2 code
       */
    getCountries (list = []) {
      return list
        .map(countryCode => this.findCountry(countryCode))
        .filter(Boolean)
    },
    remove (item) {
      if (this.multiple) {
        const index = this.localModel.indexOf(item)
        if (index >= 0) {
          this.localModel.splice(index, 1)
        }
      } else {
        this.localModel = null
      }
      this.updateData()
    },
    updateData () {
      this.$emit('updateData', this.localModel)
    }
  }
}
</script>

<style scoped>

</style>
