<template>
  <v-container>
    <v-card>
      <v-card-text>
        <h4> {{$vuetify.lang.t('$vuetify.actions.subscribe')}}</h4>
      </v-card-text>
      <v-card-text>
        <p>
          {{$vuetify.lang.t('$vuetify.actions.is_caduced')}}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="$router.push({ name: 'my_subscription' })">
          {{$vuetify.lang.t('$vuetify.actions.accept')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'NoSubcription'
}
</script>

<style scoped>

</style>
