import api from '../config/api'
import localStorage from '../config/localStorage'

export default {
  adminFetchBeneficiary () {
    return api.get('beneficiaries')
  },
  getPlansByBeneficiary () {
    return api.get('beneficiaries/plans')
  },
  sendCreateRequest (beneficiary) {
    return api.post('beneficiaries', beneficiary)
  },
  sendUpdateRequest (beneficiary) {
    if (localStorage.getTypeConnection() === 'beneficiary') {
      return api.put('beneficiaries/' + beneficiary.id, beneficiary)
    } else {
      return api.put('beneficiaries/' + beneficiary.id, beneficiary)
    }
  },
  sendDeleteRequest (beneficiaryId) {
    return api.remove('beneficiaries/' + beneficiaryId)
  },
  updateAvatar (avatar) {
    return api.post('beneficiaries/avatar/' + avatar.id, avatar)
  }
}
