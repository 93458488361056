import apiArticle from '../../api/article'
import util from '../../util'

const FETCHING_ARTICLES_SHOP = 'FETCHING_ARTICLES_SHOP'
const FETCHING_ARTICLES_ONLINE = 'FETCHING_ARTICLES_ONLINE'
const ARTICLE_TABLE_LOADING = 'ARTICLE_TABLE_LOADING'
const FAILED_ARTICLE = 'FAILED_ARTICLE'

const state = {
  loading: false,
  saved: false,
  articles_shops: [],
  onlineArticles: []
}

const mutations = {
  [ARTICLE_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
  },
  [FETCHING_ARTICLES_SHOP] (state, articles) {
    state.articles_shops = []
    state.articles_shops = Object.values(articles)
  },
  [FETCHING_ARTICLES_ONLINE] (state, articles) {
    state.onlineArticles = []
    state.onlineArticles = Object.values(articles)
  },
  [FAILED_ARTICLE] (state, error) {
    state.isActionInProgress = false
    state.isArticleTableLoading = false
    state.isTableLoading = false
    state.saved = false
    state.error = error
    this._vm.$Toast.fire({
	  icon: 'error',
	  title: this._vm.$language.t(
        '$vuetify.messages.failed_catch', [this._vm.$language.t('$vuetify.articles.name')]
	  )
    })
  }
}

const actions = {
  async getArticlesShops ({
    commit,
    dispatch
  }) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
	  .fetchArticlesShops()
	  .then(({ data }) => {
        commit(FETCHING_ARTICLES_SHOP, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
	  }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async findAllToSale ({ commit, dispatch }, emailData) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
	  .findAllToSale(emailData)
	  .then(({ data }) => {
        commit(FETCHING_ARTICLES_SHOP, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
	  }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async findAllToSaleOnline ({ commit, dispatch }, emailData) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
	  .findAllToSaleOnline(emailData)
	  .then(({ data }) => {
        commit(FETCHING_ARTICLES_SHOP, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
	  }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async getOnlineArticleSale ({ commit, dispatch }, shop) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
	  .getOnlineArticleSale(shop)
	  .then(({ data }) => {
        commit(FETCHING_ARTICLES_ONLINE, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
	  }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async updateArticlesShopOnline ({ commit, dispatch }, data) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
	  .updateArticlesShopOnline(data)
	  .then(({ data }) => {
        commit(ARTICLE_TABLE_LOADING, false)
	  }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async findAllToBuy ({ commit, dispatch }, emailData) {
    commit(ARTICLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiArticle
	  .findAllToBuy(emailData)
	  .then(({ data }) => {
        commit(FETCHING_ARTICLES_SHOP, data.data)
        commit(ARTICLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
	  }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async enabledArticleShop ({ commit, dispatch }, articleShop) {
    await apiArticle.enabledArticleShop(articleShop).then(() => {
      // window.location.reload()
    }).catch((error) => commit(FAILED_ARTICLE, error))
  },
  async sendUpdateArticleShop ({ commit, dispatch }, articleE) {
    await apiArticle
      .sendUpdateArtShopRequest(articleE)
      .then((response) => {
        commit(ARTICLE_UPDATED)
        commit(ENV_DATA_PROCESS, false)
      })
      .catch((error) => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_ARTICLE, error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
