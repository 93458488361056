var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"450","persistent":""},model:{value:(_vm.toogleOpenCloseModal),callback:function ($$v) {_vm.toogleOpenCloseModal=$$v},expression:"toogleOpenCloseModal"}},[_c('app-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingData),expression:"loadingData"}]}),(!_vm.loadingData)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.openClose.box.state === 'open' ? _vm.$vuetify.lang.t('$vuetify.actions.close') : _vm.$vuetify.lang.t('$vuetify.actions.open'))+" "+_vm._s(_vm.openClose.box.name)+" ")])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"my-10",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[(_vm.openClose.box.state === 'close')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"rules":_vm.formRule.country,"items":_vm.users,"required":"","label":_vm.$vuetify.lang.t('$vuetify.to') + ':',"item-text":"first_name","return-object":""},model:{value:(_vm.openClose.open_to),callback:function ($$v) {_vm.$set(_vm.openClose, "open_to", $$v)},expression:"openClose.open_to"}})],1):_vm._e(),(_vm.openClose.box.state !== 'open')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.variants.cant'),"options":{
                length: 15,
                precision: 2,
                empty: 0.00
              },"prefix":_vm.user.company.currency,"properties":{
                clearable: true
              },"rules":_vm.formRule.required,"required":""},model:{value:(_vm.openClose.open_money),callback:function ($$v) {_vm.$set(_vm.openClose, "open_money", $$v)},expression:"openClose.open_money"}})],1):_vm._e()],1),(_vm.openClose.box.state === 'open')?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.actions.open_to'
                ) + ':',"readonly":""},model:{value:(_vm.openClose.open_to.first_name),callback:function ($$v) {_vm.$set(_vm.openClose.open_to, "first_name", $$v)},expression:"openClose.open_to.first_name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.boxes.init'),"options":{
                length: 15,
                precision: 2,
                empty: 0.00
              },"properties":{
                clearable: true
              },"rules":_vm.formRule.required,"readonly":""},model:{value:(_vm.openClose.open_money),callback:function ($$v) {_vm.$set(_vm.openClose, "open_money", $$v)},expression:"openClose.open_money"}})],1),_vm._l((_vm.openClose.payments),function(payment){return _c('v-col',{key:payment.id,staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.payment.' + payment.method
                ),"prefix":_vm.user.company.currency,"value":payment.total
                  ? parseFloat(payment.total).toFixed(2)
                  : 0.00,"readonly":""}})],1)}),_c('v-col',{staticClass:"py-0",attrs:{"disabled":true,"cols":"12","md":"6"}},[[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.menu.refund'),"value":_vm.openClose.totalRefunds
                    ? parseFloat(
                      _vm.openClose.totalRefunds
                    ).toFixed(2)
                    : 0.00,"prefix":_vm.user.company.currency,"readonly":""}})]],2),_c('v-col',{staticClass:"py-0",attrs:{"disabled":true,"cols":"12","md":"6"}},[[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.boxes.final'),"options":{
                  length: 15,
                  precision: 2,
                  empty: 0.0
                },"prefix":_vm.user.company.currency,"properties":{
                  clearable: true
                },"rules":_vm.formRule.required,"required":""},on:{"input":_vm.calcTotal},model:{value:(_vm.openClose.close_money),callback:function ($$v) {_vm.$set(_vm.openClose, "close_money", $$v)},expression:"openClose.close_money"}})]],2),_c('v-col',{staticClass:"py-0",attrs:{"disabled":true,"cols":"12","md":"6"}},[[_c('v-text-field',{attrs:{"append-icon":_vm.total[1] < 0
                    ? 'mdi-close-circle'
                    : 'mdi-check-circle',"color":_vm.total[1] < 0 ? 'danger' : 'primary',"label":_vm.$vuetify.lang.t(
                    '$vuetify.boxes.difference'
                  ),"value":_vm.total[1]
                    ? parseFloat(_vm.total[1]).toFixed(2)
                    : 0.00,"prefix":_vm.user.company.currency,"required":""}})]],2),_c('v-col',{staticClass:"py-0",attrs:{"disabled":true,"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.description')},model:{value:(_vm.openClose.description),callback:function ($$v) {_vm.$set(_vm.openClose, "description", $$v)},expression:"openClose.description"}})],1)],2):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":_vm.isActionInProgress},on:{"click":function($event){return _vm.toogleOpenCloseModal(false)}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.cancel'))+" ")],1),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":!_vm.formValid || _vm.isActionInProgress,"loading":_vm.isActionInProgress,"color":"primary"},on:{"click":_vm.openCloseBoxHandler}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.save'))+" ")],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }