import { render, staticRenderFns } from "./EditAccess.vue?vue&type=template&id=bda92a26&scoped=true&"
import script from "./EditAccess.vue?vue&type=script&lang=js&"
export * from "./EditAccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bda92a26",
  null
  
)

export default component.exports