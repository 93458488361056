import apiSale from '../../api/sale'
import moment from 'moment'
import { fullName } from '../../util'

const FETCHING_SALES = 'FETCHING_SALES'
const FETCHING_PROFITABLENESS = 'FETCHING_PROFITABLENESS'
const FETCHING_SALES_ONLINE = 'FETCHING_SALES_ONLINE'
const FETCHING_CREDITS_SALES = 'FETCHING_CREDITS_SALES'
const FETCHING_SALES_BY_CATEGORIES = 'FETCHING_SALES_BY_CATEGORIES'
const FETCHING_SALES_BY_PAYMENT = 'FETCHING_SALES_BY_PAYMENT'
const FETCHING_SALES_BY_TYPE_ORDER = 'FETCHING_SALES_BY_TYPE_ORDER'
const FETCHING_SALES_BY_MARK = 'FETCHING_SALES_BY_MARK'
const FETCHING_SALES_BY_PRODUCT = 'FETCHING_SALES_BY_PRODUCT'
const FETCHING_SALES_BY_EMPLOYER = 'FETCHING_SALES_BY_EMPLOYER'
const FETCHING_SALES_SUMMARY = 'FETCHING_SALES_SUMMARY'
const FETCHING_SALES_BY_LIMIT = 'FETCHING_SALES_BY_LIMIT'
const FETCHING_SALES_NUMBER = 'FETCHING_SALES_NUMBER'
const FETCHING_SALES_TOTAL = 'FETCHING_SALES_TOTAL'
const FETCHING_SALES_ACCCEPTED = 'FETCHING_SALES_ACCCEPTED'
const SWITCH_LOAD_DATA = 'SWITCH_LOAD_DATA'
const SWITCH_SALE_NEW_MODAL = 'SWITCH_SALE_NEW_MODAL'
const SWITCH_SALE_EDIT_MODAL = 'SWITCH_SALE_EDIT_MODAL'
const SWITCH_SALE_SHOW_MODAL = 'SWITCH_SALE_SHOW_MODAL'
const SALE_CREATED = 'SALE_CREATED'
const SALE_EDIT = 'SALE_EDIT'
const SALE_EDIT_ONLINE = 'SALE_EDIT_ONLINE'
const SALE_UPDATED = 'SALE_UPDATED'
const SYNC_SALE = 'SYNC_SALE'
const SALE_DELETE = 'SALE_DELETE'
const SALE_TABLE_LOADING = 'SALE_TABLE_LOADING'
const FETCHING_SALE_NEW_DATA = 'FETCHING_SALE_NEW_DATA'
const FETCHING_UPDATE_VALID_SALE = 'FETCHING_UPDATE_VALID_SALE'
const FAILED_SALE = 'FAILED_SALE'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_SALE = 'SET_EDIT_SALE'
const SET_SALE_AVATAR = 'SET_SALE_AVATAR'
const CANCEL_MODAL = 'CANCEL_MODAL'
const RESTORE_NEW_SALE = 'RESTORE_NEW_SALE'

const state = {
  saleAccepted: [],
  validSale: null,
  showNewModal: false,
  showEditModal: false,
  showShowModal: false,
  sales: [],
  onlineSale: [],
  creditsSales: [],
  profitableness: [],
  avatar: '',
  loadData: false,
  loading: false,
  saved: false,
  managerSale: false,
  newSale: {
    no_facture: '',
    online: false,
    box: null,
    table: null,
    state: 'open',
    payments: [],
    type_order: null,
    taxes: [],
    discounts: [],
    modifiers: [],
    sale_article_shops: [],
    shop: null,
    client: null
  },
  managerData: {},
  editSale: {
    id: '',
    no_facture: '',
    box: {},
    table: {},
    online: false,
    pays: [],
    payments: [],
    type_order: null,
    type_order_id: null,
    taxes: [],
    delivery: {},
    totalDelivery: 0.00,
    totalPerquisite: 0.00,
    perquisite: {},
    discounts: [],
    modifiers: [],
    sale_article_shops: [],
    shop: {},
    client: {},
    client_id: ''
  },
  isSaleTableLoading: false,
  isActionInProgress: false,
  isTableLoading: false,
  salesByCategories: [],
  salesByPayments: [],
  salesByTypeOrder: [],
  salesByMark: [],
  salesByProducts: [],
  salesData: [],
  salesByEmployer: [],
  salesByLimit: [],
  salesStatics: [],
  saleNumber: ''
}

const mutations = {
  [SWITCH_SALE_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_LOAD_DATA] (state, loadData) {
    state.loadData = loadData
  },
  [SWITCH_SALE_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_SALE_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [SALE_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
  },
  [FETCHING_SALE_NEW_DATA] (state, data) {
    state.managerData = data
  },
  [FETCHING_UPDATE_VALID_SALE] (state, data) {
    state.validSale = data
  },
  [FETCHING_SALES] (state, sales) {
    sales.sales.map((v) => {
      v.state_text = this._vm.$language.t('$vuetify.sale.state.' + v.state)
    })
    if (sales.online) {
      state.onlineSale = sales.sales
    } else {
      state.sales = sales.sales
    }
  },
  [FETCHING_PROFITABLENESS] (state, profitableness) {
    state.profitableness = profitableness
  },
  [FETCHING_SALES_ACCCEPTED] (state, sales) {
    sales.sales.map((v) => {
      v.state_text = this._vm.$language.t('$vuetify.sale.state.' + v.state)
    })
    state.saleAccepted = sales.sales
  },
  [FETCHING_SALES_ONLINE] (state, sales) {
    state.onlineSale = []
    state.onlineSale = sales.sales
  },
  [FETCHING_CREDITS_SALES] (state, sales) {
    state.creditsSales = []
    state.creditsSales = sales
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [CANCEL_MODAL] (state) {
    state.newSale = {
	  no_facture: '',
	  pays: [],
	  box: null,
	  table: null,
	  online: false,
	  state: 'open',
	  discounts: [],
	  taxes: [],
	  payments: [],
      type_order: null,
	  sale_article_shops: [],
	  shop: {},
	  client: {}
    }
    state.saved = false
  },
  [RESTORE_NEW_SALE] (state) {
    state.newSale = {
	  no_facture: '',
	  pays: [],
	  box: null,
	  table: null,
	  online: false,
	  state: 'open',
	  discounts: [],
	  taxes: [],
	  payments: [],
      type_order: null,
	  sale_article_shops: [],
	  shop: {},
	  client: {}
    }
  },
  [SALE_CREATED] (state) {
    state.showNewModal = false
    state.newSale = {
	  no_facture: '',
	  pays: [],
	  box: null,
	  table: null,
	  online: false,
	  state: 'open',
	  discounts: [],
	  taxes: [],
	  sale_article_shops: [],
	  shop: {},
	  client: {}
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.sale.sale')
	  ])
    })
  },
  [SALE_EDIT] (state, saleId) {
    console.log('SALE_EDIT')
    state.editSale = Object.assign(
	  {},
	  state.sales.filter(node => node.id === saleId).shift()
    )
  },
  [SALE_EDIT_ONLINE] (state, saleId) {
    state.editSale = Object.assign(
	  {},
	  state.onlineSale.filter(node => node.id === saleId).shift()
    )
  },
  [SALE_UPDATED] (state) {
    state.showEditModal = false
    state.editSale = {
	  id: '',
	  no_facture: '',
	  pays: [],
	  box: {},
	  online: false,
	  discounts: [],
	  taxes: [],
	  payments: [],
      type_order: null,
      type_order_id: null,
	  sale_article_shops: [],
	  shop: {},
	  client: {}
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_up', [
        this._vm.$language.t('$vuetify.sale.sale')
	  ])
    })
  },
  [SYNC_SALE] (state, sale) {
    state.sales.map(value => {
      if (value.id === sale) { value = sale }
    })
    state.saleAccepted.map(value => {
      if (value.id === sale) { value = sale }
    })
    state.onlineSale.map(value => {
      if (value.id === sale) { value = sale }
    })
  },
  [SET_EDIT_SALE] (state, editSale) {
    state.editSale = editSale
  },
  [SALE_DELETE] (state) {
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.sale.sale')
	  ])
    })
  },
  [SET_SALE_AVATAR] (state, avatar) {
    state.avatar = avatar
    state.saved = true
  },
  [FAILED_SALE] (state, error) {
    state.isActionInProgress = false
    state.isSaleTableLoading = false
    state.isTableLoading = false
    state.saved = false
    state.error = error
    this._vm.$Toast.fire({
	  icon: 'error',
	  title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.sale.sale')
	  ])
    })
  },
  [FETCHING_SALES_BY_CATEGORIES] (state, saleByCategory) {
    state.salesByCategories = saleByCategory
  },
  [FETCHING_SALES_BY_PAYMENT] (state, salesByPayment) {
    state.salesByPayments = salesByPayment[0]
    state.salesData = salesByPayment[1]
  },
  [FETCHING_SALES_BY_TYPE_ORDER] (state, salesByTypeOrder) {
    state.salesByTypeOrder = salesByTypeOrder[0]
    state.salesData = salesByTypeOrder[1]
  },
  [FETCHING_SALES_BY_MARK] (state, salesByMark) {
    state.salesByMark = salesByMark[0]
    state.salesData = salesByMark[1]
  },
  [FETCHING_SALES_BY_PRODUCT] (state, salesByProduct) {
    state.salesByProducts = salesByProduct[0]
    state.salesData = salesByProduct[1]
  },
  [FETCHING_SALES_BY_EMPLOYER] (state, salesByEmployer) {
    state.salesByEmployer = salesByEmployer[0]
    state.salesData = salesByEmployer[1]
  },
  [FETCHING_SALES_SUMMARY] (state, sumary) {
    state.salesByEmployer = sumary.employer
    state.salesByProducts = sumary.product
    state.salesByPayments = sumary.payment
    state.salesByTypeOrder = sumary.order
    state.salesByMark = sumary.mark
    state.salesData = sumary.data
  },
  [FETCHING_SALES_NUMBER] (state, saleNumber) {
    state.saleNumber = saleNumber
  },
  [FETCHING_SALES_BY_LIMIT] (state, salesByLimit) {
    salesByLimit.map(value => {
	  switch (value.state) {
	  case 'open':
          value.status = this._vm.$language.t(
		  '$vuetify.sale.state.open'
          )
          value.color = 'green'
          break
	  case 'accepted':
          value.status = this._vm.$language.t(
		  '$vuetify.sale.state.accepted'
          )
          value.color = 'blue'
          break
	  case 'cancelled':
          value.status = this._vm.$language.t(
		  '$vuetify.sale.state.cancelled'
          )
          value.color = 'red'
          break
	  }
	  value.timeString = moment(value.created_at).fromNow()
	  const createdName = value.created ? fullName(
        value.created.firstName,
        value.created.lastName
	  ) : ''
	  const clientName = value.client
        ? fullName(value.client.firstName, value.client.lastName)
        : ''
	  value.text = `${createdName && clientName ? createdName + ' ' + this._vm.$language.t('$vuetify.dashboard.timeLineText') +
          ' ' + clientName : createdName && !clientName ? createdName + ' ' + this._vm.$language.t('$vuetify.dashboard.timeLineTextNoClient')
          : !createdName && clientName ? this._vm.$language.t('$vuetify.dashboard.timeLineTextNoEmployer') + ' ' + clientName
          : this._vm.$language.t('$vuetify.dashboard.timeLineTextNoEmployerNoClient')}`
    })
    state.salesByLimit = salesByLimit
  },
  [FETCHING_SALES_TOTAL] (state, salesStatics) {
    state.salesStatics = salesStatics
  }
}

const getters = {
  getNumberFacture: state => {
    return state.saleNumber
  },
  getNewData: state => {
    return state.managerData
  },
  getSalesByProducts: state => {
    return state.salesByProducts.sort(function (a, b) {
      if (a.cantTransactions > b.cantTransactions) return -1
      if (a.cantTransactions < b.cantTransactions) return 1
      return 0
    })
  },
  getSalesByProductsMargin: state => {
    return state.salesByProducts.sort(function (a, b) {
      if (a.percent > b.percent) return -1
      if (a.percent < b.percent) return 1
      return 0
    })
  },
  getSalesProductData: state => {
    console.log(state.salesData)
    return state.salesData
  }
}

const actions = {
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_SALE_NEW_MODAL, showModal)
    if (!showModal) {
	  commit(CANCEL_MODAL)
    }
  },
  restoreNewSale ({ commit }) {
    commit(RESTORE_NEW_SALE)
  },
  switchLoadData ({ commit }, loadData) {
    commit(SWITCH_LOAD_DATA, loadData)
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_SALE_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_SALE_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, saleId) {
    commit(SALE_EDIT, saleId)
  },
  openEditOnlineModal ({ commit }, saleId) {
    commit(SALE_EDIT_ONLINE, saleId)
  },
  openShowModal ({ commit }, saleId) {
    commit(SALE_EDIT, saleId)
    commit(SWITCH_SALE_SHOW_MODAL, true)
  },
  async getSales ({ commit, dispatch }, id) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSales()
	  .then(({ data }) => {
        commit(FETCHING_SALES, data.data)
        commit(SALE_TABLE_LOADING, false)
        if (id) {
          commit(SALE_EDIT, id)
        }
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getOnlineSales ({ commit, dispatch }, id) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchOnlineSales()
	  .then(({ data }) => {
        commit(FETCHING_SALES_ONLINE, data.data)
        commit(SALE_TABLE_LOADING, false)
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getCreditSalesToPay ({ commit, dispatch }, filter) {
    commit(SALE_TABLE_LOADING, true)
    await apiSale
      .fetchCreditSalesToPay(filter)
      .then(({ data }) => {
        commit(FETCHING_CREDITS_SALES, data.data)
        commit(SALE_TABLE_LOADING, false)
      })
      .catch(error => commit(FAILED_SALE, error))
  },
  async getSaleFilterRange ({ commit, dispatch }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleFilterRange(filter)
	  .then(({ data }) => {
	      commit(FETCHING_PROFITABLENESS, data.data)
        commit(SALE_TABLE_LOADING, false)
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSaleFilterDays ({ commit, dispatch }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleFilterDays(filter)
	  .then(({ data }) => {
	      commit(FETCHING_PROFITABLENESS, data.data)
        commit(SALE_TABLE_LOADING, false)
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSaleFilterMonths ({ commit, dispatch }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleFilterMonths(filter)
	  .then(({ data }) => {
	      commit(FETCHING_PROFITABLENESS, data.data)
        commit(SALE_TABLE_LOADING, false)
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSaleFilterYears ({ commit, dispatch }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleFilterYears(filter)
	  .then(({ data }) => {
	      commit(FETCHING_PROFITABLENESS, data.data)
        commit(SALE_TABLE_LOADING, false)
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSalesFilter ({ commit, dispatch }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSalesFilter(filter)
	  .then(({ data }) => {
	      commit(FETCHING_SALES, data.data)
        commit(SALE_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getAcceptedSalesFilter ({ commit, dispatch }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSalesAcceptedFilter(filter)
	  .then(({ data }) => {
	      commit(FETCHING_SALES_ACCCEPTED, data.data)
        commit(SALE_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getNewSaleData ({
    commit,
    dispatch
  }, id) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .getNewSaleData()
	  .then(({ data }) => {
        commit(FETCHING_SALE_NEW_DATA, data.data)
        commit(SALE_TABLE_LOADING, false)
        if (id) {
          commit(SALE_EDIT, id)
        }
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async validCant ({
    commit,
    dispatch
  }, data) {
    // noinspection JSUnresolvedVariable
    await apiSale
	  .getValidCant(data)
	  .then(({ data }) => {
        commit(FETCHING_UPDATE_VALID_SALE, data.data)
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSalesByCategories ({
    commit,
    dispatch
  }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleByCategory(filter)
	  .then(({ data }) => {
        commit(FETCHING_SALES_BY_CATEGORIES, data.data)
        commit(SALE_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSalesByPayment ({
    commit,
    dispatch
  }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleByPayment(filter)
	  .then(({ data }) => {
        commit(FETCHING_SALES_BY_PAYMENT, data.data)
        commit(SALE_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSalesByOrder ({
    commit,
    dispatch
  }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleByTypeOrder(filter)
	  .then(({ data }) => {
        commit(FETCHING_SALES_BY_TYPE_ORDER, data.data)
        commit(SALE_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSalesByMark ({ commit, dispatch }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleByMark(filter)
	  .then(({ data }) => {
        commit(FETCHING_SALES_BY_MARK, data.data)
        commit(SALE_TABLE_LOADING, false)
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSaleByProduct ({
    commit,
    dispatch
  }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleByProduct(filter)
	  .then(({ data }) => {
        commit(FETCHING_SALES_BY_PRODUCT, data.data)
        commit(SALE_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSaleByEmployer ({
    commit,
    dispatch
  }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleByEmployer(filter)
	  .then(({ data }) => {
        commit(FETCHING_SALES_BY_EMPLOYER, data.data)
        commit(SALE_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSaleSummary ({
    commit,
    dispatch
  }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleSummary(filter)
	  .then(({ data }) => {
        commit(FETCHING_SALES_SUMMARY, data.data)
        commit(SALE_TABLE_LOADING, false)
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getSaleByLimit ({
    commit,
    dispatch
  }, filter) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleByLimit(filter)
	  .then(({ data }) => {
        commit(FETCHING_SALES_BY_LIMIT, data.data)
        commit(SALE_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => {
        commit(FAILED_SALE, error)
	  })
  },
  async getSaleStatics ({
    commit,
    dispatch
  }, dates) {
    commit(SALE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await apiSale
	  .fetchSaleStatics(dates)
	  .then(({ data }) => {
        commit(FETCHING_SALES_TOTAL, data.data)
        commit(SALE_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async createSale ({
    commit,
    dispatch
  }, newSale) {
    commit(ENV_DATA_PROCESS, true)
    await apiSale
	  .sendCreateRequest(newSale)
	  .then(({ data }) => {
        commit(SALE_CREATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('sale/getSales', data.data.id, { root: true })
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async updateSale ({
    commit,
    dispatch
  }, saleE) {
    commit(ENV_DATA_PROCESS, true)
    await apiSale
	  .sendUpdateRequest(saleE)
	  .then(({ data }) => {
        commit(SALE_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('sale/getSales', data.data.id, { root: true })
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_SALE, error)
	  })
  },
  async syncUpdateSale ({ commit, dispatch }, saleE) {
    commit(ENV_DATA_PROCESS, true)
    commit(SYNC_SALE, saleE)
    commit(ENV_DATA_PROCESS, false)
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_SALE, error)
	  })
  },
  async changeStateSale ({
    commit,
    dispatch
  }, update) {
    commit(ENV_DATA_PROCESS, true)

    // const request = profile || state.editUser
    await apiSale
	  .changeStateSale(update)
	  .then(({ data }) => {
        commit(SALE_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('sale/getSales', null, { root: true })
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_SALE, error)
	  })
  },
  async deleteSale ({
    commit,
    dispatch,
    state
  }, saleId) {
    await apiSale
	  .sendDeleteRequest(saleId)
	  .then(data => {
        commit(SALE_DELETE)
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async multiDelete ({
    commit,
    dispatch,
    state
  }, selected) {
    await apiSale
	  .sendMultiDeleteRequest(selected)
	  .then(data => {
        commit(SALE_DELETE)
        dispatch('sale/getSales', null, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async fetchSaleNumber ({
    commit,
    dispatch
  }, info) {
    await apiSale
	  .fetchSaleNumber(info)
	  .then(({ data }) => {
        commit(FETCHING_SALES_NUMBER, data.data)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  },
  async getEditSale ({
    commit,
    dispatch
  }, id) {
    await apiSale
	  .fetchEditSale(id)
	  .then(({ data }) => {
        commit(SET_EDIT_SALE, data.data)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_SALE, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
