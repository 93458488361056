<template>
  <v-container fluid>
    <v-card>
      <v-tabs
        v-model="tabItem"
        v-if="!isMobile"
        vertical
      >
        <v-tab
          v-for="(item, i) in localTabsData.tabName"
          :id="'tab_ele_' + i"
          :key="item.name"
          style="align-self: flex-start"
        >
          <v-icon left>
            {{ item.icon }}
          </v-icon>
          <span style="text-transform: capitalize">{{
              item.name
            }}</span>
          <span style="color: red" v-if="item.required">*</span>
        </v-tab>

        <v-tab-item
          v-for="(item, j) in localTabsData.itemsTabs"
          :active-item="'item_' +j"
          :key="item.key"
        >
          <v-card flat>
            <v-card-text>
              <component :is="item.content" @show-new-article="showNewArticle" @out-manager="outManager"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-tabs
        v-else
        v-model="tab"
        background-color="transparent"
        icons-and-text
      >
        <v-tab
          v-for="item in localTabsData.tabName"
          :key="item.name"
        >
          <v-icon>
            {{ item.icon }}
          </v-icon>
          <span style="text-transform: capitalize">{{
              item.name
            }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in localTabsData.itemsTabs"
          :key="item.key"
        >
          <v-card flat>
            <v-card-text>
              <component :is="item.content" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>

import Subscription from '../../subscriptions/MySubscription'
import Shops from '../../shop/commerce/ListShop'
import Taxes from '../../tax/commerce/ListTax'
import Discounts from '../../discount/commerce/ListDiscount'
import Modifiers from '../../modifiers/commerce/ListModifiers'
import ExchangeRate from '../../exchange_rate/commerce/ListExchangeRate'
import TypeOfOrder from '../../type_order/commerce/ListTypeOfOrder'
import Marks from '../../mark/commerce/ListMark'
import CategoryList from '../../category/commerce/ListCategory'
import ActiveCategory from '../../category/commerce/ActiveCategory'
import ListAccess from '../../access/commerce/ListAccess'
import AccountFiscal from '../../facture/AccountFiscal'
import Bank from '../../bank/commerce/BankList'
import ListBoxes from '../../boxes/commerce/ListBoxes'
import Employee from '../../employees/commerce/ListUser'
import ClientList from '../../client/commerce/ListClient'
import ListArticle from '../../article/commerce/ListArticle'
import ManagerArticle from '../../article/commerce/ManagerArticle'
import { mapGetters } from 'vuex'
export default {
  props: {
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  name: 'UserSetting',
  components: {
    Subscription,
    Shops,
    Taxes,
    ListArticle,
    ManagerArticle,
    Discounts,
    Modifiers,
    ExchangeRate,
    TypeOfOrder,
    Marks,
    CategoryList,
    ActiveCategory,
    ListAccess,
    AccountFiscal,
    Bank,
    ListBoxes,
    Employee,
    ClientList
  },
  data () {
    return {
      tabItem: null,
      tab: null,
      localTabsData: { tabName: [], itemsTabs: [] },
      isMobile: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdminIn', 'access_permit', 'isManagerIn']),
    itemsTabs () {
      const data = [
        { key: 'subscription', content: 'subscription', required: true },
        { key: 'shop-list', content: 'shops', required: false },
        { key: 'tax', content: 'taxes', required: false },
        { key: 'discount', content: 'discounts', required: false },
        { key: 'marks', content: 'marks', required: false },
        { key: 'category-list', content: 'category-list', required: false },
        { key: 'active-category', content: 'active-category', required: true }
      ]
      if (this.showRequired) {
        data.push({ key: 'manager-article', content: 'manager-article', required: true })
        data.push({ key: 'list-article', content: 'list-article', required: true })
      }
      data.push(
        { key: 'exchange-rate', content: 'exchange-rate', required: false },
        { key: 'type-of-order', content: 'type-of-order', required: false },
        { key: 'list-access', content: 'list-access', required: false },
        { key: 'account-fiscal', content: 'account-fiscal', required: false },
        { key: 'bank', content: 'bank', required: false },
        { key: 'list-boxes', content: 'list-boxes', required: true },
        { key: 'employee', content: 'employee', required: false },
        { key: 'client-list', content: 'client-list', required: false },
        { key: 'modifier', content: 'modifiers', required: false })
      return data
    },
    tabName () {
      const data = [
        {
          name: this.$vuetify.lang.t('$vuetify.menu.admin_plan'),
          icon: 'mdi-clipboard-text',
          access: 'subscription',
          required: true
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.shop'),
          icon: 'mdi-shopping',
          access: 'shop',
          required: false
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.tax_list'),
          icon: 'mdi-wallet',
          access: 'tax',
          required: false
        }, {
          name: this.$vuetify.lang.t('$vuetify.menu.discount'),
          icon: 'mdi-cash-minus',
          access: 'discount',
          required: false
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.marks'),
          icon: 'mdi-certificate',
          access: 'mark',
          required: false
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.category'),
          icon: 'mdi-certificate',
          access: 'category',
          required: false
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.active_category_list'),
          icon: 'mdi-check-all',
          access: 'category',
          required: true
        }]
      if (this.showRequired) {
        data.push(
          {
            name: this.$vuetify.lang.t('$vuetify.menu.product_add'),
            icon: 'mdi-database-plus',
            access: 'article',
            required: true
          },
          {
            name: this.$vuetify.lang.t('$vuetify.menu.articles'),
            icon: 'mdi-shopping',
            access: 'article',
            required: true
          })
      }
      data.push(
        {
          name: this.$vuetify.lang.t('$vuetify.menu.exchange_rate'),
          icon: 'mdi-currency-usd',
          access: 'exchange_rate',
          required: false
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.type_order'),
          icon: 'mdi-food',
          access: 'type_of_order',
          required: false
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.access'),
          icon: 'mdi-key',
          access: 'access',
          required: false
        }, {
          name: this.$vuetify.lang.t('$vuetify.menu.fiscal_account'),
          icon: 'mdi-folder-account',
          access: 'fiscal_account',
          required: false
        }, {
          name: this.$vuetify.lang.t('$vuetify.menu.bank'),
          icon: 'mdi-bank',
          access: 'bank',
          required: false
        }, {
          name: this.$vuetify.lang.t('$vuetify.menu.box'),
          icon: 'mdi-monitor',
          access: 'box',
          required: true
        }, {
          name: this.$vuetify.lang.t('$vuetify.menu.employee'),
          icon: 'mdi-account-box',
          access: 'employer',
          required: false
        }, {
          name: this.$vuetify.lang.t('$vuetify.menu.client'),
          icon: 'mdi-account-star',
          access: 'client',
          required: false
        }, {
          name: this.$vuetify.lang.t('$vuetify.menu.modifiers'),
          icon: 'mdi-cash-multiple',
          access: 'modifier',
          required: false
        })
      return data
    }
  },
  watch: {
    access_permit: function () {
      this.tabsData()
    }
  },
  created () {
    this.onResize()
    this.tabsData()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, {
        passive: true
      })
    }
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 600
    },
    showNewArticle () {
      this.tabItem = 7
    },
    outManager () {
      this.tabItem = 8
    },
    tabsData () {
      this.localTabsData = { tabName: [], itemsTabs: [] }
      const localItemTab = this.itemsTabs
      const localTabName = this.tabName
      localTabName.forEach((v, i) => {
        const access = this.access_permit[v.access]
        if (access) {
          if (access.title.value === true) {
            this.localTabsData.tabName.push(v)
            this.localTabsData.itemsTabs.push(localItemTab[i])
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
