import buy from '../../api/buy'
import apiSale from '../../api/sale'

const FETCHING_BUYS = 'FETCHING_BUYS'
const FETCHING_CREDITS_BUYS = 'FETCHING_CREDITS_BUYS'
const FETCHING_BUYS_BY_CATEGORIES = 'FETCHING_BUYS_BY_CATEGORIES'
const FETCHING_BUYS_BY_PAYMENT = 'FETCHING_BUYS_BY_PAYMENT'
const FETCHING_BUYS_BY_PRODUCT = 'FETCHING_BUYS_BY_PRODUCT'
const FETCHING_BUYS_BY_EMPLOYER = 'FETCHING_BUYS_BY_EMPLOYER'
const FETCHING_BUYS_BY_LIMIT = 'FETCHING_BUYS_BY_LIMIT'
const FETCHING_BUYS_NUMBER = 'FETCHING_BUYS_NUMBER'
const FETCHING_BUYS_TOTAL = 'FETCHING_BUYS_TOTAL'
const SWITCH_LOAD_DATA = 'SWITCH_LOAD_DATA'
const SWITCH_BUY_NEW_MODAL = 'SWITCH_BUY_NEW_MODAL'
const SWITCH_BUY_EDIT_MODAL = 'SWITCH_BUY_EDIT_MODAL'
const SWITCH_BUY_SHOW_MODAL = 'SWITCH_BUY_SHOW_MODAL'
const BUY_CREATED = 'BUY_CREATED'
const BUY_EDIT = 'BUY_EDIT'
const SET_EDIT_BUY = 'SET_EDIT_BUY'
const BUY_UPDATED = 'BUY_UPDATED'
const BUY_DELETE = 'BUY_DELETE'
const BUY_TABLE_LOADING = 'BUY_TABLE_LOADING'
const FETCHING_BUY_DATA = 'FETCHING_BUY_DATA'
const FAILED_BUY = 'FAILED_BUY'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_BUY_AVATAR = 'SET_BUY_AVATAR'
const CANCEL_BUY = 'CANCEL_BUY'

const state = {
  buyData: [],
  showNewModal: false,
  showEditModal: false,
  showShowModal: false,
  buys: [],
  creditsBuys: [],
  avatar: '',
  loadData: false,
  loading: false,
  saved: false,
  managerBuy: false,
  newBuy: {
    no_facture: '',
    online: false,
    box: null,
    state: 'preform',
    pays: [],
    taxes: [],
    discounts: [],
    modifiers: [],
    payments: [],
    sale_article_shops: [],
    shop: null,
    client: null
  },
  editBuy: {
    id: '',
    no_facture: '',
    box: {},
    online: false,
    payments: [],
    pays: [],
    taxes: [],
    discounts: [],
    modifiers: [],
    sale_article_shops: [],
    shop: {},
    client: {},
    client_id: ''
  },
  isBuyTableLoading: false,
  isActionInProgress: false,
  isTableLoading: false,
  buysByCategories: [],
  buysByPayments: [],
  buysByProducts: [],
  buysByEmployer: [],
  buysByLimit: [],
  buysStatics: [],
  buyNumber: ''
}

const mutations = {
  [SWITCH_BUY_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_LOAD_DATA] (state, loadData) {
    state.loadData = loadData
  },
  [SWITCH_BUY_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_BUY_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [BUY_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
  },
  [FETCHING_BUY_DATA] (state, buyData) {
    state.buyData = buyData
  },
  [FETCHING_BUYS] (state, buys) {
    buys.map((v) => {
      v.state_text = this._vm.$language.t('$vuetify.sale.state.' + v.state)
    })
    state.buys = buys
  },
  [FETCHING_CREDITS_BUYS] (state, buys) {
    state.creditsBuys = []
    state.creditsBuys = buys
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [CANCEL_BUY] (state) {
    state.newBuy = {
	  no_facture: '',
	  pays: [],
	  box: null,
	  online: false,
      state: 'preform',
	  discounts: [],
	  taxes: [],
	  payments: [],
	  sale_article_shops: [],
	  shop: {},
	  client: {}
    }
    state.saved = false
  },
  [BUY_CREATED] (state) {
    state.showNewModal = false
    state.newBuy = {
	  no_facture: '',
	  pays: [],
	  box: null,
	  online: false,
      state: 'preform',
	  discounts: [],
	  taxes: [],
	  payments: [],
	  sale_article_shops: [],
	  shop: {},
	  client: {}
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.supply.name')
	  ])
    })
  },
  [BUY_EDIT] (state, buyId) {
    state.editBuy = Object.assign(
	  {},
	  state.buys.filter(node => node.id === buyId).shift()
    )
  },
  [SET_EDIT_BUY] (state, editBuy) {
    state.editBuy = editBuy
    state.editBuy.sale_article_shops.map((artS) => {
      if (artS.sale_article_taxes.length > 0) {
        const taxes = artS.sale_article_taxes
        artS.sale_article_taxes = []
        taxes.forEach((tx) => {
          artS.sale_article_taxes.push({
            tax_id: tx.tax_id,
            name: tx.name,
            name_complete: tx.name_complete,
            values_id: tx.values_id ? tx.values_id : tx.pivot ? tx.pivot.tax_value_id : null,
            type: tx.type,
            value: tx.value,
            percent: tx.percent
          })
        })
      }
    })
  },
  [BUY_UPDATED] (state) {
    state.showEditModal = false
    state.editBuy = {
	  id: '',
	  no_facture: '',
	  pays: [],
	  box: {},
	  online: false,
	  discounts: [],
	  taxes: [],
	  payments: [],
	  sale_article_shops: [],
	  shop: {},
	  client: {}
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_up', [
        this._vm.$language.t('$vuetify.supply.name')
	  ])
    })
  },
  [BUY_DELETE] (state) {
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.supply.name')
	  ])
    })
  },
  [SET_BUY_AVATAR] (state, avatar) {
    state.avatar = avatar
    state.saved = true
  },
  [FAILED_BUY] (state, error) {
    state.newBuy = {
      no_facture: '',
      pays: [],
      box: null,
      online: false,
      state: 'preform',
      discounts: [],
      taxes: [],
      payments: [],
      sale_article_shops: [],
      shop: {},
      client: {}
    }
    state.editBuy = {
      id: '',
      no_facture: '',
      pays: [],
      box: {},
      online: false,
      discounts: [],
      taxes: [],
      payments: [],
      sale_article_shops: [],
      shop: {},
      client: {}
    }
    state.isActionInProgress = false
    state.isBuyTableLoading = false
    state.isTableLoading = false
    state.saved = false
    state.error = error
    this._vm.$Toast.fire({
	  icon: 'error',
	  title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.supply.name')
	  ])
    })
  },
  [FETCHING_BUYS_BY_CATEGORIES] (state, buyByCategory) {
    state.buysByCategories = buyByCategory
  },
  [FETCHING_BUYS_BY_PAYMENT] (state, buysByPayment) {
    state.buysByPayments = buysByPayment
  },
  [FETCHING_BUYS_BY_PRODUCT] (state, buysByProduct) {
    state.buysByProducts = buysByProduct
  },
  [FETCHING_BUYS_BY_EMPLOYER] (state, buysByEmployer) {
    state.buysByEmployer = buysByEmployer
  },
  [FETCHING_BUYS_NUMBER] (state, buyNumber) {
    state.buyNumber = buyNumber
  },
  [FETCHING_BUYS_BY_LIMIT] (state, buysByLimit) {
    buysByLimit.map(value => {
	  switch (value.state) {
	  case 'open':
          value.status = this._vm.$language.t(
		  '$vuetify.buy.state.open'
          )
          value.color = 'green'
          break
	  case 'accepted':
          value.status = this._vm.$language.t(
		  '$vuetify.buy.state.accepted'
          )
          value.color = 'blue'
          break
	  case 'cancelled':
          value.status = this._vm.$language.t(
		  '$vuetify.buy.state.cancelled'
          )
          value.color = 'red'
          break
	  }
	  value.timeString = moment(value.created_at).fromNow()
	  const createdName = fullName(
        value.created.firstName,
        value.created.lastName
	  )
	  const clientName = value.client
        ? fullName(value.client.firstName, value.client.lastName)
        : ''
	  value.text =
		createdName +
		' ' +
		this._vm.$language.t('$vuetify.dashboard.timeLineText') +
		' ' +
		clientName
    })
    state.buysByLimit = buysByLimit
  },
  [FETCHING_BUYS_TOTAL] (state, buysStatics) {
    state.buysStatics = buysStatics
  }
}

const getters = {
  getNumberFacture: state => {
    return state.buyNumber
  }
}

const actions = {
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_BUY_NEW_MODAL, showModal)
    if (!showModal) {
	  commit(CANCEL_BUY)
    }
  },
  switchLoadData ({ commit }, loadData) {
    commit(SWITCH_LOAD_DATA, loadData)
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_BUY_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_BUY_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, buyId) {
    commit(BUY_EDIT, buyId)
  },
  openShowModal ({ commit }, buyId) {
    commit(SWITCH_BUY_SHOW_MODAL, true)
    commit(BUY_EDIT, buyId)
  },
  async getBuys ({ commit, dispatch }) {
    commit(BUY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await buy
	  .fetchBuys()
	  .then(({ data }) => {
        commit(FETCHING_BUYS, data.data)
        commit(BUY_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async getBuysFilters ({ commit, dispatch }, filter) {
    commit(BUY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await buy
	  .getBuysFilters(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUYS, data.data)
        commit(BUY_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => {
        commit(FAILED_BUY, error)
      })
  },
  async getNewBuyData ({ commit, dispatch }) {
    commit(BUY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await buy
	  .getNewBuyData()
	  .then(({ data }) => {
        commit(FETCHING_BUY_DATA, data.data)
        commit(BUY_TABLE_LOADING, false)
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async getCreditBuysToPay ({ commit, dispatch }, filter) {
    commit(BUY_TABLE_LOADING, true)
    await buy
      .fetchCreditBuysToPay(filter)
      .then(({ data }) => {
        commit(FETCHING_CREDITS_BUYS, data.data)
        commit(BUY_TABLE_LOADING, false)
      })
      .catch(error => commit(FAILED_BUY, error))
  },
  async getBuysByCategories ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await buy
	  .fetchBuyByCategory(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUYS_BY_CATEGORIES, data.data)
        commit(BUY_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async getBuysByPayment ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await buy
	  .fetchBuyByPayment(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUYS_BY_PAYMENT, data.data)
        commit(BUY_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async getBuyByProduct ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await buy
	  .fetchBuyByProduct(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUYS_BY_PRODUCT, data.data)
        commit(BUY_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async getBuyByEmployer ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await buy
	  .fetchBuyByEmployer(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUYS_BY_EMPLOYER, data.data)
        commit(BUY_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async getBuyByLimit ({
    commit,
    dispatch
  }, filter) {
    commit(BUY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await buy
	  .fetchBuyByLimit(filter)
	  .then(({ data }) => {
        commit(FETCHING_BUYS_BY_LIMIT, data.data)
        commit(BUY_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => {
        commit(FAILED_BUY, error)
	  })
  },
  async getBuyStatics ({
    commit,
    dispatch
  }) {
    commit(BUY_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await buy
	  .fetchBuyStatics()
	  .then(({ data }) => {
        commit(FETCHING_BUYS_TOTAL, data.data)
        commit(BUY_TABLE_LOADING, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async createBuy ({
    commit,
    dispatch
  }, newBuy) {
    commit(ENV_DATA_PROCESS, true)
    await buy
	  .sendCreateRequest(newBuy)
	  .then(data => {
        commit(BUY_CREATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async updateBuy ({
    commit,
    dispatch
  }, buyE) {
    commit(ENV_DATA_PROCESS, true)
    await buy
	  .sendUpdateRequest(buyE)
	  .then(data => {
        commit(BUY_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_BUY, error)
	  })
  },
  async changeStateBuy ({
    commit,
    dispatch
  }, update) {
    commit(ENV_DATA_PROCESS, true)

    // const request = profile || state.editUser
    await buy
	  .changeStateBuy(update)
	  .then(data => {
        commit(BUY_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_BUY, error)
	  })
  },
  async deleteBuy ({
    commit,
    dispatch,
    state
  }, buyId) {
    await buy
	  .sendDeleteRequest(buyId)
	  .then(data => {
        commit(BUY_DELETE)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async fetchBuyNumber ({
    commit,
    dispatch
  }) {
    await buy
	  .fetchBuyNumber()
	  .then(({ data }) => {
        commit(FETCHING_BUYS_NUMBER, data.data)
        dispatch('auth/updateAccess', data.access, { root: true })
	  })
	  .catch(error => commit(FAILED_BUY, error))
  },
  async getEditBuy ({ commit, dispatch }, id) {
    await apiSale
      .fetchEditSale(id)
      .then(({ data }) => {
        commit(SET_EDIT_BUY, data.data)
        dispatch('auth/updateAccess', data.access, { root: true })
      })
      .catch(error => commit(FAILED_BUY, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
