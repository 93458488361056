import pay from '../../api/pay'

const FETCHING_PAY_DATA = 'FETCHING_PAY_DATA'
const FETCHING_PAYPAL_DATA = 'FETCHING_PAYPAL_DATA'
const FETCHING_SESSION_ID = 'FETCHING_SESSION_ID'
const SEND_PAY = 'SEND_PAY'
const FAILED_PAY = 'FAILED_PAY'
const SWITCH_STRIPE_PAY_MODAL = 'SWITCH_STRIPE_PAY_MODAL'
const SWITCH_PAYPAL_SHOW_MODAL = 'SWITCH_PAYPAL_SHOW_MODAL'
const SWITCH_PRINT_PAY_MODAL = 'SWITCH_PRINT_PAY_MODAL'
const SWITCH_PAY_SHOW_MODAL = 'SWITCH_PAY_SHOW_MODAL'
const SWITCH_STRIPE_SECTION_SHOW_MODAL = 'SWITCH_STRIPE_SECTION_SHOW_MODAL'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const PRINT_DATA = 'PRINT_DATA'

const state = {
  stripeData: {
    pk: '',
    stripeAccount: '',
    apiVersion: '',
    locale: 'en'
  },
  payPlan: {
    method: '',
    description: '',
    token: '',
    plan: {},
    period: 'month'
  },
  session: '',
  paypalData: {
    client_id: ''
  },
  showShowPayPalModal: false,
  showPayModal: false,
  showPrintModal: false,
  showStripeSectionModal: false,
  showStripeModal: false,
  isActionInProgress: false
}

const mutations = {
  [FETCHING_PAY_DATA] (state, stripeData) {
    state.stripeData = stripeData
  },
  [FETCHING_PAYPAL_DATA] (state, payPalData) {
    state.paypalData = payPalData
  },
  [FETCHING_SESSION_ID] (state, data) {
    state.session = data.id
  },
  [SEND_PAY] (state) {
    state.stripeData = {
      method: '',
      description: '',
      token: '',
      plan: {}
    }
  },

  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },

  [SWITCH_STRIPE_PAY_MODAL] (state, showModal) {
    state.showStripeModal = showModal
  },
  [FAILED_PAY] (state, error) {
    state.error = error
    state.isActionInProgress = false
    this._vm.$Toast.fire({
      icon: 'error',
      title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.pay')
      ])
    })
  },
  [SWITCH_PAYPAL_SHOW_MODAL] (state, showModal) {
    state.showShowPayPalModal = showModal
  },
  [SWITCH_PRINT_PAY_MODAL] (state, showModal) {
    state.showPrintModal = showModal
  },
  [SWITCH_PAY_SHOW_MODAL] (state, showModal) {
    state.showPayModal = showModal
  },
  [SWITCH_STRIPE_SECTION_SHOW_MODAL] (state, showModal) {
    state.showStripeSectionModal = showModal
  }
}

const getters = {}

const actions = {
  toogleShowStripeSectionModal ({ commit }, showModal) {
    commit(SWITCH_STRIPE_SECTION_SHOW_MODAL, showModal)
  },
  toogleShowPayPalModal ({ commit }, showModal) {
    commit(SWITCH_PAYPAL_SHOW_MODAL, showModal)
  },
  tooglePrintPayModal ({ commit }, showModal) {
    commit(SWITCH_PRINT_PAY_MODAL, showModal)
  },
  openShowModal ({ commit }, saleId) {
    commit(PAY_EDIT, saleId)
    commit(SWITCH_PAY_SHOW_MODAL, true)
  },
  async getPayData ({ commit }, method) {
    commit(ENV_DATA_PROCESS, true)
    // noinspection JSUnresolvedVariable
    await pay
	  .getStripePayData(method)
	  .then(({ data }) => {
        commit(FETCHING_PAY_DATA, data.data)
        this.dispatch('auth/updateAccess', data)
        commit(ENV_DATA_PROCESS, false)
        return data
	  })
	  .catch(error => commit(FAILED_PAY, error))
  },
  async sendPayPlan ({ commit }, payPlan) {
    await pay.sendStripePayPlan(payPlan)
  },
  async getPayPalData ({ commit }) {
    commit(ENV_DATA_PROCESS, true)
    // noinspection JSUnresolvedVariable
    await pay
      .getPayPalData()
      .then(({ data }) => {
        commit(FETCHING_PAYPAL_DATA, data.data)
        this.dispatch('auth/updateAccess', data)
        commit(ENV_DATA_PROCESS, false)
        return data
      })
      .catch(error => commit(FAILED_PAY, error))
  },
  async payPalPayPlan ({ commit }, order) {
    await pay.payPalPayPlan(order)
  },
  async makeSession ({ commit }, plan) {
    commit(ENV_DATA_PROCESS, true)
    await pay.makeSession(plan).then(({ data }) => {
      commit(FETCHING_SESSION_ID, data.data)
      this.dispatch('auth/updateAccess', data)
      commit(ENV_DATA_PROCESS, false)
      return data
    })
  },
  async statusPayPalPay ({ commit }, payPlan) {
    await pay.paypalPayStatus(payPlan)
  },

  toogleShowStripePay ({ commit }, showModal) {
    commit(SWITCH_STRIPE_PAY_MODAL, showModal)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
