<template>
    <v-card-text>
        <h2>{{selected.name}}</h2>
        <v-row
            class="pa-4"
            justify="space-between">
            <v-col :cols="selected.src?'4': '12'" v-if="selected.steps || selected.src" >
                <v-card-text v-if="selected.steps">
                    <h3  align="left"><b>{{$vuetify.lang.t('$vuetify.help.step')}}</b></h3>
                    <template v-for="(item, i) in selected.steps">
                        <v-list-item :key="i">
                            <v-list-item-content>
                                <v-list-item-subtitle align="left">
                                    <p>{{parseInt(i) +1 +': ' + item.text}}</p>
                                </v-list-item-subtitle>

                                <v-list-item-action v-if="item.btn">
                                    <v-btn v-if="item.btn === 'add'" class="small" color="primary">
                                        <v-icon>mdi-plus</v-icon>
                                        {{ $vuetify.lang.t("$vuetify.actions.new") }}</v-btn>
                                    <v-icon v-if="item.btn === 'edit'"
                                            class="mr-2"
                                            color="warning"
                                            small>
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon v-if="item.btn === 'delete'" class="mr-2"
                                            color="error" small>
                                        mdi-delete
                                    </v-icon>
                                </v-list-item-action>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-card-text>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col class="d-flex text-center" v-if="selected.src" >
                <video :src="selected.src" controls autoplay width="100%"/>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col md="12" cols="12" v-for="(description,u ) in selected.descriptions" :key="u">
                <p>
                    {{description.text}}
                </p>
                <template v-for="(step, i) in description.steps">
                    <v-list-item :key="i">
                        <v-list-item-content>
                          <p style="opacity: 90%;">{{step.step +': ' + step.text }}</p>

                            <v-list-item-icon v-if="step.btn" style="">
                                <template v-for="(btn, j) in step.btn">
                                    <v-btn  style="margin-right: 15px" :key="j" v-if="btn === 'add'" class="small" color="primary">
                                        <v-icon>mdi-plus</v-icon>
                                        {{ $vuetify.lang.t("$vuetify.actions.new") }}</v-btn>
                                    <v-icon  style="margin-right: 15px" :key="j" v-if="btn === 'edit'"
                                             class="mr-2"
                                             color="warning"
                                             small>
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon  style="margin-right: 15px" :key="j" v-if="btn === 'delete'" class="mr-2"
                                             color="error" small>
                                        mdi-delete
                                    </v-icon>
                                    <v-btn  style="margin-right: 15px"
                                            :key="j"
                                            v-if="btn === 'cart'"
                                            fab
                                            dark
                                            small
                                            color="green"
                                    >
                                        <v-icon>mdi-cart</v-icon>
                                    </v-btn>
                                    <v-btn  style="margin-right: 15px"
                                            v-if="btn === 'file-document'"
                                            :key="j"
                                            fab
                                            dark
                                            small
                                            color="indigo"
                                    >
                                        <v-icon>mdi-file-document</v-icon>
                                    </v-btn>
                                    <v-btn  style="margin-right: 15px"
                                            :key="j"
                                            v-if="btn === 'cart-off'"
                                            fab
                                            dark
                                            small
                                            color="red"
                                    >
                                        <v-icon>mdi-cart-off</v-icon>
                                    </v-btn>
                                    <v-btn  style="margin-right: 15px" :key="j" class="mb-2" v-if="btn==='cancel'">
                                        <v-icon>mdi-close</v-icon>
                                        {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
                                    </v-btn>
                                    <v-btn  style="margin-right: 15px" :key="j" v-if="btn==='process'"
                                            class="mb-2"
                                            color="success"
                                    >
                                        <v-icon>mdi-calendar-clock</v-icon>
                                        {{ $vuetify.lang.t('$vuetify.sale.state.preform') }}
                                    </v-btn>
                                    <v-btn  style="margin-right: 15px" :key="j" v-if="btn === 'accept'"
                                            class="mb-2"
                                            color="primary"
                                    >
                                        <v-icon>mdi-check-all</v-icon>
                                        {{ $vuetify.lang.t('$vuetify.sale.state.accepted') }}
                                    </v-btn>
                                    <v-btn
                                        style="margin-right: 15px"
                                        :key="j"
                                        v-if="btn === 'next'"
                                        color="primary"
                                    >
                                        {{ $vuetify.lang.t('$vuetify.actions.next') }}
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                    <v-switch :key="j" v-if="btn === 'follow_inventory'"
                                              :title="$vuetify.lang.t('$vuetify.articles.track_inventory')"
                                    >
                                        <template v-slot:label>
                                            <div>
                                                {{
                                                    $vuetify.lang.t("$vuetify.articles.track_inventory")
                                                }}
                                                <v-tooltip
                                                    max-width="350"
                                                    right
                                                    class="md-6"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            color="primary"
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-information-outline
                                                        </v-icon>
                                                    </template>
                                                    <p>
                                                        {{
                                                            $vuetify.lang.t(
                                                                "$vuetify.messages.warning_article_service"
                                                            )
                                                        }}
                                                    </p>
                                                </v-tooltip>
                                            </div>
                                        </template>
                                    </v-switch>
                                </template>
                            </v-list-item-icon>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script>
export default {
  name: 'Selected',
  props: {
    selected: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
