import api from '../config/api'

export default {
  getStripePayData (method) {
    return api.post('stripe-pay/data', method)
  },
  sendStripePayPlan (data) {
    return api.post('stripe-pay/execute-plan-pay', data)
  },
  makeSession (data) {
    return api.post('stripe-pay/session', data)
  },
  sendDeleteRequest (paymentId) {
    return api.remove('payment/' + paymentId)
  },
  getPayPalData () {
    return api.post('paypal-pay/data')
  },
  payPalPayPlan (data) {
    return api.post('paypal-pay/execute-plan-pay', data)
  },
  paypalPayStatus (data) {
    return api.post('paypal-pay/status', data)
  }
}
