import {
  LayoutAuth,
  LayoutDefault,
  RouteWrapper,
  LayoutVerify,
  LayoutLock
  /*,
      LayoutSales */
} from '../components/layouts'

export const publicRoute = [{
  path: '*',
  children: [],
  component: () =>
    import('../views/error/NotFound')
},
{
  path: '/auth',
  component: LayoutAuth,
  meta: {
    title: 'Register'
  },
  redirect: '/auth/register',
  hidden: true,
  children: [{
    path: 'register',
    name: 'register',
    meta: {
      title: 'Register'
    },
    component: () =>
      import('../views/auth/Register')
  }]
},
{
  path: '/home',
  component: () =>
    import('../views/basic-home-page/Home'),
  name: 'home',
  hidden: true,
  children: [
  ]
},
{
  path: '/home/referred-by/ref=:affiliate_id',
  component: () => import('../views/basic-home-page/Home'),
  name: 'invitation_home',
  meta: { title: 'Invitation Register' },
  children: []
},
{
  path: '/affiliate/:affiliate_id',
  component: LayoutAuth,
  meta: {
    title: 'Home'
  },
  redirect: '/home/referred-by/ref=:affiliate_id',
  hidden: true,
  children: [{
    path: 'affiliate',
    name: 'affiliate',
    meta: {
      title: 'Home'
    },
    component: () =>
      import('../views/basic-home-page/Home')
  }]
},
{
  path: '/auth/register/:hash',
  component: LayoutAuth,
  hidden: true,
  children: [{
    path: '/auth/register/:hash',
    name: 'invitation_password',
    meta: { title: 'Invitation Register' },
    component: () =>
      import('../views/auth/Register')
  }]
},
{
  path: '/auth',
  component: LayoutAuth,
  meta: {
    title: 'Login'
  },
  redirect: '/auth/login',
  hidden: true,
  children: [{
    path: 'login',
    name: 'login',
    meta: {
      title: 'Login'
    },
    component: () =>
      import('../views/auth/Login')
  }]
},
{
  path: '/auth',
  component: LayoutAuth,
  meta: {
    title: 'Forgot'
  },
  redirect: '/auth/forgot',
  hidden: true,
  children: [{
    path: 'forgot',
    name: 'forgot',
    meta: {
      title: 'Forgot Password'
    },
    component: () =>
      import('../views/auth/Forgot')
  }]
},
{
  path: '/404',
  name: '404',
  hidden: true,
  meta: {
    title: 'Not Found'
  },
  children: [],
  component: () =>
    import('../views/error/NotFound')
},
{
  path: '/500',
  name: '500',
  hidden: true,
  meta: {
    title: 'Server Error'
  },
  children: [],
  component: () =>
    import('../views/error/Error')
},
{
  path: '/password/reset/:type/:hash',
  component: LayoutAuth,
  hidden: true,
  children: [{
    path: '/password/reset/:type/:hash',
    name: 'reset_password',
    meta: {
      title: 'Reset Password'
    },
    component: () =>
      import('../views/auth/ResetPassword')
  }]
}
]

export const protectedRoute = [
  {
    path: '/hi',
    component: LayoutVerify,
    meta: {
      title: 'verify'
    },
    redirect: '/hi/welcome',
    hidden: true,
    children: [{
      path: 'welcome',
      name: 'welcome',
      meta: {
        title: 'welcome',
        hiddenInMenu: true,
        requiresAuth: true
      },
      component: () =>
        import('../views/welcome/commerce/Welcome')
    },
    {
      path: 'verify/:type/:id/:hash',
      name: 'verify',
      props: true,
      meta: {
        title: 'verify',
        hiddenInMenu: true,
        requiresAuth: false
      },
      component: () =>
        import('../views/auth/Verify')
    }
    ]
  },
  {
    path: '/lock',
    component: LayoutLock,
    meta: {
      title: 'pinlogin'
    },
    redirect: '/lock/pin',
    hidden: true,
    children: [{
      path: 'pin',
      name: 'pinlogin',
      meta: {
        title: 'pinlogin',
        hiddenInMenu: true,
        requiresAuth: true
      },
      component: () =>
        import('../views/AppLock')
    }]
  },
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      group: 'apps',
      icon: ''
    },
    redirect: '/commerce/dashboard',
    children: [
      {
        path: '/commerce/dashboard',
        name: 'dashboard',
        names: ['dashboard'],
        access: ['dashboard'],
        meta: {
          title: 'dashboard',
          group: 'apps',
          icon: 'mdi-view-dashboard',
          requiresAuth: true
        },
        children: [],
        component: () =>
          import('../views/dashboard/commerce/Dashboard')
      },
      {
        path: '/commerce/user/profile',
        name: 'profile',
        names: ['profile'],
        access: ['profile'],
        meta: {
          title: 'profile',
          hiddenInMenu: true,
          requiresAuth: true
        },
        children: [],
        component: () =>
          import('../views/auth/Profile')
      },
      {
        path: '/commerce/my-subscription',
        access: ['manager_subscription'],
        name: 'my_subscription',
        meta: {
          title: 'my_subscription',
          icon: 'mdi-briefcase-upload',
          hiddenInMenu: true,
          requiresAuth: true
        },
        children: [],
        component: () =>
          import('../views/subscriptions/MySubscription')
      },
      {
        path: '/commerce/sales',
        component: RouteWrapper,
        access: ['sales'],
        names: ['sales', 'refund', 'boxes', 'table', 'manager_shop'],
        redirect: '/commerce/sales/vending.list',
        meta: {
          title: 'vending',
          icon: 'mdi-currency-usd',
          group: 'sales'
        },
        children: [
          {
            path: '/commerce/sales/vending/new',
            name: 'vending_new',
            access: 'create',
            meta: {
              title: 'vending_new',
              hiddenInMenu: false
            },
            component: () =>
              import('../views/sales/commerce/ManagerSale')
          },
          {
            path: '/commerce/sales/vending.list',
            name: 'vending',
            access: 'list',
            meta: {
              title: 'vending_history',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/sales/commerce/ListSale')
          },
          {
            path: '/commerce/sales/vending-accepted.list',
            name: 'vending_acepted',
            access: 'list',
            meta: {
              title: 'vending_acepted',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/sales/commerce/ListAccepted'),
            props: {
              state: 'accepted'
            }
          },
          {
            path: '/commerce/sales/vending-process.list',
            name: 'vending_process',
            access: 'list',
            meta: {
              title: 'vending_process',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/sales/commerce/ListProcess'),
            props: {
              state: 'open'
            }
          },
          {
            path: '/commerce/sales/vending-cancelled.list',
            name: 'vending_cancelled',
            access: 'list',
            meta: {
              title: 'vending_cancelled',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/sales/commerce/ListCancelled'),
            props: {
              state: 'cancelled'
            }
          },
          {
            path: '/commerce/sales/make-refund.list',
            name: 'make_refund',
            access: 'list',
            meta: {
              title: 'refund',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/refund/commerce/ListSaleRefund')
          },
          {
            path: '/commerce/sales/refund.list',
            name: 'refund',
            access: 'list',
            meta: {
              title: 'history_refund',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/refund/commerce/ListRefund')
          },
          {
            path: '/commerce/sales/boxes.list',
            name: 'boxes',
            access: 'list',
            meta: {
              title: 'boxes',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/boxes/commerce/ListBoxes')
          },
          {
            path: '/commerce/sales/delivery.list',
            name: 'delivery',
            access: 'list',
            meta: {
              title: 'delivery',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/delivery/commerce/ListDelivery')
          },
          {
            path: '/commerce/sales/perquisite.list',
            name: 'perquisite',
            access: 'list',
            meta: {
              title: 'perquisite',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/perquisite/commerce/ListPerquisite')
          },
          {
            path: '/commerce/sales/tables.list',
            name: 'table',
            access: 'list',
            meta: {
              title: 'table',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/tables/commerce/ListTable')
          },
          {
            path: '/commerce/sales/vending/edit/:hash',
            name: 'vending_edit',
            access: 'edit',
            meta: {
              title: 'vending_edit',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/sales/commerce/ManagerSale'),
            props: {
              managerSale: false
            }
          },
          {
            path: '/commerce/sales/tables.operations',
            name: 'tables_operations',
            access: 'list',
            meta: {
              title: 'tables_operations',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/tables/commerce/ListTableOperation')
          },
          {
            path: '/commerce/sales/boxes.operations',
            name: 'boxes_operations',
            access: 'list',
            meta: {
              title: 'boxes_operations',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/boxes/commerce/ListBoxOperation')
          },
          /*
          {
            path: '/commerce/sales/refund.list',
            name: 'refund',
            access: 'list',
            meta: {
              title: 'history_refund',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/refund/commerce/ListRefund')
          },
          {
            path: '/commerce/sales/boxes.list',
            name: 'boxes',
            access: 'list',
            meta: {
              title: 'boxes',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/boxes/commerce/ListBoxes')
          },
          {
            path: '/commerce/sales/boxes.operations',
            name: 'boxes_operations',
            access: 'list',
            meta: {
              title: 'boxes_operations',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/boxes/commerce/ListBoxOperation')
          },
          {
            path: '/commerce/sales/tables.list',
            name: 'table',
            access: 'list',
            meta: {
              title: 'table',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/tables/commerce/ListTables')
          },
          {
            path: '/commerce/sales/tables.operations',
            name: 'tables_operations',
            access: 'list',
            meta: {
              title: 'tables_operations',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/tables/commerce/ListTableOperation')
          }, */
          {
            path: '/commerce/sales/type-order.list',
            name: 'type_of_order',
            access: 'list',
            meta: {
              title: 'type_order',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/type_order/commerce/ListTypeOfOrder')
          }
        ]
      },
      {
        path: '/commerce/articles',
        component: RouteWrapper,
        names: ['product_list', 'service_list', 'expire_list', 'slow_move',
          'category_list', 'active_category_list', 'modifiers_list', 'discounts_list'
        ],
        access: ['article', 'category', 'modifier'],
        redirect: '/commerce/articles/product.list',
        meta: {
          title: 'articles',
          icon: 'mdi-shopping',
          requiresAuth: true,
          group: 'articles'
        },
        children: [
          {
            path: '/commerce/inventory/shop.list',
            name: 'manager_shop',
            access: 'list',
            meta: {
              title: 'shop',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/shop/commerce/ListShop')
          },
          {
            path: '/commerce/articles/product.add',
            name: 'product_add',
            access: 'list',
            meta: {
              title: 'product_add',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/article/commerce/ManagerArticle')
          },
          {
            path: '/commerce/articles/product.list',
            name: 'product_list',
            access: 'list',
            meta: {
              title: 'product_list',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/article/commerce/ListArticle')
          },
          {
            path: '/commerce/articles/service.list',
            name: 'service_list',
            access: 'list',
            meta: {
              title: 'service_list',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/article/commerce/ListArticle'),
            props: {
              typeData: 'service'
            }
          },
          {
            path: '/commerce/articles/under-intentory.list',
            name: 'product_under_inventory',
            access: 'list',
            meta: {
              title: 'product_under_inventory',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/article/commerce/ListArticle'),
            props: {
              typeData: 'under-inventory'
            }
          },
          {
            path: '/commerce/articles/buy.add',
            name: 'buy_add',
            access: 'list',
            meta: {
              title: 'buy_products',
              icon: 'mdi-database-plus',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/buy/commerce/ManagerBuy'),
            props: {
              managerBuy: false
            }
          },
          {
            path: '/commerce/articles/buy.list',
            name: 'buy_products',
            access: 'list',
            meta: {
              title: 'history_buy_products',
              icon: 'mdi-database-plus',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/buy/commerce/ListBuy')
          },
          {
            path: '/commerce/articles/buy-process.list',
            name: 'supply_process',
            access: 'list',
            meta: {
              title: 'supply_process',
              icon: 'mdi-database-plus',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/buy/commerce/ListProcessBuy')
          },
          {
            path: '/commerce/articles/buy-preform.list',
            name: 'supply_emit',
            access: 'list',
            meta: {
              title: 'supply_emit',
              icon: 'mdi-database-plus',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/buy/commerce/ListPreformBuy')
          },
          {
            path: '/commerce/articles/buy-cancelled.list',
            name: 'supply_cancelled',
            access: 'list',
            meta: {
              title: 'supply_cancelled',
              icon: 'mdi-database-plus',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/buy/commerce/ListCancelledBuy')
          },
          {
            path: '/commerce/articles/supplier.list',
            name: 'supplier_list',
            access: 'list',
            meta: {
              title: 'supplier_list',
              icon: 'mdi-car',
              requiresAuth: true
            },
            component: () =>
              import('../views/supplier/commerce/SupplierInfo')
          },
          {
            path: '/commerce/articles/buy.edit/:hash',
            name: 'buy_edit',
            access: 'list',
            meta: {
              title: 'buy_products',
              icon: 'mdi-database-plus',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/buy/commerce/ManagerBuy'),
            props: {
              managerBuy: true
            }
          },
          {
            path: '/commerce/articles/product.edit/:hash',
            name: 'product_edit',
            access: 'edit',
            meta: {
              title: 'product_edit',
              icon: 'mdi-database-plus',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/article/commerce/ManagerArticle')
          },
          {
            path: '/commerce/articles/expire.list',
            name: 'expire_list',
            access: 'list',
            meta: {
              title: 'expire_list',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/article/commerce/ListArticle'),
            props: {
              typeData: 'expire'
            }
          },
          {
            path: '/commerce/articles/variant/product.edit/:hash',
            name: 'product_variant_edit',
            access: 'edit',
            meta: {
              title: 'product_edit',
              icon: 'mdi-database-plus',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/article/commerce/list-article/EditArticle')
          },
          {
            path: '/commerce/articles/category.list',
            name: 'category_list',
            access: 'list',
            meta: {
              title: 'category_list',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/category/commerce/ListCategory')
          },
          {
            path: '/commerce/articles/active_category.list',
            name: 'active_category_list',
            access: 'edit',
            meta: {
              title: 'active_category_list',
              icon: 'mdi-database-plus',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/category/commerce/ActiveCategory')
          },
          {
            path: '/commerce/mark/marks_list.list',
            name: 'marks_list',
            access: 'list',
            meta: {
              title: 'marks_list',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/mark/commerce/ListMark')
          },
          {
            path: '/commerce/articles/modifiers.list',
            name: 'modifiers_list',
            access: 'list',
            meta: {
              title: 'modifiers_list',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/modifiers/commerce/ListModifiers')
          }
        ]
      },
      {
        path: '/commerce/accounting',
        component: RouteWrapper,
        redirect: '/commerce/accounting/accounting.list',
        names: ['account_classify', 'accounting_account', 'bank_list'],
        access: ['account_classify', 'accounting_account', 'bank'],
        meta: {
          title: 'account_classify',
          icon: 'mdi-book-open-page-variant',
          requiresAuth: true,
          group: 'accounting'
        },
        children: [
          {
            path: '/commerce/accounting/back.list',
            name: 'bank_list',
            access: 'list',
            props: true,
            meta: {
              title: 'bank',
              icon: 'mdi-bank',
              requiresAuth: true
            },
            component: () =>
              import('../views/bank/commerce/BankList')
          },
          {
            path: '/commerce/accounting/accounting.list',
            name: 'account_classify',
            access: 'list',
            meta: {
              title: 'account_classify',
              icon: 'mdi-book-open-page-variant',
              requiresAuth: true
            },
            component: () =>
              import('../views/accounting-category/commerce/ListAccountCategory')
          },
          {
            path: '/commerce/accounting/account.list',
            name: 'accounting_account',
            access: 'list',
            props: true,
            meta: {
              title: 'accountings',
              icon: 'mdi-book-open-page-variant',
              requiresAuth: true
            },
            component: () =>
              import('../views/accounting-account/commerce/ListAccountingAccount')
          },
          {
            path: '/commerce/accounting/accounting_move.list/:account',
            name: 'account_move_by_category',
            access: 'list',
            props: true,
            meta: {
              title: 'account_moves',
              icon: 'mdi-book-open-page-variant',
              hiddenInMenu: true,
              requiresAuth: true
            },
            component: () =>
              import('../views/accounting-move/commerce/ListAccountMove')
          },
          {
            path: '/commerce/accounting/accounting-profitableness',
            name: 'profitableness',
            access: 'list',
            props: true,
            meta: {
              title: 'profitableness',
              icon: 'mdi-book-open-page-variant',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/accounting-account/Profitableness')
          },
          {
            path: '/commerce/accounting/accounting_income.list',
            name: 'account_income',
            access: 'list',
            props: true,
            meta: {
              title: 'account_income',
              icon: 'mdi-book-open-page-variant',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/accounting-account/commerce/OthersIncomes')
          },
          {
            path: '/commerce/accounting/accounting_expenses.list',
            name: 'account_expenses',
            access: 'list',
            props: true,
            meta: {
              title: 'account_expense',
              icon: 'mdi-book-open-page-variant',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/accounting-account/commerce/OthersExpenses')
          },
          {
            path: '/commerce/accounting/bills.list',
            name: 'account_bills',
            access: 'list',
            meta: {
              title: 'account_bills',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/accounting-bills/ListBills')
          },
          {
            path: '/commerce/accounting/clients/account_pay.list',
            name: 'account_pay_list',
            access: 'list',
            meta: {
              title: 'accounts_to_receivable',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/client/commerce/AccountToPay')
          },
          {
            path: '/commerce/accounting/clients/account_receivable.list',
            name: 'account_receivable_list',
            access: 'list',
            meta: {
              title: 'account_to_pay',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/supplier/commerce/SupplierToPay')
          },
          {
            path: '/commerce/accounting/tax.list',
            name: 'tax_list',
            access: 'list',
            meta: {
              title: 'tax_list',
              icon: 'mdi-car',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/tax/commerce/ListTax')
          },
          {
            path: '/commerce/accounting/discount.list',
            name: 'discount',
            access: 'list',
            meta: {
              title: 'discount',
              icon: 'mdi-car',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/discount/commerce/ListDiscount')
          },
          {
            path: '/commerce/sales/exchange-rate.list',
            name: 'exchange_rate',
            access: 'list',
            meta: {
              title: 'exchange_rate',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/exchange_rate/commerce/ListExchangeRate')
          }/*,
          {
            path: '/accounting/accounting_income.list',
            name: 'account_income',
            access: 'list',
            props: true,
            meta: {
              title: 'account_income',
              icon: 'mdi-book-open-page-variant',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/account/commerce/incomes/OthersIncomes')
          },
          {
            path: '/accounting/accounting_expenses.list',
            name: 'account_expenses',
            access: 'list',
            props: true,
            meta: {
              title: 'account_expense',
              icon: 'mdi-book-open-page-variant',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/account/commerce/expenses/OthersExpenses')
          },
          {
            path: '/commerce/bank/back.list',
            name: 'bank_list',
            access: 'list',
            props: true,
            meta: {
              title: 'bank',
              icon: 'mdi-bank',
              requiresAuth: true
            },
            component: () =>
              import('../views/bank/commerce/BankList')
          } */
        ]
      },
      {
        path: '/commerce/resume',
        component: RouteWrapper,
        redirect: '/commerce/resume/sell_product.list',
        names: ['sell_by'],
        access: ['sell_by'],
        meta: {
          title: 'resume',
          icon: 'mdi-chart-bar',
          group: 'resume'
        },
        children: [
          {
            path: '/commerce/resume/sell_product_margin.list',
            name: 'sell_product_margin',
            access: 'sell_by_product',
            meta: {
              title: 'sell_product_margin',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/sales_by/commerce/SalesProductMargin')
          },
          {
            path: '/commerce/resume/sell_product.list',
            name: 'sell_product',
            access: 'sell_by_product',
            meta: {
              title: 'sell_product',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/sales_by/commerce/SalesProduct')
          },
          {
            path: '/commerce/resume/sell_mark.list',
            name: 'sell_mark',
            access: 'sell_by_product',
            meta: {
              title: 'sell_mark',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/sales_by/commerce/SalesMark.vue')
          },
          {
            path: '/commerce/resume/sell_types_payment.list',
            name: 'sell_types_payment',
            access: 'sell_by_payments',
            meta: {
              title: 'sell_types_payment',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/sales_by/commerce/SalesPayment')
          },
          {
            path: '/commerce/resume/sell_user.list',
            name: 'sell_user',
            access: 'sell_by_employer',
            meta: {
              title: 'sell_user',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/sales_by/commerce/SalesEmployer')
          },
          {
            path: '/commerce/resume/sell_summary.list',
            name: 'summary',
            access: 'sell_by_payments',
            meta: {
              title: 'summary',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/sales_by/commerce/Summary')
          }
        ]
      },
      {
        path: '/commerce/users',
        component: RouteWrapper,
        redirect: '/commerce/users/employer.list',
        // access: ['manager_employer', 'manager_access', 'manager_assistence', 'manager_client'],
        names: ['clients_list', 'employer_list', 'manager_client'],
        access: ['employer', 'client'],
        meta: {
          title: 'client_employer',
          icon: 'mdi-clipboard-account',
          group: 'user'
        },
        children: [
          {
            path: '/commerce/users/employer.list',
            name: 'employer_list',
            access: 'list',
            meta: {
              title: 'employer_list',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/employees/commerce/ListUser')
          },
          {
            path: '/commerce/users/client.list',
            name: 'clients_list',
            access: 'list',
            meta: {
              title: 'client_list',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/client/commerce/ClientInfo')
          },
          {
            path: '/commerce/users/access.list',
            name: 'manager_access',
            access: 'list',
            meta: {
              title: 'access',
              icon: 'mdi-database-plus',
              requiresAuth: true
            },
            component: () =>
              import('../views/access/commerce/ListAccess')
          }]
      },
      {
        path: '/commerce/user/partners',
        access: ['dashboard'],
        name: 'partners',
        names: ['partners'],
        meta: {
          title: 'affiliate',
          icon: 'mdi-account-network',
          requiresAuth: true,
          hiddenMenu: false
        },
        children: [],
        component: () =>
          import('../views/partners/commerce/CommercePartners')
      },
      {
        path: '/commerce/sales/vending_online.list',
        component: RouteWrapper,
        redirect: '/commerce/sales/vending_online.list',
        names: ['shop_online'],
        access: ['shop_online'],
        meta: {
          title: 'shop_online',
          icon: 'mdi-shopping',
          group: 'online'
        },
        children: [
          {
            path: '/commerce/online/setting',
            name: 'shop_online',
            access: 'list',
            meta: {
              title: 'shop_online',
              hiddenInMenu: false,
              requiresAuth: true
            },
            component: () =>
              import('../views/online-config/OnlineShopConfigList')
          }
        ]
      },
      {
        path: '/commerce/online/config.add',
        name: 'config_add',
        access: ['shop_online'],
        meta: {
          title: 'online_config',
          icon: 'mdi-briefcase-upload',
          hiddenInMenu: true,
          requiresAuth: true
        },
        children: [],
        component: () =>
          import('../views/online-config/OnlineShopConfig')
      },
      {
        path: '/commerce/setting',
        access: ['shop', 'access', 'expense_category', 'exchange_rate', 'type_of_order', 'tax', 'discount'],
        name: 'setting',
        meta: {
          title: 'setting',
          icon: 'mdi-cog',
          requiresAuth: true
        },
        children: [],
        component: () =>
          import('../views/setting/commerce/UserSetting')
      }
    ]
  },
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      group: 'apps',
      icon: ''
    },
    redirect: '/admin/dashboard',
    children: [{
      path: '/admin/dashboard',
      name: 'admin_dashboard',
      access: ['dashboard'],
      meta: {
        title: 'dashboard',
        group: 'apps',
        icon: 'mdi-view-dashboard',
        requiresAuth: true
      },
      component: () =>
        import('../views/dashboard/admin/AdminDashboard')
    },
    {
      path: '/admin/403',
      name: 'admin_forbidden',
      access: ['Forbidden'],
      meta: {
        title: 'access_denied',
        hiddenInMenu: true,
        requiresAuth: true
      },
      component: () =>
        import('../views/error/Deny')
    },
    {
      path: '/admin/user/profile',
      name: 'admin_profile',
      access: ['profile'],
      meta: {
        title: 'profile',
        hiddenInMenu: true,
        requiresAuth: true
      },
      children: [],
      component: () =>
        import('../views/auth/Profile')
    },
    {
      path: '/admin/plan/plan.list',
      access: 'list',
      name: 'manager_plan',
      meta: {
        title: 'admin_plan',
        hiddenInMenu: false,
        requiresAuth: true
      },
      component: () =>
        import('../views/plans/admin/ListPlan')
    },
    {
      path: '/admin/plan/plan.add',
      access: 'create',
      name: 'new_plan',
      meta: {
        title: 'admin_plan',
        hiddenInMenu: true,
        requiresAuth: true
      },
      component: () =>
        import('../views/plans/admin/NewPlan')
    },
    {
      path: '/admin/plan/plan.edit',
      access: 'edit',
      name: 'edit_plan',
      meta: {
        title: 'admin_plan',
        hiddenInMenu: true,
        requiresAuth: true
      },
      component: () =>
        import('../views/plans/admin/EditPlan')
    },
    {
      path: '/admin/plan/subscriptions.list',
      access: 'list',
      name: 'manager_subscriptions_admin',
      meta: {
        title: 'admin_subscription',
        hiddenInMenu: false,
        requiresAuth: true
      },
      component: () =>
        import('../views/subscriptions/admin/CompanySubscription')
    },
    {
      path: '/admin/plan/subscriptions-suspend.list',
      access: 'list',
      name: 'admin_subscription_suspend',
      meta: {
        title: 'admin_subscription_suspend',
        hiddenInMenu: false,
        requiresAuth: true
      },
      component: () =>
        import('../views/subscriptions/admin/Company-Subscription-Suspend')
    },
    {
      path: '/admin/beneficiary.list',
      access: 'list',
      name: 'manager_beneficiary',
      meta: {
        title: 'admin_beneficiary',
        hiddenInMenu: false,
        requiresAuth: true
      },
      component: () =>
        import('../views/beneficiary/admin/ListBeneficiary')
    },
    {
      path: '/admin/partners',
      component: RouteWrapper,
      redirect: '/admin/partners/solicited',
      access: ['manager_partners'],
      meta: {
        title: 'affiliate',
        icon: 'mdi-account-multiple',
        group: 'affiliate',
        requiresAuth: true
      },
      children: [
        {
          path: '/admin/partners/approved',
          name: 'admin_partner_approved',
          access: 'list',
          meta: {
            title: 'affiliate_approved',
            icon: 'mdi-database-plus',
            requiresAuth: true
          },
          component: () =>
            import('../views/partners/admin/ListPartners'),
          props: {
            state: 'approved'
          }
        },
        {
          path: '/admin/partners/solicited',
          name: 'admin_partner_solicited',
          access: 'list',
          meta: {
            title: 'affiliate_solicited',
            icon: 'mdi-database-plus',
            requiresAuth: true
          },
          component: () =>
            import('../views/partners/admin/ListPartners'),
          props: {
            state: 'solicited'
          }
        },
        {
          path: '/admin/partners/denied',
          name: 'admin_partner_denied',
          access: 'list',
          meta: {
            title: 'affiliate_denied',
            icon: 'mdi-database-plus',
            requiresAuth: true
          },
          component: () =>
            import('../views/partners/admin/ListPartners'),
          props: {
            state: 'denied'
          }
        },
        {
          path: '/admin/user/partners/blocked',
          name: 'admin_partner_blocked',
          access: 'list',
          meta: {
            title: 'affiliate_blocked',
            icon: 'mdi-database-plus',
            requiresAuth: true
          },
          component: () =>
            import('../views/partners/admin/ListPartners'),
          props: {
            state: 'blocked'
          }
        }
      ]
    }
    ]
  },
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      group: 'apps',
      icon: ''
    },
    redirect: '/partner/dashboard',
    children: []
  },
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      group: 'apps',
      icon: ''
    },
    redirect: '/beneficiary/dashboard',
    children: [
      {
        path: '/beneficiary/dashboard',
        name: 'beneficiary_dashboard',
        access: ['list'],
        meta: {
          title: 'dashboard',
          group: 'apps',
          icon: 'mdi-view-dashboard',
          hiddenInMenu: false,
          requiresAuth: true
        },
        children: [],
        component: () =>
          import('../views/dashboard/beneficiary/BeneficiaryDashboard')
      }
    ]
  },
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      group: 'apps',
      icon: ''
    },
    redirect: '/partner/dashboard',
    children: [
      {
        path: '/partner/dashboard',
        name: 'partner_dashboard',
        access: ['list'],
        meta: {
          title: 'dashboard',
          group: 'apps',
          icon: 'mdi-view-dashboard',
          hiddenInMenu: false,
          requiresAuth: true
        },
        children: [],
        component: () =>
          import('../views/dashboard/partner/PartnerDashboard')
      }
    ]
  }
]
