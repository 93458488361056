<template>
  <v-container>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
      >
        <new-type-order v-if="showNewModal" />
        <edit-type-order v-if="showEditModal" />
        <app-data-table
          :title="$vuetify.lang.t('$vuetify.menu.type_of_order')"
          csv-filename="TypeOrder"
          :headers="getTableColumns"
          :options="vBindOption"
          :is-loading="isTableLoading"
          :items="typeOrders"
          :manager="'type_of_order'"
          :sort-by="['name']"
          :sort-desc="[false, true]"
          multi-sort
          @create-row="toogleNewModal(true)"
          @edit-row="openEditModal($event)"
          @delete-row="deleteHandler($event)"
        >

          <template v-slot:item.name="{ item }">
            <template v-if="item.description">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{item.name}}</span>
                </template>{{item.description}}
              </v-tooltip>
            </template>
            <span v-else>{{item.name}}</span>
          </template>
          <template v-slot:item.shopName="{ item }">
            <v-chip v-for="(shop, i) in item.shopName" :key="i">{{shop}}</v-chip>
          </template>
          <template
            v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
          >
            <v-btn
              color="primary"
              fab
              x-small
              dark
              @click="expand(!isExpanded)"
            >
              <v-icon v-if="isExpanded">
                mdi-chevron-up
              </v-icon>
              <v-icon v-else>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              style="padding: 0 0 0 0"
            >
              <span>{{item.description}} </span>
            </td>
          </template>
        </app-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewTypeOrder from './NewTypeOrder'
import EditTypeOrder from './EditTypeOrder'

export default {
  name: 'ListTypeOfOrder',
  components: {
    NewTypeOrder,
    EditTypeOrder
  },
  data () {
    return {
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: true
      },
      search: ''
    }
  },
  computed: {
    ...mapState('typeOrder', [
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'typeOrders',
      'isTableLoading'
    ]),
    ...mapGetters('auth', ['user']),
    bindProps () {
      return {
        itemKey: Math.random().toString(),
        groupBy: 'shopName'
      }
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.name'),
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shopName',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  mounted () {
    this.getTypeOfOrders()
  },
  methods: {
    ...mapActions('typeOrder', [
      'toogleNewModal',
      'openEditModal',
      'openShowModal',
      'getTypeOfOrders',
      'setPrincipalTypeOrder',
      'deleteTypeOrder'
    ]),
    deleteHandler (id) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.type_of_order')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deleteTypeOrder(id)
        })
    },
    setPrincipal (item) {
      if (item.principal) {
        this.setPrincipalTypeOrder(item)
      }
    }
  }
}
</script>

<style scoped></style>
