// import api from '@/config/api'
import api from '../config/api'
import localStorage from '../config/localStorage'

/** Auth API */
const getUserData = () => {
  return api.get('auth')
}

const loginRequest = ({ email, password }) => {
  const data = { email, password }
  return api.post('login', data)
}

const loginPincodeRequest = ({ email, pincode }) => {
  const user = { email: email, pincode: pincode }
  if (localStorage.getTypeConnection() === 'beneficiary') {
    return api.post('login/pincode-beneficiary', user)
  } else if (localStorage.getTypeConnection() === 'admin') {
    return api.post('login/pin-code', user)
  }
  return api.post('login/pincode', user)
}

const registerRequest = user => {
  return api.post('register', user)
}

const registerPartner = partner => {
  return api.post('register-partner', partner)
}

const logoutRequest = () => {
  return api.post('logout')
}

const verifyResendRequest = () => {
  return api.get('email/resend')
}

const verifyRequest = (params) => {
  return api.post('email/verify', params)
}

const verifyMailForgot = data => {
  return api.post('password/reset/link', data)
}
const updateMenuConfig = data => {
  return api.post('auth/change-menu-config', data)
}

const resetPassword = (hash, newData) => {
  return api.post('password/reset', newData)
}

const sendChangePassword = data => {
  return api.post('user/password/change', data)
}
const changePinCode = data => {
  return api.post('user/pin/change', data)
}

const readNotification = idNotification => {
  return api.post('user/read/notification/' + idNotification)
}

const readAllNotification = notifications => {
  return api.post('user/read-all', notifications)
}

const affiliateRequest = (affiliateId) => {
  return api.post('affiliate/' + affiliateId)
}
const sendContact = (data) => {
  return api.post('contact-us', data)
}

const callNewAccount = (type) => {
  return api.post('stripe-pay/callNewAccount', type)
}

const solicitedPartner = (data) => {
  return api.post('solicit-partner', data)
}
export default {
  getUserData,
  sendContact,
  loginRequest,
  loginPincodeRequest,
  logoutRequest,
  registerRequest,
  registerPartner,
  verifyRequest,
  verifyResendRequest,
  verifyMailForgot,
  updateMenuConfig,
  resetPassword,
  sendChangePassword,
  changePinCode,
  readNotification,
  readAllNotification,
  affiliateRequest,
  callNewAccount,
  solicitedPartner
}
