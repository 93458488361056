<template>
  <v-badge
    :content="content"
    :value="content"
    color="pink"
    class="parpadear"
    overlap
  >
    <v-icon>mdi-bell</v-icon>
  </v-badge>
</template>

<script>
export default {
  props: {
    content: {
      type: Number,
      default: 0
    }
  },
  name: 'NotificationIconDrawer'
}
</script>

<style scoped>

</style>
