import api from '../config/api'

export default {
  fetchDeliveries () {
    return api.get('delivery')
  },
  sendCreateRequest (delivery) {
    return api.post('delivery', delivery)
  },
  sendUpdateRequest (delivery) {
    return api.put('delivery/' + delivery.id, delivery)
  },
  sendDeleteRequest (deliveryId) {
    return api.remove('delivery/' + deliveryId)
  }
}
