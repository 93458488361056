import plan from '../../api/plan'

const FETCHING_PLANS = 'FETCHING_PLANS'
const FETCHING_PLANS_GENERAL = 'FETCHING_PLANS_GENERAL'
const SWITCH_PLAN_NEW_MODAL = 'SWITCH_PLAN_NEW_MODAL'
const SWITCH_SUBSCRIPTION_MODAL = 'SWITCH_SUBSCRIPTION_MODAL'
const SWITCH_PLAN_EDIT_MODAL = 'SWITCH_PLAN_EDIT_MODAL'
const SWITCH_PLAN_SHOW_MODAL = 'SWITCH_PLAN_SHOW_MODAL'
const PLAN_CREATED = 'PLAN_CREATED'
const PLAN_EDIT = 'PLAN_EDIT'
const PLAN_UPDATED = 'PLAN_UPDATED'
const PLAN_DELETE = 'PLAN_DELETE'
const PLAN_TABLE_LOADING = 'PLAN_TABLE_LOADING'
const FAILED_PLAN = 'FAILED_PLAN'
const UPDATE_RESTRICTION = 'UPDATE_RESTRICTION'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_PLAN = 'SET_EDIT_PLAN'
const CANCEL_MODAL = 'CANCEL_MODAL'
const UPDATE_MY_SUBSCRIPTION = 'UPDATE_MY_SUBSCRIPTION'

const state = {
  showNewModal: false,
  restrictionPlan: null,
  showEditModal: false,
  showShowModal: false,
  showSelectSubscription: false,
  plans: [],
  generalPlan: [],
  avatar: '',
  loading: false,
  saved: false,
  haveSubscriptions: true,
  mySubscription: {},
  server_time: {},
  newPlan: {
    name: '', // ok
    general: false,
    description: '', // ok
    type: 'basic',
    is_active: true, // ok
    is_free: false, // ok
    is_coupon: false, // ok
    currency: 'USD', // ok
    color: '#1CA085', // ok
    countries: [], // ok
    starts_at: null,
    platform_payment_gateway: ['stripe'],
    partner_payment_gateway: ['stripe'],
    ends_at: null,
    limit_money: null,
    facture_period: {
      month: '0.00',
      trimester: '0.00',
      semester: '0.00',
      annually: '0.00',
      years_2: '0.00',
      years_3: '0.00',
      years_4: '0.00',
      years_5: '0.00'
    },
    invoice_days: 1,
    prorate_day: 1,
    test_day: 14,
    partner_percent: 19.40,
    subscribers_limit: null, // ok
    benefited: null
  },
  editPlan: {
    id: '',
    name: '', // ok
    description: '', // ok
    is_active: true, // ok
    is_free: false, // ok
    general: false,
    is_coupon: false, // ok
    currency: 'USD', // ok
    color: '#1CA085', // ok
    countries: [], // ok
    type: 'basic',
    partner_percent: 19.40,
    partner_permanent: 0.00,
    starts_at: null,
    ends_at: null,
    facture_period: {
      month: '0.00',
      trimester: '0.00',
      semester: '0.00',
      annually: '0.00',
      years_2: '0.00',
      years_3: '0.00',
      years_4: '0.00',
      years_5: '0.00'
    },
    invoice_days: 1,
    prorate_day: 1,
    test_day: 14,
    subscribers_limit: null, // ok
    benefited: null
  },
  isPlanLoading: false,
  isActionInProgress: false,
  isTableLoading: false
}

const mutations = {
  [SWITCH_PLAN_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_SUBSCRIPTION_MODAL] (state, showModal) {
    state.showSelectSubscription = showModal
  },
  [SWITCH_PLAN_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_PLAN_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [PLAN_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
    state.isPlanLoading = isLoading
  },
  [FETCHING_PLANS] (state, plans) {
    plans.map(value => {
      value.countries = JSON.parse(value.countries)
    })
    state.plans = plans
    state.plans.sort((a, b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0)).reverse()
  },
  [FETCHING_PLANS_GENERAL] (state, plans) {
    plans.map(value => {
      value.countries = JSON.parse(value.countries)
    })
    state.generalPlan = plans
    state.plans.sort((a, b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0)).reverse()
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [PLAN_CREATED] (state) {
    state.showNewModal = false
    state.newPlan = {
      name: '', // ok
      description: '', // ok
      is_active: true, // ok
      is_free: false, // ok
      general: false,
      is_coupon: false, // ok
      currency: 'USD', // ok
      color: '#1CA085', // ok
      countries: [], // ok
      starts_at: null,
      ends_at: null,
      type: 'basic',
      facture_period: {
        month: '0.00',
        trimester: '0.00',
        semester: '0.00',
        annually: '0.00',
        years_2: '0.00',
        years_3: '0.00',
        years_4: '0.00',
        years_5: '0.00'
      },
      invoice_days: 1,
      prorate_day: 1,
      test_day: 14,
      partner_percent: 19.40,
      subscribers_limit: null, // ok
      benefited: null
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.menu.admin_plan')
	  ])
    })
  },
  [PLAN_EDIT] (state, planId) {
    state.editPlan = Object.assign(
	  {},
	  state.plans.filter(node => node.id === planId).shift()
    )
  },
  [PLAN_UPDATED] (state) {
    state.showEditModal = false
    state.editPlan = {
      id: '',
      name: '', // ok
      description: '', // ok
      is_active: true, // ok
      is_free: false, // ok
      general: false,
      is_coupon: false, // ok
      currency: 'USD', // ok
      color: '#1CA085', // ok
      countries: [], // ok
      starts_at: null,
      ends_at: null,
      type: 'basic',
      facture_period: {
        month: '0.00',
        trimester: '0.00',
        semester: '0.00',
        annually: '0.00',
        years_2: '0.00',
        years_3: '0.00',
        years_4: '0.00',
        years_5: '0.00'
      },
      invoice_days: 1,
      prorate_day: 1,
      test_day: 14,
      subscribers_limit: null, // ok
      benefited: null
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.subscription_updated')
    })
  },
  [SET_EDIT_PLAN] (state, profile) {
    state.editPlan.push(profile)
  },
  [CANCEL_MODAL] (state) {
    state.newPlan = {
      name: '', // ok
      description: '', // ok
      is_active: true, // ok
      is_free: false, // ok
      is_coupon: false, // ok
      general: false,
      currency: 'USD', // ok
      color: '#1CA085', // ok
      countries: [], // ok
      starts_at: null,
      type: 'basic',
      ends_at: null,
      facture_period: {
        month: '0.00',
        trimester: '0.00',
        semester: '0.00',
        annually: '0.00',
        years_2: '0.00',
        years_3: '0.00',
        years_4: '0.00',
        years_5: '0.00'
      },
      invoice_days: 1,
      prorate_day: 1,
      test_day: 14,
      partner_percent: 19.40,
      subscribers_limit: null, // ok
      benefited: null
    }
    state['plan/showSelectSubscription'] = false
    state.saved = false
  },
  [PLAN_DELETE] (state, error) {
    state.saved = true
    state.error = error
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.menu.admin_plan')
	  ])
    })
  },
  [FAILED_PLAN] (state, error) {
    state.saved = false
    state.error = error
    state.isActionInProgress = false
    this._vm.$Toast.fire({
	  icon: 'error',
	  title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.admin_plan')
	  ])
    })
  },
  [UPDATE_RESTRICTION] (state, data) {
    console.log(data)
    state.restrictionPlan = data
  },
  [UPDATE_MY_SUBSCRIPTION] (state, data) {
    if (data[0]) {
      state.haveSubscriptions = !!data[1]
      state.mySubscription = data[1]
      if (data[2]) { state.server_time = data[2] }
    }
  }
}

const getters = {
  haveSubscriptions: state => state.haveSubscriptions,
  mySubscription: state => state.mySubscription
}

const actions = {
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_PLAN_NEW_MODAL, showModal)
    if (!showModal) {
	  commit(CANCEL_MODAL)
    }
  },
  toogleShowSelectSubscription ({ commit }, showModal) {
    commit(SWITCH_SUBSCRIPTION_MODAL, showModal)
    if (!showModal) {
	  commit(CANCEL_MODAL)
    }
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_PLAN_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_PLAN_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, planId) {
    commit(SWITCH_PLAN_EDIT_MODAL, true)
    commit(PLAN_EDIT, planId)
  },
  openShowModal ({ commit }, planId) {
    commit(SWITCH_PLAN_SHOW_MODAL, true)
    commit(PLAN_EDIT, planId)
  },
  async getPlans ({ commit }) {
    commit(PLAN_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await plan
	  .fetchAll()
	  .then(({ data }) => {
        commit(FETCHING_PLANS, data.data)
        commit(PLAN_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },
  async getPlansGeneral ({ commit }) {
    commit(PLAN_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await plan
	  .fetchGeneral()
	  .then(({ data }) => {
        commit(FETCHING_PLANS_GENERAL, data.data)
        commit(PLAN_TABLE_LOADING, false)
        return data
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },
  async createPlan ({ commit, dispatch }, newPlan) {
    commit(ENV_DATA_PROCESS, true)

    await plan
	  .sendCreateRequest(newPlan)
	  .then(data => {
        commit(PLAN_CREATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('plan/getPlans', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },
  async getPlansCountry ({ commit }) {
    commit(PLAN_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await plan
	  .getPlansCountry()
	  .then(({ data }) => {
        commit(FETCHING_PLANS, data.data)
        commit(PLAN_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
	  })
	  .catch(error => {
        commit(FAILED_PLAN, error)
      })
  },
  async updatePlan ({ commit, dispatch }, editPlan) {
    commit(ENV_DATA_PROCESS, true)
    await plan
	  .sendUpdateRequest(editPlan)
	  .then(data => {
        commit(PLAN_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('plan/getPlans', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },
  async paySubscription ({ commit, dispatch }, data) {
    commit(ENV_DATA_PROCESS, true)
    await plan
	  .sendPaySubscription(data)
	  .then(data => {
        commit(PLAN_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('plan/getPlans', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },
  async sendCouponActive ({ commit, dispatch }, data) {
    commit(ENV_DATA_PROCESS, true)
    await plan
	  .sendCouponActive(data)
	  .then(data => {
        commit(PLAN_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('plan/getPlans', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },
  async sendFreePlan ({ commit, dispatch }, data) {
    commit(ENV_DATA_PROCESS, true)
    await plan
	  .sendFreePlan(data)
	  .then(data => {
        commit(PLAN_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('plan/getPlans', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },
  async activeMyTestSubscription ({ commit, dispatch }, data) {
    commit(ENV_DATA_PROCESS, true)
    await plan
	  .activeTestSubscription(data)
	  .then(data => {
        commit(PLAN_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },
  async changePlanSubcription ({ commit, dispatch }, data) {
    commit(ENV_DATA_PROCESS, true)
    await plan
	  .changePlanSubcription(data)
	  .then(data => {
        commit(PLAN_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('plan/getPlans', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },
  async deletePlan ({ commit, dispatch }, planId) {
    await plan
	  .sendDeleteRequest(planId)
	  .then(data => {
        commit(PLAN_DELETE)
        dispatch('plan/getPlans', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_PLAN, error))
  },

  /// //OTHERS////
  async getMySubscription ({ commit, dispatch }, data) {
    commit(UPDATE_MY_SUBSCRIPTION, data)
  },
  async getRestrictions ({ commit, dispatch }, country) {
    await plan
      .getRestrictions(country)
      .then(({ data }) => {
        console.log(data)
        commit(UPDATE_RESTRICTION, data.data)
      })
      .catch(error => commit(FAILED_PLAN, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
