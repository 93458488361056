<template>
  <v-row>
    <v-dialog v-model="showNewVariantCost" max-width="500">
      <new-variant-cost :variants="articleShop.variant_cost"
                        @cancelNewVariant="showNewVariantCost = false"
                        @refreshVariantCost="refreshVariantCost"/>
    </v-dialog>
    <v-col cols="12" md="4" v-if="articleShop.variant_cost.length > 1 && getEditVC">
      <v-text-field-money
        v-model="articleShop.local_cost"
        @change="changeCosts()"
        :label="$vuetify.lang.t('$vuetify.articles.cost')"
        required
        :properties="{
          suffix: getCurrency
        }"
        :options="{
          length: 15,
          precision: 2,
          empty: 0.00,
        }"
      />
    </v-col>
    <v-spacer/>
    <v-col cols="12" md="4">
      <v-btn
        @click="showVariantCost()"
        color="primary"
        style="margin-top: 10px"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $vuetify.lang.t('$vuetify.titles.new', [$vuetify.lang.t('$vuetify.articles.cost')]) }}
      </v-btn>
    </v-col>
    <v-col cols="12" md="12">
      <span>
        <v-tooltip bottom max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
          <span> {{$vuetify.lang.t('$vuetify.messages.variant_cost')}}</span>
        </v-tooltip></span>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              {{ $vuetify.lang.t('$vuetify.articles.ref') }}
            </th>
            <th class="text-left">
              {{ $vuetify.lang.t('$vuetify.articles.cost') }}
            </th>
            <th class="text-left">
              {{ $vuetify.lang.t('$vuetify.shop_article.stock') }}
            </th>
            <th class="text-left">
              {{ $vuetify.lang.t('$vuetify.shop_article.under_inventory') }}
            </th>
            <th class="text-left">
              {{ $vuetify.lang.t('$vuetify.articles.due_date') }}
            </th>
            <th class="text-left">
              {{ $vuetify.lang.t('$vuetify.barCode') }}
            </th>
            <th class="text-left">
              {{ $vuetify.lang.t('$vuetify.actions.actions') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in articleShop.variant_cost" :key="item.name">
            <td>{{ item.ref }}</td>
            <td>
              <v-edit-dialog
                :return-value.sync="item.cost"
                large
                persistent
                :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                @save="salveVariantCost(item)"
              >
                <div>{{ parseFloat(item.cost).toFixed(2) + ' ' + getCurrency }}</div>
                <template v-slot:input>
                  <div class="mt-4 title">
                    {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                  </div>
                  <v-text-field-money
                    v-model="item.cost"
                    :label="$vuetify.lang.t('$vuetify.actions.save')"
                    :properties="{
                      clearable: true
                    }"
                    :options="{
                      length: 15,
                      precision: 2,
                      empty: 0.00,
                    }"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <td>
              <v-edit-dialog
                :return-value.sync="item.stock"
                large
                persistent
                :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                @save="salveVariantCost(item)"
              >
                <div>{{ parseFloat(item.stock).toFixed(2) }}</div>
                <template v-slot:input>
                  <div class="mt-4 title">
                    {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                  </div>
                  <v-text-field-money
                    v-model="item.stock"
                    :label="$vuetify.lang.t('$vuetify.actions.save')"
                    :properties="{
                      clearable: true
                    }"
                    :options="{
                      length: 15,
                      precision: 2,
                      empty: 0.00,
                    }"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <td>
              <v-edit-dialog
                :return-value.sync="item.under_inventory"
                large
                persistent
                :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                @save="salveVariantCost(item)"
              >
                <div>{{ parseFloat(item.under_inventory).toFixed(2) }}</div>
                <template v-slot:input>
                  <div class="mt-4 title">
                    {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                  </div>
                  <v-text-field-money
                    v-model="item.under_inventory"
                    :label="$vuetify.lang.t('$vuetify.actions.save')"
                    :properties="{
                        clearable: true
                      }"
                    :options="{
                      length: 15,
                      precision: 2,
                      empty: 0.00,
                    }"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <td>
              <v-edit-dialog
                :return-value.sync="item.expire_date"
                large
                persistent
                :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                :save-text="$vuetify.lang.t('$vuetify.actions.save')"
              >
                <div v-if="item.expire_date">{{ item.expire_date }}</div>
                <div v-else>{{ $vuetify.lang.t('$vuetify.no_defined') }}</div>
                <template v-slot:input>
                  <div class="mt-4 title">
                    {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                  </div>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.expire_date"
                        :label="$vuetify.lang.t('$vuetify.articles.due_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.expire_date"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </template>
              </v-edit-dialog>
            </td>
            <td>
              <v-edit-dialog
                :return-value.sync="item.barCode"
                large
                persistent
                :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                :save-text="$vuetify.lang.t('$vuetify.actions.save')"
              >
                <div v-if="item.barCode">{{ item.barCode }}</div>
                <div v-else>{{ $vuetify.lang.t('$vuetify.no_defined') }}</div>
                <template v-slot:input>
                  <div class="mt-4 title">
                    {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                  </div>
                  <v-text-field-simplemask
                    v-model="item.barCode"
                    :label="$vuetify.lang.t('$vuetify.barCode')"
                    :properties="{
                    clearable: true,
                    }"
                    :options="{
                      inputMask: '##-####-####-###',
                      outputMask: '#############',
                      empty: null,
                      alphanumeric: true,
                    }"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <td>
              <v-icon @click="deleteVariantCostHandler(item)">mdi-delete</v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import NewVariantCost from './NewVariantCost'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'VariantCostList',
  components: { NewVariantCost },
  props: {
    articleShop: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      menuDate: false,
      menu: false,
      ref: 1000,
      itemVariant: null,
      showNewVariantCost: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'isManagerIn']),
    ...mapState('variantCost', ['variantCostAdded']),
    ...mapGetters('article', ['getNumberArticle']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getEditVC () {
      return this.$route.name === 'product_list' || this.$route.name === 'service_list' ||
        this.$route.name === 'expire_list' || this.$route.name === 'slow_move' || this.$route.name === 'product_under_inventory'
    },
    articleNumber: {
      get () {
        return this.getNumberArticle
      },
      set (newNumber) {
        return newNumber
      }
    }
  },
  async created () {
    if (this.$route.name === 'product_add') {
      await this.fetchArticleNumber().then(() => {
        this.ref = this.articleNumber
        this.articleShop.variant_cost.map((vc) => {
          vc.ref = ++this.ref
        })
      })
    }
  },
  methods: {
    ...mapActions('article', ['fetchArticleNumber']),
    ...mapActions('variantCost', ['createVariantCost', 'deleteVariantCost', 'updateVariantCosts']),
    async salveVariantCost (item) {
      if (this.getEditVC) {
        await this.updateVariantCosts(item)
        this.$emit('calcInventory')
      }
    },
    async deleteVariantCostHandler (item) {
      if (this.articleShop.variant_cost.length === 1) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.titles.delete', [
              this.$vuetify.lang.t('$vuetify.menu.variant_cost')
            ]),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.error_delete_cost'
            ),
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
      } else {
        if (item.id) {
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.delete', [
                this.$vuetify.lang.t('$vuetify.menu.variant_cost')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_delete'
              ),
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.cancel'
              ),
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.delete'
              ),
              confirmButtonColor: 'red'
            })
            .then(result => {
              if (result.isConfirmed) {
                this.deleteVariantCost(item.id)
                  .then(() => {
                    this.articleShop.variant_cost = this.articleShop.variant_cost.filter((cv) => cv !== item)
                    this.$emit('calcInventory')
                  }
                  )
              }
            })
        } else {
          this.articleShop.variant_cost = this.articleShop.variant_cost.filter((cv) => cv !== item)
        }
      }
    },
    async refreshVariantCost (variant) {
      variant.ref = ++this.ref
      if (this.getEditVC) {
        variant.article_shop_id = this.articleShop.id
        await this.createVariantCost(variant)
        this.articleShop.variant_cost.push(this.variantCostAdded)
      } else {
        this.articleShop.variant_cost.push(variant)
      }
      this.itemVariant = null
      this.showNewVariantCost = false
    },
    changeCosts () {
      this.articleShop.variant_cost.forEach(vc => {
        vc.cost = this.articleShop.local_cost
      })
    },
    showVariantCost () {
      this.itemVariant = this.articleShop.variant_cost
      this.showNewVariantCost = true
    }
  }
}
</script>

<style scoped>

</style>
