import api from '../config/api'

export default {
  fetchAccount () {
    return api.get('fiscal')
  },
  sendUpdateRequest (fiscal) {
    return api.put('fiscal/' + fiscal.id, fiscal)
  },
  sendGenerateFiscalAccount (sale) {
    return api.post('fiscal/generate', sale)
  }
}
