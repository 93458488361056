<template>
  <v-dialog
    v-model="toogleNewModal"
    max-width="850px"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $vuetify.lang.t("$vuetify.menu.access_new") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="formValid"
          class="my-10"
          lazy-validation
        >
          <v-row
            cols="12"
            justify="space-around"
          >
            <v-col md="4" cols="12">
              <v-text-field
                v-model="newAccess.name"
                :label="$vuetify.lang.t('$vuetify.access.name')"
                required
                :rules="formRule.position"
              />
            </v-col>
            <v-col md="4" cols="12">
              <v-checkbox
                v-model="newAccess.accessEmail"
                :label="
                  $vuetify.lang.t(
                    '$vuetify.access.accessEmail'
                  )
                "
              />
            </v-col>
            <v-col md="12" cols="12">
              <v-text-field
                v-model="newAccess.description"
                :label="
                  $vuetify.lang.t(
                    '$vuetify.access.description'
                  )
                "
              />
            </v-col>
          </v-row>
          <v-row>
            <v-expansion-panels popout>
              <v-col
                v-for="(access, j) in access_permit"
                :key="j"
                cols="12"
                md="6"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-switch
                      v-model="access.title.value"
                      :title="
                        $vuetify.lang.t(
                          '$vuetify.access.access.' +
                            access.title.name
                        )
                      "
                      @change="changeAccess(access)"
                    >
                      <template v-slot:label>
                        <div>
                          <b>
                            {{
                              $vuetify.lang.t(
                                "$vuetify.access.access." +
                                access.title
                                  .name
                              )
                            }}
                          </b>
                          <v-tooltip
                            right
                            class="md-6"
                          >
                            <template
                              v-slot:activator="{
                                on,
                                attrs
                              }"
                            >
                              <v-icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{
                                $vuetify.lang.t(
                                  "$vuetify.access.access.manager_help"
                                )
                              }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-switch>
                    <template v-slot:actions>
                      <v-icon
                        v-show="access.title.value"
                        color="error"
                      >
                        mdi-key-plus
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-show="access.title.value"
                  >
                    <v-row>
                      <v-col
                        v-for="(action,
                                i) in access.actions"
                        :key="i"
                      >
                        <v-switch
                          v-model="access.actions[i]"
                          :label="
                            $vuetify.lang.t(
                              '$vuetify.access.access.' +
                                i
                            )
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-col>
            </v-expansion-panels>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          class="mb-2"
          :disabled="isActionInProgress"
          @click="toogleNewModal(false)"
        >
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!formValid || isActionInProgress"
          class="mb-2"
          color="primary"
          :loading="isActionInProgress"
          @click="createNewRole"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.actions.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      formValid: false,
      access_permit: [],
      formRule: this.$rules
    }
  },
  computed: {
    ...mapState('role', ['saved', 'newAccess', 'isActionInProgress']),
    ...mapState('keys', ['saved', 'keys', 'isActionInProgress'])
  },
  created () {
    this.formValid = false
    this.access_permit = {
      dashboard: { title: { name: 'dashboard', value: true }, actions: { dashboard: true } },
      sales: {
        title: { name: 'sales', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      refund: {
        title: { name: 'refund', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: true,
          import: true,
          export: true
        }
      },
      box: {
        title: { name: 'box', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: true,
          boxes_open: true,
          boxes_close: true,
          import: true,
          export: true
        }
      },
      tables: {
        title: { name: 'tables', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: true,
          boxes_open: true,
          boxes_close: true,
          import: true,
          export: true
        }
      },
      article: {
        title: { name: 'article', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          transport: true,
          edit: true,
          delete: true,
          import: true,
          export: true
        }
      },
      service: {
        title: { name: 'service', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          transport: true,
          edit: true,
          delete: true,
          import: true,
          export: true
        }
      },
      category: {
        title: { name: 'category', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false,
          active: true
        }
      },
      modifier: {
        title: { name: 'modifier', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      sell_by: {
        title: { name: 'sell_by', value: false },
        actions: {
          just_yours: true,
          sell_by_product: true,
          sell_by_category: true,
          sell_by_employer: true,
          sell_by_payments: true,
          import: true,
          export: true
        }
      },
      type_of_order: {
        title: { name: 'type_of_order', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      buy: {
        title: { name: 'buy', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      supplier: {
        title: { name: 'supplier', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      order: {
        title: { name: 'order', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      supply: {
        title: { name: 'supply', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      account_classify: {
        title: { name: 'account_classify', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      accounting_account: {
        title: { name: 'accounting_account', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      bank: {
        title: { name: 'bank', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      summary: {
        title: { name: 'summary', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      client: {
        title: { name: 'client', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      employer: {
        title: { name: 'employer', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      access: {
        title: { name: 'access', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      shop_online: {
        title: { name: 'shop_online', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      subscription: {
        title: { name: 'subscription', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      fiscal_account: {
        title: { name: 'fiscal_account', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          transport: true,
          edit: true,
          delete: true,
          import: true,
          export: true
        }
      },
      shop: {
        title: { name: 'shop', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false
        }
      },
      tax: {
        title: { name: 'tax', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false
        }
      },
      expense_category: {
        title: { name: 'expense_category', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      discount: {
        title: { name: 'discount', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false
        }
      },
      assistence: {
        title: { name: 'assistence', value: false },
        actions: {
          just_yours: true,
          list: true,
          create: true,
          edit: true,
          delete: false,
          import: false,
          export: false
        }
      },
      exchange_rate: {
        title: { name: 'exchange_rate', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false
        }
      },
      mark: {
        title: { name: 'mark', value: true },
        actions: {
          just_yours: true,
          list: true,
          create: false,
          edit: false,
          delete: false,
          import: false,
          export: false
        }
      }
    }
  },
  methods: {
    ...mapActions('role', ['createRole', 'toogleNewModal']),
    changeAccess (access) {
      if (access.title.name === 'sell_by') {
        access.actions.just_yours = access.title.value
        access.actions.sell_by_product = access.title.value
        access.actions.sell_by_category = access.title.value
        access.actions.sell_by_employer = access.title.value
        access.actions.sell_by_payments = access.title.value
        access.actions.import = access.title.value
        access.actions.export = access.title.value
      } else {
        access.actions.create = access.title.value
        access.actions.just_yours = access.title.value
        access.actions.import = access.title.value
        access.actions.export = access.title.value
        access.actions.edit = access.title.value
        access.actions.delete = access.title.value
        access.actions.list = access.title.value
      }
    },
    async createNewRole () {
      if (this.newAccess.name === 'CEO') {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.titles.delete', [
              this.$vuetify.lang.t('$vuetify.menu.access')
            ]),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.warning_ceo_name'
            ),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.cancel'
            ),
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.delete'
            ),
            confirmButtonColor: 'red'
          })
      } else if (this.$refs.form.validate()) {
        this.newAccess.access_permit = this.access_permit
        await this.createRole(this.newAccess)
      }
    }
  }
}
</script>

<style scoped></style>
