var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.toogleNewModal),callback:function ($$v) {_vm.toogleNewModal=$$v},expression:"toogleNewModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.titles.new", [ _vm.$vuetify.lang.t("$vuetify.menu.discount") ])))])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"my-10",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.firstName'),"rules":_vm.formRule.firstName,"required":""},model:{value:(_vm.newDiscount.name),callback:function ($$v) {_vm.$set(_vm.newDiscount, "name", $$v)},expression:"newDiscount.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.tax.value'),"rules":_vm.formRule.required,"required":"","properties":{
                clearable: true
              },"options":{
                length: 15,
                precision: 2,
                empty: 0.0
              }},model:{value:(_vm.newDiscount.cant),callback:function ($$v) {_vm.$set(_vm.newDiscount, "cant", $$v)},expression:"newDiscount.cant"}})],1),_c('v-col',[_c('h4',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.tax.rate")))]),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.newDiscount.percent),callback:function ($$v) {_vm.$set(_vm.newDiscount, "percent", $$v)},expression:"newDiscount.percent"}},[_c('v-radio',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.tax.percent'),"value":"true"}}),_c('v-radio',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.tax.permanent'),"value":"false"}})],1)],1),(
              _vm.newDiscount.cant &&
                _vm.newDiscount.cant !== 0.0 &&
                _vm.newDiscount.cant !== 0.0 &&
                _vm.newDiscount.percent === 'true'
            )?_c('v-col',{attrs:{"md":"12"}},[_c('i',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t( '$vuetify.tax.example', [_vm.newDiscount.cant], [_vm.user.company.currency] )))])]):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":_vm.isActionInProgress},on:{"click":function($event){return _vm.toogleNewModal(false)}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.actions.cancel"))+" ")],1),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":!_vm.formValid || _vm.isActionInProgress,"loading":_vm.isActionInProgress,"color":"primary"},on:{"click":_vm.createNewDiscount}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.actions.save"))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }