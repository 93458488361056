<template>
  <v-card>
    <v-card-text>{{ $vuetify.lang.t('$vuetify.titles.new', [$vuetify.lang.t('$vuetify.articles.cost')]) }}</v-card-text>
    <v-card-text>
      <v-row>
        <v-col md="4" cols="12">
          <v-text-field-money
            v-model="newVariantCost.cost"
            :label="$vuetify.lang.t('$vuetify.articles.cost')"
            required
            :properties="{
                    suffix: getCurrency
                  }"
            :options="{
                    length: 15,
                    precision: 2,
                    empty: 0.00,
                  }"
          />
        </v-col>
        <v-col md="4" cols="12" v-if="showAll">
          <v-text-field-money
            v-model="newVariantCost.stock"
            :label="$vuetify.lang.t('$vuetify.shop_article.stock')"
            required
            :options="{
              length: 15,
              precision: 2,
              empty: 0.00,
            }"
          />
        </v-col>
        <v-col md="4" cols="12">
          <v-text-field-money
            v-model="newVariantCost.under_inventory"
            :label="$vuetify.lang.t('$vuetify.shop_article.under_inventory')"
            required
            :options="{
              length: 15,
              precision: 2,
              empty: 0.00,
            }"
          />
        </v-col>
        <v-col md="4" cols="12">
            <v-text-field-simplemask
              v-model="newVariantCost.barCode"
              :label="$vuetify.lang.t('$vuetify.barCode')"
              :properties="{
                clearable: true,
              }"
              :options="{
                inputMask: '##-####-####-###',
                outputMask: '#############',
                empty: null,
                alphanumeric: true,
              }"
              :focus="focus"
              @focus="focus = false"
            />
        </v-col>
        <v-col
          cols="12"
          md="4">
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            :return-value.sync="newVariantCost.expire_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newVariantCost.expire_date"
                :label="$vuetify.lang.t('$vuetify.articles.due_date')"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="newVariantCost.expire_date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menuDate = false"
              >
                <v-icon>mdi-close-circle</v-icon>
                {{$vuetify.lang.t('$vuetify.actions.cancel')}}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menuDate.save(newVariantCost.expire_date)"
              >
                {{$vuetify.lang.t('$vuetify.actions.save')}}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="mb-2"
        @click="$emit('cancelNewVariant')"
      >
        <v-icon>mdi-close</v-icon>
        {{ smallScreen ? '': $vuetify.lang.t('$vuetify.actions.cancel') }}
      </v-btn>
      <v-btn
        :disabled="parseFloat(newVariantCost.cost).toFixed(2) === '0.00'"
        color="primary"
        class="mb-2"
        @click="validNewVariant"
      >
        <v-icon>mdi-check</v-icon>
        {{ smallScreen ? '': $vuetify.lang.t('$vuetify.actions.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'NewVariantCost',
  props: {
    variants: {
      type: Array,
      default: function () {
        return []
      }
    },
    showAll: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      menu: false,
      menuDate: false,
      focus: false,
      newVariantCost: {
        stock: 0,
        expire_date: null,
        bar_code: null,
        under_inventory: 1,
        cost: 0.00
      }
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    }
  },
  methods: {
    validNewVariant () {
      if (this.variants.filter(vc => parseFloat(vc.cost).toFixed(2) === parseFloat(this.newVariantCost.cost).toFixed(2)).length === 0) {
        this.$emit('refreshVariantCost', this.newVariantCost)
        this.newVariantCost = {
          stock: 0,
          under_inventory: 1,
          cost: 0.00
        }
      } else {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.titles.new', [this.$vuetify.lang.t('$vuetify.articles.cost')]),
            text: this.$vuetify.lang.t(
              '$vuetify.messages.warning_exist_cost'
            ),
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
