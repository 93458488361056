import bill from '../../api/bill'

const FETCHING_BILLS = 'FETCHING_BILLS'
const SWITCH_BILL_NEW_MODAL = 'SWITCH_BILL_NEW_MODAL'
const SWITCH_BILL_EDIT_MODAL = 'SWITCH_BILL_EDIT_MODAL'
const SWITCH_BILL_SHOW_MODAL = 'SWITCH_BILL_SHOW_MODAL'
const BILL_CREATED = 'BILL_CREATED'
const BILL_EDIT = 'BILL_EDIT'
const BILL_UPDATED = 'BILL_UPDATED'
const BILL_DELETE = 'BILL_DELETE'
const BILL_TABLE_LOADING = 'BILL_TABLE_LOADING'
const FAILED_BILL = 'FAILED_BILL'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_BILL = 'SET_EDIT_BILL'
const SET_BILL_AVATAR = 'SET_BILL_AVATAR'
const CANCEL_MODAL = 'CANCEL_MODAL'

const state = {
  showNewModal: false,
  showEditModal: false,
  showShowModal: false,
  bills: [],
  avatar: '',
  loading: false,
  saved: false,
  newBill: {
    name: '',
    description: ''
  },
  editBill: {
    id: '',
    name: '',
    description: ''
  },
  isBillLoading: false,
  isActionInProgress: false,
  isTableLoading: false
}

const mutations = {
  [SWITCH_BILL_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_BILL_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_BILL_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [BILL_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
    state.isBillLoading = isLoading
  },
  [FETCHING_BILLS] (state, bills) {
    state.bills = bills
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [BILL_CREATED] (state) {
    state.showNewModal = false
    state.newBill = {
      name: '',
      currency: {},
      count_number: '',
      init_balance: 0.00,
      date: '',
      description: ''
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.menu.account_bills')
	  ])
    })
  },
  [BILL_EDIT] (state, billId) {
    state.editBill = Object.assign(
	  {},
	  state.bills.filter(node => node.id === billId).shift()
    )
  },
  [BILL_UPDATED] (state) {
    state.showEditModal = false
    state.editBill = {
	  id: '',
      name: '',
      currency: {},
      count_number: '',
      init_balance: 0.00,
      date: '',
      description: ''
    }
    state.saved = true
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_up', [
        this._vm.$language.t('$vuetify.menu.account_bills')
	  ])
    })
  },
  [SET_EDIT_BILL] (state, profile) {
    state.editBill.push(profile)
  },
  [CANCEL_MODAL] (state) {
    state.newBill = {
      type: '',
	  name: '',
      init_balance: 0.00,
      currency: {},
      count_number: '',
      description: '',
	  color: ''
    }
    state.saved = false
  },
  [BILL_DELETE] (state, error) {
    state.saved = true
    state.error = error
    this._vm.$Toast.fire({
	  icon: 'success',
	  title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.menu.account_bills')
	  ])
    })
  },
  [SET_BILL_AVATAR] (state, avatar) {
    state.avatar = avatar
    state.saved = true
  },
  [FAILED_BILL] (state, error) {
    state.saved = false
    state.error = error
    state.isActionInProgress = false
    this._vm.$Toast.fire({
	  icon: 'error',
	  title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.account_bills')
	  ])
    })
  }
}

const getters = {}

const actions = {
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_BILL_NEW_MODAL, showModal)
    if (!showModal) {
	  commit(CANCEL_MODAL)
    }
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_BILL_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_BILL_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, billId) {
    commit(SWITCH_BILL_EDIT_MODAL, true)
    commit(BILL_EDIT, billId)
  },
  openShowModal ({ commit }, billId) {
    commit(SWITCH_BILL_SHOW_MODAL, true)
    commit(BILL_EDIT, billId)
  },
  async getBills ({ commit }) {
    commit(BILL_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await bill
	  .fetchBills()
	  .then(({ data }) => {
        commit(FETCHING_BILLS, data.data)
        commit(BILL_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
	  })
	  .catch(error => commit(FAILED_BILL, error))
  },
  async getBillsFilter ({ commit }, filter) {
    commit(BILL_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await bill
	  .getBillsFilter(filter)
	  .then(({ data }) => {
        commit(FETCHING_BILLS, data.data)
        commit(BILL_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
	  })
	  .catch(error => commit(FAILED_BILL, error))
  },
  async createBill ({
    commit,
    dispatch
  }, newBill) {
    commit(ENV_DATA_PROCESS, true)

    await bill
	  .sendCreateRequest(newBill)
	  .then(data => {
        commit(BILL_CREATED)
        commit(ENV_DATA_PROCESS, false)
	  })
	  .catch(error => commit(FAILED_BILL, error))
  },
  async saveBillValue ({
    commit,
    dispatch
  }, newBill) {
    commit(ENV_DATA_PROCESS, true)

    await bill
	  .sendSaveBillValue(newBill)
	  .then(data => {
        commit(BILL_CREATED)
        commit(ENV_DATA_PROCESS, false)
	  })
	  .catch(error => commit(FAILED_BILL, error))
  },
  async getBillsShop ({ commit }, data) {
    commit(BILL_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await bill
	  .getBillsShop(data)
	  .then(({ data }) => {
        commit(FETCHING_BILLS, data.data)
        commit(BILL_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
        return data
	  })
	  .catch(error => commit(FAILED_BILL, error))
  },
  async updateBill ({
    commit,
    dispatch
  }, editBill) {
    commit(ENV_DATA_PROCESS, true)
    await bill
	  .sendUpdateRequest(editBill)
	  .then(data => {
        commit(BILL_UPDATED)
        commit(ENV_DATA_PROCESS, false)
	  })
	  .catch(error => commit(FAILED_BILL, error))
  },
  async deleteBill ({
    commit,
    dispatch
  }, billId) {
    await bill
	  .sendDeleteRequest(billId)
	  .then(data => {
        commit(BILL_DELETE)
        dispatch('bill/getBills', null, { root: true })
        this.dispatch('auth/updateAccess', data)
	  })
	  .catch(error => commit(FAILED_BILL, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
