<template>
    <div ref="paypal"/>
</template>

<!--<script src="https://www.paypal.com/sdk/js?client-id=test"></script>-->

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PayPalPaySdk',
  props: {
    order: {
      type: Object,
      default: function () {
        return {}
      }
    },
    user: {
      type: Object,
      default: function () {
        return {}
      }
    },
    period: {
      type: String,
      default: 'month'
    },
    periodId: {
      type: String,
      default: null
    },
    plan: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      loading: false

    }
  },
  computed: {
    ...mapState('pay', ['paypalData'])
  },
  async created () {
    const script = document.createElement('script')
    await this.getPayPalData()
    // script.src = `https://www.paypal.com/sdk/js?client-id=${this.paypalData.client_id}&enable-funding=mercadopago&merchant-id=QMQQDXYMWPHKS`
    script.src = `https://www.paypal.com/sdk/js?client-id=${this.paypalData.client_id}`
    document.body.appendChild(script)
    script.addEventListener('load', this.setLoaded)
  },
  methods: {
    ...mapActions('pay', ['getPayPalData', 'payPalPayPlan']),
    setLoaded: function () {
      this.loading = false
      window.paypal.Buttons({
        style: {
          color: 'blue',
          shape: 'pill',
          label: 'pay',
          height: 40
        },
        createOrder: (data, actions, self) => {
          return actions.order.create({
            purchase_units: [this.order]
          })
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture()
          /// aqui
          await this.payPalPayPlan({
            order: order,
            user: this.user,
            period: {
              text: this.$vuetify.lang.t('$vuetify.plan.' + this.period),
              value: this.period,
              id: this.periodId
            },
            plan: this.plan
          }).then(() => {
            window.location.reload()
          })
        }
      }).render(this.$refs.paypal).then(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
