<template>
  <div />
</template>

<script>
export default {
  name: 'CheckOutDialog'
}
</script>

<style scoped>

</style>
