<template>
  <v-card>
    <v-dialog
      v-model="showCouponModal"
      max-width="350px"
    >
      <v-card>
        <v-card-subtitle>
          {{ $vuetify.lang.t('$vuetify.actions.insert', [$vuetify.lang.t('$vuetify.plan.coupon')]) }}
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="coupon"
            :label="$vuetify.lang.t('$vuetify.plan.coupon')"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mb-2"
            :disabled="isActionInProgress"
            @click="showModalCouponModal(false, {})"
          >
            <v-icon>mdi-close</v-icon>
            {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
          </v-btn>
          <v-btn
            :loading="isActionInProgress"
            class="mb-2"
            color="primary"
            @click="handlerSendCouponActive"
          >
            <v-icon>mdi-content-save</v-icon>
            {{ $vuetify.lang.t("$vuetify.actions.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text>
      <v-row>
        <v-col
          v-for="(plan, i) in ( getHaveSubscription ? plans.filter(pl => pl.id !== getMySubscription.plan.id): plans)"
          :key="i"
          md="12"
          cols="12"
        >
          <pay-plan :plan="plan"
                    :is-modal="isModal"
                    :user="userData"
                    @showModalCouponModal="showModalCouponModal"
                    @handlerActiveFreePlan="handlerActiveFreePlan"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PayPlan from '../pay/PayPlan'
export default {
  name: 'Subscription',
  components: { PayPlan },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    pending: {
      type: Number,
      default: 0.0
    },
    currency: {
      type: String,
      default: ''
    },
    plans: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      localPlans: [],
      menu2: false,
      coupon: '',
      showCouponModal: false,
      formValid: false,
      formRule: this.$rules,
      planSelected: {},
      period: 'month'
    }
  },
  computed: {
    ...mapState('plan', ['showSelectSubscription', 'isActionInProgress']),
    ...mapGetters('auth', ['companySubscription']),
    ...mapState('auth', ['userData']),
    valid () {
      return false
    },
    getHaveSubscription () {
      return !!this.companySubscription
    },
    getMySubscription () {
      return this.companySubscription
    }
  },
  async created () {
    await this.getUserData()
    const script = document.createElement('script')
    await this.getPayData({ method: 'stripe' })
    // Vue.use(StripePlugin, this.stripeData)
    script.src = 'https://polyfill.io/v3/polyfill.min.js?version=3.52.1&features=fetch'
    document.body.appendChild(script)
    this.loading = false
  },
  methods: {
    ...mapActions('auth', ['getUserData']),
    ...mapActions('plan', ['toogleShowSelectSubscription', 'paySubscription', 'sendCouponActive', 'sendFreePlan', 'activeMyTestSubscription']),
    ...mapActions('pay', ['getPayData']),
    lettersNumbers (event) {
      const regex = new RegExp('^[a-zA-Z0-9 ]+$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    showStripeSectionHandler (plan) {
      this.planSelected = plan
      const stripe = Stripe('pk_test_51Ijzg1ERMjeCzo0h1FCxshXpyXU4GGEumSxwpfKcYjN64rjsISTySUnHafW4Mr8ZLt5o6ssAdkalPZdHTm6rlBRI00ZatJMQ3A')
      this.makeSession(plan)
        // .then(function (response) {
        //   return response.json()
        // })
        .then(() => {
          return stripe.redirectToCheckout({ sessionId: this.session })
        })
        .then(function (result) {
          // If redirectToCheckout fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using error.message.
          if (result.error) {
            alert(result.error.message)
          }
        })
        .catch(function (error) {
          console.error('Error:', error)
        })
    },
    showModalCouponModal (plan) {
      this.showCouponModal = true
      this.planSelected = plan
    },
    handlerActiveFreePlan (plan) {
      this.loading = true
      this.sendFreePlan({ plan: plan })
      this.loading = false
    },
    handlerSendCouponActive () {
      this.loading = true
      this.sendCouponActive({ coupon: this.coupon, plan: this.planSelected })
      this.loading = false
    }
  }
}
</script>

<style scoped></style>
