import api from '../config/api'

export default {
  fetchConfigs () {
    return api.get('online-config')
  },
  sendCreateRequest (online) {
    return api.post('online-config', online)
  },
  findConfigShop (shopId) {
    return api.post('online-config/shop', shopId)
  },
  sendUpdateRequest (online) {
    return api.put('online-config/' + online.id, online)
  },
  sendDeleteRequest (onlineId) {
    return api.remove('online-config/' + onlineId)
  }
}
