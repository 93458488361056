import tables from '../../api/tables'

const FETCHING_TABLES = 'FETCHING_TABLES'
const FETCHING_OPERATIONS = 'FETCHING_OPERATIONS'
const SWITCH_TABLE_NEW_MODAL = 'SWITCH_TABLE_NEW_MODAL'
const SWITCH_TABLE_EDIT_MODAL = 'SWITCH_TABLE_EDIT_MODAL'
const SWITCH_TABLE_SHOW_MODAL = 'SWITCH_TABLE_SHOW_MODAL'
const TABLE_CREATED = 'TABLE_CREATED'
const TABLE_EDIT = 'TABLE_EDIT'
const GET_TABLE = 'GET_TABLE'
const EDIT_OPEN_CLOSE = 'EDIT_OPEN_CLOSE'
const TABLE_OPEN_CLOSE = 'TABLE_OPEN_CLOSE'
const TABLE_UPDATED = 'TABLE_UPDATED'
const TABLE_DELETE = 'TABLE_DELETE'
const TABLE_TABLE_LOADING = 'TABLE_TABLE_LOADING'
const FAILED_TABLE = 'FAILED_TABLE'
const ENV_DATA_PROCESS = 'ENV_DATA_PROCESS'
const SET_EDIT_TABLE = 'SET_EDIT_TABLE'
const SWITCH_OPEN_CLOSE_MODAL = 'SWITCH_OPEN_CLOSE_MODAL'
const CANCEL_MODAL = 'CANCEL_MODAL'

const state = {
  showNewModal: false,
  opencloseTables: false,
  showEditModal: false,
  showShowModal: false,
  showRefoundModal: false,
  tables: [],
  operations: [],
  alltables: [],
  avatar: '',
  boxSelected: {},
  loading: false,
  saved: false,
  newTable: {
    name: '',
    shop: {}
  },
  editTables: {
    id: '',
    name: '',
    shop: {}
  },
  openClose: {
    id: '',
    box: {},
    sales: [],
    open_money: 0.0,
    close_money: 0.0,
    open_to: null,
    closeBy: {}
  },
  showImportModal: false,
  isTableTableLoading: false,
  isActionInProgress: false,
  isTableLoading: false
}

const mutations = {
  [SWITCH_OPEN_CLOSE_MODAL] (state, showModal) {
    state.opencloseTable = showModal
  },
  [SWITCH_TABLE_NEW_MODAL] (state, showModal) {
    state.showNewModal = showModal
  },
  [SWITCH_TABLE_EDIT_MODAL] (state, showModal) {
    state.showEditModal = showModal
  },
  [SWITCH_TABLE_SHOW_MODAL] (state, showModal) {
    state.showShowModal = showModal
  },
  [TABLE_TABLE_LOADING] (state, isLoading) {
    state.isTableLoading = isLoading
  },
  [FETCHING_TABLES] (state, data) {
    state.tables = data[0]
    state.alltables = data[1]
  },
  [FETCHING_OPERATIONS] (state, data) {
    state.operations = data
  },
  [GET_TABLE] (state, data) {
    state.boxSelected = data
  },
  [ENV_DATA_PROCESS] (state, isActionInProgress) {
    state.isActionInProgress = isActionInProgress
  },
  [TABLE_CREATED] (state) {
    state.showNewModal = false
    state.opencloseTable = false
    state.newTable = {
      name: '',
      shop: {}
    }
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_add', [
        this._vm.$language.t('$vuetify.menu.box')
      ])
    })
  },
  [TABLE_EDIT] (state, boxId) {
    state.editTables = Object.assign({}, state.alltables
      .filter(node => node.id === boxId)
      .shift()
    )
  },
  [EDIT_OPEN_CLOSE] (state, data) {
    // state.openClose.id = data.id
    // state.openClose.open_money = data.open_money
    // state.openClose.openTo = data.open_to
    // state.openClose.sales = data.sales
    state.openClose = data
  },
  [TABLE_OPEN_CLOSE] (state, boxId) {
    state.openClose.box = Object.assign({}, state.alltables
      .filter(node => node.id === boxId)
      .shift()
    )
  },
  [TABLE_UPDATED] (state) {
    state.showEditModal = false
    state.opencloseTable = false
    state.editTable = {
      id: '',
      name: '',
      shop: {}
    }
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_up', [
        this._vm.$language.t('$vuetify.menu.box')
      ])
    })
  },
  [SET_EDIT_TABLE] (state, profile) {
    state.editTable.push(profile)
  },
  [TABLE_DELETE] (state) {
    state.saved = true
    this._vm.$Toast.fire({
      icon: 'success',
      title: this._vm.$language.t('$vuetify.messages.success_del', [
        this._vm.$language.t('$vuetify.menu.box')
      ])
    })
  },
  [CANCEL_MODAL] (state) {
    state.newTable = {
      name: '',
      shop: {}
    }
    state.saved = false
  },
  [FAILED_TABLE] (state, error) {
    state.isActionInProgress = false
    state.isTableTableLoading = false
    state.isTableLoading = false
    state.saved = false
    state.error = error
    this._vm.$Toast.fire({
      icon: 'error',
      title: this._vm.$language.t('$vuetify.messages.failed_catch', [
        this._vm.$language.t('$vuetify.menu.tables')
      ])
    })
  }
}

const getters = {}

const actions = {
  toogleOpenCloseModal ({ commit }, showModal) {
    commit(SWITCH_OPEN_CLOSE_MODAL, showModal)
    if (!showModal) {
      commit(CANCEL_MODAL)
    }
  },
  toogleNewModal ({ commit }, showModal) {
    commit(SWITCH_TABLE_NEW_MODAL, showModal)
  },
  toogleEditModal ({ commit }, showModal) {
    commit(SWITCH_TABLE_EDIT_MODAL, showModal)
  },
  toogleShowModal ({ commit }, showModal) {
    commit(SWITCH_TABLE_SHOW_MODAL, showModal)
  },
  openEditModal ({ commit }, boxId) {
    commit(TABLE_EDIT, boxId)
    commit(SWITCH_TABLE_EDIT_MODAL, true)
  },
  async openCloseModal ({ commit, state }, box) {
    commit(TABLE_TABLE_LOADING, true)
    commit(TABLE_OPEN_CLOSE, box.id)
    if (box.state === 'open') {
      await tables
        .fetchOpenClose(box.id)
        .then(({ data }) => {
          commit(EDIT_OPEN_CLOSE, data.data)
          commit(TABLE_TABLE_LOADING, false)
          this.dispatch('auth/updateAccess', data)
        })
        .catch(error => commit(FAILED_TABLE, error))
    }
    commit(SWITCH_OPEN_CLOSE_MODAL, true)
  },
  async getTables ({ commit, dispatch }) {
    commit(TABLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await tables
      .fetchTables()
      .then(({ data }) => {
        commit(FETCHING_TABLES, data.data)
        commit(TABLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
      })
      .catch(error => commit(FAILED_TABLE, error))
  },
  async getOperations ({ commit, dispatch }, id) {
    commit(TABLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await tables
      .fetchOperations(id)
      .then(({ data }) => {
        commit(FETCHING_OPERATIONS, data.data)
        commit(TABLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
      })
      .catch(error => commit(FAILED_TABLE, error))
  },
  async createTable ({ commit, dispatch }, newTable) {
    commit(ENV_DATA_PROCESS, true)

    await tables
      .sendCreateRequest(newTable)
      .then(data => {
        commit(TABLE_CREATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('tables/getTables', null, { root: true })
        this.dispatch('auth/updateAccess', data)
      })
      .catch(error => commit(FAILED_TABLE, error))
  },
  async updateTable ({
    commit,
    dispatch
  }, tablesE) {
    commit(ENV_DATA_PROCESS, true)
    const request = tablesE || state.editTable

    // const request = profile || state.editUser
    await tables
      .sendUpdateRequest(request)
      .then(response => {
        commit(TABLE_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('tables/getTables', null, { root: true })
      })
      .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_TABLE, error)
      })
  },

  async getTable ({
    commit,
    dispatch
  }, boxId) {
    commit(TABLE_TABLE_LOADING, true)
    // noinspection JSUnresolvedVariable
    await tables
      .fetchTable(boxId)
      .then(({ data }) => {
        commit(GET_TABLE, data.data)
        commit(TABLE_TABLE_LOADING, false)
        this.dispatch('auth/updateAccess', data)
      })
      .catch(error => commit(FAILED_TABLE, error))
  },
  async openCloseTable ({
    commit,
    dispatch
  }, tablesE) {
    commit(ENV_DATA_PROCESS, true)
    const request = tablesE || state.editTable

    // const request = profile || state.editUser
    await tables
      .sendOpenCloseTable(request)
      .then(response => {
        commit(TABLE_UPDATED)
        commit(ENV_DATA_PROCESS, false)
        dispatch('tables/getTable', null, { root: true })
        this.dispatch('auth/updateAccess', response.access)
      })
      .catch(error => {
        commit(ENV_DATA_PROCESS, false)
        commit(FAILED_TABLE, error)
      })
  },
  async deleteTable ({
    commit,
    dispatch
  }, boxId) {
    await tables
      .sendDeleteRequest(boxId)
      .then(response => {
        commit(TABLE_DELETE)
        this.dispatch('tables/getTables')
      })
      .catch(error => commit(FAILED_TABLE, error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
