<template>
  <v-container>
    <!--app-loading v-if="loadingData"/-->
    <v-card>
      <v-card-title v-if="smallScreen">{{ $vuetify.lang.t('$vuetify.panel.basic') }}</v-card-title>
      <v-card-text>
        <new-mark v-if="$store.state.mark.showNewModal"/>
        <new-tax v-if="$store.state.tax.showNewModal" />
        <new-category v-if="$store.state.category.showNewModal" :parent-category="parentCategory"/>
        <v-form
          ref="form"
          v-model="formValid"
          style="padding: 0"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="article.name"
                :label="$vuetify.lang.t('$vuetify.firstName')"
                :rules="formRule.required"
                required
                autofocus
                @keypress="lnSpace"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="article.um"
                :disabled="isActionInProgress"
                :items="arrayUM"
                :label="$vuetify.lang.t('$vuetify.um.name')"
                :filter="customFilter"
                item-text="name"
                auto-select-first
                return-object
              >
                <template v-slot:selection="data">
                  {{ $vuetify.lang.t('$vuetify.um.' + data.item.name) }}
                  <template v-if="data.item.presentation">
                    <template v-if="data.item.presentation.split('__').length === 0">
                      ({{ data.item.presentation }})
                    </template>
                    ({{ data.item.presentation.split('__')[0] }}<sup>{{ data.item.presentation.split('__')[1] }}</sup>)
                  </template>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content>
                      {{ $vuetify.lang.t('$vuetify.um.' + data.item.header) }}
                    </v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $vuetify.lang.t('$vuetify.um.' + data.item.name) }}
                        <template v-if="data.item.presentation">
                          <template v-if="data.item.presentation.split('__').length === 0">
                            ({{ data.item.presentation }})
                          </template>
                          ({{ data.item.presentation.split('__')[0] }}<sup>{{ data.item.presentation.split('__')[1] }}</sup>)
                        </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="article.variant_values.length === 0 && $route.name !== 'product_edit'"
              cols="12"
              md="4"
            >
              <v-text-field-money
                v-model="article.price"
                :label="$vuetify.lang.t('$vuetify.price')"
                required
                :properties="{
                              suffix: user.company ? getCurrency : ''
                            }"
                :options="{
                              length: 15,
                              precision: 2,
                              empty: 0.00,
                            }"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-y
                transition="fab-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    style="margin-top: 15px"
                    :label="$vuetify.lang.t('$vuetify.menu.category')"
                    v-bind="attrs"
                    v-on="on"
                    :value="getCategoryName"
                    readonly
                    :rules="formRule.required"
                    required
                  >
                  </v-text-field>
                </template>
                <v-card height="250">
                  <v-sheet>
                    <v-text-field
                      v-model="search"
                      :label="$vuetify.lang.t('$vuetify.actions.search')"
                      hide-details
                      clearable
                      clear-icon="mdi-close-circle-outline"
                    />
                    <v-spacer/>
                    <v-btn v-if="access_permit.category ? access_permit.category.actions.create : false"
                      class="mb-2"
                      color="primary"
                      @click="addCategory(null)"
                    >
                      <v-icon>mdi-plus</v-icon>
                      {{ $vuetify.lang.t('$vuetify.actions.newF') }}
                    </v-btn>
                  </v-sheet>
                  <v-treeview
                    style="background-color: white"
                    activatable
                    :search="search"
                    :filter="filter"
                    :items="getCategoriesLanguage"
                    item-text="category"
                  >
                    <template v-slot:label="{ item }" >
                      <v-list-item two-line @click="selectCategory(item)" @dblclick="selectDBCategory(item);">
                        <v-list-item-icon v-if="item.id !== ''">
                          <v-spacer />
                          <v-divider />
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                style="margin-top: 10px"
                                class="mr-2"
                                small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-bookmark-check
                              </v-icon>
                            </template>
                            <span>{{ $vuetify.lang.t('$vuetify.component.select_one') }}</span>
                          </v-tooltip>
                          <v-divider horizontal/>
                          <v-tooltip top style="margin-top: 10px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="access_permit.category ? access_permit.category.actions.create : false"
                                class="mr-2"
                                small
                                color="withe"
                                v-bind="attrs"
                                v-on="on"
                                @click="addCategory(item)"
                              >
                                mdi-plus
                              </v-icon>
                            </template>
                            <span>{{ $vuetify.lang.t('$vuetify.actions.add', [$vuetify.lang.t('$vuetify.menu.category')]) }}</span>
                          </v-tooltip>
                        </v-list-item-icon>
                        <v-list-item-title>
                        <span v-if="item.id" class="subtitle-2 font-weight-light">
                          {{UpperCaseFirstLetter(item.category) }}
                        </span>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-treeview>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="menu = false"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                      {{$vuetify.lang.t('$vuetify.actions.cancel')}}
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="acceptCategory"
                    >
                      {{$vuetify.lang.t('$vuetify.actions.save')}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col
              v-if="!article.composite"
              cols="12"
              md="4"
            >
              <v-select
                v-model="article.marks"
                chips
                clearable
                deletable-chips
                :items="marks"
                multiple
                :label="$vuetify.lang.t('$vuetify.menu.marks')"
                item-text="name"
                :loading="isMarksLoading"
                :disabled="!!isMarksLoading"
                return-object
                required
                :rules="formRule.country"
              >
                <template v-slot:append-outer v-if="permitAddMark">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="$store.dispatch('mark/toogleNewModal',true)"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.titles.newAction') }}</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="article.taxes"
                chips
                clearable
                deletable-chips
                :items="taxes"
                multiple
                :label="$vuetify.lang.t('$vuetify.tax.name')"
                item-text="name"
                :loading="isTaxLoading"
                :disabled="!!isTaxLoading"
                return-object
                required
                :rules="formRule.country"
              >
                <template v-slot:append-outer v-if="access_permit.tax ? access_permit.tax.actions.create: false">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="$store.dispatch('tax/toogleNewModal',true)"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.titles.newAction') }}</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="12" v-if="article.variant_values.length > 0 && $route.name === 'product_add'">
              <v-tooltip max-width="350" right class="md-6">
                <template v-slot:activator="{ on, attrs }">
                  {{ $vuetify.lang.t('$vuetify.articles.track_inventory') }}<v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
                </template>
                <p> {{ $vuetify.lang.t("$vuetify.messages.warning_article_service")}}</p>
              </v-tooltip>
              <v-radio-group v-model="article.service" row >
                <v-radio
                  :label="$vuetify.lang.t('$vuetify.menu.article')"
                  :value="false"
                ></v-radio>
                <v-radio
                  :label="$vuetify.lang.t('$vuetify.menu.service')"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <!--v-col
              cols="12"
              md="4"
            >
              <v-switch
                v-show="!article.id"
                v-model="article.composite"
                :title="$vuetify.lang.t('$vuetify.articles.composite_text')"
                @change="$emit('changeComposite')"
              >
                <template v-slot:label>
                  <div>
                    {{ $vuetify.lang.t('$vuetify.articles.composite') }}
                    <v-tooltip
                      max-width="100"
                      right
                      class="md-6"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{
                          $vuetify.lang.t('$vuetify.articles.composite_text')
                        }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-switch>
            </v-col-->
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="article.description"
                :counter="250"
                maxlength="250"
                :label="$vuetify.lang.t('$vuetify.access.description')"
              />
            </v-col>
          </v-row>
          <v-row v-show="article.composite">
            <v-col
              cols="12"
              md="12"
            >
              <composite-list
                :article="article"
                style="margin-top: 0"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import categories from '../../../../data/categories'
import CompositeList from './CompositeList'
import NewMark from '../../../mark/commerce/NewMark'
import NewTax from '../../../tax/commerce/NewTax'
import NewCategory from '../../../category/commerce/NewCategory'

export default {
  name: 'Basic',
  components: { NewCategory, NewTax, NewMark, CompositeList },
  props: {
    article: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      loadingData: false,
      getCategoryName: null,
      localCategory: null,
      search: '',
      menu: false,
      menuDate: false,
      formValid: false,
      focus: false,
      formRule: this.$rules
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapGetters('auth', ['user', 'access_permit']),
    ...mapState('statics', ['arrayUM']),
    ...mapState('tax', ['taxes', 'isTaxLoading']),
    ...mapState('mark', ['marks', 'isMarksLoading']),
    ...mapState('article', ['saved', 'managerArticle', 'articleNumber', 'newArticle', 'editArticle', 'articles', 'isActionInProgress']),
    ...mapGetters('article', ['getNumberArticle']),
    ...mapGetters('category', ['allCategories', 'allActivated', 'getCategoryAdded']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ' '
    },
    permitAddMark () {
      return this.access_permit.mark ? this.access_permit.mark.actions ? this.access_permit.mark.actions.create : false : false
    },
    permitAddTax () {
      return this.access_permit.tax ? this.access_permit.tax ? this.access_permit.tax.actions.create : false : false
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    getCategoriesLanguage () {
      const localCat = categories[this.$vuetify.lang.current]()
      let newLocal = []
      if (this.allActivated.length > 0) {
        newLocal = this.updateActiveCat(localCat, newLocal)
        this.allActivated.forEach((v) => {
          const cat = this.allCategories.filter(cat => parseInt(cat.id) === parseInt(v))[0]
          if (cat.company_id) {
            if (cat.parent_id) {
              this._find(newLocal, 'id', cat.parent_id, cat)
            } else {
              newLocal.push(cat)
            }
          }
        })
      }
      return newLocal
    },
    articleNumber: {
      get () {
        return this.getNumberArticle
      },
      set (newNumber) {
        return newNumber
      }
    }
  },
  watch: {
    'article.category.category': function () {
      this.getCategoryName = this.article.category ? this.UpperCaseFirstLetter(this.article.category.category) : null
    }
  },
  async created () {
    this.loadingData = true
    this.article.um = !this.managerArticle ? this.arrayUM[0] : this.editArticle.um ? this.editArticle.um : this.arrayUM[0]
    this.article.onlinePrice = parseFloat(this.article.onlinePrice).toFixed(2)
    await this.getCategories().then((callbackfn, thisArg) => {
      if (this.$route.name === 'product_edit') {
        this.getCategoriesLanguage.forEach((v) => {
          const cat = this.searchTree(v, this.editArticle.category_id)
          if (cat) {
            this.localCategory = cat
            this.acceptCategory(cat)
          }
        })
      }
    })
    await this.getTaxes()
    await this.getMarks()
    this.loadingData = false
  },
  methods: {
    ...mapActions('category', ['getCategories']),
    ...mapActions('tax', ['getTaxes']),
    ...mapActions('mark', ['getMarks']),
    numbers (event) {
      const regex = new RegExp('^\\d+(.\\d{1,2})?$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    lnSpace (event) {
      const regex = new RegExp('^[a-zA-Z0-9 ]+$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    updateDueDate (date) {
      this.article.expire_date = date
    },
    addCategory (category) {
      this.parentCategory = category
      this.$store.state.category.showNewModal = true
    },
    updateActiveCat (collection, newLocal) {
      for (const o of collection) {
        const ele = {
          category: o.category,
          id: o.id,
          children: []
        }
        if (o.children && Array.isArray(o.children) && o.children.length > 0) {
          const _o = this.updateActiveCat(o.children, ele.children)
          if (!Array.isArray(_o)) {
            ele.children.push(_o)
          }
        }
        if (this.allActivated.filter(act => parseInt(act) === parseInt(ele.id)).length > 0 || ele.children.length > 0) {
          if (!Array.isArray(ele)) {
            newLocal.push(ele)
          }
        }
      }
      return newLocal
    },
    _find (collection, key, value) {
      for (const o of collection) {
        for (const [k, v] of Object.entries(o)) {
          if (k === key && v === value.toString()) {
            return o
          }
          if (Array.isArray(v)) {
            const _o = this._find(v, key, value)
            if (_o) {
              return _o
            }
          }
        }
      }
    },
    searchTree (element, elementId) {
      if (parseInt(element.id) === parseInt(elementId)) {
        return element
      } else if (element.children != null) {
        let i = 0
        let result = null
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchTree(element.children[i], elementId)
        }
        return result
      }
      return null
    },
    selectDBCategory (item) {
      this.selectCategory(item)
      this.acceptCategory()
    },
    selectCategory (category) {
      this.localCategory = category
    },
    acceptCategory () {
      this.article.category = this.localCategory
      this.article.category_id = this.localCategory.category_id
      this.getCategoryName = this.article.category ? this.UpperCaseFirstLetter(this.article.category.category) : null
      this.menu = false
    },
    UpperCaseFirstLetter (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    customFilter (item, queryText, itemText) {
      return this.$vuetify.lang.t('$vuetify.um.' + item.name).toLowerCase().indexOf(queryText.toLowerCase()) > -1
    }
  }
}
</script>

<style scoped>

</style>
