<template>
  <v-container>
    <app-loading v-if="loading"/>
    <v-card max-height="90%" v-else >
      <v-card-title>
        <v-list-item two-line>
          <v-list-item-title>
            {{ $vuetify.lang.t('$vuetify.menu.category_list') }}
          </v-list-item-title>
          <v-spacer/>
          <v-text-field
            v-model="search"
            :label="$vuetify.lang.t('$vuetify.actions.search')"
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-list-item>
      </v-card-title>
      <v-card-subtitle>{{$vuetify.lang.t('$vuetify.messages.active_categories')}}</v-card-subtitle>
      <v-card-text>
        <v-switch
          v-model="singleSelect"
          :label="!singleSelect ? $vuetify.lang.t('$vuetify.component.unselect_all'): $vuetify.lang.t('$vuetify.component.select_all')"
          class="pa-3"
        ></v-switch>
        <v-treeview
          v-model="allActivated"
          selectable
          selected-color="red"
          activatable
          color="warning"
          open-on-click
          :items="getCategoriesLanguage"
          :search="search"
          :filter="filter"
          item-text="category"
        >
          <template v-slot:label="{ item }">
            <v-list-item two-line>
              <v-list-item-title>
                <span
                  v-if="item.id"
                  class="subtitle-2 font-weight-light"
                >
                  {{ UpperCaseFirstLetter(item) }}</span>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-treeview>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="isTableLoading"
          :loading="isTableLoading"
          class="mb-2"
          color="primary"
          @click="activeCategoriesHandle"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.actions.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import categories from '../../../data/categories'
import AddArticlesToCategory from './AddArticlesToCategory'
import NewCategory from './NewCategory'
import EditCategory from './EditCategory'
import AppLoading from '../../../components/core/AppLoading'

export default {
  name: 'ActiveCategory',
  components: { AppLoading },
  data () {
    return {
      loading: false,
      singleSelect: false,
      selected: [],
      allActivated: [],
      showAddArticle: false,
      categoryId: null,
      parentCategory: null,
      search: ''
    }
  },
  computed: {
    ...mapState('category', [
      'activated',
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'isTableLoading'
    ]),
    ...mapGetters('category', ['allCategories']),
    ...mapState('auth', ['company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.color'),
          value: 'color'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    getCategoriesLanguage () {
      const localCat = categories[this.$vuetify.lang.current]()
      if (this.allCategories.length > 0) {
        this.allCategories.forEach((v) => {
          if (v.company_id) {
            if (v.parent_id) {
              this._find(localCat, 'id', v.parent_id, v)
            } else {
              localCat.push(v)
            }
          }
        })
      }
      return localCat
    }
  },
  watch: {
    singleSelect: function () {
      this.allActivated = this.singleSelect ? [] : this.activated
    }
  },
  async created () {
    this.loading = true
    await this.getCategories()
    this.allActivated = this.activated
    this.loading = false
  },
  methods: {
    ...mapActions('category', ['toogleNewModal', 'openEditModal', 'openShowModal',
      'getCategories', 'deleteCategory', 'updateActiveCategories']),
    async activeCategoriesHandle () {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else { await this.updateActiveCategories(this.allActivated) }
    },
    _find (collection, key, value, child) {
      for (const o of collection) {
        for (const [k, v] of Object.entries(o)) {
          if (k === key && v === value.toString()) {
            if (!o.children && child) { o.children = [] }
            if (child)o.children.push(child)
          }
          if (Array.isArray(v)) {
            const _o = this._find(v, key, value, child)
            if (_o) {
              return _o
            }
          }
        }
      }
    },
    addArticlesToCategory (categoryId) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        this.categoryId = categoryId
        this.showAddArticle = true
      }
    },
    addCategory (category) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        this.parentCategory = category
        this.toogleNewModal(true)
      }
    },
    UpperCaseFirstLetter (item) {
      return item.category.charAt(0).toUpperCase() + item.category.slice(1)
    },
    showArticlesByCategory (categoryId) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else { this.$router.push({ name: 'product_by_category', params: { categoryId } }) }
    },
    editCategoryHandler ($event) {
      this.openEditModal($event)
    },
    deleteCategoryHandler (categoryId) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        this.categories.filter(cat => cat.id === categoryId)[0].articles.length === 0
          ? this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.delete', [
                this.$vuetify.lang.t('$vuetify.menu.category')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_delete'
              ),
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.cancel'
              ),
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.delete'
              ),
              confirmButtonColor: 'red'
            })
            .then(result => {
              if (result.value) this.deleteCategory(categoryId)
            }) : this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.delete', [
                this.$vuetify.lang.t('$vuetify.menu.category')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_exist_articles'
              ),
              icon: 'info',
              showCancelButton: false,
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'red'
            })
      }
    },
    showSubscriptionMessage () {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
          text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.accept'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'my_subscription' })
          }
        })
    }
  }
}
</script>

<style scoped>

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}</style>
