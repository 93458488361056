import es from 'vuetify/es5/locale/es'

export default {
  label: 'Español',
  // page login
  login: 'Iniciar sesión',
  name_of: 'Nombre de  {0}',
  unlimited: 'Ilimitado',
  money_limit: 'Límite de Dinero a Facturar',
  welcome_login: 'Ingrese a su cuenta de Billing Money App',
  reset_password: 'Cambiar contraseña',
  find_password: 'Enviar su contraseña',
  forgot: '¿Olvidó su contraseña?',
  have_pin: 'Bloquer Pantalla',
  full_screen: 'Pantalla Completa',
  value_of: 'Valor de {0}',
  generated_by_sale: 'Generado por venta',
  pinTile: 'Pin Code',
  date: 'Fecha',
  created_by: 'Creado por',
  forgot_btn: 'Enviar',
  hint_forgot: 'Ingrese su correo electrónico y se le enviarán las instrucciones',
  username: 'Usuario',
  name: 'Nombre',
  no_defined: 'No definido',
  provider: 'Proveedor',
  email: 'Correo',
  holder_email: 'Dirección de correo de la compañía',
  firstName: 'Nombre',
  lastName: 'Apellidos',
  bornDate: 'Fecha de Nacimiento',
  password: 'Contraseña',
  actual_password: 'Contraseña Actual',
  new_password: 'Nueva Contraseña',
  pinCode: 'Código',
  confirm: 'Confirmación',
  register: 'Regístrate',
  no_account: '¿No tienes una cuenta?',
  noDefined: 'No definido',
  aboutMe: 'Acerca de mi',
  activeAccount: 'Hola, su cuenta se ha creado, pero aún no ha sido activada. Por favor, acceda al correo registrado, se le ha enviado un mensaje para la activación.',
  confirm_password: 'Confirmar Contraseña',
  confirm_pinCode: 'Confirmar Código',
  company: 'Compañía',
  first_name: 'Nombre(s)',
  last_name: 'Apellidos',
  description: 'Descripción',
  address: 'Dirección',
  shop_address: 'Dirección de la Tienda',
  slogan: 'Eslogan',
  footer: 'Firma',
  city: 'Ciudad/Estado',
  country: 'País',
  currency: 'Moneda',
  change: 'Tasa de Cambio',
  province: 'Provincia',
  municipality: 'Municipio',
  barCode: 'Código de Barras',
  position: 'Cargo',
  postal_code: 'Código Postal',
  about_me: 'Acerca de mi',
  phone: 'Teléfono',
  wait: 'Espere por favor...',
  phone_holder: 'Entre su número de teléfono',
  price: 'Precio',
  onlinePrice: 'Precio en línea',
  cost: 'Costo',
  color: 'Color',
  principal: 'Principal',
  file: 'Archivo',
  system: 'Sistema',
  import_csv: 'Importando archivo',
  state: 'Estado',
  to: 'A',
  fromData: 'Realizado por',
  guide: 'Click para iniciar guía',
  referralLink: 'Link de refrencia',
  slogan_bill: 'EL ÉXITO DE LA EMPRESA EN SUS MANOS',
  all: 'Todos',
  any: 'Ninguno',
  // menu
  menu: {
    perquisite: 'Propina',
    delivery: 'Envíos',
    profitableness: 'Rentabilidad del Negocio',
    variant_cost: 'Variantes de Costo',
    product_under_inventory: 'En Bajo Inventario',
    buy: 'Compra',
    buys: 'Compras',
    manager_article: 'Gestionar Inventario',
    product_by_category: 'Ver Resumen de Ventas por Categoría',
    accounting_account: 'Cuentas contable',
    partners: 'Partners',
    menu: 'Menu',
    summary: 'Resumen Completo',
    tables: 'Mesas',
    table: 'Mesa',
    tables_list: 'Lista de Mesas',
    type_order: 'Tipo de Orden',
    active_category_list: 'Activar Categorías',
    my_subscription: 'Mi Subscripción',
    about: 'Acerca',
    contact: 'Contáctenos',
    prices: 'Precios',
    accounts: 'Cuentas',
    account: 'Cuenta',
    pasarela_config: 'Configuración de Pasarelas',
    fiscal_account: 'Cuenta Fiscal',
    manager_fiscal: 'Cuenta Fiscal',
    admin_plan: 'Planes',
    admin_subscription: 'Subscripciones',
    admin_subscription_suspend: 'Subscripciones Suspendidas',
    admin_subscription_canceled: 'Subscripciones Suspendidas',
    admin_beneficiary: 'Beneficiarios',
    beneficiary: 'Beneficiario',
    sub_file: 'Sub Archivo Contable',
    account_move: 'Movimiento Contable',
    account_moves: 'Movimientos Contable',
    account_bills: 'Otros Gastos',
    account_bill: 'Gasto',
    account_expense: 'Egresos',
    account_expenses: 'Egresos',
    account_income: 'Ingresos',
    bank: 'Banco',
    setting: 'Configuración',
    shop_online: 'Tienda Online(BETA)',
    supply_online: 'Pedidos Online',
    online_config: 'Configuración de Tienda Online(BETA)',
    home: 'Inicio',
    home_home: 'Home',
    shops: 'Tiendas',
    shop: 'Tienda',
    marks: 'Marca',
    marks_list: 'Lista de Marcas',
    manager_shop: 'Gestionar Tienda',
    manager_access: 'Gestionar Permisos de acceso',
    dashboard: 'Dashboard',
    admin_dashboard: 'Dashboard',
    access_denied: 'Acceso denegado',
    verify: 'Verificar Correo',
    welcome: 'Bienvenido',
    profile: 'Perfil',
    logout: 'Salir',
    user_list: 'Usuarios',
    employer_list: 'Empleados',
    user: 'Empleados',
    employee: 'Empleado',
    employees: 'Empleados',
    client_employer: 'Clientes y Empleados',
    client_employer_admin: 'Clientes y Empleados',
    access: 'Permisos de acceso',
    keys: 'Llaves para permiso',
    access_new: 'Nuevo Permiso de acceso',
    access_list: 'Lista de Permisos',
    client: 'Cliente',
    client_list: 'Clientes',
    clients_list: 'Clientes',
    articles: 'Inventario',
    services: 'Servicios',
    products: 'Productos',
    article: 'Artículo',
    detail: 'Detalle',
    service: 'Servicio',
    product: 'Producto',
    category: 'Categoría',
    expense_category: 'Categoría de Gasto',
    expense_category_list: 'Lista Categorías de Gastos',
    exchange_rate: 'Moneda extranjera',
    exchange_rate_list: 'Lista Moneda Extranjera',
    category_list: 'Lista de Categorías',
    plan_list: 'Lista de Planes',
    accounting_category_list: 'Lista de Categorías Contables',
    bank_list: 'Lista de Bancos',
    boxes_list: 'Lista de Cajas',
    product_list: 'Productos en Inventario',
    service_list: 'Lista de Servicios',
    expire_list: 'Expiración de artículos',
    slow_move: 'Lento Movimiento',
    modifiers_list: 'Lista de Modificadores',
    modifiers: 'Modificador',
    discounts_list: ' Lista de Descuentos',
    pay: 'Métodos de Pago',
    supplier: 'Proveedor',
    suppliers: 'Proveedores',
    company_subscription: 'Compañias y Subscriptiones ',
    supplier_list: 'Lista de Proveedores',
    resume: 'Resumen',
    account_classify: 'Archivo Contable',
    accountings: 'Cuentas Contables',
    account_to_pay: 'Cuentas por Pagar',
    account_pay_list: 'Cuentas por Pagar',
    accounts_to_receivable: 'Cuentas por Cobrar',
    account_receivable: 'Cuentas por Cobrar',
    account_receivable_list: 'Cuentas por Cobrar',
    accounting: 'Cuenta Contable',
    sell_product: 'Productos más vendidos',
    sell_mark: 'Ventas por Marcas',
    sell_product_margin: 'Productos de más ganancias',
    sell_category: 'Venta por Categorías',
    sell_user: 'Empleado que más venden',
    sell_types_payment: 'Venta por Tipo de Pago',
    sell_types_order: 'Venta por Tipo de Orden',
    pinlogin: 'Código Pin',
    refund: 'Reembolsos',
    history_refund: 'Historial de Reembolsos',
    boxes: 'Cajas',
    boxes_operations: 'Operaciones de Caja',
    tables_operations: 'Operaciones de Mesa',
    box: 'Caja',
    vending: 'Ventas',
    vending_history: 'Historial de Todas las Ventas',
    vending_acepted: 'Historial de Ventas Aceptadas',
    vending_process: 'Historial de Ventas En Proceso',
    vending_cancelled: 'Historial de Ventas Canceladas',
    vending_online: 'Ventas en Linea',
    vending_new: 'Nueva Venta',
    vending_edit: 'Editar Venta',
    turnOn: 'Registrar Turno',
    product_add: 'Crear Nuevo Producto',
    product_edit: 'Editar Artículo',
    assistance: 'Tarjeta de asistencia',
    buy_products: 'Actualizar Inventario',
    history_buy_products: 'Historial de mis Compras',
    buy_product: 'Compra',
    buy_add: 'Nueva Compra',
    buy_edit: 'Editar Compra',
    supply_product: 'Pedidos',
    supply_productS: 'Pedido',
    supply_emit: 'Pedidos Emitidos',
    supply_cancelled: 'Pedidos/Órdenes Canceladas',
    supply_process: 'Órdenes en proceso',
    supply_emit_title: 'Pedidos a Proveedores en el sistema',
    supply_received: 'Pedidos Recibidos',
    supply_add: 'Pedido',
    supply_edit: 'Editar Pedido',
    order_product: 'Órdenes',
    history_order_product: 'Historial de Órdenes',
    order_productS: 'Orden',
    order_emit: 'Órdenes Emitidas',
    order_emitS: 'Orden Emitida',
    order_emit_title: 'Órdenes a Proveedores fuera del sistema sistema',
    order_received: 'Órdenes Recibidas',
    order_receivedS: 'Orden Recibida',
    order_add: 'Adicionar Orden de pedido',
    emit_order: 'Emitir Orden',
    received_order: 'Recibir Orden',
    order_edit: 'Editar Orden de pedido',
    finance: 'Entradas',
    type_of_order: 'Lista Tipo de Pedido',
    tax_list: 'Impuesto',
    discount: 'Descuento',
    discounts: 'Descuentos',
    coin: 'Moneda',
    affiliate: 'Partners',
    affiliate_solicited: 'Solicitudes',
    affiliate_blocked: 'Bloqueados',
    affiliate_approved: 'Aprobados',
    affiliate_denied: 'Denegados'
  },
  // settings
  settings: {
    title: 'Ajuste de Tema',
    color: 'Opciones de color',
    lang: 'Idioma',
    sidebar: 'Opción de barra lateral'
  },
  // rules
  rule: {
    required: '{0} es requerido',
    min: 'La cantidad mínima es de {0} caracteres',
    max: 'La cantidad máxima es de {0} caracteres',
    minCant: 'La cantidad mínima es de {0}',
    maxCant: 'La cantidad máxima es de {0}',
    between: 'El valor de {0} debe ser entre {1} y {2}',
    length: 'Este campo necesita {0} caracteres',
    match: 'Los parámetros {0} y la {1} no son iguales',
    bad_email: '{0} debe ser válido',
    shop_name: 'Solo se adminten letras números y el caracter "_".',
    bad_numeric: '{0} debe ser valor numérico',
    required_element: 'Este campo es requerido',
    bad_phone: '{0} debe ser válido',
    select: 'Seleccione',
    pin: {
      len: 'La cantidad de dígitos es {0}',
      min: 'La cantidad mínima es de {0}',
      max: 'La cantidad máxima es de {0}'
    },
    general_required: 'Este campo es requerido'
  },
  // profile
  profile: {
    user: 'Usuario',
    edit_profile: 'Editar Perfil',
    sub_profile: 'Complete su perfil',
    btn_edit: 'Actualizar',
    company: 'Compañia',
    manager: 'Propietario'
  },
  messages: {
    variants_values: 'Una variante se usa cuando un producto puede tener diferentes caracteríticas.\n' +
      '        Ejemplo un producto Pantalón puede tener una Variante "Color", y sus valores: "Rojo" y "Negro". \n' +
      '        A su vez puede tener la variante "Talla", con los valores: "L" y "M", lo cual generará las combinaciones:  "Pantalón - Rojo/L",\n' +
      '        "Pantalón - Rojo/M", "Pantalón - Negro/L", "Pantalón - Negro/M". Válido aclarar que cada una se relacionará con todas' +
      ' las tiendas en el sistema por defecto. Si usted desea, puede eliminarlas a su gusto.',
    variant_cost: 'Una variante de costo, se usa para cuando un producto ha sido comprado a diferentes valores, pueda ser reflejado sin tener que afectar los ya ' +
      'guardados previamente así como sus operaciones',
    order_definition: 'Usted puede crear pedidos manteniéndolos en el estado de PREFORMA, en el momento en que le defina ' +
      'un método de pago, entonces se empezará a reconocer como una compra en PROCESO o ACEPTADA en dependencia del estado' +
      ' que usted seleccione',
    no_client: 'Para emitir un Comprobante Fiscal, debe asociar un\n' +
      ' cliente a la venta:\n' +
      '1. Si no tiene el cliente resgistrado en el sistema, por\n' +
      ' favor insértelo, no olvide guardar el RNC del mismo para\n' +
      ' poder emitir la factura.\n' +
      '2. Si ya tiene el cliente registrado, edite la venta y asóciele, \n' +
      'el cliente deseado seleccionándolo en la sección de \n' +
      'Datos Adicionales en Editar Venta.\n' +
      'Recuerde siempre haber configurado su Factura Fiscal\n' +
      ' en Configuración.',
    no_rnc: 'Por favor, establezca su rnc en\n Configuración-> Factura Fiscal',
    no_client_rnc: 'Por favor para generar un Comprobante Fiscal\n para este cliente, debe definir su RNC',
    no_total_pay: 'Para emitir un Comprobante Fiscal no puede tener\n cuentas por pagar',
    invalid_cant: 'Lo sentimos, usted está utilizando la versión básica de nuestro sistema y agotó el límite de facturación para el período subscrito. \n' +
      ' Para continuar operando, debe de cambiar a nuestra versión ilimitada UNICORNIO.\n' +
    'Disculpe los inconvenientes causados.\n Gracias.',
    rentability_text_1: 'Para el rendimiento de un negocio, se tiene presente varias cosas, entre ellas sus costos directos(costo de las mercancías compradas),' +
      ' costos indirectos, gastos, así como otros egresos reflejados en el sistema. Igualmente se relaciona lo abonado en ventas, impuestos, ' +
      'y otros ingresos, asi como lo pagado en cuentas a crédito, o lo que le han abonado a usted a través de las mismas.',
    rentability_text_2: 'Su Archivo Contable juega un papel fundamental en este punto, ya que el tiene todas estas relaciones en sus cuentas contables.\n' +
      'El objetivo de este apartado es mostrarle aproximadamente como se balancea el fortalecimiento de su negocio haciendo uso de la plataforma ' +
      'y los datos reflejados en la misma',
    resume_rentability_text_range: 'Durante el periodo comprendido {0}:',
    resume_rentability_text_days: 'En los días {0}:',
    resume_rentability_text_months: 'En los meses {0}:',
    resume_rentability_text_years: 'En los años {0}:',
    resume_rentability_text_1: 'usted incurrió en un gasto total de: {0} {6},\n' +
      'de manera general usted reportó un costo en compra productos o mercancia de: {1} {6}, y así como un costo indirecto: de {2}  {6}, \n' +
      'en este tiempo reportó egresos por un concepto de {3} {6} y pagó un total de {4} {6}, por concepto de pagos a crédito.',
    resume_rentability_text_2: 'En este mismo intervalo de tiempo, usted Ingresó por concepto de Ventas Netas: {0} {3}, ' +
      'así mismo abonó por otras vías un total de: {1} {3}, y recuperando {2} {3} por concepto de cuentas por cobrar a crédito.',
    resume_rentability_text_3: 'Todo esto le genera como beneficio o rentabilidad un total de: {0} {1}.',
    resume_rentability_text_4: 'Como dato adicional, le informamos que tiene pendiente a pago un total de {1} y por cobrar {2},' +
      ' si desea tener esto en cuenta, entonces su futuro benedicio será de {3}.',
    resume_rentability_text_5: 'NOTA: ESTE CÁLCULO SE NUTRE DE LA INFORMACIÓN INSERTADA EN EL SISTEMA, PARA HACERLA MÁS ACERTADA, POR FAVOR, SIEMPRE MANTANGA EL SISTEMA ACTUALIZADO',
    indirect_cost_description: 'Usted puede adicionar los costos indirectos que tiene una determinada Compra/Venta, ' +
      '(ej: Transporte, Combustible, Alojamiento, Alimentación, etc.). Esta información le será útil al momento de ' +
      'reducir gastos y tener un resumen más acertado de Factibilidad o Rentabilidad del negocio',
    indirect_cost_description2: 'Para ello seleccione el Botón "Nuevo", esta acción mostrará una ventana seleccione el Gasto en que incurrió, ' +
      'así como el valor que tuvo, en caso de equivocarse, puede eliminarlo o editarle el valor directamente.',
    active_article_shop: 'Desea activar: "{0}" en la tienda: "{1}"',
    warning_no_image: 'Este elemento no contiene imagen',
    refund_detail: 'Movimiento generado por el reembolso de  la venta con factura: {0}, en el artículo {1}',
    variant_cost_detail: 'Movimiento generado por la actualización del inventario del producto: {0}, con referencia : {1}',
    sale_detail: 'Movimiento generado por la venta con factura: {0}',
    pay_detail: 'Movimiento generado por el pago del crédito acordado en la venta con factura: {0}',
    sale_tax_detail: 'Movimiento generado por el pago del impuesto {0} en la venta con factura: {1}',
    sale_article_tax_detail: 'Movimiento generado por el pago del impuesto {0} sobre el artículo {1} en la venta con factura: {2}',
    buy_detail: 'Movimiento generado por la compra con factura: {0}',
    info_change_rate_1: 'El equivalente, según la tasa de cambio establecida({0}),\n' +
      '                es de {1}, al redondearlo a dos lugares, {2}, pero debido al redondeo dejará pérdidas:',
    info_change_rate_2: 'Valor redondeado: {0} x tasa de cambio {1} = {2}, valor que es menor que la cantidad a cobrar' +
      '({3}), esto le ocacionara una pérdida de: {4}',
    info_change_rate_3: 'El sistema recomienda cobrar {0} y devolver {1}',
    max_size: 'El tamaño máximo de las imágenes debe ser de 1M',
    wait: {
      loadingArticle: 'Buscando Artículos'
    },
    merchant_id_paypal: 'Este campo es requerido para hacer pagos mediante la pasarela PayPal',
    no_shop_to_config: 'Usted ya configuró las tiendas que tiene registrada en el sistema, por favor cree una nueva, o edite las que ya tiene.',
    delete_mark_variant: 'Esta Variante no puede ser eliminada. Si no la desea, no seleccione Marca en el Paso 1',
    paypal_currency_example_exclange: 'Si usted tiene un artículo que cuesta 100 {0}, aplicando su tasa de cambio de un 1 {1} equivale a {2} {0} en su tienda online costará {3} {1}',
    paypal_currency_note: 'Si la moneda seleccionada contiene (1) (2) o (3), tenga en cuenta las aclaraciones de PayPal',
    paypal_currency_note_1: '(1) Esta moneda no admite decimales. Si pasa una cantidad decimal, se produce un error.',
    paypal_currency_note_2: '(2) Esta moneda se admite como moneda de pago y saldo de moneda solo para cuentas de PayPal nacionales.' +
      ' Si el destinatario de los fondos no es de Brasil, PayPal convierte los fondos a la moneda de retención principal de la cuenta con ' +
      'la tasa de conversión de moneda aplicable. La tasa de conversión de divisas incluye el margen o tarifa aplicable de PayPal.',
    paypal_currency_note_3: '(3) Esta moneda se admite como moneda de pago y saldo de moneda solo para cuentas de PayPal nacionales.',
    waring_delete_all: 'Usted ha seleccionado todos estos elementos para ser eliminados. ¿Está seguro que desea borrarlos? Esta acción será irreversible',
    warning_basic_plan: 'Lo sentimos, solo puede tener un banco en el plan "Básico". Cambie al plan "Unicornio"',
    warning_basic_users: 'Sorry, you can only have 3 users on the "Basic" plan. Please switch to the "Unicorn" plan',
    fail_verified: 'Le enviamos un email de verificación registrado, por favor compruébelo.',
    active_categories: 'Active y/o desactive las catgorías que se usarán en el sistema. ' +
      'Tenga en cuenta que una vez desactivada no será vista por los usarios, y con ella se desactivarán también los ' +
      'artículos que contengan.',
    export_subscription: 'No puede exportar/importar elementos a menos que se subscriba a un plan.',
    active_subscription: 'Mucha gracias por subscribirse a nuestro plan {0}, usted tendrá {1} días de prueba. ' +
      'En ese intervalo de tiempo usted puede probar la aplicación.',
    iqual_subscription: 'Muchas gracias por cambiarse de plan, el dinero que le resta por consumir en el plan de su subscripción({0}),' +
      ' es equivalente al periodo {1}, en el plan seleccionado',
    mayor_subscription: 'Muchas gracias por cambiarse de plan, el dinero que le resta por consumir en el plan de su subscripción({0}),' +
      ' es mayor al periodo {1}, en el plan seleccionado, su subscripción cambiará al plan y periodo seleccionado, y será válida hasta {2}',
    menor_subscription: 'Muchas gracias por cambiarse de plan, el dinero que le resta por consumir en el plan de su subscripción({0}),' +
      ' es menor al periodo {1}, en el plan seleccionado, su subscripción cambiará de plan y periodo seleccionado, y será válida hasta {2}, para ello usted debe de pagar {3}',
    no_subscription: 'Lo sentimos, no podrás realizar esta opción hasta que se subscriba a un plan.' +
      ' Hágalo ahora para que pueda probar nuestro sistema y hacer su gestión más cómoda. ' +
      'No tiene que pagar inmediatamente, ' +
      'le ofrecemos días de prueba una vez que active la subscrición.',
    subscription_updated: 'Subscripción actualizada.',
    empty_elements: 'No existen elementos.',
    no_delete_default: 'No se puede ejecutar esta función. Este elemento es por defecto.',
    refused: 'Fallido: Conexión rechazada.',
    success_avatar: 'La imagen se salvado satisfactoriamente.',
    warning_requested_provider: 'Si su proveedor no está registrado, ¿desea que le enviemos un correo con invitación?',
    warning_no_boxes: 'Por favor adicione al menos una caja para poder gestionar una venta.',
    warning_no_articles: 'Por favor adicione al menos una artículo.',
    warning_delete: 'No se podrá revertir esta acción!',
    warning_search_days: 'Debe seleccionar al menos dos días para poder filtrar y graficar los datos',
    restart_articles: 'Recuerde que también se eliminará el ingreso o egreso asociado a esta compra,' +
      ' así como cuenta por cobrar o pagar si tenía procesos a créditos' +
      '¿desea restaurar el inventario de los artículos (como si esta Compra o Venta) no se hubiese efectuado nunca?',
    warning_delete_ceo: 'No se puede eliminar esta permiso de acceso',
    warning_ceo_name: 'CEO es un cargo que no puede crear, ese nombre esta reservado',
    warning_change_cant_pay: 'La cantidad pagada es menor que la cantidad a cobrar el sistema, automáticamente cambiará la cantidad a cobrar por el mismo valor de la cantidad pagada',
    warning_no_change_plan: 'No puede cambiar a este plan hasta que su subscripción no caduque, o le falte al menos un día para ello',
    warning_safe_articles_online: 'Desea guardar estos cambios independiente de si guarda o no la configuración de la tienda en línea',
    warning_state: 'Por favor seleccione un estado.',
    warning_supplier_no_article: 'Este proveedor no tiene artículos a la venta.',
    warning_exist_articles: 'No se puede eliminar esta Categoría. Tiene Artículos adjuntos, por favor elimínelos primero!',
    warning_exist_subscriptions: 'No se puede editar/eliminar esta Plan. Tiene Subscripciones adjuntas, por favor elimínelos primero!',
    warning_zero_price: 'El precio debe ser mayor a 0.50!',
    warning_zero_active_subscribers_limit: 'Si este plan tiene asociado cupones debe especificar la cantidad a entregar.',
    warning_digital_box: 'Esta Caja es necesaria, el sistema no acepta su edición o eliminación gestión.',
    warning_exist: 'Ya existe una variante con ese nombre!',
    warning_exist_cost: 'Ya este elemento tiene ese costo, por favor cambie el valor o cancele la acción',
    warning_excess_money: 'La cantidad definida a cobrar es mayor o igual a la precio total. Disminuya las cantidades ya definidas.',
    warning_preform: 'Usted creará una Preforma(PRE-FACTURA). ' +
      'Esto no se contemplará como venta real hasta tanto no defina la Forma de Pago en la Sección de Datos Adicionales!',
    error_delete_shop: 'Debe existir al menos una tienda.',
    error_delete_cost: 'Debe existir al menos un costo',
    error_associate: 'Ocurrió un error mientras intentábamos sincronizarnos con Sripe, por favor inténtelo más tarde',
    error_no_logo: 'Debe seleccionar un logo para su tienda.',
    error_name: 'Debe seleccionar un nombre para su tienda.',
    error_delete_manager: 'Este usuario no puede ser eliminado.',
    error_edit_manager: 'Este usuario no puede ser editado. Use el perfil para editarlo.',
    success_profile: 'Los datos han sido actualizados.',
    success_system: 'El systema ha sido actualizado.',
    success_add: '{0} ha sido creado satisfactoriamente.',
    success_up: '{0} ha sido actualizado satisfactoriamente.',
    success_del: '{0} ha sido eliminado satisfactoriamente.',
    failed_catch: 'Ocurrió error al procesar datos de {0}.',
    exist_pinCode: 'Este Código de acceso ya está en uso.',
    failed_catch_exist_name: 'Error. Ya existe una tienda con ese nombre por favor edítelo.',
    check_mail: 'Se ha enviado un correo electrónico con los detalles para cambiar contraseña.',
    invalid_check_mail: 'Este correo no se corresponde con el Tipo de cuenta seleccionada, por favor verifíquela.',
    password_success: 'La contraseña se actualizo correctamente.',
    warning_create: 'No se puede crear este elemento. Primero debe de crear {0}.',
    sure_delete: 'Está seguro de eliminar este elemento?',
    dont_add: 'Ya este elemento se encuentra adicinado.',
    pin_failed: 'No tienes acceso. El código PIN no es válido.',
    login_failed: 'Este correo electrónico no está registrado o contraseña no válida.',
    register_exist_email: 'Este correo electrónico ya está en uso.',
    login_failed_exist_email: 'Este correo electrónico no está registrado.',
    warning_no_category: 'Debe seleccionar una Categoría al elemento.',
    warning_composite: 'Si el elemento es compuesto, debe definir los que lo componen.',
    warning_price: 'No puede adicionar ese artículo. Si el precio es mayor que el costo, no le dará beneficios.',
    warning_cant_article: 'No puede adicionar esta Compras. Debe adicionar al menos un artículo.',
    warning_no_facture: 'No puede gestionar esta Compras. Debe adicionar el No. de Factura en la sección Datos Adicionales.',
    warning_no_article: 'No puede  realizar esta operación. Debe adicionar al menos un artículo en el sistema.',
    warning_no_article_services: 'No puede  realizar esta operación. Debe adicionar al menos un artículo en el sistema. Los servicios no pueden ser abastecidos.',
    warning_no_article_cat: 'No puede  realizar esta operación. Sus artículos no tienen categorías, debe especificarle al menos a uno para vender.',
    warning_no_clients_supplier: 'No puede realizar esta operación. Debe adicionar al menos un Proveedor que esté registrado en el sistema.',
    warning_supply_state: 'No puede realizar esta operación. Se ha iniciado el proceso por parte del proveedor, por favor cancele el pedido si no lo desea.',
    warning_supply_state_cancelled: 'No puede realizar esta operación. Este pedido ha sido cancelado, no tiene sentido editarlo.',
    warning_supply_delete: 'No puede realizar esta operación. Primero debe Cancelar el pedido, y entonces puede eliminarlo.',
    warning_exist_refunds: 'No puede editar esta Venta. Ya posee reembolsos, por favor cámbiele el estado a Cancelada o cree una nueva.',
    warning_exist_article_deleted: 'Esta Venta contiene artículos eliminados, no se recomienda editarla, sino eliminarla y crear una nueva.',
    warning_no_box: 'No puede adicionar esta compra. Debe Adicionar o  Seleccionar una caja.',
    warning_difference_price: 'Existe una diferencia entre el precio total y el definido en los pagos de {0}. \n Por favor arregle la diferencia.',
    warning_no_client_credit: 'Usted tiene seleccionado como un método de pago a crédito, debe seleccionar a que cliente le va a realizar tal cobro',
    warning_no_supplier_credit: 'Usted tiene seleccionado como un método de pago a crédito, debe seleccionar a que proveedor le va a realizar tal pago',
    warning_tax_cost: 'Usted está pagando mas de impuesto que el propio costo del producto.',
    warning_value_tax: 'El valor del impuesto no puede ser nulo ni cero.',
    warning_refund_Cant: 'Usted ha reembolsado {0} artículos, solo puede reembolsar entre 0.00 y {1}.',
    warning_refund_Money: 'Usted ha reembolsado $' + '{0}, solo puede reembolsar entre $0.00 y $' + '{1}.',
    warning_refund_all: 'No se puede reembolsar. Usted ha reembolsado todo el dinero o los artículos.',
    warning_article_service: 'Al crear un Artículo podrá abastecerlo y ponerle valor en tienda o almacén, en el caso del Servicio sería imposible el abastecimiento.',
    warning_partner_beneficiary: 'Si selecciona esta opción, el beneficiario obtendrá ganancias como si fuera partner para los referenciados que él consiga y paguen la versión UNICORNIO',
    info_exchange_rate: 'La tasa de cambio es con respecto a 1.',
    info_enable_email: 'Permitir que el correo de la tienda sea mostrado',
    info_enable_phone: 'Permitir que el teléfono de la tienda sea mostrado',
    info_enable_address: 'Permitir que la dirección física de la tienda sea mostrada',
    info_enable_defaultCurrency: 'Permitir que además de la moneda de pago configurada en la pasarela, se muestre la moneda local configurada para su país',
    idle_title: 'Sesión expirada.',
    idle_info: 'Ha dejado este navegador inactivo durante 10 minutos.',
    idle_counter: 'La sesión se cierra en {0} segundos.',
    warning_ref: 'La Referencia {0} ya está en uso.',
    warning_barCode: 'El Código de Barras {0} se está usando por {1} y {2}.',
    info_import_category: 'Si este archivo contiene Categorías, serán importadas al sistema, pero si usted ya ha creado alguna antes de importar, ' +
      'y coinciden los nombres, entonces se mantendrán las antiguas, adjuntándole los nuevos artículos.',
    info_import_shop: 'Si este archivo contiene artículos relacionado con tiendas, las mismas serán importadas al sistema  con el país donde usted fue registrado, ' +
      'pero si usted ya ha creado alguna antes de importar, y coinciden los nombres, entonces se mantendrá la antigua, relacionándole los nuevos artículos. Si desea ' +
      'cambiar el país, luego de importarla, puede ir al menú de Configuración, sección de Tiendas y Editar la misma en el Lista.',
    info_import_ref: 'Si usted ya ha creado Artículos y las Referencias coinciden con las de los Artículos importados, se le rescribirá a los nuevos artículos.',
    observation: 'Observación.',
    massage_print_facture: 'Gracias por visitarnos, vuelva pronto.:::::::'
  },
  /// hints messages
  hints: {
    round: 'El sistema redondeará automáticamente a {0} lugares decimales, el resultado será: {1}',
    gateway_system: 'El sistema reconoce a los pagos por pasarelas como "Transferencia Digital"',
    permit_invite: 'Permitir que los clientes compren sin estar registrados en el sistema',
    online_price_zero: 'Si el artículo tiene Precio en línea igual a cero(0.00) no se mostrará en la tienda en línea',
    online_stock_zero: 'Si el artículo tiene el inventario en cero no se mostrará en la tienda en línea',
    online_basic_limit_zero: 'Este Plan es de tipo Básico, puede que deba poner una cantidad máxima a facturar para este país',
    cant_box_zero: 'Este Plan es de tipo Básico, puede que deba poner una cantidad máxima de cajas para este país',
    invoice_interval: 'Billing Money tiene definido estos períodos de facturación, a los cuales se le define un precio. ' +
      'Si se selecciona como activo el campo "Free", entonces aunque tengan valos los precios, se guardarán como "0.00"',
    facture_period: 'Cada qué período se debe de volver a cobrar el plan',
    press_enter: 'Presione ENTER para adicionar a la lista',
    rnc: 'RNC o RUC o ID. Se refiere a la identificación como contribuyente',
    select_country: 'Seleccione el o los paises, si lo deja en blanco se aplicará para todos',
    active_subscribers_limit: 'Si no especifica cantidad, no tendrá límite de usuarios',
    prorate_day: 'Días que podrá usar el plan sin pagar la siguiente subscripción',
    invoice_period: 'Días que puede demorar el proceso de cobro del plan',
    test_day: 'Cantidad de días que se podrá pobar la aplicación sin tener que pagar el plan al que esté subcrito',
    format_date: 'Formato Año/Mes/Día',
    partner_percent: 'Porciento del pago que le corresponde al partner',
    partner_permanent: 'Cantidad fija que le corresponde al partner',
    account_archive: 'Archivo Contable: son los archivos que sirven para registrar todas las actividades comerciales a las que se dedica una empresa. Por ende, funcionan como prueba de todos los movimientos financieros que tiene la organización durante un determinado lapso de tiempo.',
    account_actives: 'Los Activos: son todos los bienes o derechos que posee una persona o empresa y que son susceptibles de convertirse en dinero, en beneficios o en rendimientos económicos.',
    account_expenses: 'Los Egresos: Son salidas de recursos financieros con el fin de cumplir un pago, también puede considerarse a la salida de dinero provocada por otros motivos, como realizar una inversión.',
    account_incomes: 'Los Ingresos:  son entradas de dinero por motivo de las actividades que brinda la empresa como servicios y bienes.',
    account_passives: 'Los Pasivos: son las deudas y obligaciones con la que la empresa financia su actividad. Ejemplo: los préstamos, saldos pendientes de pagos a proveedores, las deudas por concepto de impuestos, seguridad social, entre otros. '
  },
  // options
  actions: {
    is_caduced: 'Su subcripción ha caducado o no tiene, por favor renuévela, o subscríbase.',
    update: 'Actualizar',
    update_data: 'Actualizar {0}',
    no_active: 'Este elemento no está habilitado, selecciónelo para activar su uso',
    load_image: 'Cargar Imágenes',
    send_to_update: 'Generar nueva actualización para las compañías',
    goTo: 'Ir a {0}',
    save_continue: 'Guardar y Limpiar Datos',
    pay: 'Pagar',
    readAll: 'Leer Todas',
    suspend: 'Suspender temporalmente',
    subscribe: 'Subscríbase',
    add: 'Adicionar {0}',
    add_one: 'Adicionar',
    active: 'Activar',
    active_el: 'Activar {0}',
    actions: 'Acciones',
    insert: 'Insertar {0}',
    config: 'Configurar {0}',
    refund: 'Reembolsar',
    accept: 'Aceptar',
    open: 'Abrir',
    open_to: 'Abierto a',
    close_by: 'Cerrado por',
    search: 'Buscar',
    new: 'Nuevo',
    created: 'Crear {0}',
    newF: 'Nueva',
    edit: 'Editar',
    show: 'Mostrar',
    show_p: 'Mostrar {0}',
    hidden: 'Ocultar',
    hidden_p: 'Ocultar {0}',
    delete: 'Eliminar',
    deleteAll: 'Eliminar Todos',
    list: 'Listar',
    save: 'Guardar',
    cancel: 'Cancelar',
    close: 'Cerrar',
    change: 'Cambiar',
    print: 'Imprimir',
    apply: 'Aplicar',
    transfer: 'Transferir',
    next: 'Siguiente',
    back: 'Atrás',
    yes: 'Si',
    no: 'No',
    eye: 'Ver',
    see: 'Ver {0}',
    see_other_plan: 'Ver otros planes',
    no_see_other_plan: 'No ver otros planes',
    send: 'Enviar',
    minus: 'Menos',
    plus: 'Más',
    solicit: 'Solicitar'
  },
  titles: {
    extra_bills: 'Gastos fuera de Compras-Ventas',
    extra_sale_bills: 'Gastos en Ventas(sin costos)',
    change_plan: 'Cambiar de Plan',
    solicited: 'Solicitudes de Partners',
    solicit: 'Solicitudes {0}',
    list: 'Lista de {0}',
    new: 'Nuevo {0}',
    newF: 'Nueva {0}',
    edit: 'Editar {0}',
    show: 'Mostrar {0}',
    delete: 'Eliminar {0}',
    no_action: 'No puede {0}',
    newAction: 'Crear nuevo',
    importData: 'Importar'
  },
  access: {
    key: 'Llave',
    name: 'Cargo o Especialidad',
    accessPin: 'Acceso por código',
    accessEmail: 'Acceso con el correo',
    description: 'Descripción',
    keys: {
      atm: 'Cajero',
      waiter: 'Camarero',
      supervisor: 'Supervisor',
      seller: 'Vendedor',
      super_manager: 'CEO Administrador'
    },
    access: {
      dashboard: 'Inicio',
      just_yours: 'Solo los del usuario',
      manager_help: 'Defina que acciones puede ejecutar',
      manager_article: 'Gestionar Inventario',
      sales: 'Gestionar Venta',
      box: 'Gestionar Caja',
      refund: 'Gestionar Reembolso',
      tables: 'Gestionar Mesa',
      article: 'Gestionar Artículo',
      service: 'Gestionar Servicio',
      category: 'Gestionar Categoría',
      modifier: 'Gestionar Modificador',
      sell_by: 'Ver Resumen de ventas',
      type_of_order: 'Gestionar Tipos de Orden',
      buy: 'Gestionar Compras',
      supplier: 'Gestionar Proveedor',
      order: 'Gestionar Órdenes',
      supply: 'Gestionar Pedidos',
      account_classify: 'Gestionar Archivo Contable',
      accounting_account: 'Gestionar Cuenta Contable',
      bank: 'Gestionar Banco',
      summary: 'Gestionar Resumen',
      client: 'Gestionar Clientes',
      employer: 'Gestionar Empleados',
      access: 'Gestionar Permisos de Acceso',
      shop_online: 'Gestionar Tienda en Línea',
      expense_category: 'Gestionar Categoría de Gasto',
      subscription: 'Gestionar Subscripción',
      fiscal_account: 'Gestionar Cuenta Fiscal',
      shop: 'Gestionar Tienda',
      tax: 'Gestionar Impuesto',
      discount: 'Gestionar Descuento',
      exchange_rate: 'Gestionar Monedas de Cambio',
      assistence: 'Gestionar Asistencia',
      mark: 'Gestionar Marca',
      list: 'Listar',
      create: 'Adicionar',
      edit: 'Editar',
      delete: 'Eliminar',
      import: 'Importar',
      export: 'Exportar',
      active: 'Activar',
      boxes_open: 'Abrir Caja',
      boxes_close: 'Cerrar Caja',
      boxes_detail: 'Detalles de Caja',
      transport: 'Trasladar Artículos',
      manager_vending: 'Gestionar Ventas',
      manager_refunds: 'Gestionar Reembolsos',
      manager_accounting: 'Gestionar Cuentas Contables',
      manager_category: 'Gestionar Categorías',
      manager_tables: 'Gestionar Mesas',
      manager_account_classify: 'Gestionar Archivo Contable',
      manager_accounting_account: 'Gestionar Cuentas contable',
      manager_marks: 'Gestionar Marcas',
      manager_service: 'Gestionar Servicios',
      manager_mod: 'Gestionar Modificadores',
      manager_supplier: 'Gestionar Proveedores',
      manager_supply: 'Gestionar Pedidos',
      manager_order: 'Gestionar Orden',
      manager_buy: 'Gestionar Compras',
      manager_bank: 'Gestionar Bancos',
      manager_fiscal: 'Gestionar Cuenta Fiscal',
      manager_sell: 'Ver Resumen de Ventas',
      manager_sumary: 'Ver Resumen de Ventas',
      manager_summary: 'Ver Resumen de Ventas',
      manager_shop_online: 'Gestionar Tienda Online',
      sell_by_product: 'Ver Resumen de Ventas por Artículos',
      sell_by_category: 'Ver Resumen de Ventas por Categoría',
      sell_by_employer: 'Ver Resumen de Ventas por Empleados',
      sell_by_payments: 'Ver Resumen de Ventas por Métodos de Pago',
      manager_employer: 'Gestionar Empleados',
      manager_assistence: 'Gestionar Tarjeta de asistencia',
      manager_tax: 'Gestionar Impuesto',
      manager_discount: 'Gestionar Descuento',
      manager_boxes: 'Gestionar Cajas',
      manager_factures: 'Gestionar Facturas',
      manager_client: 'Gestionar Clientes',
      config: 'Configuración',
      fast_config: 'Configuración Rápida',
      manager_shop: 'Gestionar Tienda',
      warning_manager_shop: '1 Tienda',
      warning_manager_facture: '300 Facturas al mes',
      warning_manager_boxes: '2 Cajas',
      warning_manager_users: '3 Usuarios',
      manager_access: 'Gestionar Permisos de acceso',
      manager_payment: 'Gestionar Métodos de Pagos',
      manager_expense_category: 'Gestionar Categoría de Gasto',
      manager_exchange_rate: 'Gestionar Moneda extranjera',
      manager_type_of_order: 'Gestionar Tipos de Orden'
    }
  },
  assistance: {
    entry: 'Entrada',
    exit: 'Salida',
    entry_hour: 'Hora de Entrada',
    exit_hour: 'Hora de Salida',
    total_hours: 'Total de Horas'
  },
  component: {
    group_by: 'Agrupado por',
    range: 'Rango',
    select: 'Selecciona columnas',
    others: 'Otros',
    filter: 'Filtrar',
    fieldColor: 'Elige un color',
    select_all: 'Seleccionar Todos',
    unselect_all: 'Deseleccionar todo',
    select_account: 'Seleccionar Cuenta',
    select_one: 'Seleccionar',
    active_select_filter: 'Filtros de selección activos',
    active_many_filter: 'Seleccionar filtros activos (de muchos)',
    many_filter: 'Seleccionar (de muchos)',
    active_switch_filter: 'Filtros de conmutador activo',
    download_csv: 'Descargar como archivo CSV',
    upload_csv: 'Importar archivo CSV',
    choose_columns: 'Elija columnas',
    reset_columns: 'Restablecer columnas',
    no_filters: 'No hay filtros habilitados',
    clear_filters: 'Borrar filtros',
    filter_data: 'Filtrar datos',
    filter_disabled: 'Filtro deshabilitado',
    loading: 'Cargando',
    images: {
      name: 'Imágenes',
      dragText: 'Arrastra la imagen (múltiple)',
      browseText: '(o) Seleccione ',
      primaryText: 'Defecto',
      markIsPrimaryText: 'Establecer como predeterminado ',
      popupText: 'Esta imagen se mostrará como predeterminada ',
      dropText: 'Suelta tu archivo aquí ...'
    },
    banner: 'Banner',
    logo: 'Logo',
    image: 'Imagen'
  },
  tips: {
    delivery: 'Usted puede configurar todos los cobros de envíos, según sus valores, teniendo una lista de ellos.' +
      'Con estos datos, al realizar una venta, puede añadirle ese valor, y le quedará reflejado en la factura al cliente.',
    perquisite: 'Usted puede configurar todos los cobros de propina, según sus valores, teniendo una lista de ellos.' +
      'Con estos datos, al realizar una venta, puede añadirle ese valor, y le quedará reflejado en la factura al cliente.',
    tables_operations: 'Aquí usted puede observar todas las operaciones reailizadas en una mesa',
    copy_link: 'Click para copiar link',
    boxes_operations: 'Aquí usted puede observar todas las operaciones reailizadas en una caja',
    buy_edit: 'Usted puede editar un pedido de compra usando esta interfaz',
    product_edit: 'Si usted desea editar un producto, haciendo uso de esta interfaz, podrá hacerlo',
    product_variant_edit: 'Si usted desea editar una variante de un producto, puede hacerlo mediante esta interfaz',
    account_move_by_category: 'Si desea ver los movimientos contables, puede acceder a esta interfaz para tener los detalles',
    account_delete: 'Eliminar cuenta permanentemente.',
    referrals_on: 'Activar sistema de referencia',
    referrals_off: 'Desactivar sistema de refrencia',
    dashboard: 'Inicio del sistema, aquí puede observar todo el comportamiento de ventas del día, así como un resumen de' +
      ' algunos factores de importancia para una reunión inmediata, o noción del comportamiento diario. Esta función se actualiza diario',
    vending_new: 'Desde aquí usted puede acceder directamente a realizar una venta, sin necesidad de pasar por tantos munú u opciones,' +
      ' solo seleccionar el producto, impuestos, descuentos, forma de pago y realiza una venta al instante ',
    vending: 'Haciendo click en esta opción usted puede ver el listado de todas las ventas realizadas en el día, pero también puede filtrarlas por rango de fecha. ' +
      'Cuenta con un enlace para crear nueva venta, y en el listado también puede editarlas y/o eliminarlas. Puede realizar reembolsos directamente, y ' +
      'emitir factura fiscal, factura simple(Ticket) o factura en formato de carta  ',
    vending_edit: '',
    vending_acepted: 'Haciendo click en esta opción usted puede ver el listado de todas las ventas realizadas en el estado de ACEPTADAS en el día, pero también puede filtrarlas por rango de fecha. ' +
      'Cuenta con un enlace para crear nueva venta, y en el listado también puede editarlas y/o eliminarlas. Puede realizar reembolsos directamente, y ' +
      'emitir factura fiscal, factura simple(Ticket) o factura en formato de carta',
    vending_process: 'Haciendo click en esta opción usted puede ver el listado de todas las ventas realizadas en el estado de EN PROCESO en el día, pero también puede filtrarlas por rango de fecha. ' +
      'Cuenta con un enlace para crear nueva venta, y en el listado también puede editarlas y/o eliminarlas. Puede realizar reembolsos directamente, y ' +
      'emitir factura fiscal, factura simple(Ticket) o factura en formato de carta. LAS VENTAS EN PROCESO SON VENTAS QUE AÚN NO SON ACEPTADAS O CERRADAS, ESTAS NO INFLUYEN EN LAS CUENTAS,' +
      ' PERO SI RESTAN EN EL INVENTARIO',
    vending_cancelled: 'Haciendo click en esta opción usted puede ver el listado de todas las ventas realizadas en el estado de CANCELADAS en el día, pero también puede filtrarlas por rango de fecha. ' +
      'Cuenta con un enlace para crear nueva venta, y en el listado también puede editarlas y/o eliminarlas. Puede realizar reembolsos directamente, y ' +
      'emitir factura fiscal, factura simple(Ticket) o factura en formato de carta. LAS VENTAS CANCELADAS SON VENTAS QUE POR UN MOTIVO DETERMINADO SE DECIDIÓ CANCELAR, ESTAS PUEDEN O NO INFLUIR EN LAS CUENTAS SI EL USUARIO ASÍ LO DESEA,' +
      ' PERO SI RESTAN EN EL INVENTARIO',
    make_refund: 'A pesar que desde desde la interfaz de venta, se pueden hacer reembolsos, si usted desea puede acceder directamente y abstatraerse de toda ' +
      'la información que brinda dicha vista, centrándose solamente en lo que requiere para realizarle un Reembolso a un cliente',
    refund: 'Haciendo uso de este componente, usted puede ver el historial de todos los reembolsos realizados en el día, de igual manera, puede filtrarlo por un rango de días si así desea',
    boxes: 'Para poder realizar un proceso de venta, debe tener cajas adicionas, el sistema le provee de una interfaz cómoda que le ' +
      'permite adicionar cajas de manera sencilla, haciendo uso de esta interfaz, usted puede ver las cajas registradas, agrupadas por las tiendas a las que pertenecen, así como abriras o cerrarlas.' +
      'Desde esta interfaz el sistema le proporciona una herramienta autómata para el cuadre de la misma, basado en el registro de todas las transacciones reflejadas en el mismo.',
    table: 'Si en su negocio usted brinda servicios a mesas, entonces Billing Money le proporciona ese campo también, con nosotros ' +
      'le queda reflejado los procesos por las mesas, al igual que la caja por que se realizó una venta, también puede guardar la mesa en que se realizó',
    type_of_order: 'Con este componente usted puede definir diferentes modos o formas si desea especificar en sus procesos de compra, ej: ' +
      'Para llevar, Delivery, Para entregar, Comer en el local, etc.',
    manager_shop: 'Cuando usted realice su registro en nuestra plataforma, nosotros le generamos automáticamente una tienda para que usted pueda empezar a vender inmediatamente,' +
      ' pero si su negocio es más grande y agrupa varios locales o puntos de ventas, usted puede adicionarlos haciendo uso de esta interfaz',
    product_add: 'Con este componente, flexible y amplio, usted puede definir todas las características ' +
      'para sus artículos y/o servicios que ofertará al cliente o público. (NOTA: Billing Money define como Productos a su lista de artículos, y servicios)',
    product_list: 'Desde esta interfaz usted puede ver todos los articulos que tiene en inventario',
    service_list: 'Desde esta interfaz usted puede ver todos los servicios que tiene en inventario',
    product_under_inventory: 'Billing Money tiene como objetivo que usted siempre esté alerta sobre su inventario, para ello, cuando se crea un producto, ' +
      'existe una opción que define notificar bajo inventario. Haciendo uso de ella, el sistema le notifica en esta interfaz sobre ellos, o los productos que' +
      ' estén en cero en el inventario',
    expire_list: 'Billing Money tiene como objetivo que usted siempre esté alerta sobre su inventario, para ello, cuando se crea un producto, ' +
      'existe una opción que define fecha de vencimiento. Haciendo uso de ella, el sistema le notifica en esta interfaz sobre los productos un mes antes de vencer, y los que ya tienen un mes vencido',
    buy_add: 'Si usted desea mantener su parte contable actualizada, el sistema le brinda la función para que usted refleje sus compras, tal cual las realiza,' +
      ' almacenando toda la información necesaria para informes, resúmenes y estadísticas',
    buy_products: 'Haciendo uso de esta interfaz, usted ve el historial de compras realizadas en el día, aunque también puede extenderlas por un rango de fecha en su filtro',
    supply_process: 'Las Órdenes en proceso son las compras reflejadas en el sistema, pero que no se han concretado o cerrado',
    supply_emit: 'Los pedidos emitidos, son solicitudes de compras que aún están en negociación, o preformas de compras',
    supply_cancelled: 'Los Pedidos/Ordenes Canceladas, son las compras que por algún motivo, no se culminaron y se decidió cancelar',
    supplier_list: 'Para hacer sus procesos automáticos, el sistema le recomienda que usted refleje sus proveedores en el mismo. Con una sencilla lista, ' +
      'usted tendrá siempre en cuenta sus PROVEEDORES y las CUENTAS POR PAGAR a los mismos',
    category_list: 'Billing Money le provee de una amplia lista de categorías, pero, si no se ajustan a su actividad comercial, usted puede adicionar nuevas  y si es preciso editarlas',
    active_category_list: 'Al tener una extensa lista de categorías, puede que usted no las use todas, entonces desde aquí puede activar o desactivar las mismas según sus necesidades',
    marks_list: 'En esta función, usted puede definir las marcas de los productos que ofrece. Esto le permitirá tener mayores controle de inventarios, búsquedas,  resúmenes, estadísticas específicas, entre otras opciones',
    modifiers_list: 'Los modificadores van a ser plus que sus productos tendrán, pero q no son obligatorios al momento de la venta. ej. Su producto es una pizza de queso, ' +
      'los modificadores serían todos los agregados que tendrá: Champiñón, Jamón Piña, etc. A cada modificador se le define un precio, y se le suma al valor del producto en el momento de la venta.',
    bank_list: 'Para llevar a cabo una contabilidad más organizada, el sistema le permite llevar todos los bancos de forma virtual, ' +
      'reflejando las actividades o movimientos contables asociados a los mismos. Por defecto se le genera uno',
    account_classify: 'Desde esta interfaz usted podrá ver el sistema contable reflejado a través de las operaciones que ha realizado o configurado.' +
      ' Por defecto Billing Money genera una estructura básica, que usted puede ir enriqueciendo según sus necesidades',
    accounting_account: 'Las Cuentas Contables son instrumentos de operación donde se asientan los activos (cuentas por cobrar), ' +
      'los pasivos (cuentas por pagar) y el capital de una empresa (flujo de caja). De manera que estas reflejan los aumentos y ' +
      'la disminución que tiene una institución.',
    profitableness: 'Haciendo uso de toda la información brindada  por los movimientos contables, ya sean entradas o salidas de dinero, ' +
      'Billing Money le brinda una certera información o resumen de la rentabilidad de su negocio, basado siempre en la información insertada.',
    account_income: 'Todos los ingresos que usted refleja en el sistema, los puede observar desde esta interfaz',
    account_expenses: 'Todos los egresos que usted refleja en el sistema, los puede observar desde esta interfaz',
    account_bills: 'Independientemente de los egresos por concepto de compras, usted incurre en otros gastos, puede ser Electricidad, Internet, Agua, Gas, etc.' +
      ' Estos son reflejados y le ayudan enormemente en su resumen de rentabilidad',
    account_pay_list: 'Si su negocio permite cuentas por cobrar, desde esta interfaz, usted puede tener una lista de todos los clientes que incurren en ello.' +
      ' También puede ir actualizando los pagos que va realizando el mismo',
    account_receivable_list: 'Si su negocio permite cuentas por pagar, desde esta interfaz, usted puede tener una lista de todos los proveedores con los que incurre en ello.' +
      ' También puede ir actualizando los pagos que va realizando al mismo',
    tax_list: 'Con este componente usted define los Impuestos que debe de pagar, así como su valor',
    discount: 'Con este componente usted define los Descuentos que realizará, así como su valor',
    exchange_rate: 'Si su negocio cobra o paga en diferentes monedas, este componente le ayudará al mismo, ya que le permite listarlos y establecer su propia tasa de cambio',
    sell_product_margin: 'Brinda un gráfico, una tabla y un resumen sobre los productos vendidos que le brindan mayor márgen de ganancias',
    sell_product: 'Brinda un gráfico, una tabla y un resumen sobre los productos que más se venden',
    sell_mark: 'Brinda un gráfico, una tabla y un resumen sobre las Marcas que más se venden',
    sell_types_payment: 'Brinda un gráfico, una tabla y un resumen sobre el método de pago que más operaciones se realiza',
    sell_user: 'Brinda un gráfico, una tabla y un resumen sobre el empleado que más vende',
    summary: 'Resumen completo teniendo en cuenta el producto de mayor márgen de ganancias, el más vendido, las marcas, métodos de pago y empleado que más vende',
    employer_list: 'Lista de todos los empleados, así como los permisos de acceso que tiene',
    manager_access: 'Lista de los permisos de acceso asignados o por asignar a los empleados',
    shop_online: 'Listado y gestión de las tiendas en línea',
    clients_list: 'Lista e información de los clientes',
    partners: 'Puedes trabajar desde cualquier parte, en cualquier momento sin horarios ni obligaciones más que atraer clientes a la aplicación. ' +
      'Para ser Partner de nuestra aplicación, solo debes rellenar un formulario y esperar a ser aprobado, piensa que esto no requiere una inversión, ' +
      'sin embargo vas a ganar una comisión.  Nuestra empresa también ofrecerá cursos de preparación de marketing, y otros temas para que mantengas a tus afiliados' +
      ' dentro de nuestro sistema y puedas ganar cada mes tu parte.',
    setting: 'Una configuración general del sistema, módulos agrupados para una configuración básica y empezar a vender rápidamente',
    fiscal_init: 'Primera factura que enumerará, es decir si usted ha emitido 47, debe de poner 48, de esa manera la proxima factura fiscal emitida, sera con ese número',
    fiscal_prefix: 'Si sus facturas fiscales tienen prefijos incluidos, solo escríbalos y presione ENTER, evite el caracter "-" ' +
      'el sistema lo pondrá por defecto para separar el prefijo del número'
  },
  articles: {
    price_product: 'Precio(Producto)',
    total_price_product: 'Precio Total(Producto)',
    remove_inventory: 'Eliminado de inventario',
    name: 'Artículo',
    due_date: 'Fecha de Vencimiento',
    names: 'Artículos',
    price: 'Precio',
    cost: 'Costo',
    percent: '% de ganancia',
    sell_by: 'Vendido por',
    unit: 'Unidad',
    p_v: 'Peso/Volumen',
    ref: 'Referencia',
    inventory: 'Inventario',
    new_inventory: 'Nuevo Inventario',
    total_inventory: 'Inventorario Total',
    composite: 'Artículo compuesto',
    track_inventory: 'Seguir  inventario',
    itbis: 'ITBIS(18%)',
    tax: 'Impuesto',
    tax_by_sale: 'Impuestos por Venta Total',
    tax_by_product: 'Impuestos por productos',
    taxes: 'Impuestos',
    lay: 'Propina de ley(10%)',
    walking: 'Ambulante',
    composite_text: 'Los artículos compuestos contienen una determinada cantidad de otros artículos',
    online_text: 'Los artículos serán insertado para que se puedan vender en línea automáticamente o no',
    onlineSale: 'Venta en línea',
    onlineSale_text: 'Este artículo se venderá en su tienda virtual'
  },
  panel: {
    basic: 'Básico',
    inventory: 'Inventario',
    variant: 'Variantes',
    shop: 'Tienda'
  },
  variants: {
    variant: 'Variante',
    name: 'Nombre',
    price: 'Precio',
    cost: 'Costo',
    ref: 'Referencia',
    barCode: 'Código de Barras',
    options: 'Opciones',
    cant: 'Cantidad',
    total_cost: 'Costo Total',
    direct_costs: 'Costos Directos',
    direct_cost: 'Costo Directo',
    indirect_cost: 'Costo Indirecto',
    indirect_costs: 'Costos Indirectos',
    total_price: 'Precio Total',
    total_cant: 'Cantidad Total'
  },
  shop_article: {
    bajo_inventory: 'Bajo Inventario',
    under_inventory: ' Notificar al tener',
    stock: 'En Stock(Cantidad)',
    enabled: 'Disponible',
    person_sale: 'Venta en Persona'
  },
  representation: {
    representation: 'Representación',
    color_shape: 'Color',
    image: 'Imagen'
  },
  buy_product: {
    supply: 'Pedido'
  },
  payment: {
    name: 'Método de Pago',
    counted: 'Contado',
    cash: 'Efectivo',
    bank: 'Banco',
    credit_card: 'Targeta de Crédito',
    card: 'Targeta',
    check: 'Cheque',
    credit: 'Crédito',
    deposit: 'Depósito',
    wire_transfer: 'Transferencia Bancaria',
    digital_transfer: 'Transferencia Digital',
    other: 'Otro',
    pay_before: 'Pagar antes de',
    pay_delay: 'Pagar por demora',
    cant_pay: 'Pagado',
    cant_charge: 'Cobrar',
    cant_back: 'Devolver',
    check_number: 'No. Cheque',
    card_number: 'Número de Targeta',
    card_expiry: 'Expira',
    card_cvc: 'CVC'
  },
  supplier: {
    name: 'Proveedor',
    identity: 'ID/Documento',
    phone: 'Teléfono',
    email: 'Correo',
    country: 'País',
    address: 'Dirección',
    contract: 'Número de Contrato',
    expense: 'Categoría de Gasto',
    note: 'Nota'
  },
  tax: {
    name: 'Impuesto',
    nameGeneral: 'Impuesto general',
    value: 'Valor',
    rate: 'Tasa',
    percent: 'Porciento',
    permanent: 'Fijo',
    noFacture: 'No. Factura',
    type: 'Tipo',
    include_tax: 'Incluido en el precio',
    added_tax: 'Adicionado al precio',
    option_tax: 'Aplicar a todos los artículos existentes',
    example: 'Ejemplo: Para un precio de 100 {1}, el {0}% sería: {0} {1}',
    to_pay_tax: 'A pagar por Impuesto',
    total_pay_tax: 'Total por Impuesto',
    to_pay_discount: 'A descontar',
    total_pay_discount: 'Total de Descuento'
  },
  delivery: {
    name: 'Envío',
    nameGeneral: 'Envío general',
    value: 'Valor',
    rate: 'Tasa',
    percent: 'Porciento',
    permanent: 'Fijo',
    type: 'Tipo',
    include_delivery: 'Incluido en el precio',
    added_delivery: 'Adicionado al precio',
    option_delivery: 'Aplicar a todos los artículos existentes',
    example: 'Ejemplo: Para un precio de 100 {1}, el {0}% sería: {0} {1}',
    to_pay_delivery: 'A pagar por Envío',
    total_pay_delivery: 'Total por Envío',
    to_pay_discount: 'A descontar',
    total_pay_discount: 'Total de Descuento'
  },
  perquisite: {
    name: 'Propina',
    nameGeneral: 'Propina general',
    value: 'Valor',
    rate: 'Tasa',
    percent: 'Porciento',
    permanent: 'Fijo',
    type: 'Tipo',
    include_perquisite: 'Incluido en el precio',
    added_perquisite: 'Adicionado al precio',
    option_perquisite: 'Aplicar a todos los artículos existentes',
    example: 'Ejemplo: Para un precio de 100 {1}, el {0}% sería: {0} {1}',
    to_pay_perquisite: 'A pagar por Propina',
    total_pay_perquisite: 'Total por Prpina',
    to_pay_discount: 'A descontar',
    total_pay_discount: 'Total de Descuento'
  },
  marks: {
    description: 'Descripción',
    color: 'Color',
    avatar: 'Avatar',
    marks_edit: 'Editar Marcas',
    marks_delete: 'Eliminar Marca',
    marks_show: 'Mostrar Marca'
  },
  pay: {
    counted: 'Contado',
    credit: 'Crédito',
    pay: 'Pago',
    pays: 'Pagos',
    extra_data: 'Datos adicionales',
    total_pay: 'Total Pagado',
    outstanding: 'Pendiente de Pago',
    total_to_pay: 'Total a cobrar',
    sub_total: 'Sub Total',
    total: 'Total'
  },
  supply: {
    supplier_shop: 'Tienda del proveedor',
    supply_shop: 'Mi Tienda',
    name: 'Pedido',
    emit: 'Pedidos Emitidos',
    received: 'Pedidos Recibidos'
  },
  order: {
    supplier_shop: 'Tienda del proveedor',
    supply_shop: 'Mi Tienda',
    name: 'Orden',
    emit: 'Órdenes Emitidas',
    received: 'Órdenes Recibidas'
  },
  sale: {
    update_price: 'Precio Actual',
    sale_price: 'Precio de Venta',
    sale: 'Venta',
    online_text: 'Esta venta se realiza de forma online, y se realizará por la Caja Digital de manera automática',
    selectArticle: 'Por favor seleccione el artículo',
    no_article: 'No existen artículos sin categoría en esta tienda, por favor primero adicione artículos',
    selectShop: 'Debe seleccionar una tienda',
    discountGeneral: 'Descuento Total por venta',
    clear_cart: 'Limpiar Carrito',
    ticket: 'TICKET DE VENTA',
    fiscal: 'COMPROBANTE FISCAL',
    ticket_buy: 'TICKET DE COMPRA',
    facture: 'Factura',
    cart: 'Carrito',
    add_cart: 'Adicionar al carrito',
    pay_detail: 'Detalles del Pago',
    cant_money: 'Cantidad de dinero',
    cant_article: 'Cantidad de artículo',
    state: {
      solicited: 'Solicitadas',
      approved: 'Aprobadas',
      denied: 'Denegadas',
      blocked: 'Bloqueadas',
      open: 'En proceso',
      preform: 'Preforma',
      accepted: 'Aceptada',
      cancelled: 'Cancelada',
      close: 'Cerrada'
    },
    emptyArticle: 'Esta tienda no tiene artículos. Por favor abastezca la tienda'
  },
  box_state: {
    open: 'Abierta',
    close: 'Cerrada'
  },
  tables_close: 'Mesa Libre',
  tables_open: 'Mesa en Uso',
  report: {
    contact_us: 'Contáctenos',
    cant: 'Cant',
    print_fiscal: 'Imprimir Factura Fiscal',
    print_ticket: 'Imprimir Ticket',
    print_letter: 'Imprimir Carta',
    breakdown: 'Desglose',
    sale_footer: '¡GRACIAS POR COMPRAR EN NUESTRA TIENDA!',
    top5Category: 'Las 5 Categorías que más venden',
    top5Articles: 'Los Artículos que más venden',
    top5Payment: 'Los Métodos de Pago por los que más se vende',
    top5Employers: 'Los Empleados que más venden',
    netSale: 'Ventas Netas',
    grossPrice: 'Precio bruto',
    netPrice: 'Precio neto',
    total_sale: 'Precio de Venta',
    final_price: 'Precio Final',
    cant_operations: 'Cantidad de Operaciones',
    cant_sale: 'Cantidad Vendida',
    grossSale: 'Venta bruta',
    discountsSale: 'Descuentos por Ventas',
    graphics: 'Gráfico',
    noTop5: 'No existen ventas para mostrar información en el intervalo seleccionado',
    barGraphics: 'Gráfico de Barras',
    margin: 'Margen'
  },
  sector: {
    name: 'Sector',
    arts: 'Arte',
    rental: 'Alquiler',
    food: 'Alimentos',
    drink: 'Bebidas',
    health: 'Salud',
    agricultural: 'Agropecuarios',
    industrial: 'Industrial',
    hostelry: 'Hostelería',
    home: 'Hogar',
    shops: 'Tiendas',
    ironmongery: 'Ferretería',
    restaurant: 'Restaurantes',
    tourism: 'Turismo',
    construction: 'Construcción',
    service: 'Servicios',
    technologies: 'Tecnologías',
    consultancy: 'Consultoría',
    education: 'Educación',
    entertainment: 'Entretenimiento',
    automotive: 'Automotriz',
    transport: 'Transporte',
    real_state: 'Bienes de raíces',
    others: 'Otros'
  },
  dashboard: {
    sales: 'Ventas',
    salesSub: 'Incremento de ventas',
    expenses: 'Gastos',
    expensesSub: 'Total de gastos',
    costs: 'Costos',
    costsSub: 'Total de costos',
    revenue: 'Beneficios',
    revenueSub: 'Incremento de beneficios',
    orders: 'Órdenes',
    ordersSub: 'Incremento de órdenes',
    info: 'Información',
    access: 'Accesos',
    mergeTable: 'Margen de ganancias',
    timeLine: 'Últimas facturas',
    timeLineText: 'ha creado nueva venta para el cliente',
    timeLineTextNoClient: 'ha creado nueva venta',
    timeLineTextNoEmployer: 'Se ha creado nueva venta para el cliente',
    timeLineTextNoEmployerNoClient: 'Se ha creado nueva venta'
  },
  boxes: {
    difference: 'Diferencia',
    init: 'Balance Inicial',
    current: 'Balance Actual',
    future: 'Balance Futuro',
    final: 'Balance Final'
  },
  tour: {
    buttonSkip: 'Omitir',
    buttonPrevious: 'Anterior',
    buttonNext: 'Siguiente',
    buttonStop: 'Final',
    initGuide: 'Click para iniciar guía'
  },
  steps: {
    step_5: 'Más vendidos'
  },
  tourMsg: {
    step1: '<strong>BILLING MONEY APP</strong>!<br> Bienvenidos a nuestro sistema',
    step2: '<strong>Menu</strong>!<br> Aquí encontramos las funcionalidades principales por módulos',
    step3: '<strong>Configuración</strong>!<br> Elementos de <a href="/setting" style="color:#4fc08d">configuración</a>',
    step4: '<strong>Gestión de Artículos</strong>!<br> ' +
      '<a href="/articles/product.list" style="color:#4fc08d">Productos</a><br>' +
      '<a href="/articles/category.list" style="color:#4fc08d">Categorías</a>',
    step5: '<strong>Gestión  de Ventas</strong>!<br> ' +
      '<a href="/sales/vending.list" style="color:#4fc08d">Ventas</a><br>' +
      '<a href="/sales/refund.list" style="color:#4fc08d">Reembolsos</a><br>' +
      '<a href="/sales/boxes.list" style="color:#4fc08d">Cajas</a>',
    step6: '<strong>Gestión de empleados</strong>!<br> ' +
      'Lista de <a href="/users/employer.list" style="color:#4fc08d">empleados</a><br>' +
      'Tarjetas de <a href="/users/assistance.list" style="color:#4fc08d">asistencia</a>',
    step7: '<strong>Gestión de <a href="/clients/client.list" style="color:#4fc08d">clientes</a></strong>!',
    step8: '<strong>Gestión de mis finanzas</strong>!<br>' +
      '<a href="/finance/supplier.list" style="color:#4fc08d">Proveedores</a><br>' +
      '<a href="/finance/buy_list" style="color:#4fc08d">Gestión de compras</a>',
    step9: '<strong>Resumen</strong>!<br>Resumen gráficos de mis ventas',
    step10: '<strong>Nav Icon</strong>!<br>Abrir y cerrar el menu general',
    step11: '<strong>Lock Icon</strong>!<br>Bloqueo de pantalla o aplicación',
    step12: '<strong>Full Screen Icon</strong>!<br>Abrir la aplicación en pantalla completa',
    step13: '<strong>Ventas</strong>!<br>Acceso directo a ventas de productos',
    step14: '<strong>Compras</strong>!<br>Acceso directo a la compras de prodcutos',
    step15: '<strong>Selector de idioma</strong>!<br>Selector de idioma para la visualiazación',
    step16: '<strong>User Icon</strong>!<br>' +
      '<a href="/finance/supplier.list" style="color:#4fc08d">Perfil</a><br>' +
      'Cerrar sesión',
    step17: '<strong>Ajuste de Tema</strong>!<br>Selecionar colores, idiomas y estilos de la aplicación',
    step18: '<strong>Estadísticas generales</strong>!<br> Principales indicadores de compañía'
  },
  um: {
    name: 'Unidad de Medida',
    distance: 'Distancia',
    u: 'Unidad(s)',
    pq: 'Paquete(s)',
    mm: 'Milímetro(s)',
    cm: 'Centímetro(s)',
    pp: 'Pulgada(s)',
    m: 'Metro(s)',
    footer: 'Pie(s)',
    yard: 'Yarda(s)',
    km: 'Kilómetro(s)',
    mll: 'Milla(s)',
    temp: 'Temperatura',
    c: 'Celsius',
    k: 'Kelvin',
    f: 'Faraday',
    time: 'Tiempo',
    s: 'Segundo(s)',
    mt: 'Minuto(s)',
    h: 'Hora(s)',
    d: 'Día(s)',
    ms: 'Masa',
    mg: 'Miligramo(s)',
    g: 'Gramo(s)',
    kg: 'Kilogramo(s)',
    ton: 'Tonelada(s)',
    lb: 'Libra(s)',
    oz: 'Onza(s)',
    velocity: 'Velocidad',
    m_s: 'Metro/Segundo',
    km_h: 'Kilómetro/Hora',
    ms2: 'Aceleración',
    vol: 'Volumen',
    gl: 'Galón',
    l: 'Litro',
    m3: 'Metro cúbico',
    m2: 'Metro cuadrado',
    digital: 'Digitales',
    bit: 'Bit',
    byte: 'Byte',
    mega: 'Mega',
    tr: 'Tera',
    px: 'Pixel',
    mPx: 'MegaPixel',
    other: 'Otras',
    mhz: 'Microhertz',
    hz: 'Hertz',
    hom: 'Ohmio',
    volt: 'Volt',
    wat: 'Wat',
    amp: 'Ampere',
    joule: 'Joule'
  },
  helpSales: {
    listStep1: '<strong>Botón Adicionar</strong>!<br> Crear nueva venta',
    listStep2: '<strong>Filtros</strong>!<br> Establecer filtros de búsquedas y descargar datos',
    listStep3: '<strong>Lista</strong>!<br> Lista las ventas creadas.'
  },
  helpSaleManager: {
    selectShop: 'Aquí selecciona la Tienda en que se realiza la venta',
    selectBox: 'Aquí selecciona la Caja por donde se venderá. Si está cerrada, automáticamente se ' +
      'abrirá para el usuario autenticado con $0.00',
    addBox: 'Aquí adiciona una nueva Caja',
    selectArticle: 'Aquí selecciona los artículos que se venderá'
  },
  supply_state: {
    state: {
      name: 'Estado',
      requested: 'Solicitada',
      accepted: 'Aceptado',
      process: 'En Proceso',
      ship: 'Enviado',
      received: 'Recibida',
      cancelled: 'Cancelada'
    }
  },
  online: {
    online: 'En línea',
    delivery: 'Costo de Envío',
    template: 'Plantilla',
    templates: {
      template_1: 'Plantilla 1',
      template_2: 'Plantilla 2',
      template_3: 'Plantilla 3'
    },
    merchant_id: 'ID de Comerciante',
    paypal_email: 'Correo registrado en Paypal',
    enable_email: 'Permitir mostrar correo',
    enable_phone: 'Permitir mostrar teléfono',
    enable_address: 'Permitir mostrar dirección',
    enable_defaultCurrency: 'Permitir mostrar en también su moneda local',
    showing: 'Mostrando',
    of: 'de',
    permit_invite: 'Permitir invitados',
    credits_pay: 'Credenciales de pago',
    paypal_credit: 'Credenciales de PayPal',
    paypal_client_id: 'ID CLIENTE',
    paypal_secret: 'PAYPAL SECRET'
  },
  notifications: {
    supplier_is_register: 'El proveedor {0} se ha registrado en el sistema. Usted puede realizar sus pedidos usando BILLING MONEY APP',
    solicited_supply: 'El cliente {0} ha registrado un pedido para usted.',
    under_inventory: 'El artículo {0} se encuentra en bajo inventario, actualmente cuenta con {1}.',
    under_inventory_one_param: 'El artículo {0} se encuentra en bajo inventario.'
  },
  bank: {
    count_number: 'No. de Cuenta',
    type_operation: 'Tipo de Operación Bancaria'
  },
  partners: {
    title: 'Solicitud de partner',
    referrals: 'Recuento de referencias',
    referralsSub: 'Recuento de referencias vinculadas',
    referrer: 'Referenciado',
    referrerSub: 'Referenciado por empresa',
    not_specified: 'No especificado',
    referralsList: 'Lista de referencias',
    activate: 'Activa tu programa de referidos',
    solicit: 'Solicitud de activación de referidos',
    activate_description: 'Si invitas a una persona y paga su membresía en la versión "UNICORNIO", obtienes ganancias por ello. ' +
      ' Billing Money realiza pagos de una comisión mediante la pasarelas de pago. ',
    solicit_description: 'Billing Money App se reserva el derecho de activar o no su cuenta de referidos, ' +
      'en dependencia de las necesidades que tenga la empresa en ese lugar(Lugar, Edad etc.),  y las condiciones que presente en el completamiento de ' +
      'el formulario de Solicitud, este proceso puede llegar a tardar varios días, ' +
      'ya que se debe de reunir un grupo de expertos para aprobar su solicitud. Esto no interfiere con el funcionamiento del resto del sistema',
    activate_activation: 'Por favor asocie su cuenta con la nuestra, complete los datos que la pasarela solicita para poder transferirle los pagos.',
    confirm_copied: 'Se copió su enlace de referencia: ',
    error_copied: 'No se pudo copiar el texto',
    wait_result: 'Billing Money App está procesando sus datos para aceptar la solicitud, este proceso puede demorar días,' +
      ' esperemos disculpe nuestra demora, trataremos de agilizar el proceso lo antes posible. En cuanto tengamos los resultados le notificaremos ' +
      'al correo que registró en nuestro sistema. Gracias por unirse a nosotros!!! Pronto le enviaremos el resultado de su solicitud, y cuando ' +
      'sea aprobado podrá empezar a facturar de manera inmediata. Bienvenido a la Familia Billing Money, donde todos ganamos',
    online_stripe_associate: 'Por favor asocie su cuenta con la nuestra, complete los datos que la pasarela solicita para poder transferirle los pagos.',
    existing_account: 'Asociar a cuenta en Stripe',
    existing_account_paypal: 'Asociar a cuenta en PayPal',
    no_paypal_asociate: 'Por favor edite su configuración y en el Paso 3(Pasarelas), inserte los datos requeridos para pagar mediante PayPal',
    no_articles_online: 'Por favor adicione productos a la venta en línea, edite su configuración, y en el paso 4: Gestión de Inventario, active nuevos productos, que tengan inventario mayor que cero, así como su precio en línea',
    is_not_online: 'Si muestra este ícono su tienda no se encuentra en línea, ya que antes debe sincronizar su cuenta para realizarle los pagos',
    is_online: 'Si muestra este ícono su tienda está correctamente configurada y se encuentra en línea'
  },
  accounting_category: {
    buy_bills: 'Gastos en Compras',
    name: 'Categoría contable',
    bills: 'Gastos',
    others_incomes: 'Otros Ingresos',
    other_expenses: 'Otros Egresos',
    expenses_buy: 'Inversión en Compra',
    others_bills: 'Otros Gastos',
    sale_bills: 'Gastos en Compra/Ventas',
    expenses_credit: 'Egresos por pago de Cuentas a crédito',
    accounting_accounts: 'Cuentas contable',
    actives: 'Activos',
    passives: 'Pasivos',
    incomes: 'Ingresos',
    income_tax: 'Ingresos a Impuestos',
    income_credit: 'Ingresos por cobro de Cuentas a crédito',
    income_tax_credit: 'Ingresos a impuestos por cobro de Cuentas a crédito',
    income: 'Ingreso',
    expenses: 'Egresos',
    expense: 'Egreso',
    amount: 'Cantidad',
    code: 'Código',
    nature: 'Naturaleza',
    creditor: 'Acreedora',
    debtor: 'Deudora',
    debit: 'Debe(Débito)',
    assets: 'Haber(Crédito)',
    description_nature: {
      creditor: 'Por su naturaleza aumentan su saldo al registrar un crédito (haber, abono).\n' +
        'Por contra, se reduce su saldo al hacer un registro de débito (haber, abono) en ellas.',
      debtor: 'Por su naturaleza disminuye su saldo al registrar un crédito (haber, abono).\n' +
        'Por contra, se aumenta su saldo al hacer un registro de débito (haber, abono) en ellas.\n'
    }
  },
  fiscal: {
    receiver: 'Destinatario',
    sender: 'Remitente',
    rnc: 'RNC o ID',
    cant_digits: 'Cantidad de Dígitos',
    cant_at: 'Generar hasta',
    first_facture: 'Primera Factura',
    prefix: 'Prefijos de Factura'
  },
  plan: {
    name: 'Plan',
    general: 'General',
    period: 'Período',
    countries: 'Paises',
    is_active: 'Activo',
    use_coupon: 'Usar Cupón',
    coupon: 'Cupón',
    is_free: 'Free',
    signup_fee: 'Tarifa de registro',
    invoice_period: 'Período de facturación(Días)',
    test_day: 'Días de prueba',
    platform_payment_gateway: 'Pasarela de pago a la plataforma',
    partner_payment_gateway: 'Pasarela de pago al partner',
    partner_percent: 'Prociento del partner',
    partner_permanent: 'Cantidad permanente al partner',
    invoice_interval: 'Intervalo de factura',
    trial_period: 'Periodo de prueba',
    rest_time: 'Tiempo Restante',
    trial_interval: 'Intervalo de prueba',
    resettable_interval: 'Intervalo reiniciable',
    active_subscribers_limit: 'Límite de usuarios',
    prorate_day: 'Día de prorroga',
    subscriber: 'El suscriptor',
    benefited: 'Beneficiario',
    blessed: 'Beneficiados',
    referral_limit: 'Límite de Referidos',
    benefit_limit: 'Límite de Beneficiados',
    valid_until: 'Válido hasta',
    subscriptions: 'Subscripciones',
    month: 'Mes',
    trimester: 'Trimestral(3 Meses)',
    semester: 'Semestre(6 Meses)',
    annually: 'Anual(12 Meses)',
    years_2: '2 Años(24 Meses)',
    years_3: '3 Años(36 Meses)',
    years_4: '4 Años(48 Meses)',
    years_5: '5 Años(60 Meses)',
    week: 'Semana',
    time_limit: 'Establecer intervalo de tiempo para subscripciones',
    starts_at: 'A partir de',
    ends_at: 'Válido hasta',
    country: 'País/Paises',
    day: 'Día',
    type: {
      free: 'Gratis',
      basic: 'Básico',
      unicorn: 'Unicornio',
      personalized: 'Personalizado'
    },
    platform: {
      paypal: 'PayPal',
      stripe: 'Stripe',
      card: 'Targeta',
      bank_account: 'Cuenta bancaria'
    }
  },
  gateway: {
    stripe: 'Stripe',
    stripe_secret: 'STRIPE_SECRET',
    stripe_key: 'STRIPE_KEY',
    paypal: 'PayPal'
  },
  sex: {
    text: 'Sexo',
    f: 'Femenino',
    o: 'Otro',
    m: 'Masculino'
  },
  level_student: {
    text: 'Nivel de Estudios',
    primary: 'Primarios',
    preparatory: 'Preparatoria',
    university: 'Universidad',
    master: 'Master',
    doctor: 'Doctor',
    other: 'Otro'
  },
  type: {
    type_account: 'Tipo de Cuenta',
    merchant: 'Comerciante',
    partner: 'Partner',
    beneficiary: 'Beneficiario'
  },
  texts: {
    last_week: 'Última semana',
    suspend: 'Si suspende esta cuenta, automáticamente los usuarios no podrán acceder hasta que se habilitado nuevamente, ' +
      'o se cumpla el tiempo definido a continuación',
    sale_exchange_rate: ' A cobrar {0} {1}',
    home_text: 'Una solución para su negocio, para su empresa. ' +
      'Un sistema que crece cada día para complacer al cliente y hacer su vida mas cómoda',
    download_app: 'Descargue nuestra aplicación móvil para su dispositivo en las principales tiendas',
    contact_text: 'Nuestro principal objetivo es complacer al cliente haciendo su negocio más próspero con las opciones que le presentamos.' +
      'Estamos disponibles siempre para cada suegerencia duda o inquietud que usted tenga.',
    sale_characteristic: 'BILLING MONEY APP te permite hacer las ventas a tu gusto, y las que se realicen en la tienda online, serán monitoreadas constantemente. ' +
      'Si un empleado realiza un reembolso, te queda constancia porque BILLING MONEY APP almacena esa información',
    employer_characteristic: 'puedes adicionar tus empleados, y desde la misma plataforma ' +
      'saber cuál vendió qué producto. Te ofrecemos un gráfico de resumen de ' +
      'venta de empleado para que te nutras de la información',
    boxes_characteristic: 'BILLING MONEY APP permite realizar ventas por cajas para controlar mejor ' +
      'los ingresos. Permite abrir, cerrar y cuadrar una cajar de forma rápida y sencilla. Además de emitir una notificación cuando no se cierra y' +
      ' el cuadre no es exacto.',
    facture_characteristic: 'Con BILLING MONEY APP puedes emitir facturas en diferentes formatos. Permitimos que gestiones tus facturas fiscales desde la misma plataforma',
    choice_plan_text: 'Escoja su plan de preferencia',
    free_characteristics1: 'Puede usar todas las funcionalidades básicas de manera gratis por un intervalo de 7 días',
    free_characteristics2: 'La empresa se reserva el derecho de dar esta versión del sistema a determinadas personas que hagan un aporte notorio a la aplicación.',
    free_characteristics3: 'En de pendencia de los convenios y condiciones económicas en determinados países, se puede obtener esta versión para su uso por un tiempo determinado.',
    basic_characteristics: {
      no_limit: 'Solo una imagen a cada artículo, así como 3 imágenes para el banner en la Tienda Online, así como 300 Facturas/Mes, 50 Facturas Fiscales/Mes',
      limit: 'Solo una imagen a cada artículo, así como 3 imágenes para el banner en la Tienda Online,  facturando hasta {0} {1}/Mes así como 300 Facturas/Mes, 50 Facturas Fiscales/Mes'
    },
    basic_characteristics2: 'No permite adicionar mas roles de los establecidos(2)',
    basic_characteristics3: 'En de pendencia de los convenios y condiciones económicas, determinados países pueden obtener esta versión para su uso por un tiempo indeterminado.',
    unicorn_characteristics1: 'No existe límite  de registro en ninguna de las funcionalidades',
    unicorn_characteristics2: 'Gestión y emisión de facturas fiscales ,adicionar tantos roles como estime conveniente, gestión contable automática',
    unicorn_characteristics3: 'La empresa se reserva el derecho de dar esta versión del sistema a determinadas personas que hagan un aporte notorio a la aplicación.',
    personalized_characteristics: 'Podemos personalizar la aplicación,  diseñar y crear funciones de acuerdo a sus necesidades. Somos flexibles, y siempre estamos dispuestos  a buscar las soluciones que su empresa requiera para alcanzar el éxito financiero.',
    note_beneficiaries: 'La empresa está abierta para hacer planes personalizados, puede ponerse en contato con nuestro equipo y podemos establecer una mejor comunicación.',
    partner_text: 'Billing Money entrega a sus partners una comisión del pago que realice el cliente que este traiga si se subscribe a la versión unicornio',
    partner_characteristic: 'Puedes trabajar desde cualquier parte, en cualquier momento sin horarios ni obligaciones más que atraer clientes a la aplicación. ' +
      'Para ser Partner de nuestra aplicación, solo debes rellenar un formulario y esperar a ser aprobado, piensa que esto no requiere una inversión, ' +
      'sin embargo vas a ganar una comisión.  Nuestra empresa también ofrecerá cursos de preparación de marketing, y otros temas para que mantengas a tus afiliados' +
      ' dentro de nuestro sistema y puedas ganar cada mes tu parte.',
    partner_characteristic2: 'Buscamos responsabilidad, y creatividad para que sus beneficios crescan. Con solo invertir algo de tu tiempo y compartir en redes sociales, ' +
      'aumentarán tus ingresos exponencialmente, nosotros te ofrecemos la oportunidad, solo tienes que decidirte y ya formas parte del equipo. '
  },
  date_text: {
    seconds: 'Segundos',
    init: 'Inicio',
    finish: 'Final',
    minutes: 'Minutos',
    hours: 'Horas',
    last_24H: 'Últimas 24 horas',
    days: 'Días',
    months: 'Meses',
    years: 'Años'
  },
  categories: {
    1: 'comida y bebida > comida > sopas y caldos',
    2: 'comida y bebida > comida > frutos secos y semillas',
    3: 'comida y bebida > comida > snacks y patatas fritas',
    4: 'comida y bebida > comida > sustitutos de la carne',
    5: 'comida y bebida > comida > especias y condimentos',
    6: 'comida y bebida > comida > pasta y cereales',
    7: 'comida y bebida > comida > alimentos preparados y kits de comidas',
    8: 'comida y bebida > comida > golosinas y chocolate',
    9: 'comida y bebida > comida > salsas para mojar y untar',
    10: 'comida y bebida > comida > carne y marisco',
    11: 'comida y bebida > comida > pastelería y pan',
    12: 'comida y bebida > comida > salsas y aderezos',
    13: 'comida y bebida > comida > lácteos huevos y queso',
    14: 'comida y bebida > comida > comida congelada',
    15: 'comida y bebida > comida > ingredientes para hornear',
    16: 'comida y bebida > comida > frutas y verduras',
    17: 'comida y bebida > comida > desayuno y cereales',
    18: 'comida y bebida > comida',
    19: 'comida y bebida > bebidas > bebidas isotónicas y energéticas',
    20: 'comida y bebida > bebidas > zumo',
    21: 'comida y bebida > bebidas > bebidas en polvo',
    22: 'comida y bebida > bebidas > refresco',
    23: 'comida y bebida > bebidas > agua',
    24: 'comida y bebida > bebidas > café y té',
    25: 'comida y bebida > bebidas > bebidas alcohólicas',
    26: 'comida y bebida > bebidas > leche',
    27: 'comida y bebida > bebidas',
    28: 'comida y bebida',
    29: 'juguetes y juegos > juguetes para exteriores',
    30: 'juguetes y juegos > animales y juguetes de peluche',
    31: 'juguetes y juegos > juegos > juegos de rol',
    32: 'juguetes y juegos > juegos > ajedrez',
    33: 'juguetes y juegos > juegos > juegos de guerra y miniatura',
    34: 'juguetes y juegos > juegos > juego de dados',
    35: 'juguetes y juegos > juegos > juegos de fichas',
    36: 'juguetes y juegos > juegos > juegos de cartas',
    37: 'juguetes y juegos > juegos > juegos de destreza',
    38: 'juguetes y juegos > juegos > juegos de mesa',
    39: 'juguetes y juegos > juegos',
    40: 'juguetes y juegos > rompecabezas',
    41: 'juguetes y juegos > juguetes para montar',
    42: 'juguetes y juegos > juguetes de simulación',
    43: 'juguetes y juegos > vehículos de juguete',
    44: 'juguetes y juegos > juguetes a control remoto',
    45: 'juguetes y juegos > juguetes educativos',
    46: 'juguetes y juegos > muñecas y casas de muñecas',
    47: 'juguetes y juegos > juguetes de construcción',
    48: 'juguetes y juegos > figuras de acción',
    49: 'juguetes y juegos > juguetes electrónicos',
    50: 'juguetes y juegos',
    51: 'reformas > climatización del hogar',
    52: 'reformas > herramientas y productos de construcción',
    53: 'reformas > elementos de plomería',
    54: 'reformas > herramientas > escaleras y andamios',
    55: 'reformas > herramientas > herramientas de pintura',
    56: 'reformas > herramientas > herramientas eléctricas',
    57: 'reformas > herramientas > sensores y herramientas de medición',
    58: 'reformas > herramientas > herramientas neumáticas',
    59: 'reformas > herramientas > herramientas manuales',
    60: 'reformas > herramientas',
    61: 'reformas',
    62: 'artículos deportivos > equipamiento deportivo > hockey sobre hielo y sobre patines',
    63: 'artículos deportivos > equipamiento deportivo > rugby',
    64: 'artículos deportivos > equipamiento deportivo > tenis y deportes de raqueta',
    65: 'artículos deportivos > equipamiento deportivo > voleibol',
    66: 'artículos deportivos > equipamiento deportivo > golf',
    67: 'artículos deportivos > equipamiento deportivo > fútbol',
    68: 'artículos deportivos > equipamiento deportivo > fútbol americano',
    69: 'artículos deportivos > equipamiento deportivo > atletismo',
    70: 'artículos deportivos > equipamiento deportivo > críquet',
    71: 'artículos deportivos > equipamiento deportivo > hockey sobre césped y lacrosse',
    72: 'artículos deportivos > equipamiento deportivo > boxeo y artes marciales',
    73: 'artículos deportivos > equipamiento deportivo > danza y gimnasia',
    74: 'artículos deportivos > equipamiento deportivo > béisbol y sóftbol',
    75: 'artículos deportivos > equipamiento deportivo > baloncesto',
    76: 'artículos deportivos > equipamiento deportivo',
    77: 'artículos deportivos > ejercicio y fitness > yoga y pilates',
    78: 'artículos deportivos > ejercicio y fitness > equipos de cardio',
    79: 'artículos deportivos > ejercicio y fitness > equipamiento para leventamiento de pesas',
    80: 'artículos deportivos > ejercicio y fitness',
    81: 'artículos deportivos > juegos de interior',
    82: 'artículos deportivos > entretenimiento al aire libre > pesca',
    83: 'artículos deportivos > entretenimiento al aire libre > deportes acuáticos y navegación',
    84: 'artículos deportivos > entretenimiento al aire libre > monopatinaje',
    85: 'artículos deportivos > entretenimiento al aire libre > juegos al aire libre',
    86: 'artículos deportivos > entretenimiento al aire libre > ciclismo',
    87: 'artículos deportivos > entretenimiento al aire libre > deportes de invierno',
    88: 'artículos deportivos > entretenimiento al aire libre > escalada',
    89: 'artículos deportivos > entretenimiento al aire libre > patinaje en línea y sobre ruedas',
    90: 'artículos deportivos > entretenimiento al aire libre > acampada y senderismo',
    91: 'artículos deportivos > entretenimiento al aire libre',
    92: 'artículos deportivos',
    93: 'suministros para mascotas > transportadores y contención para mascotas',
    94: 'suministros para mascotas > productos de higiene para mascotas',
    95: 'suministros para mascotas > artículos para animales pequeños',
    96: 'suministros para mascotas > artículos para comida y agua de animales',
    97: 'suministros para mascotas > artículos para reptiles y anfibios',
    98: 'suministros para mascotas > collares arneses y correas para mascotas',
    99: 'suministros para mascotas > rampas y escalones para mascotas',
    100: 'suministros para mascotas > productos para pájaros',
    101: 'suministros para mascotas > cuidado y salud de las mascotas',
    102: 'suministros para mascotas > camas y soluciones para dormir para mascotas',
    103: 'suministros para mascotas > productos para peces',
    104: 'suministros para mascotas > productos para gatos',
    105: 'suministros para mascotas > productos para perros',
    106: 'suministros para mascotas',
    107: 'patio y jardín > equipos de riego',
    108: 'patio y jardín > estanques y fuentes ornamentales',
    109: 'patio y jardín > maquinaria de jardinería',
    110: 'patio y jardín > iluminación para exteriores',
    111: 'patio y jardín > barbacoas y cocina de exterior',
    112: 'patio y jardín > plantas semillas y bulbos',
    113: 'patio y jardín > muebles para exteriores',
    114: 'patio y jardín > piscinas y spas',
    115: 'patio y jardín > cuidado de plantas sustrato y accesorios',
    116: 'patio y jardín > decoración para el jardín',
    117: 'patio y jardín > material y herramientas de mano para jardín',
    118: 'patio y jardín',
    119: 'material de oficina > accesorios de escritura',
    120: 'material de oficina > paneles de presentación y exhibición',
    121: 'material de oficina > grapadoras y taladradoras',
    122: 'material de oficina > proyectores',
    123: 'material de oficina > cintas y adhesivos',
    124: 'material de oficina > archivado y almacenamiento',
    125: 'material de oficina > pernos y tornillos',
    126: 'material de oficina > etiquetas',
    127: 'material de oficina > calendarios y agendas',
    128: 'material de oficina > archivadores y accesorios',
    129: 'material de oficina > máquinas y material de oficina',
    130: 'material de oficina > artículos para correo',
    131: 'material de oficina > productos de papel',
    132: 'material de oficina',
    133: 'instrumentos musicales > material de audio profesional',
    134: 'instrumentos musicales > accesorios para música',
    135: 'instrumentos musicales > guitarras y bajos',
    136: 'instrumentos musicales > instrumentos de viento de latón',
    137: 'instrumentos musicales > baterías e instrumentos de percusión',
    138: 'instrumentos musicales > pianos e instrumentos de teclado',
    139: 'instrumentos musicales > instrumentos de viento-madera',
    140: 'instrumentos musicales > instrumentos de cuerda',
    141: 'instrumentos musicales',
    142: 'productos multimedia > periódicos',
    143: 'productos multimedia > revistas y catálogos',
    144: 'productos multimedia > libros',
    145: 'productos multimedia > películas y programas de televisión',
    146: 'productos multimedia > música',
    147: 'productos multimedia',
    148: 'viajes y equipaje > fundas para la ropa',
    149: 'viajes y equipaje > accesorios para viajes',
    150: 'viajes y equipaje > carteras y billeteras > carteras',
    151: 'viajes y equipaje > carteras y billeteras > carteras',
    152: 'viajes y equipaje > carteras y billeteras > bolsos cruzados',
    153: 'viajes y equipaje > carteras y billeteras > bolsas',
    154: 'viajes y equipaje > carteras y billeteras > bandoleras',
    155: 'viajes y equipaje > carteras y billeteras > bolsos clutch',
    156: 'viajes y equipaje > carteras y billeteras > riñoneras',
    157: 'viajes y equipaje > carteras y billeteras',
    158: 'viajes y equipaje > maletas',
    159: 'viajes y equipaje > bolsos de viaje y para el gimnasio',
    160: 'viajes y equipaje > conjuntos de equipaje',
    161: 'viajes y equipaje > maletines y bolsos de ordenador portátil',
    162: 'viajes y equipaje > mochilas',
    163: 'viajes y equipaje',
    164: 'arte y manualidades > artículos para manualidades',
    165: 'arte y manualidades > costura',
    166: 'arte y manualidades > costura e hilado',
    167: 'arte y manualidades > diseño de abalorios y joyas',
    168: 'arte y manualidades > artículos de arte',
    169: 'arte y manualidades',
    170: 'repuestos y accesorios de automóviles > piezas y accesorios de remolques',
    171: 'repuestos y accesorios de automóviles > piezas y accesorios de vehículos todoterreno',
    172: 'repuestos y accesorios de automóviles > piezas y accesorios para casas rodantes y caravanas',
    173: 'repuestos y accesorios de automóviles > repuestos y accesorios de automóviles',
    174: 'repuestos y accesorios de automóviles > piezas y accesorios para barcos',
    175: 'repuestos y accesorios de automóviles > accesorios y piezas para motocicletas',
    176: 'repuestos y accesorios de automóviles > herramientas para automóviles',
    177: 'repuestos y accesorios de automóviles',
    178: 'antigüedades y artículos coleccionables > recuerdos deportivos coleccionables',
    179: 'antigüedades y artículos coleccionables > artículos para el hogar coleccionables',
    180: 'antigüedades y artículos coleccionables > artículos electrónicos coleccionables',
    181: 'antigüedades y artículos coleccionables > monedas y billetes coleccionables',
    182: 'antigüedades y artículos coleccionables > sellos coleccionables',
    183: 'antigüedades y artículos coleccionables > muebles coleccionables',
    184: 'antigüedades y artículos coleccionables > electrodomésticos coleccionables',
    185: 'antigüedades y artículos coleccionables > juguetes coleccionables',
    186: 'antigüedades y artículos coleccionables',
    187: 'productos para bebés > alimentación de bebés',
    188: 'productos para bebés > artículos para el transporte de bebés > mochilas portabebés',
    189: 'productos para bebés > artículos para el transporte de bebés > cochecitos y accesorios',
    190: 'productos para bebés > artículos para el transporte de bebés > sillitas para coches y accesorios',
    191: 'productos para bebés > artículos para el transporte de bebés',
    192: 'productos para bebés > seguridad y salud del bebé',
    193: 'productos para bebés > productos de baño para bebés',
    194: 'productos para bebés > juguetes > centros de actividades y entretenimiento',
    195: 'productos para bebés > juguetes > juguetes para bebés',
    196: 'productos para bebés > juguetes',
    197: 'productos para bebés > artículos para el cambio de pañales y el control de esfínteres',
    198: 'productos para bebés > cuarto de bebé > sábanas para cuna',
    199: 'productos para bebés > cuarto de bebé > decoración infantil',
    200: 'productos para bebés > cuarto de bebé > muebles para cuartos de bebé',
    201: 'productos para bebés > cuarto de bebé',
    202: 'productos para bebés',
    203: 'electrónica > reproductores de audio y vídeo portátiles',
    204: 'electrónica > sonido y vídeo para el hogar',
    205: 'electrónica > seguridad y automatización del hogar',
    206: 'electrónica > móviles y relojes inteligentes > teléfonos móviles',
    207: 'electrónica > móviles y relojes inteligentes > accesorios para móviles relojes inteligentes',
    208: 'electrónica > móviles y relojes inteligentes > relojes inteligentes',
    209: 'electrónica > móviles y relojes inteligentes',
    210: 'electrónica > proyectores',
    211: 'electrónica > cámaras > prismáticos y telescopios',
    212: 'electrónica > cámaras > cámaras analógicas',
    213: 'electrónica > cámaras > accesorios para cámaras',
    214: 'electrónica > cámaras > drones con cámara',
    215: 'electrónica > cámaras > cámaras digitales',
    216: 'electrónica > cámaras > videocámaras',
    217: 'electrónica > cámaras',
    218: 'electrónica > televisores y monitores > televisores',
    219: 'electrónica > televisores y monitores > monitores de ordenador',
    220: 'electrónica > televisores y monitores',
    221: 'electrónica > software',
    222: 'electrónica > impresoras y escáneres',
    223: 'electrónica > consolas de videojuegos y videojuegos > accesorios para videojuegos',
    224: 'electrónica > consolas de videojuegos y videojuegos > videoconsolas',
    225: 'electrónica > consolas de videojuegos y videojuegos > videojuegos',
    226: 'electrónica > consolas de videojuegos y videojuegos',
    227: 'electrónica > ordenadores y tabletas > tabletas y lectores de libros electrónicos',
    228: 'electrónica > ordenadores y tabletas > redes y servidores',
    229: 'electrónica > ordenadores y tabletas > componentes y hardware para ordenadores',
    230: 'electrónica > ordenadores y tabletas > portátiles',
    231: 'electrónica > ordenadores y tabletas > ordenadores de sobremesa',
    232: 'electrónica > ordenadores y tabletas',
    233: 'electrónica > accesorios > periféricos para ordenadores',
    234: 'electrónica > accesorios > pilas y fuentes de alimentación',
    235: 'electrónica > accesorios > dispositivos de almacenamiento multimedia vacíos',
    236: 'electrónica > accesorios > cables cargadores y adaptadores',
    237: 'electrónica > accesorios',
    238: 'electrónica',
    239: 'salud y belleza > salud > cuidado bucal',
    240: 'salud y belleza > salud > primeros auxilios',
    241: 'salud y belleza > salud > cuidado íntimo para mujer',
    242: 'salud y belleza > salud > cuidado de la vista',
    243: 'salud y belleza > salud > masajes',
    244: 'salud y belleza > salud > afeitado y depilación',
    245: 'salud y belleza > salud',
    246: 'salud y belleza > belleza > cuidado de la piel > loción y aceite de bronceado',
    247: 'salud y belleza > belleza > cuidado de la piel > tratamientos y bálsamos para labios',
    248: 'salud y belleza > belleza > cuidado de la piel > máscaras y exfoliantes',
    249: 'salud y belleza > belleza > cuidado de la piel > exfoliantes',
    250: 'salud y belleza > belleza > cuidado de la piel > loción y crema hidratante para el rostro',
    251: 'salud y belleza > belleza > cuidado de la piel > tiras de limpieza de poros',
    252: 'salud y belleza > belleza > cuidado de la piel > tratamientos de noche',
    253: 'salud y belleza > belleza > cuidado de la piel > protectores solares',
    254: 'salud y belleza > belleza > cuidado de la piel > sistemas y cepillos para la limpieza de la piel',
    255: 'salud y belleza > belleza > cuidado de la piel > tratamientos para el acné y las imperfecciones de la piel',
    256: 'salud y belleza > belleza > cuidado de la piel > limpiadores y tónicos',
    257: 'salud y belleza > belleza > cuidado de la piel',
    258: 'salud y belleza > belleza > manicura > piedras pómez',
    259: 'salud y belleza > belleza > manicura > esmalte y decoración de uñas',
    260: 'salud y belleza > belleza > manicura > instrumentos para manicura',
    261: 'salud y belleza > belleza > manicura > uñas postizas y accesorios',
    262: 'salud y belleza > belleza > manicura',
    263: 'salud y belleza > belleza > maquillaje > materiales y accesorios de maquillaje',
    264: 'salud y belleza > belleza > maquillaje > maquillaje de labios > voluminizadores labiales',
    265: 'salud y belleza > belleza > maquillaje > maquillaje de labios > brillo labial',
    266: 'salud y belleza > belleza > maquillaje > maquillaje de labios > delineadores de labios',
    267: 'salud y belleza > belleza > maquillaje > maquillaje de labios > labiales líquidos',
    268: 'salud y belleza > belleza > maquillaje > maquillaje de labios > pintalabios',
    269: 'salud y belleza > belleza > maquillaje > maquillaje de labios',
    270: 'salud y belleza > belleza > maquillaje > kits y conjuntos de maquillaje',
    271: 'salud y belleza > belleza > maquillaje > maquillaje facial > bases de maquillaje',
    272: 'salud y belleza > belleza > maquillaje > maquillaje facial > polvos faciales',
    273: 'salud y belleza > belleza > maquillaje > maquillaje facial > coloretes',
    274: 'salud y belleza > belleza > maquillaje > maquillaje facial > bronceadores e iluminadores',
    275: 'salud y belleza > belleza > maquillaje > maquillaje facial > prebases para rostro',
    276: 'salud y belleza > belleza > maquillaje > maquillaje facial > correctores',
    277: 'salud y belleza > belleza > maquillaje > maquillaje facial > cremas bb cc y del alfabeto',
    278: 'salud y belleza > belleza > maquillaje > maquillaje facial',
    279: 'salud y belleza > belleza > maquillaje > maquillaje de ojos > productos para cejas',
    280: 'salud y belleza > belleza > maquillaje > maquillaje de ojos > delineadores de ojos',
    281: 'salud y belleza > belleza > maquillaje > maquillaje de ojos > máscara de pestañas',
    282: 'salud y belleza > belleza > maquillaje > maquillaje de ojos > sombras de ojos',
    283: 'salud y belleza > belleza > maquillaje > maquillaje de ojos > pestañas postizas y adhesivos',
    284: 'salud y belleza > belleza > maquillaje > maquillaje de ojos > prebases para ojos',
    285: 'salud y belleza > belleza > maquillaje > maquillaje de ojos',
    286: 'salud y belleza > belleza > maquillaje',
    287: 'salud y belleza > belleza > cuidado y peinado del cabello > champús y acondicionadores',
    288: 'salud y belleza > belleza > cuidado y peinado del cabello > rodillos y rulos',
    289: 'salud y belleza > belleza > cuidado y peinado del cabello > planchas y rizadores de pelo',
    290: 'salud y belleza > belleza > cuidado y peinado del cabello > productos de peluquería',
    291: 'salud y belleza > belleza > cuidado y peinado del cabello > protectores y aceites para el cabello',
    292: 'salud y belleza > belleza > cuidado y peinado del cabello > coleteros y accesorios para el cabello',
    293: 'salud y belleza > belleza > cuidado y peinado del cabello > tijeras de peluquería',
    294: 'salud y belleza > belleza > cuidado y peinado del cabello > secadores de cabello',
    295: 'salud y belleza > belleza > cuidado y peinado del cabello > extensiones capilares y pelucas',
    296: 'salud y belleza > belleza > cuidado y peinado del cabello > cepillos y peines',
    297: 'salud y belleza > belleza > cuidado y peinado del cabello > coloración y tintes para el cabello',
    298: 'salud y belleza > belleza > cuidado y peinado del cabello',
    299: 'salud y belleza > belleza > fragancias > fragancias para hombre',
    300: 'salud y belleza > belleza > fragancias > fragancias para mujer',
    301: 'salud y belleza > belleza > fragancias > colonias infantiles',
    302: 'salud y belleza > belleza > fragancias',
    303: 'salud y belleza > belleza > baño y cuerpo > baños de burbujas',
    304: 'salud y belleza > belleza > baño y cuerpo > sales de baño',
    305: 'salud y belleza > belleza > baño y cuerpo > lociones y cremas hidratantes para el cuerpo',
    306: 'salud y belleza > belleza > baño y cuerpo > jabones y geles para el cuerpo',
    307: 'salud y belleza > belleza > baño y cuerpo > esponjas y cepillos',
    308: 'salud y belleza > belleza > baño y cuerpo > exfoliantes',
    309: 'salud y belleza > belleza > baño y cuerpo > aceites para baño',
    310: 'salud y belleza > belleza > baño y cuerpo > bombas de baño',
    311: 'salud y belleza > belleza > baño y cuerpo',
    312: 'salud y belleza > belleza',
    313: 'salud y belleza',
    314: 'joyas y relojes > relojes > accesorios para relojes',
    315: 'joyas y relojes > relojes > relojes infantiles',
    316: 'joyas y relojes > relojes > relojes para hombre',
    317: 'joyas y relojes > relojes > relojes para mujer',
    318: 'joyas y relojes > relojes',
    319: 'joyas y relojes > joyería > anillos',
    320: 'joyas y relojes > joyería > collares',
    321: 'joyas y relojes > joyería > conjuntos de joyas',
    322: 'joyas y relojes > joyería > prendedores de solapa y broches',
    323: 'joyas y relojes > joyería > pulsera de tobillo',
    324: 'joyas y relojes > joyería > dijes y colgantes',
    325: 'joyas y relojes > joyería > pendientes',
    326: 'joyas y relojes > joyería > joyas para el cuerpo',
    327: 'joyas y relojes > joyería > pulseras',
    328: 'joyas y relojes > joyería',
    329: 'joyas y relojes',
    330: 'casa > productos de limpieza > productos y químicos de limpieza',
    331: 'casa > productos de limpieza > aspiradoras y limpiasuelos',
    332: 'casa > productos de limpieza > productos para lavado de ropa',
    333: 'casa > productos de limpieza',
    334: 'casa > grandes electrodomésticos > placas de cocina y hornos',
    335: 'casa > grandes electrodomésticos > neveras y congeladores',
    336: 'casa > grandes electrodomésticos > lavadoras y secadoras',
    337: 'casa > grandes electrodomésticos > hornos microondas',
    338: 'casa > grandes electrodomésticos > lavavajillas',
    339: 'casa > grandes electrodomésticos',
    340: 'casa > ropa de cama > edredones',
    341: 'casa > ropa de cama > colchoncillos y protectores de colchón',
    342: 'casa > ropa de cama > fundas de edredón',
    343: 'casa > ropa de cama > mantas y cobertores',
    344: 'casa > ropa de cama > mantas colchas y cubrecamas',
    345: 'casa > ropa de cama > cubrecanapés',
    346: 'casa > ropa de cama > sábanas y fundas de almohadas para camas',
    347: 'casa > ropa de cama > conjuntos y juegos de cama',
    348: 'casa > ropa de cama > almohadas y posicionadores de cama',
    349: 'casa > ropa de cama',
    350: 'casa > muebles > mobiliario de salón',
    351: 'casa > muebles > muebles de oficina',
    352: 'casa > muebles > muebles para comedor',
    353: 'casa > muebles > muebles para dormitorio',
    354: 'casa > muebles',
    355: 'casa > artículos para el hogar > organización y almacenamiento',
    356: 'casa > artículos para el hogar > lámparas e iluminación',
    357: 'casa > artículos para el hogar > cocina y comedor > pequeños electrodomésticos',
    358: 'casa > artículos para el hogar > cocina y comedor > cubiertos',
    359: 'casa > artículos para el hogar > cocina y comedor > vajilla',
    360: 'casa > artículos para el hogar > cocina y comedor > utensilios y herramientas de cocina',
    361: 'casa > artículos para el hogar > cocina y comedor > utensilios para hornear',
    362: 'casa > artículos para el hogar > cocina y comedor > cristalería',
    363: 'casa > artículos para el hogar > cocina y comedor > utensilios de cocina',
    364: 'casa > artículos para el hogar > cocina y comedor > utensilios para bares',
    365: 'casa > artículos para el hogar > cocina y comedor',
    366: 'casa > artículos para el hogar > baño > toallas y ropa de baño',
    367: 'casa > artículos para el hogar > baño > accesorios para el baño',
    368: 'casa > artículos para el hogar > baño',
    369: 'casa > artículos para el hogar > decoración del hogar > decoración navideña y estacional',
    370: 'casa > artículos para el hogar > decoración del hogar > alfombras y moquetas',
    371: 'casa > artículos para el hogar > decoración del hogar > tratamientos y herrajes para ventanas',
    372: 'casa > artículos para el hogar > decoración del hogar > fragancias para el hogar',
    373: 'casa > artículos para el hogar > decoración del hogar > relojes',
    374: 'casa > artículos para el hogar > decoración del hogar > accesorios decorativos',
    375: 'casa > artículos para el hogar > decoración del hogar',
    376: 'casa > artículos para el hogar',
    377: 'casa',
    378: 'ropa y accesorios > complementos de vestir > accesorios para mujer > cinturones',
    379: 'ropa y accesorios > complementos de vestir > accesorios para mujer > guantes y mitones',
    380: 'ropa y accesorios > complementos de vestir > accesorios para mujer > gafas de sol',
    381: 'ropa y accesorios > complementos de vestir > accesorios para mujer > bufandas y chales',
    382: 'ropa y accesorios > complementos de vestir > accesorios para mujer > sombreros',
    383: 'ropa y accesorios > complementos de vestir > accesorios para mujer',
    384: 'ropa y accesorios > complementos de vestir > accesorios para hombre > corbatas',
    385: 'ropa y accesorios > complementos de vestir > accesorios para hombre > cinturones',
    386: 'ropa y accesorios > complementos de vestir > accesorios para hombre > sombreros',
    387: 'ropa y accesorios > complementos de vestir > accesorios para hombre > tirantes',
    388: 'ropa y accesorios > complementos de vestir > accesorios para hombre > gafas de sol',
    389: 'ropa y accesorios > complementos de vestir > accesorios para hombre > guantes y mitones',
    390: 'ropa y accesorios > complementos de vestir > accesorios para hombre > bufandas',
    391: 'ropa y accesorios > complementos de vestir > accesorios para hombre',
    392: 'ropa y accesorios > complementos de vestir > accesorios para niña > sombreros',
    393: 'ropa y accesorios > complementos de vestir > accesorios para niña > guantes y mitones',
    394: 'ropa y accesorios > complementos de vestir > accesorios para niña > cinturones',
    395: 'ropa y accesorios > complementos de vestir > accesorios para niña > bufandas',
    396: 'ropa y accesorios > complementos de vestir > accesorios para niña > gafas de sol',
    397: 'ropa y accesorios > complementos de vestir > accesorios para niña',
    398: 'ropa y accesorios > complementos de vestir > accesorios para niño > corbatas',
    399: 'ropa y accesorios > complementos de vestir > accesorios para niño > tirantes',
    400: 'ropa y accesorios > complementos de vestir > accesorios para niño > carteras',
    401: 'ropa y accesorios > complementos de vestir > accesorios para niño > guantes y mitones',
    402: 'ropa y accesorios > complementos de vestir > accesorios para niño > sombreros',
    403: 'ropa y accesorios > complementos de vestir > accesorios para niño > bufandas',
    404: 'ropa y accesorios > complementos de vestir > accesorios para niño > cinturones',
    405: 'ropa y accesorios > complementos de vestir > accesorios para niño > gafas de sol',
    406: 'ropa y accesorios > complementos de vestir > accesorios para niño',
    407: 'ropa y accesorios > complementos de vestir',
    408: 'ropa y accesorios > zapatos y calzado > calzado para mujer > botas',
    409: 'ropa y accesorios > zapatos y calzado > calzado para mujer > zapatos de tacón',
    410: 'ropa y accesorios > zapatos y calzado > calzado para mujer > calzado deportivo de moda',
    411: 'ropa y accesorios > zapatos y calzado > calzado para mujer > pantuflas',
    412: 'ropa y accesorios > zapatos y calzado > calzado para mujer > sandalias',
    413: 'ropa y accesorios > zapatos y calzado > calzado para mujer > zapatos bajos',
    414: 'ropa y accesorios > zapatos y calzado > calzado para mujer > calzado deportivo',
    415: 'ropa y accesorios > zapatos y calzado > calzado para mujer',
    416: 'ropa y accesorios > zapatos y calzado > calzado para hombre > sandalias',
    417: 'ropa y accesorios > zapatos y calzado > calzado para hombre > calzado informal y de vestir',
    418: 'ropa y accesorios > zapatos y calzado > calzado para hombre > botas',
    419: 'ropa y accesorios > zapatos y calzado > calzado para hombre > calzado deportivo',
    420: 'ropa y accesorios > zapatos y calzado > calzado para hombre > pantuflas',
    421: 'ropa y accesorios > zapatos y calzado > calzado para hombre > calzado deportivo de moda',
    422: 'ropa y accesorios > zapatos y calzado > calzado para hombre',
    423: 'ropa y accesorios > zapatos y calzado > calzado para niños > calzado para niños',
    424: 'ropa y accesorios > zapatos y calzado > calzado para bebés > calzado de bebé para niño',
    425: 'ropa y accesorios > zapatos y calzado > calzado para niños > calzado para niñas',
    426: 'ropa y accesorios > zapatos y calzado > calzado para bebés > calzado de bebé para niña',
    427: 'ropa y accesorios > zapatos y calzado > calzado para niños',
    428: 'ropa y accesorios > zapatos y calzado > calzado para bebés',
    429: 'ropa y accesorios > zapatos y calzado',
    430: 'ropa y accesorios > ropa > ropa para mujer > tops y camisetas',
    431: 'ropa y accesorios > ropa > ropa para mujer > medias calcetines y calzas > calcetines',
    432: 'ropa y accesorios > ropa > ropa para mujer > medias calcetines y calzas > calentadores',
    433: 'ropa y accesorios > ropa > ropa para mujer > medias calcetines y calzas > pantis y medias',
    434: 'ropa y accesorios > ropa > ropa para mujer > medias calcetines y calzas > calcetines y medias largas',
    435: 'ropa y accesorios > ropa > ropa para mujer > medias calcetines y calzas',
    436: 'ropa y accesorios > ropa > ropa para mujer > sudaderas con y sin capucha',
    437: 'ropa y accesorios > ropa > ropa para mujer > ropa de baño',
    438: 'ropa y accesorios > ropa > ropa para mujer > trajes y blazers',
    439: 'ropa y accesorios > ropa > ropa para mujer > pantalones cortos',
    440: 'ropa y accesorios > ropa > ropa para mujer > jerséis',
    441: 'ropa y accesorios > ropa > ropa para mujer > partes de abajo > pantalones',
    442: 'ropa y accesorios > ropa > ropa para mujer > partes de abajo > faldas',
    443: 'ropa y accesorios > ropa > ropa para mujer > vestidos',
    444: 'ropa y accesorios > ropa > ropa para mujer > lencería y pijamas > ropa para dormir',
    445: 'ropa y accesorios > ropa > ropa para mujer > lencería y pijamas > ropa interior',
    446: 'ropa y accesorios > ropa > ropa para mujer > lencería y pijamas > calzoncillos',
    447: 'ropa y accesorios > ropa > ropa para mujer > lencería y pijamas > sujetadores',
    448: 'ropa y accesorios > ropa > ropa para mujer > lencería y pijamas > prendas moldeadoras',
    449: 'ropa y accesorios > ropa > ropa para mujer > lencería y pijamas',
    450: 'ropa y accesorios > ropa > ropa para mujer > vaqueros',
    451: 'ropa y accesorios > ropa > ropa para mujer > monos',
    452: 'ropa y accesorios > ropa > ropa para mujer > mallas',
    453: 'ropa y accesorios > ropa > ropa para mujer > ropa de deporte',
    454: 'ropa y accesorios > ropa > ropa para mujer > abrigos y chaquetas',
    455: 'ropa y accesorios > ropa > ropa para mujer',
    456: 'ropa y accesorios > ropa > ropa para hombre > ropa interior',
    457: 'ropa y accesorios > ropa > ropa para hombre > partes de abajo > ropa de baño',
    458: 'ropa y accesorios > ropa > ropa para hombre > partes de arriba > sudaderas con y sin capucha',
    459: 'ropa y accesorios > ropa > ropa para hombre > camisetas',
    460: 'ropa y accesorios > ropa > ropa para hombre > ropa para dormir',
    461: 'ropa y accesorios > ropa > ropa para hombre > trajes',
    462: 'ropa y accesorios > ropa > ropa para hombre > camisas de vestir',
    463: 'ropa y accesorios > ropa > ropa para hombre > vaqueros',
    464: 'ropa y accesorios > ropa > ropa para hombre > calcetines',
    465: 'ropa y accesorios > ropa > ropa para hombre > partes de abajo > pantalones cortos',
    466: 'ropa y accesorios > ropa > ropa para hombre > partes de arriba > jerséis',
    467: 'ropa y accesorios > ropa > ropa para hombre > pantalones',
    468: 'ropa y accesorios > ropa > ropa para hombre > ropa de deporte',
    469: 'ropa y accesorios > ropa > ropa para hombre > americanas y abrigos informales',
    470: 'ropa y accesorios > ropa > ropa para hombre > abrigos y chaquetas',
    471: 'ropa y accesorios > ropa > ropa para hombre > camisas informales',
    472: 'ropa y accesorios > ropa > ropa para hombre',
    473: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > ropa de baño',
    474: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > ropa interior',
    475: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > tops y camisetas',
    476: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > sudaderas con y sin capucha',
    477: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > faldas',
    478: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > calcetines y medias',
    479: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > pantalones cortos',
    480: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > ropa para dormir',
    481: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > pantalones',
    482: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > jerséis',
    483: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > ropa de abrigo',
    484: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > vaqueros',
    485: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > monos',
    486: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > mallas',
    487: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > conjuntos de ropa',
    488: 'ropa y accesorios > ropa > ropa de niños > ropa para niña > vestidos',
    489: 'ropa y accesorios > ropa > ropa de niños > ropa para niña',
    490: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > calcetines',
    491: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > trajes',
    492: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > ropa interior',
    493: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > sudaderas con y sin capucha',
    494: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > jerséis',
    495: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > ropa de baño',
    496: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > tops y camisetas',
    497: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > ropa para dormir',
    498: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > conjuntos de ropa',
    499: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > pantalones',
    500: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > pantalones cortos',
    501: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > vaqueros',
    502: 'ropa y accesorios > ropa > ropa de niños > ropa para niño > ropa de abrigo',
    503: 'ropa y accesorios > ropa > ropa de niños > ropa para niño',
    504: 'ropa y accesorios > ropa > ropa de niños',
    505: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > partes de arriba',
    506: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > ropa de baño',
    507: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > vestidos',
    508: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > ropa para dormir',
    509: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > ropa de abrigo',
    510: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > partes de abajo',
    511: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > jerséis',
    512: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > conjuntos de ropa',
    513: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > calcetines',
    514: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña > bodis',
    515: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niña',
    516: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > trajes',
    517: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > ropa de baño',
    518: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > jerséis',
    519: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > calcetines',
    520: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > partes de arriba',
    521: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > bodis',
    522: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > ropa para dormir',
    523: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > partes de abajo',
    524: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > ropa de abrigo',
    525: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño > conjuntos de ropa',
    526: 'ropa y accesorios > ropa > ropa para bebés > ropa de bebé para niño',
    527: 'ropa y accesorios > ropa > ropa para bebés',
    528: 'ropa y accesorios > ropa',
    529: 'ropa y accesorios',
    530: 'otro',
    535: 'ropa y accesorios > ropa > ropa para hombre > partes de arriba',
    536: 'ropa y accesorios > ropa > ropa para hombre > partes de arriba > activewear tops',
    537: 'ropa y accesorios > ropa > ropa para hombre > partes de arriba > sleepwear tops',
    538: 'ropa y accesorios > ropa > ropa para hombre > partes de abajo',
    539: 'ropa y accesorios > ropa > ropa para hombre > partes de abajo > activewear bottoms',
    540: 'ropa y accesorios > ropa > ropa para hombre > partes de abajo > sleepwear bottoms',
    541: 'ropa y accesorios > ropa > ropa para mujer > partes de arriba',
    542: 'ropa y accesorios > ropa > ropa para mujer > partes de arriba > activewear tops',
    543: 'ropa y accesorios > ropa > ropa para mujer > partes de arriba > swimwear tops',
    544: 'ropa y accesorios > ropa > ropa para mujer > partes de abajo',
    545: 'ropa y accesorios > ropa > ropa para mujer > partes de abajo > swimwear bottoms',
    546: 'ropa y accesorios > ropa > ropa para hombre > ropa de baño'
  },
  functions: {
    text: 'Funciones',
    follow: 'Síguenos',
    say: 'Una buena comunicación nos permite ayudarle a construir un mejor negocio',
    politics: 'Políticas de Privacidad',
    suport: 'Brindamos servicio de Soporte personalizado y guiado por nuestros especialistas, estamos a su disposición las 24 horas, solo para que su negocio siempre esté en ascenso.' +
      ' Nos importa cada comentario idea o sugerencia, usted es importante para nosotros, y estamos para escucharle.',
    functions: {
      facture_pos: {
        title: 'Emisión de Facturas Simples y de Facturas Fiscales.',
        text: 'Ofrecemos facturación en distintos formatos y tamaños. Con  nuestro sistema puedes crear Factura Simplificada o Ticket, Factura Fiscal y Factura Proforma.'
      },
      pos: {
        title: 'Punto  de Venta POS',
        text: 'Con Billing Money App usted puede utilizar las funciones en distintos dispositivos móviles y de escritorios, como Celulares, Tablet, Computadora, Terminal, optimizando de manera sencilla, eficiente y rápida, todas las operaciones de administración gestión y control de su negocio.'
      },
      cloude_store: {
        title: 'Almacenamiento en la nube',
        text: 'Le ofrecemos acceder a su negocio desde cualquier parte del mundo, utilizamos servidores externos, con enorme capacidad de almacenamiento de datos y archivos, excelente sistema de seguridad, recuperación de datos, y rápido procesamiento de datos.'
      },
      shop: {
        title: 'Tiendas Online',
        text: 'La plataforma BILLING MONEY APP te provee de una tienda online gratis por cada tienda registrada en el sistema, para que puedas vender tus productos en todo el mundo.'
      },
      counted: {
        title: 'Gestión Contable',
        text: 'Le mantenemos al tanto de la gestión contable a través de notificaciones y mensajes, aportándote gráficos y resúmenes.'
      },
      articles: {
        title: 'Gestión de Inventario',
        text: 'Usted puede gestionar su inventario, desde la manera más sencilla hasta lo más complejo, pudiendo registrar tanto servicios como artículos.'
      },
      functions: {
        title: 'Más de 120 funciones',
        text: 'Nuestra aplicación tiene más de 120 funciones para que usted pueda tener control de gestión y administrativa de su empresa o actividad comercial.'
      },
      multi_user: {
        title: 'Múltiples Usuarios',
        text: 'Con Billing Money App usted puede gestionar varios empleados, y tener un control sobre su negocio y comportamiento de los ingresos.'
      },
      facture: {
        title: 'Emisión de Facturas Simples y de Facturas Fiscales',
        text: 'Nuestro sistema te provee de una manera fácil y rápida para emitir una factura, tanto en forma de Ticket como en Formato de Carta. ' +
          'Si configura la sección de Factura Fiscal, igual podrá emitirlas para su cliente.'
      },
      sales: {
        title: 'Gestión de Ventas Pos y en Línea',
        text: 'Haciendo uso de una flexible interfaz y amplias funciones usted gestiona sus ventas POS de forma autmática y segura.'
      },
      buy: {
        title: 'Gestión de Compras',
        text: 'Usted puede actualizar su inventario de productos, reflejando sus compras, de esta manera se le calcularán los márgenes, asi como los parámetros estadísticos, para futuros diagnósticos que les permitirán disminuir los riesgos y aumentar las posibilidades de éxitos'
      },
      report: {
        title: 'Emisión de Reportes y Notificaciones',
        text: 'Una vez comenzado en el proceso de negocio, usted tiene funciones para emitirle resumenes y reportes del comportamiento de las ventas,' +
          ' para un mayor entendimiento del mismo'
      }
    },
    about_as: {
      title: 'Acerca de nosotros',
      text: 'Somos un equipo innovador, que tiene como principal objetivo satisfacer al cliente, ofreciéndole un producto de alta calidad, y un grado de compromiso único. Tenemos una solición creativa, intuitiva, fácil de usar y un precio acorde a su presupuesto.\n' +
        'Billing Money App es una potente y flexible aplicación que está enfocada a la facturación en línea, con más de 120 funciones que hacen su negocio más productivo.\n' +
        'Nos enfocamos en proporcionar una herramienta que haga crecer su negocio y le permita planificar sus inversiones sobre la base del comportamiendo histórico. \n' +
        'Automatizamos la mayoría de sus procesos para que con solo un click ahorre tiempo, y pueda invertirlo en otras funciones. \n' +
        'Con nosotros usted obtiene seguridad, confianza calidad, compromiso y responsabilidad.\n' +
        '\n' +
        'Sistema fácil de usar, con una interfaz sencilla y amigable\n' +
        'Diseño minimalista, enfocado en los usuarios sin práctica o poca relación con la tegnología\n' +
        'Flexibilidad en todas las funciones, y control total de la información por parte del director o manager de la empresa registrada\n' +
        'Soporte inmediato y personalizado\n' +
        'Seguridad y compartimentación de la información\n'
    },
    characteristics: {
      why_as: 'Qué ofrecemos',
      answer: 'Llevamos tiempo con un profundo estudio de mercado, para dar una solución que permite resolver varias problemáticas del mismo.' +
        ' La experiencia adquirida durante ese tiempo, permitió que se creara un sistema de facturación en línea, con las mejores características y funcionalidades, ' +
        'enfocado en la reducción de tiempo y la automatización de los servicios y/o procesos, para que mejore sus beneficios y el rendimiendo del negocio. ' +
        'Hemos logrado una amplia gama de funciones para que usted, trabaje más cómodo.'
    },
    about: {
      one: 'Sistema fácil de usar, con una interfaz sencilla y amigable',
      two: 'Diseño minimalista, enfocado en los usuarios sin práctica o poca relación con la tegnología',
      three: 'Flexibilidad en todas las funciones, y control total de la información por parte del director o manager de la empresa registrada',
      four: 'Soporte inmediato y personalizado',
      five: 'Seguridad y compartimentación de la información'
    },
    descriptions: {
      manager_article: 'El sitema permite una amplia y abarcadora gestión de inventario de artículos y servicios. ' +
        'Podrás gestionar tanto diferentes precios y costos en tiendas, así como variantes del mismo producto.' +
        'Adicionarás imágenes o colores para identificarlos y/o reconocerlos',
      manager_vending: 'El sitema permite una forma de punto venta pos, con una interactiva y amplia gestión de los elementos(artículos y/o servicios) a vender.',
      manager_factures: 'Con Billing Money App usted puede generar sus facturas con un simple click, siempre estarán desponibles para sus operaciones de compra y venta, así como ' +
        'para que pueda imprimir acorde a sus necesidades. Usamos formatos de Ticket y Carta para que pueda imprimir como mejor considere',
      manager_shop: 'En el momento del registro, Billing Money App le autogenera una tienda con el mismo nombre de la empresa, luego podrá editarla si así lo desea.' +
        'Todos los artículos y/o servicios que registre serán asociados a las tienda que tenga en el sistema',
      manager_refunds: 'Una vez realizada una venta, puede realizar reembolsos tanto de dinero como productos, y el sitema actualizará automáticamente el costo, ' +
        'y margen de ganancia del producto, así como la venta realizada, siempre dejando constancia del proceso realizado así como una descripción y ' +
        'quién realizó la operación(ver Gestión de Permisos)',
      manager_boxes: 'Cada tienda que usted tenga(incluida la creada por defecto), se le autogenera una caja para agilizar su tiempo, usted puede adicionar más.' +
        'Las cajas serán abiertas a los empleados, y se cuadrarán automáticamente al cerrarlas, teniendo solo que verificar que coincida, ya que todas las operaciones quedan registradas y asociadas a una. En caso de no cuadrar se le notificará en el sistema y vía email',
      manager_bank: 'Para una mayor interacción con la gestión contable, Billing Money App le genera un banco por defecto al registrarse, usted puede asociar cuentas y el sistema se' +
        'encarga de mantenerle actualizado del estado de la cuenta respecto a as operaciones que realice en el sistema ',
      manager_category: 'Los elementos a vender(artículos y/o servicios), se asocian a categorías, que pueden ser gestionadas, sin límite de cantidad.',
      manager_supplier: 'Usted puede gestionar sus proveedores en el sistema, registrándolos. Si su proveedor está registrado en nuestra aplicación podrá solicitarle ' +
        'un pedido(Ver Gestión de Pedidos) mediante la misma, haciendo el proceso único y continuo, de manera tal que ambos puedan actualizarlo y no teniendo que ir personalmente.',
      manager_buy: 'Al registrar sus compras en Billing Money App su inventario se actualizará automáticamente, sin necesidad ni esfuerzo, así como el registro para la parte contable ' +
        ' permitiendo siempre una sincronización automática del sistema.',
      supply_emit: 'Billing Money App le permite tener un registro de los pedidos que usted emita mediante el sistema, así como el estado en que se encuentran, ya que si su proveedor está ' +
        'inscrito en nuestra aplicación el podrá actualizar los estados(Aceptado, En proceso, En Camino) y al recibir su pedido usted podrá actualizarle a Recibido',
      supply_received: 'Así como usted puede emitir pedidos, también puede recibirlos en caso que sea proveedor de alguien en el sistema, teniendo un registro de los pedidos que les realizan mediante el sistema, ' +
        ' teniendo la facilidad de actualizarl el estado del pedido a su cliente(Aceptado, En proceso, En Camino), para que el mismo esté al tanto de como va su proceso',
      account_classify: 'Nuestro sistema le crea por defecto un Archivo Contable con el registro de Egresos, Ingresos, Pasivos y Activos así como cuentas por defecto para la gestión de la misma, si usted configura ' +
        'nuestro sistema podemos irle actualizando constantemente el estado de sus cuentas.',
      accountings: 'Las Cuentas Contables pueden ser editadas, así como adicionar nuevas según la denominación que tengan en su país, permitiéndole hacer movimientos contables de manera manual.',
      manager_employer: 'Si usted tiene varios empleados, Billing Money App le permite que los pueda registrar y le ayuda a que controle sus horarios de entrada y salida, mediante las Targetas de asistencia ' +
        '(Ver Targeta de Asistencia) y los permisos de acceso(Ver Permisos de Acceso).',
      manager_mod: 'Si usted desea ampliar la varidad de su producto, Biling Money App le permite adicionar modificadores para ello',
      manager_client: 'Usted puede tener un registro de sus clientes regulares para así conocerlos y mantenerlos fiel a su negocio, ya que Billing Money App le permite registrarlos de manera sencilla y rápida.',
      affiliate: 'Con Billing Money App todos ganan, y nuestro deseo siempre es que Usted aumente sus ganancias, por eso hemos creado una forma en nuestra empresa para que sus ingresos puedan ser mayores.' +
        '¿Cómo? Sencillo, solo debes solicitar unirte a nuestra comunidad de Partners mediante la cual la empresa le paga una comisión por cada afiliado que unas al sistema.' +
        'Si su solicitud es aceptada, le entregamos un link para que lo comparta ya sea en sus redes, a fammiliares y amigos, en la comunidad, y si las personas se subscriben en la versión Unicornio te ' +
        'bonificamos por ello.',
      shop_online: 'Billing Money te provee de una Tienda Online por cada tienda que tengas registrada, de manera sencilla e intuitiva puedes definir las pasarelas de pago por las que deseas cobrar ' +
        'así como los productos que decidas vender en las mismas. No cobranos nada por ese servicio, es TOTALMENTE GRATUITO, para que puedas vender tus productos en el mundo entero y aumentar tus ganancias rápidamente.',
      fiscal_account: 'Billing Money permite emitir facturas fiscales para que te sea más rápido y puedas invertir tu tiempo en otras cosas, solo necesitas rellenar uno formulario mínimo y podrá generar sus Facturas' +
        ' Fiscales cuendo lo desee ',
      access: 'Si usted tiene varios trabajadores y sus funciones están separadas, Billing Money App lo ayuda con eso también. Tenemos una forma sencilla y rápida para que usted ' +
        'limite las funciones que dada empleado va tener dentro del sistema, definiendo usted que puede o no hacer cada uno, así como que puede ver o no en el sistema.',
      exchange_rate: 'Si usted opera con varias monedas, pues Billing Money App se adapta a esto. Usted puede registrar las monedas con las que desea trabajar así como las Tasas de Cambio(definidas por usted), de manera tal que cuando genere una factura ' +
        'se le especificará hasta la moneda en que su cliente pagó y las ganancias que esto reporta paar usted',
      tax_list: 'Si usted es de los que maneja varios impuestos, y se le vuelve tedioso la relación y cálculo de estos, pues está usando la aplicación perfecta para erradicar su problema. ' +
        'Nosotros le permitimos que gestione sus impuestos, y así podrá adicionarlos tanto en ventas compras y artículos con el mínimo de tiempo empleado.',
      discounts: 'Si usted es de los que maneja varios descuentos, y se le vuelve tedioso la relación y cálculo de estos, pues está usando la aplicación perfecta para erradicar su problema. ' +
        'Nosotros le permitimos que gestione sus descuentos, y así podrá adicionarlos tanto en ventas compras y artículos con el mínimo de tiempo empleado.'
    },
    text_voz: {
      welcome: 'Bienvenido a Billing Money App. Con nosotros usted tiene Laempresa en sus Manos'
    },
    ...es
  },
  help: {
    text: 'Ayuda',
    index: 'Índice',
    step: 'Pasos',
    btn_add: 'Botón Adicionar',
    btn_edit: 'Botón Editar',
    btn_delete: 'Botón Eliminar',
    description: {
      text: 'Billing Money App es un sistema de Facturación que le permitirá realizar cómodamente la gestión de ' +
        'su sistema de venta.' +
        'Para poceder correctamente con la aplicación, le recomendamos que siga los siguientes pasos:',
      step_tax: {
        first: 'Crear los Impuestos que usted deba usar para la venta y los productos. Ver sección de Impuestos',
        two: 'Crear los Descuentos que usted deba usar para la venta y los productos.Ver sección de Descuentos',
        three: 'Active las Categorías que desee usar. Para ello puede ir a Configuración->Activar Categorías',
        four: 'Billing Money App le propone un listado de categorías a las cuales usted puede adicionarle nuevas.',
        five: 'Adicione los artículos que desee. Ver sección de Artículos',
        six: 'Adicione los Clientes si los quiere controlar como tal desde el inicio. ' +
          'Así a la hora de realizarles una venta podrá enviarle la factura por correo si este lo requiere. ',
        seven: 'Registre sus proveedores.',
        eight: 'Si desea emitir Facturas Fiscales, complete los datos que se piden en la sección de Factura Fiscal en Configuración',
        text_one: 'Billing Money App le da la opción de aumentar su capital convitiéndose en Partner de nuestra empresa.' +
          ' Para ellos usted puede completar los datos que se le solicitan y una vez aceptado por la Empresa, usted ganará una comisión ' +
          'por cada cliente que traiga a nuestra plataforma y use la versión Unicornio',
        text_two: 'Esta sistema le da la opción de mantener un contacto con su proveedor para realizarle pedidos a través del mismo, si el está registrado aquí.' +
          ' Solo debe ingresar sus datos y podrá reflejarlo en sus compras y pedidos'

      }
    },
    children: {
      descriptions: {
        text: 'Billin Money App le da la posibilidad de gestionar sus propios Impuestos de manera sencilla e interactiva.' +
          ' Usted puede Adicionar, Editar y Eliminar los mismos. Este paso debe hacerlo antes de gestionar los artículos ya ' +
          'que estos están estrechamente relacionados, y dependen de esa configuración previa.'
      },
      tax: {
        list_tax: {
          descriptions: {
            text: 'La Gestión de Impuestos permite desde una interfaz sencilla el listado, así como Adicionar, Editar y Eliminar los mismos.' +
              'Desde esta vista usted podrá ver todos los impuestos definidos'
          }
        },
        new_tax: {
          descriptions: {
            text: 'Una vez en la interfaz de Adicionar usted debe definir el nombre del impuesto, el valor que tendrá. En el campo Taza define si es porcentual o fijo, ' +
              'luego aparece el campo para seleccionar el Tipo : "Incluido en el precio" o "Adicionado en el precio".Este valor a seleccionar es de vital importancia ya que definirá el valor del precio del producto a la' +
              ' hora de cobrarle a un cliente. También la vista cuenta con una opción de adicionársele a todos los artículos existentes. Una vez definida las opciones pertinentes procede a Aceptar en el botón con el mismo nombre.' +
              ' En caso de no querer salvar los cambios, pues precione el botón Cancelar'
          }
        },
        edit_tax: {
          descriptions: {
            text: 'En el listado de impuestos, seleccione el elemento que desea editar. ' +
              'Una vez en la interfaz de Editar usted puede redefinir el nombre del impuesto, así como el valor que tendrá. ' +
              'En el campo Taza define si es porcentual o fijo, ' +
              'luego aparece el campo para seleccionar el Tipo : "Incluido en el precio" o "Adicionado en el precio", lo cual recalculará en todos los artículos que contengan este impuesto.' +
              'Este valor a seleccionar es de vital importancia ya que definirá el valor del precio del producto a la' +
              ' hora de cobrarle a un cliente. También la vista cuenta con una opción de adicionársele a todos los artículos existentes. Una vez definida las opciones pertinentes procede a Aceptar en el botón con el mismo nombre.' +
              ' En caso de no querer salvar los cambios, pues precione el botón Cancelar'
          }
        },
        delete_tax: {
          descriptions: {
            text: 'El procedimiento de Eliminar de un Impuesto, es sencillo. Solo debe desde el listado de  Impuestos, precionar en el botón Eliminar de la tabla.' +
              'Automáticamente se le mostrará un cartel de confirmación para que ratifique la opción de Eliminar. En caso de ya no querer proceder con esta acción,' +
              ' presione el botón Cancelar. Si aún desea eliminar pues procesa a presionar el botón Aceptar.'

          }
        }
      },
      discount: {
        list_discount: {
          descriptions: {
            text: 'La Gestión de Descuentos permite desde una interfaz sencilla el listado, así como Adicionar, Editar y Eliminar los mismos.' +
              'Desde esta vista usted podrá ver todos los descuentos definidos'
          }
        },
        new_discount: {
          descriptions: {
            text: 'Una vez en la interfaz de Adicionar usted debe definir el nombre del descuento, el valor que tendrá. En el campo Taza define si es porcentual o fijo, ' +
              'luego aparece el campo para seleccionar el Tipo : "Incluido en el precio" o "Adicionado en el precio".Este valor a seleccionar es de vital importancia ya que definirá el valor del precio del producto a la' +
              ' hora de cobrarle a un cliente. También la vista cuenta con una opción de adicionársele a todos los artículos existentes. Una vez definida las opciones pertinentes procede a Aceptar en el botón con el mismo nombre.' +
              ' En caso de no querer salvar los cambios, pues precione el botón Cancelar'
          }
        },
        edit_discount: {
          descriptions: {
            text: 'En el listado de Descuentos, seleccione el elemento que desea editar. ' +
              'Una vez en la interfaz de Editar usted puede redefinir el nombre del Descuento, así como el valor que tendrá. ' +
              'En el campo Taza define si es porcentual o fijo, ' +
              'luego aparece el campo para seleccionar el Tipo : "Incluido en el precio" o "Adicionado en el precio", lo cual recalculará en todos los artículos que contengan este impuesto.' +
              'Este valor a seleccionar es de vital importancia ya que definirá el valor del precio del producto a la' +
              ' hora de cobrarle a un cliente. También la vista cuenta con una opción de adicionársele a todos los artículos existentes. Una vez definida las opciones pertinentes procede a Aceptar en el botón con el mismo nombre.' +
              ' En caso de no querer salvar los cambios, pues precione el botón Cancelar'
          }
        },
        delete_discount: {
          descriptions: {
            text: 'El procedimiento de Eliminar de un Descuento, es sencillo. Solo debe desde el listado de  Descuentos, precionar en el botón Eliminar de la tabla.' +
              'Automáticamente se le mostrará un cartel de confirmación para que ratifique la opción de Eliminar. En caso de ya no querer proceder con esta acción,' +
              ' presione el botón Cancelar. Si aún desea eliminar pues procesa a presionar el botón Aceptar.'
          }
        }
      },
      articles: {
        descriptions: {
          text: 'La gestión de Artículos es una función básica para el correcto funcionamiento de sus sistema.' +
            ' Billing Money App le da la posibilidad de que usted pueda crear los artículos y/o servicios, con una amplia ' +
            'variedad de características.'
        },
        steps: {
          text: 'Menú lateral izquierdo'
        },
        list_articles: {
          descriptions: {
            text: 'El listado de Artículos permite tener un control total sobre la gestión de los mismos, como Adicionar, Editar y Eliminar los mismos.' +
              'Desde esta vista usted podrá ver todos los artículos definidos'
          }
        },
        new_articles: {
          descriptions: {
            text: 'Una vez en la interfaz de Adicionar se le muestran una serie de pasos. En primer lugar están las características básicas, ' +
              'donde usted debe definir el nombre del artículo, La unidad de medida, etc. Como punto importante de este paso, está definir si el artículo es compuesto.' +
              'En caso de serlo, usted definirá de cuales artículos se compone. Usted definirá la(s) tienda(s) en que se venderá el mismo así como en el siguiente paso, ' +
              'podrá especificar como representarlo ya sea por una o varias imágenes o un color si así lo prefiere'
          },
          steps: {
            first: 'Adicione el nombre del nuevo artículo. Seleccione la Unidad de Medida, Código de Barras, edite si desea la referencia. ' +
              'Defina el Costo y Precio, así como la Categoría a la que pertenece y el Impuesto si tiene. así como la Descripción del mismo',
            two: 'Defina si el artículo es Compuesto.',
            three: 'Seleccione lo artículos que lo componen',
            four: 'Presione el botón Siguiente',
            five: 'Si el artículo no es Compuesto usted puede definir variantes del mismo en el Paso 2: "Variante"',
            six: 'Defina el nombre de la variante y los valores que tendrá. (Ejemplo: Nombre->Colores, Opciones->Rojo, Negro, Blanco)',
            seven: 'Si no desea adicionar la Variante puede presionar el botón Cancelar, en caso contrario presione Aceptar',
            eight: 'Puede Editar la representación de las convinaciones de Variantes desde la tabla que las muestra, seleccionando en la columna Imagen.',
            nine: 'Usted puede seleccionar una Imagen o color que represente a la Variante',
            ten: 'Si no desea adicionar la Imagen puede presionar el botón Cancelar, en caso contrario presione Aceptar',
            eleven: 'Presione el botón Siguiente',
            twelve: 'Es importante este paso ya que usted definirá si el sistema asume este elemento como un Artículo ' +
              'o como un Servicio al activar la opción Seguir Inventario',
            thirteen: 'También usted definirá si el producto y/o servicio será vendido en línea o solo personalmente, así como los precios para ambos casos.' +
              'Si definió variantes, serán estas las que mostrarán en la tabla. Este despliegue se hará por cada tienda que usted tenga, para definir ' +
              'donde se venderá el mismo',
            fourteen: 'En caso de que definina el seguir inventario a la tabla se le sumarán dos columnas nuevas, las cuales definirán la cantidad en Stock y la ' +
              'cantidad Bajo Inventario para notificarle cuando esto suceda',
            fifteen: 'Presione el botón Siguiente',
            sixteen: 'El paso de Representación es el último, ahí al igual que en la variante usted define como representar el elemento, ya sea con un color o con una imagen',
            seventeen: 'Si no desea guardar los cambios puede presionar el botón Cancelar, en caso contrario presione Aceptar'
          }
        },
        edit_articles: {
          descriptions: {
            first: 'Edite el nombre del nuevo artículo. Cambie la Unidad de Medida, Código de Barras, edite si desea la referencia. ' +
              'Redefina el Costo y Precio, así como la Categoría a la que pertenece y el Impuesto si tiene. y/o la Descripción del mismo',
            two: 'En caso de habero definido como compuesto puede redifinir los art[iculos que lo componen.',
            three: 'Cambiar lo artículos que lo componen',
            four: 'Presione el botón Siguiente',
            six: 'Si el artículo no es Compuesto usted puede redefinir variantes del mismo en el Paso 2: "Variante"',
            seven: 'Defina el nombre de la variante y los valores que tendrá. (Ejemplo: Nombre->Colores, Opciones->Rojo, Negro, Blanco)',
            eight: 'Si no desea adicionar la Variante puede presionar el botón Cancelar, en caso contrario presione Aceptar',
            nine: 'Puede Editar la representación de las convinaciones de Variantes desde la tabla que las muestra, seleccionando en la columna Imagen.',
            ten: 'Usted puede seleccionar una Imagen o color que represente a la Variante',
            eleven: 'Si no desea adicionar la Imagen puede presionar el botón Cancelar, en caso contrario presione Aceptar',
            twelve: 'Presione el botón Siguiente',
            thirteen: 'TUsted definirá si el producto y/o servicio será vendido en línea o solo personalmente, así como los precios para ambos casos.' +
              'Si definió variantes, serán estas las que mostrarán en la tabla. Este despliegue se hará por cada tienda que usted tenga, para definir ' +
              'donde se venderá el mismo',
            fourteen: 'En caso de que definina el seguir inventario a la tabla se le sumarán dos columnas nuevas, las cuales definirán la cantidad en Stock y la ' +
              'cantidad Bajo Inventario para notificarle cuando esto suceda',
            fifteen: 'Presione el botón Siguiente',
            sixteen: 'El paso de Representación es el último, ahí al igual que en la variante usted define como representar el elemento, ya sea con un color o con una imagen',
            seventeen: 'Si no desea salvar los cambios puede presionar el botón Cancelar, en caso contrario presione Aceptar'
          }
        },
        delete_articles: {
          descriptions: {
            text: 'El procedimiento de Eliminar de un Descuento, es sencillo. Solo debe desde el listado de  Descuentos, precionar en el botón Eliminar de la tabla.' +
              'Automáticamente se le mostrará un cartel de confirmación para que ratifique la opción de Eliminar. En caso de ya no querer proceder con esta acción,' +
              ' presione el botón Cancelar. Si aún desea eliminar pues procesa a presionar el botón Aceptar.'

          }
        }
      },
      vending: {
        descriptions: {
          text: 'Las ventas son de las principales funciones que tiene el sistema, para ellos se requieren previamente haber gestionados impuestos, descuentos, y artículos' +
            ' para poder proceder de una manera rápida y sin tener que cancelar las mismas por falta de datos.'
        },
        children: {
          steps: {
            text: 'Menú lateral izquierdo'
          },
          descriptions: {
            text: 'El listado de venta permite que pueda ver todas las ventas realizadas, así como la persona que la realizó.' +
              ' Esta interfaz provee al usuario de varias funcionalidades como Adicionar, Editar Eliminar, Reembolzos, Imprimir Ticket, ' +
              'Imprimir Factura en Forma de Carta además de los ' +
              'detalles del procedimiento como precio, desgloce del pago, detalles del reembolzo, etc. Esta interfaz está diseñada para ' +
              'que usted pueda desde una sola vista controlar todo lo relacionado con Venta',
            steps: {
              first: 'Seleccione la Tienda donde se encuentra los artículos a vender',
              two: 'Los artículos pueden ser filtrados en la ventana por Codigo de Barra o Nombre',
              three: 'Puede Buscarlos especfificamente por la Categoría a la que pertence',
              four: 'Una vez listado los artículos seleccione en el Carrito que tiene para adicionarlo a la venta',
              five: 'Una vez seleccionado al menos un artículo se mostrarán las funciones de Artículos, Factura, Limpiar Carrito',
              six: 'La función Artículos permite que se edite el elemento a vender adicionándole descuentos y modificadores. esto recalculará automáticamente el nuevo precio.',
              seven: 'La función Factura permite la selección del cliente, así como editar el número de Factuta si fuera necesario, adicionarle Impuestos y/o Descuentos Generales a la venta',
              eight: 'Dentro de esta vista, existe una interfaz para adicionar la forma de pago la cual controla que coincida la cantidad calculada con la definida como paga por el método especificado',
              nine: 'Limpiar Carrito elimina todos los elementos adicionados para efectuar la venta',
              ten: 'Una vez definida la forma de pago así como la cantidad a pagar por cada una, puede proceder a Aceptar o definirla como En Proceso',
              eleven: 'En proceso se define una venta que está abierta, o que no se le define el(los) método(s) de pago',
              twelve: 'Aceptada se establece cuando coincide el precio con la cantidad a pagar definida en la sección de Factura'
            }
          }
        },
        vending_edit: {
          descriptions: {
            text: 'El procedimiento de Editar de una venta, puede ser desde lo más sencillo hasta lo más complejo.' +
              'Una vez dentro de la interfaz, puede filtrar en la barra de búsqueda, tanto por nombre o por código de barra' +
              'del producto o servicio que le brindará a su cliente.' +
              'Inmmeditamente que seleccione el carrito en la imagen del artículo o servicio, se motrarán un grupo de funciones.'
          },
          steps: {
            first: 'Edite la Tienda donde se encuentra los artículos a vender',
            two: 'Los artículos pueden ser filtrados en la ventana por Codigo de Barra o Nombre',
            three: 'Puede Buscarlos especfificamente por la Categoría a la que pertence',
            four: 'Una vez listado los artículos seleccione en el Carrito que tiene para adicionarlo a la venta',
            five: 'Una vez seleccionado al menos un artículo se mostrarán las funciones de Artículos, Factura, Limpiar Carrito',
            six: 'La función Artículos permite que se edite el elemento a vender adicionándole descuentos y modificadores. esto recalculará automáticamente el nuevo precio.',
            seven: 'La función Factura permite la selección del cliente, así como editar el número de Factuta si fuera necesario, adicionarle Impuestos y/o Descuentos Generales a la venta',
            eight: 'Dentro de esta vista, existe una interfaz para adicionar la forma de pago la cual controla que coincida la cantidad calculada con la definida como paga por el método especificado',
            nine: 'Limpiar Carrito elimina todos los elementos adicionados para efectuar la venta',
            ten: 'Una vez definida la forma de pago así como la cantidad a pagar por cada una, puede proceder a Aceptar o definirla como En Proceso',
            eleven: 'En proceso se define una venta que está abierta, o que no se le define el(los) método(s) de pago',
            twelve: 'Aceptada se establece cuando coincide el precio con la cantidad a pagar definida en la sección de Factura'
          }

        },
        vending_delete: {
          descriptions: {
            text: 'El procedimiento de Editar de una venta, puede ser desde lo más sencillo hasta lo más complejo.' +
              'Una vez dentro de la interfaz, puede filtrar en la barra de búsqueda, tanto por nombre o por código de barra' +
              'del producto o servicio que le brindará a su cliente.' +
              'Inmmeditamente que seleccione el carrito en la imagen del artículo o servicio, se motrarán un grupo de funciones.'
          }
        }
      }
    }
  },
  subscription_state: {
    test: 'Prueba',
    active: 'Activa',
    canceled_change: 'Cambio de Plan',
    suspend: 'Suspendida'
  },
  ...es
}
