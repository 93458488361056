<template>
  <no-subcription v-if="!getSubscription"/>
  <v-container v-else>
    <app-loading v-if="loadingData"/>
    <v-card-title v-else>
      {{
        $vuetify.lang.t(!managerArticle ? '$vuetify.titles.edit' : '$vuetify.titles.new', [
          !article.service ? $vuetify.lang.t('$vuetify.menu.product') : $vuetify.lang.t('$vuetify.menu.service'),
        ])
      }}
    </v-card-title>
    <v-stepper
      v-if="!loadingData"
      v-model="step"
      vertical
    >
      <v-stepper-step
        step="1"
        editable
        complete
      >
        {{ $vuetify.lang.t('$vuetify.panel.basic') }}
        <v-spacer/>
        <br/>
      </v-stepper-step>

      <v-stepper-content step="1">
        <basic :article="article" @managerArticle="managerArticleHandler"
               @changeComposite="changeComposite"/>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="isActionInProgress"
            @click="step = 2"
          >
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.next') }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer/>

          <v-btn
            class="mb-2"
            :disabled="isActionInProgress"
            @click="cancelManager"
          >
            <v-icon>mdi-close</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.cancel') }}
          </v-btn>
        </v-card-actions>
        <v-divider/>
        <v-card-actions>
          <v-btn
            class="mb-2"
            color="primary"
            :disabled="!valid || isActionInProgress"
            :loading="isActionInProgress"
            @click="managerArticleHandler('save')"
          >
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.save') }}
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn
            class="mb-2"
            color="primary"
            :disabled="!valid || isActionInProgress"
            :loading="isActionInProgress"
            @click="managerArticleHandler('continue')"
          >
            <v-icon>mdi-content-save-all</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.save_continue') }}
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-step
        step="2"
        editable
        complete
      >
        {{ $vuetify.lang.t('$vuetify.panel.variant') }}
      </v-stepper-step>

      <v-stepper-content step="2">
        <variants :article="article"/>
        <v-card-actions>

          <v-btn
            text
            @click="step = 1"
          >
            <v-icon>mdi-chevron-left</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.back') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isActionInProgress"
            @click="step = 3"
          >
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.next') }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer/>

          <v-btn
            class="mb-2"
            :disabled="isActionInProgress"
            @click="cancelManager"
          >
            <v-icon>mdi-close</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.cancel') }}
          </v-btn>
        </v-card-actions>
        <v-divider/>
        <v-card-actions>
          <v-btn
            class="mb-2"
            color="primary"
            :disabled="!valid || isActionInProgress"
            :loading="isActionInProgress"
            @click="managerArticleHandler('save')"
          >
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.save') }}
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn
            class="mb-2"
            color="primary"
            :disabled="!valid || isActionInProgress"
            :loading="isActionInProgress"
            @click="managerArticleHandler('continue')"
          >
            <v-icon>mdi-content-save-all</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.save_continue') }}
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-step
        v-if="article.variant_values.length === 0"
        complete
        editable
        :step="article.variant_values.length === 0 ? 3: 5"
      >{{ $vuetify.lang.t('$vuetify.articles.inventory') }}
        <small> {{ $vuetify.lang.t('$vuetify.menu.shop') }}</small>
      </v-stepper-step>

      <v-stepper-content
        v-if="article.variant_values.length === 0"
        :step="article.variant_values.length === 0 ? 3: 5">
        <article-shop :article="article"/>
        <v-card-actions>
          <v-btn
            text
            @click="step = article.composite ? 1 : 2"
          >
            <v-icon>mdi-chevron-left</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.back') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isActionInProgress"
            @click="step = (article.composite  || article.variant_values.length > 0) ? 3 : 4"
          >
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.next') }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer/>

          <v-btn
            class="mb-2"
            :disabled="isActionInProgress"
            @click="cancelManager"
          >
            <v-icon>mdi-close</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.cancel') }}
          </v-btn>
        </v-card-actions>
        <v-divider/>
        <v-card-actions>
          <v-btn
            class="mb-2"
            color="primary"
            :disabled="!valid || isActionInProgress"
            :loading="isActionInProgress"
            @click="managerArticleHandler('save')"
          >
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.save') }}
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn
            class="mb-2"
            color="primary"
            :disabled="!valid || isActionInProgress"
            :loading="isActionInProgress"
            @click="managerArticleHandler('continue')"
          >
            <v-icon>mdi-content-save-all</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.save_continue') }}
          </v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-step
        :step="article.variant_values.length === 0 ? 4 : 3"
        complete
        editable
      >
        {{ $vuetify.lang.t('$vuetify.representation.representation') }}
      </v-stepper-step>

      <v-stepper-content
        :step="article.variant_values.length === 0 ? 4 : 3"
      >
        <representation :article="article"/>
        <v-card-actions>
<!--          <v-btn-->
<!--            color="primary"-->
<!--            :disabled="isActionInProgress"-->
<!--            @click="step = 1"-->
<!--          >-->
<!--            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.next') }}-->
<!--            <v-icon>mdi-chevron-right</v-icon>-->
<!--          </v-btn>-->
          <v-spacer/>

          <v-btn
            class="mb-2"
            :disabled="isActionInProgress"
            @click="cancelManager"
          >
            <v-icon>mdi-close</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.cancel') }}
          </v-btn>
        </v-card-actions>
        <v-divider/>
        <v-card-actions>
          <v-btn
            class="mb-2"
            color="primary"
            :disabled="!valid || isActionInProgress"
            :loading="isActionInProgress"
            @click="managerArticleHandler('save')"
          >
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.save') }}
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn
            class="mb-2"
            color="primary"
            :disabled="!valid || isActionInProgress"
            :loading="isActionInProgress"
            @click="managerArticleHandler('continue')"
          >
            <v-icon>mdi-content-save-all</v-icon>
            {{ smallScreen ? '' : $vuetify.lang.t('$vuetify.actions.save_continue') }}
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Basic from './manager-article/Basic'
import Variants from './manager-article/Variants'
import ArticleShop from './manager-article/ArticleShop'
import Representation from './manager-article/Representation'

export default {
  name: 'ManagerArticle',
  components: {
    Representation,
    ArticleShop,
    Variants,
    Basic
  },
  data () {
    return {
      managerArticle: this.$route.name === 'product_add',
      loadingData: false,
      article: {
        name: '',
        description: '',
        um: '',
        price: 0.00,
        online_price: 0.00,
        online_sale: true,
        unit: 'unit',
        variants: [],
        taxes: [],
        marks: [],
        variant_values: [],
        composite: false,
        service: false,
        track_inventory: true,
        category: null,
        color: '#1FBC9C',
        article_shops: [],
        composites: [],
        images: [],
        cost_id: null,
        price_id: null,
        online_price_id: null,
        remove_image: []
      },
      step: 1
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapState('article', ['saved', 'articleNumber', 'newArticle', 'editArticle', 'articles', 'isActionInProgress']),
    ...mapState('auth', ['company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    valid () {
      return this.article.name !== ''
    }
  },
  async created () {
    if (this.$route.name === 'product_edit') {
      if (!this.article.id) {
        this.loadingData = true
        await this.getEditArticle({ id: atob(this.$route.params.hash) })
        this.loadingData = false
      }
      this.article = Object.assign({}, this.editArticle)
      if (this.article.expire_date) {
        this.article.expire_date = this.article.expire_date.split(' ')[0]
      }
    }
  },
  methods: {
    ...mapActions('article', ['createArticle', 'fetchArticleNumber', 'updateArticle', 'toogleNewModal', 'getEditArticle']),
    cancelManager () {
      this.$emit('out-manager')
      this.article.service ? this.$router.push({ name: 'service_list' }) : this.$router.push({ name: 'product_list' })
    },
    changeComposite () {
      if (this.article.composite) {
        this.article.variant_values = []
      } else {
        this.updated = false
      }
    },
    showAlertMessage (textMsg) {
      this.$Swal.fire({
        title: this.$vuetify.lang.t(this.managerArticle ? '$vuetify.titles.edit' : '$vuetify.titles.new', [
          !this.article.service ? this.$vuetify.lang.t('$vuetify.menu.product') : this.$vuetify.lang.t('$vuetify.menu.service')
        ]),
        text: textMsg,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: this.$vuetify.lang.t(
          '$vuetify.actions.accept'
        ),
        confirmButtonColor: 'red'
      })
    },
    validateBarCode () {
      let valid = true
      this.article.variant_values.forEach((value) => {
        if (value.barCode) {
          const compareBarCode = this.articles.filter(art => art.barCode === value.barCode)
          if (compareBarCode.length > 0) {
            if (compareBarCode[0].id !== value.id) {
              valid = false
              this.showAlertMessage(this.$vuetify.lang.t('$vuetify.messages.warning_barCode', [value.barCode], [compareBarCode[0].name], [value.name]
              ))
            } else if (this.article.barCode === compareBarCode[0].barCode && valid) {
              valid = false
              this.showAlertMessage(this.$vuetify.lang.t('$vuetify.messages.warning_barCode', [value.barCode], [compareBarCode[0].name], [this.article.name]
              ))
            }
          }
        }
      })
      if (this.article.barCode && valid) {
        const existBarCode = this.articles.filter(art => art.barCode === this.article.barCode)
        if (existBarCode.length > 0) {
          if (this.article.barCode === existBarCode[0].barCode && this.article.id !== existBarCode[0].id) {
            valid = false
            this.showAlertMessage(this.$vuetify.lang.t('$vuetify.messages.warning_barCode', [this.article.barCode], [existBarCode[0].name], [this.article.name]
            ))
          }
        }
      }
      return valid
    },
    validateRef () {
      let valid = true
      this.article.variant_values.forEach((value) => {
        if (valid) {
          const localArt = this.articles.filter(art => art.ref === value.ref)
          if (localArt.length > 0) {
            if (localArt[0].id !== value.id) {
              valid = false
              if (!valid) {
                this.showAlertMessage(this.$vuetify.lang.t(
                  '$vuetify.messages.warning_ref', [value.ref]
                ))
              }
            }
          }
        }
      })
      if (this.article.variant_values.filter(vd => vd.ref === this.article.ref).length > 0) {
        valid = false
        this.showAlertMessage(this.$vuetify.lang.t(
          '$vuetify.messages.warning_ref', [this.article.ref]
        ))
      } else {
        const localArt = this.articles.filter(art => art.ref === this.article.ref && art.id !== this.article.id)
        if (localArt.length > 0) {
          valid = false
          this.showAlertMessage(this.$vuetify.lang.t(
            '$vuetify.messages.warning_ref', [this.article.ref]
          ))
        }
      }
      return valid
    },
    managerArticleHandler (action = 'save') {
      let valid = true
      if (!this.article.category || !this.article.category.id) {
        valid = false
        this.showAlertMessage(this.$vuetify.lang.t(
          '$vuetify.messages.warning_no_category'
        ))
        this.step = 1
      } else {
        if (!this.validateBarCode()) {
          valid = false
        } else {
          if (this.article.composite) {
            if (this.article.composite.length === 0) {
              this.showAlertMessage(this.$vuetify.lang.t('$vuetify.messages.warning_composite'))
            }
          }
        }
      }
      if (valid) {
        this.validCreate(action)
      }
    },
    async validCreate (action = 'save') {
      this.loading = true
      this.$route.name === 'product_add' ? await this.createArticle(this.article) : await this.updateArticle(this.article)
      if (action === 'save') {
        this.$emit('out-manager')
        await this.article.service ? this.$router.push({ name: 'service_list' }) : this.$router.push({ name: 'product_list' })
      } else {
        this.$emit('out-manager')
        await this.$router.push({ name: 'product_add' })
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
