<template>
  <div>
    <v-card-title v-if="textLabel!=''">{{textLabel}} {{new Date(atTime).toLocaleDateString()}}  {{new Date(atTime).toLocaleTimeString()}}</v-card-title>
    <div class="row">
      <item-time v-for="time in times" :key="time.id" v-bind:time="time"/>
    </div>
  </div>
</template>

<script>
import ItemTime from './ItemTime'
export default {
  name: 'TimerCounterDown',
  props: {
    textLabel: {
      type: String,
      default: ''
    },
    atTime: {
      type: String,
      default: new Date().toString()
    },
    stTime: {
      type: String,
      default: new Date().toString()
    }
  },
  components: { ItemTime },
  data: function () {
    return {
      startTime: this.stTime,
      times: [
        { id: 0, text: this.$vuetify.lang.t('$vuetify.date_text.days'), time: 1, alert: false },
        { id: 1, text: this.$vuetify.lang.t('$vuetify.date_text.hours'), time: 1, alert: false },
        { id: 2, text: this.$vuetify.lang.t('$vuetify.date_text.minutes'), time: 1, alert: false },
        { id: 3, text: this.$vuetify.lang.t('$vuetify.date_text.seconds'), time: 1, alert: false }
      ],
      progress: 100,
      // isActive: false,
      timeInterval: undefined
    }
  },
  methods: {
    updateTimer: function () {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining()
        this.updateProgressBar()
      } else {
        clearTimeout(this.timeInterval)
        // this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
        this.progress = 0
      }
    },
    getTimeRemaining: function () {
      const t = Date.parse(new Date(this.atTime)) - Date.parse(new Date())
      if (t >= 0) {
        this.times[3].time = Math.floor(t / 1000 % 60) // seconds
        this.times[2].time = Math.floor(t / 1000 / 60 % 60) // minutes
        this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24) // hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)) // days
      } else {
        this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0
        this.progress = 0
      }
      if (this.times[0].time < 3) {
        this.times[3].alert = true
        this.times[2].alert = true
        this.times[1].alert = true
        this.times[0].alert = true
      }
    },
    updateProgressBar: function () {
      const startTime = Date.parse(new Date(this.stTime))
      const currentTime = Date.parse(new Date())
      const endTime = Date.parse(new Date(this.atTime))
      const interval = parseFloat(
        (currentTime - startTime) / (endTime - startTime) * 100
      ).toFixed(2)
      this.progress = 100 - interval
    }
  },
  created: function () {
    this.updateTimer()
    this.timeInterval = setInterval(this.updateTimer, 1000)
  }
}
</script>

<style scoped>
.card-title{
  padding: 1em;
}
.card {
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  padding-bottom: ;
  margin-bottom: 2em;
}
</style>
