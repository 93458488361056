<template>
  <v-navigation-drawer
    v-model="drawer"
    :dark="$vuetify.dark"
    :width="drawerWidth"
    app
    class="app--drawer"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-toolbar
          id="drawer-toolbar"
          v-bind="attrs"
          color="primary"
          :style="{cursor:'pointer'}"
          dark
          v-on="on"
        >
          <img
            :src="computeLogo"
            alt="BILLING MONEY LOGO"
            width="36"
          >
          <v-toolbar-title class="ml-0 pl-3"
                           @click="initTour">
            <span class="hidden-xs-down notranslate" content="notranslate" name="google" translate="no">BILLING MONEY APP</span>
          </v-toolbar-title>
        </v-toolbar>
      </template>
      <span>{{ $vuetify.lang.t('$vuetify.guide') }}</span>
    </v-tooltip>
    <template>
      <v-card-subtitle><h5>{{ $vuetify.lang.t('$vuetify.slogan_bill') }}</h5></v-card-subtitle>
    </template>
    <v-list class="pa-0">
      <template v-for="(item, key) in computeMenu">
        <template v-if="item.children && item.children.length > 0">
          <v-list-group
            :id="item.meta.title"
            :key="key"
            :group="item.path"
            :prepend-icon="item.meta.icon"
            :to="item.path"
            no-action
          >
            <template v-slot:prepend-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-text="item.meta.icon"
                  />
                </template>
                <span>
                  {{
                    $vuetify.lang.t(
                      '$vuetify.menu.' + item.meta.title,
                    )
                  }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-if="item.meta.title === 'shop_online'">
                  <span>{{ $vuetify.lang.t('$vuetify.menu.' + item.meta.title) }}</span>
                  <v-badge
                    v-if="onlineRequest > 0"
                    :content="onlineRequest"
                    :value="onlineRequest"
                    color="pink"
                    overlap
                  >
                    <v-icon>mdi-bell</v-icon>
                  </v-badge>
                </v-list-item-title>
                <v-list-item-title v-else-if="item.meta.title === 'articles'" style="margin-top: 0px !important;">
                  <span>{{ $vuetify.lang.t('$vuetify.menu.' + item.meta.title) }}</span>
                  <notification-icon-drawer
                    :content="underInventory + expire"/>
                </v-list-item-title>
                <v-list-item-title v-else>
                  <span>{{ $vuetify.lang.t('$vuetify.menu.' + item.meta.title) }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="subItem in item.children">
              <v-list-item
                v-if="!subItem.children"
                v-show="!subItem.meta.hiddenInMenu  && showConfigMenu(subItem)"
                :key="subItem.name"
                :class="drawerWidth === 64 ? 'pl-4' : ''"
                :to="subItem.path"
              >
                <template
                  v-if="drawerWidth === 64 && showConfigMenu(subItem)"
                >
                  <v-list-item-icon>
                    <v-tooltip bottom>
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ subItem.meta.icon }}
                        </v-icon>
                      </template>
                      <span>{{
                          $vuetify.lang.t(
                            '$vuetify.menu.' +
                            subItem.meta.title,
                          )
                        }}</span>
                    </v-tooltip>
                  </v-list-item-icon>
                </template>
                <v-list-item-content v-if="showConfigMenu(subItem)">
                  <v-list-item-title>
                      <span>
                          {{ $vuetify.lang.t('$vuetify.menu.' + subItem.meta.title) }}
                      </span>
                    <v-tooltip right max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          color="primary">
                          mdi-information
                        </v-icon>
                      </template>
                      {{ $vuetify.lang.t('$vuetify.tips.' + subItem.name) }}
                    </v-tooltip>
                    <notification-icon-drawer
                      v-if="subItem.name === 'expire_list' || subItem.name === 'product_under_inventory' || subItem.name === 'supply_online'"
                      :content="subItem.name === 'expire_list' ? expire : subItem.name === 'supply_online' ? onlineRequest  : subItem.name === 'product_under_inventory' ? underInventory : 0"/>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group
                style="margin-left: 15"
                :key="subItem.meta.group"
                v-else
                color="indigo"
                :group="subItem.meta.group"
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $vuetify.lang.t('$vuetify.menu.' + subItem.meta.title) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-for="(subSubItem, k) in subItem.children">
                  <v-list-item
                    :key="`subheader-${k}`"
                    v-show="!subSubItem.meta.hiddenInMenu"
                    color="indigo"
                    :value="true"
                    :to="subSubItem.path"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ $vuetify.lang.t('$vuetify.menu.' + subSubItem.meta.title) }}</span></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item
            v-if="item.meta.title === 'affiliate' || item.meta.title === 'dashboard' || item.meta.title === 'setting' "
            :id="item.meta.title"
            :key="key"
            :to="item.path">
            <v-list-item-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-text="item.meta.icon"
                  />
                </template>
                <span>{{
                    $vuetify.lang.t(
                      '$vuetify.menu.' + item.meta.title,
                    )
                  }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content v-if="drawerWidth !== 64">
              <v-tooltip right max-width="250">
                <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ $vuetify.lang.t('$vuetify.menu.' + item.meta.title) }}
                              </span>
                </template>
                {{ $vuetify.lang.t('$vuetify.tips.' + item.name) }}
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else
            v-show="!hiddenMenu(item) && showConfigMenu(item)"
            :id="item.meta.title"
            :key="key"
            :to="item.path"
          >
            <v-list-item-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-text="item.meta.icon"
                  />
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.menu.' + item.meta.title,) }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content v-if="drawerWidth !== 64">
              <v-list-item-title
                v-text="
                  $vuetify.lang.t(
                    '$vuetify.menu.' + item.meta.title
                  )
                "
              />
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { protectedRoute as routes } from '../router/config'
import { mapGetters, mapState } from 'vuex'
import localStore from '../config/localStorage'
import NotificationIconDrawer from './NotificationIconDrawer'

export default {
  name: 'AppDrawer',
  components: { NotificationIconDrawer },
  props: {
    expanded: {
      type: Boolean,
      default: true
    },
    showDrawer: Boolean
  },
  data () {
    return {
      mini: false,
      localAccess: [],
      drawerWidth: 350,
      drawer: true,
      scrollSettings: {
        maxScrollbarLength: 160
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdminIn', 'access_permit', 'getMenu', 'onlineRequest', 'getNotifications']),
    ...mapState('app', ['themeColor']),
    ...mapState('auth', ['underInventory', 'expire']),
    computeLogo () {
      return '/assets/logo/' + (this.themeColor || localStore.getTheme()) + '.PNG'
    },
    computeMenu () {
      let routers = []
      let localRoutes = routes[2]
      if (localStore.getTypeConnection() === 'partner') {
        localRoutes = routes[4]
        localRoutes.children.forEach((a) => {
          a.children.forEach((c) => {
            c.meta.hiddenInMenu = c.meta ? c.meta.hiddenInMenu : true
          })
          routers.push(a)
        })
      } else if (localStore.getTypeConnection() === 'beneficiary') {
        localRoutes = routes[5]
        localRoutes.children.forEach((a) => {
          a.children.forEach((c) => {
            c.meta.hiddenInMenu = c.meta ? c.meta.hiddenInMenu : true
          })
          routers.push(a)
        })
      } else if (localStore.getTypeConnection() === 'admin') {
        routers = routes[3].children
      } else if (this.access_permit) {
        localRoutes.children.forEach((v) => {
          if (this.showInMenu(v.access)) {
            routers.push(v)
          }
        })
        routers.forEach((a) => {
          a.children.forEach((c) => {
            c.meta.hiddenInMenu = (this.localAccess.filter(a => a.actions[c.access]).length > 0) ? c.meta.hiddenInMenu : true
          })
        })
      }
      return routers
    }
  },
  watch: {
    access_permit: function () {
      this.localAccess = []
      const keys = Object.keys(this.access_permit)
      keys.forEach((v) => {
        this.localAccess.push(this.access_permit[v])
      })
    },
    showDrawer: {
      handler (val) {
        this.drawer = val
      },
      immediate: true
    }
  },
  methods: {
    hiddenMenu (item) {
      return item.meta.hiddenInMenu
    },
    showConfigMenu (item) {
      if (localStore.getTypeConnection() === 'admin') {
        return true
      } else if (!localStore.getTypeConnection() === 'commerce') {
        return true
      } else if (!this.getMenu) {
        return true
      } else if (item.name === 'dashboard' || item.name === 'setting') {
        return true
      } else {
        const filt = this.getMenu.menu.filter(mn => mn.title.name === item.name)
        if (filt.length === 0) {
          return true
        }
        return filt[0].title.value === true
      }
    },
    handleDrawerCollapse () {
      this.drawerWidth = this.drawerWidth === 315 ? 64 : 315
    },
    showInMenu (permit) {
      let countAccess = 0
      if (this.localAccess.length > 0) {
        permit.forEach((v) => {
          const exist = this.localAccess.filter(a => a.title.name === v && a.title.value === true)
          if (exist.length > 0) {
            countAccess++
          }
        })
      }
      return countAccess > 0
    },
    initTour () {
      this.$tours.App.start()
    }
  }
}
</script>
<style>

.parpadear {

  animation-name: parpadeo;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 15s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
</style>

<style lang="sass" scoped>
.app--drawer
  overflow: hidden !important

  .drawer-menu--scroll
    height: calc(100vh - 48px)
    overflow: auto
</style>
