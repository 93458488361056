<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
              $vuetify.lang.t( !newVariant ? "$vuetify.titles.edit" : "$vuetify.titles.newF", [this.$vuetify.lang.t("$vuetify.variants.variant")] )
            }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-text-field
                  v-model="name"
                  :hint="hintText[0]"
                  persistent-hint
                  style="margin-top: 14px"
                  :label="
                    $vuetify.lang.t(
                      '$vuetify.variants.name'
                    )
                  "
                />
              </v-col>
              <v-col
                cols="12"
                sm="8"
                md="8"
              >
                <v-combobox
                  v-model="select"
                  multiple
                  value=""
                  :label="
                    $vuetify.lang.t(
                      '$vuetify.variants.options'
                    )
                  "
                  :hint="$vuetify.lang.t('$vuetify.hints.press_enter')"
                  chips
                  deletable-chips
                  class="tag-input"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            class="mb-2"
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
            {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
          </v-btn>
          <v-btn
            class="mb-2"
            color="primary"
            :disabled="
              select.length === 0 || name === '' || hintText[1]
            "
            @click="saveVariant"
          >
            <v-icon>mdi-check</v-icon>
            {{ $vuetify.lang.t("$vuetify.actions.accept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-loading v-if="loading"/>
    <div v-else>
      <template v-if="showHeader">
        <template
          v-for="variant in article.variants">
          <v-chip
            v-if="!variant.is_mark"
            :key="variant.name"
            style="margin-right: 10px"
            close
            :close-icon="'mdi-delete'"
            color="success"
            text-color="white"
            @click:close="removeVariant(variant)"
          >
            <v-icon
              left
              @click="editVariant(variant)"
            >
              mdi-pencil
            </v-icon>
            {{ variant.name }}
          </v-chip>
          <v-chip
            v-else
            :key="variant.name"
            style="margin-right: 10px"
            color="success"
            text-color="white"
          >
            {{ variant.name }}
          </v-chip>
        </template>
      </template>

      <span>{{ $vuetify.lang.t('$vuetify.panel.variant') }}
        <v-tooltip bottom max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
          <span> {{$vuetify.lang.t('$vuetify.messages.variants_values')}}</span>
        </v-tooltip>
       </span>
      <v-data-table
        :headers="getHeader"
        :items="article.variant_values"
        :single-expand="false"
        :expanded.sync="expanded"
        sort-by="calories"
        show-expand
        class="elevation-1"
      >
        <template v-slot:top v-if="showHeader">
          <v-toolbar
            flat
          >
            <v-toolbar-title v-if="smallScreen">{{ $vuetify.lang.t('$vuetify.panel.variant') }}</v-toolbar-title>
            <v-divider
              v-if="smallScreen"
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="addVariant"
            >
              {{$vuetify.lang.t('$vuetify.actions.newF')}}
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <v-edit-dialog
            v-if="$route.name === 'product_edit' && $route.name === 'product_variant_edit'"
            :return-value.sync="item.name"
            large
            persistent
            :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
            :save-text="$vuetify.lang.t('$vuetify.actions.save')"
            @save="$emit('updateVariant')"
          >
            <div>
              {{ item.name }}
            </div>
            <template v-slot:input>
              <div class="mt-4 title">
                {{ $vuetify.lang.t("$vuetify.actions.edit") }}
              </div>
              <v-text-field
                v-model="item.name"
                :label="$vuetify.lang.t('$vuetify.firstName')"
                required
                :rules="formRule.required"
              />
            </template>
          </v-edit-dialog>
          <span v-else>
            {{ item.name }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                color="warning"
                small
                v-bind="attrs"
                v-on="on"
                @click="$emit('showEditVariantModal',item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $vuetify.lang.t('$vuetify.actions.edit') }}</span>
          </v-tooltip>
          <v-icon
            v-if="article.variants.filter(v=> v.is_mark).length !== article.variants.length && article.variant_values.length > 1"
            small
            @click="deleteVariant(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.price="{ item }">
          <v-edit-dialog
            :return-value.sync="item.price"
            large
            persistent
            :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
            :save-text="$vuetify.lang.t('$vuetify.actions.save')"
            @save="updateVariants(item, 'price')"
          >
            <div v-if="parseFloat(item.price).toFixed(2) === '0.00'" style="color: #ef700e">

              <v-icon
                v-if="!variant.is_mark"
                left
                @click="editVariant(variant)"
              >
                mdi-pencil
              </v-icon> {{ `${parseFloat(item.price).toFixed(2)}` + ' ' + getCurrency}}
            </div>
            <div v-else>

              <v-icon
                v-if="!variant.is_mark"
                left
                @click="editVariant(variant)"
              >
                mdi-pencil
              </v-icon>{{ `${parseFloat(item.price).toFixed(2)}`  + ' ' + getCurrency}}
            </div>
            <template v-slot:input>
              <div class="mt-4 title">
                {{ $vuetify.lang.t("$vuetify.actions.edit") }}
              </div>
              <v-text-field-money
                v-model="item.price"
                :label="$vuetify.lang.t('$vuetify.actions.edit')"
                required
                :properties="{
                prefix: getCurrency,
                clearable: true
              }"
                :options="{
                length: 15,
                precision: 2,
                empty: 0.0
              }"
              />
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.cost="{ item }">
          <v-edit-dialog
            :return-value.sync="item.cost"
            large
            persistent
            :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
            :save-text="$vuetify.lang.t('$vuetify.actions.save')"
            @save="updateVariants(item, 'cost')"
          >
            <div v-if="parseFloat(item.cost).toFixed(2) === '0.00'" style="color: #ef700e">
              {{ `${parseFloat(item.cost).toFixed(2)}` + ' ' + getCurrency}}
            </div>
            <div v-else>
              {{ `${parseFloat(item.cost).toFixed(2)}`  + ' ' + getCurrency}}
            </div>
            <template v-slot:input>
              <div class="mt-4 title">
                {{ $vuetify.lang.t("$vuetify.actions.edit") }}
              </div>
              <v-text-field-money
                v-model="item.cost"
                :label="$vuetify.lang.t('$vuetify.actions.edit')"
                required
                :properties="{
                prefix: getCurrency,
                clearable: true
              }"
                :options="{
                length: 15,
                precision: 2,
                empty: 0.0
              }"
              />
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.ref="{ item }">
          <v-edit-dialog
            :return-value.sync="item.ref"
            large
            persistent
            :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
            :save-text="$vuetify.lang.t('$vuetify.actions.save')"
            @save="updateVariants(item, 'ref')"
          >
            <div v-if="!item.ref" style="color: #ef700e">
              ---
            </div>
            <div v-else>
              {{ `${item.ref}`}}
            </div>
            <template v-slot:input>
              <div class="mt-4 title">
                {{ $vuetify.lang.t("$vuetify.actions.edit") }}
              </div>
              <v-text-field
                type="number"
                v-model="item.ref"
                :label="$vuetify.lang.t('$vuetify.actions.edit')"
                required
              />
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.barCode="{ item }">
          <v-edit-dialog
            :return-value.sync="item.barCode"
            large
            persistent
            :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
            :save-text="$vuetify.lang.t('$vuetify.actions.save')"
            @save="updateVariants(item, 'barCode')"
          >
            <div v-if="!item.barCode" style="color: #ef700e">
              ---
            </div>
            <div v-else>
              {{ `${item.barCode}`}}
            </div>
            <template v-slot:input>
              <div class="mt-4 title">
                {{ $vuetify.lang.t("$vuetify.actions.edit") }}
              </div>
              <v-text-field
                v-model="item.barCode"
                :label="$vuetify.lang.t('$vuetify.actions.edit')"
                required
              />
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <v-btn
            color="red"
            fab
            x-small
            dark
            @click="expand(!isExpanded)"
          >
            <v-icon v-if="isExpanded">
              mdi-chevron-up
            </v-icon>
            <v-icon v-else>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <h4 class="text-center" style="margin-top: 10px;margin-bottom: 15">{{$vuetify.lang.t('$vuetify.menu.shop')}}</h4>
            <article-shop :article="item" :shop-header="false"/>
            <!--v-simple-table dense v-if="item.article_shops.length > 0">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    {{$vuetify.lang.t('$vuetify.menu.shop')}}
                  </th>
                  <th class="text-left">
                    {{ $vuetify.lang.t('$vuetify.shop_article.person_sale') }}
                  </th>
                  <th class="text-left">
                    {{$vuetify.lang.t('$vuetify.variants.price')}}
                  </th>
                  <th class="text-left">
                    {{ $vuetify.lang.t('$vuetify.articles.onlineSale') }}
                  </th>
                  <th class="text-left">
                    {{ $vuetify.lang.t('$vuetify.onlinePrice')}}
                  </th>
                  <th class="text-left">
                    {{ $vuetify.lang.t('$vuetify.shop_article.stock') }}
                  </th>
                  <th class="text-left">
                    {{ $vuetify.lang.t('$vuetify.shop_article.under_inventory') }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="artShops in item.article_shops" :key="artShops.ref"
                >
                  <td>{{ artShops.shop_name }}</td>
                  <td>
                    <v-simple-checkbox v-model="artShops.person_sale"/>
                  </td>
                  <td>
                    <v-edit-dialog
                      :return-value.sync="artShops.price"
                      large
                      persistent
                      :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                      :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                    >
                      <div v-if="parseFloat(artShops.price).toFixed(2) === '0.00'" style="color: #ef700e">
                        {{ `${parseFloat(item.price).toFixed(2)}` + ' ' + getCurrency}}
                      </div>
                      <div v-else>
                        {{ `${parseFloat(artShops.price).toFixed(2)}`  + ' ' + getCurrency}}
                      </div>
                      <template v-slot:input>
                        <div class="mt-4 title">
                          {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                        </div>
                        <v-text-field-money
                          v-model="artShops.price"
                          :label="$vuetify.lang.t('$vuetify.actions.edit')"
                          required
                          :properties="{
                              prefix: getCurrency
                            }"
                          :options="{
                              length: 15,
                              precision: 2,
                              empty: 0.0
                            }"
                        />
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-simple-checkbox v-model="artShops.online_sale"/>
                  </td>
                  <td>
                    <v-edit-dialog
                      :return-value.sync="artShops.online_price"
                      large
                      persistent
                      :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                      :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                    >
                      <div v-if="parseFloat(artShops.online_price).toFixed(2) === '0.00'" style="color: #ef700e">
                        {{ `${parseFloat(item.price).toFixed(2)}` + ' ' + getCurrency}}
                      </div>
                      <div v-else>
                        {{ `${parseFloat(artShops.online_price).toFixed(2)}`  + ' ' + getCurrency}}
                      </div>
                      <template v-slot:input>
                        <div class="mt-4 title">
                          {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                        </div>
                        <v-text-field-money
                          v-model="artShops.online_price"
                          :label="$vuetify.lang.t('$vuetify.actions.edit')"
                          required
                          :properties="{
                              prefix: getCurrency
                            }"
                          :options="{
                              length: 15,
                              precision: 2,
                              empty: 0.0
                            }"
                        />
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog
                      :return-value.sync="artShops.stock"
                      large
                      persistent
                      :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                      :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                    >
                      <div v-if="parseFloat(artShops.stock).toFixed(2) === '0.00'" style="color: #ef700e">
                        {{ `${parseFloat(item.price).toFixed(2)}` + ' ' + getCurrency}}
                      </div>
                      <div v-else>
                        {{ `${parseFloat(artShops.stock).toFixed(2)}`}}
                      </div>
                      <template v-slot:input>
                        <div class="mt-4 title">
                          {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                        </div>
                        <v-text-field-money
                          v-model="artShops.stock"
                          :label="$vuetify.lang.t('$vuetify.actions.edit')"
                          required
                          :options="{
                              length: 15,
                              precision: 2,
                              empty: 0.0
                            }"
                        />
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog
                      :return-value.sync="artShops.under_inventory"
                      large
                      persistent
                      :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                      :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                    >
                      <div v-if="parseFloat(artShops.under_inventory).toFixed(2) === '0.00'" style="color: #ef700e">
                        {{ `${parseFloat(item.price).toFixed(2)}` + ' ' + getCurrency}}
                      </div>
                      <div v-else>
                        {{ `${parseFloat(artShops.under_inventory).toFixed(2)}`}}
                      </div>
                      <template v-slot:input>
                        <div class="mt-4 title">
                          {{ $vuetify.lang.t("$vuetify.actions.edit") }}
                        </div>
                        <v-text-field-money
                          v-model="artShops.under_inventory"
                          :label="$vuetify.lang.t('$vuetify.actions.edit')"
                          required
                          :options="{
                              length: 15,
                              precision: 2,
                              empty: 0.0
                            }"
                        />
                      </template>
                    </v-edit-dialog>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table-->
          </td>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ArticleShop from './ArticleShop'

export default {
  name: 'Variants',
  components: { ArticleShop },
  props: {
    article: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showHeader: {
      type: [Boolean, Number],
      default: true
    }
  },
  watch: {
    'article.ref': function () {
      this.ref = parseFloat(this.article.ref) + 1
    },
    'article.marks': function () {
      if (this.article.marks.length === 0) {
        this.article.variants = this.article.variants.filter(function (obj) {
          return !obj.is_mark
        })
      } else {
        const values = []
        this.article.marks.forEach((v) => {
          values.push(v.name)
        })
        if (this.article.variants.filter(vM => vM.is_mark).length === 0) {
          this.article.variants.push({
            is_mark: true,
            name: this.$vuetify.lang.t('$vuetify.menu.marks'),
            value: values
          })
        } else {
          const mark = this.article.variants.filter(vM => vM.is_mark)[0]
          mark.value = values
        }
      }
      this.myComb()
    }
  },
  data () {
    return {
      loading: false,
      expanded: [],
      dialog: false,
      ref: 10000,
      newVariant: true,
      select: [],
      hintText: ['', false],
      name: '',
      formRule: this.$rules,
      cell: -1,
      editedIndex: -1,
      variantManager: {
        name: '',
        value: []
      }
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapState('shop', ['shops']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getHeader () {
      const data = [{
        text: this.$vuetify.lang.t('$vuetify.variants.name'),
        value: 'name'
      }
      ]
      if (this.$route.name !== 'product_add' && this.$route.name !== 'product_variant_edit') {
        data.push({
          text: this.$vuetify.lang.t('$vuetify.articles.total_inventory'),
          value: 'inventory',
          sortable: false
        })
      }
      data.push({
        text: this.$vuetify.lang.t('$vuetify.actions.actions'),
        value: 'actions',
        sortable: false
      })
      return data
    }
  },
  created () {
    this.loadShops()
  },
  methods: {
    ...mapActions('shop', ['getShops']),
    updateVariants (item, action) {
      item.article_shops.map((articleShop) => {
        if (action === 'price') {
          if (parseFloat(articleShop.price).toFixed(2) === '0.00' || parseFloat(articleShop.price).toFixed(2) === parseFloat(oldV).toFixed(2)) {
            articleShop.price = this.article.price
            articleShop.price_id = null
            if (parseFloat(articleShop.online_price).toFixed(2) === '0.00' || parseFloat(articleShop.online_price).toFixed(2) === parseFloat(oldV).toFixed(2)) {
              articleShop.online_price = this.article.price
              articleShop.online_price_id = null
            }
          }
        } else if (action === 'cost') {
          if (parseFloat(articleShop.cost).toFixed(2) === '0.00' || parseFloat(articleShop.cost).toFixed(2) === parseFloat(oldV).toFixed(2)) {
            articleShop.cost = this.article.cost
            articleShop.cost_id = null
          }
        }
      })
      this.$emit('updateVariant')
    },
    async loadShops () {
      this.loading = true
      await this.getShops()
      this.loading = false
    },
    deleteVariant (item) {
      if (this.article.variants.length === 1 && this.article.variants[0].is_mark) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.titles.delete', [
              this.$vuetify.lang.t('$vuetify.variants.variant')
            ]),
            text: this.$vuetify.lang.t('$vuetify.messages.delete_mark_variant'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
      } else {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.titles.delete', [
              this.$vuetify.lang.t('$vuetify.variants.variant')
            ]),
            text: this.$vuetify.lang.t('$vuetify.messages.sure_delete'),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.cancel'
            ),
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.delete'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.value) {
              this.article.variant_values = this.article.variant_values.filter(vv => vv !== item)
              if (this.article.variants.length === 1) {
                this.article.variants[0].value.length === 1
                  ? this.removeVariant(this.article.variants[0])
                  : this.article.variants[0].value.splice(item.name, 1)
              }
            }
          })
      }
    },
    closeDialog () {
      this.dialog = false
      this.name = ''
      this.select = []
    },
    addVariant () {
      this.variantManager = {
        name: '',
        value: []
      }
      this.newVariant = true
      this.select = []
      this.dialog = true
    },
    saveVariant () {
      if (this.newVariant) {
        this.article.variants.push({
          is_mark: false,
          name: this.name,
          value: this.select
        })
      } else {
        this.article.variants[this.editedIndex] = {
          name: this.name,
          value: this.select
        }
      }
      this.closeDialog()
      this.myComb()
    },
    myComb () {
      const data = this.article.variants
      let result = []
      let localResult = []
      data.forEach((value, index) => {
        if (index === 0) {
          value.value.forEach(localValue => {
            let variant = []
            const localArticle = this.article.variant_values.filter(sh => sh.variant_name === localValue)
            if (localArticle.length === 0) {
              this.ref = parseInt(this.ref) + 1
            } else {
              variant = localArticle[0].variants
            }
            if (variant.filter((artV) => artV.name === value.name && artV.value === localValue && value.is_mark === artV.is_mark).length === 0) {
              variant.push({ name: value.name, value: localValue, is_mark: value.is_mark })
            }
            const vValue = {
              images: [],
              variants: variant,
              color: '#1FBC9C',
              name: localArticle.length > 0 ? localArticle[0].name : localValue.toString(),
              variant_name: localValue.toString(),
              price: localArticle.length > 0 ? parseFloat(localArticle[0].price).toFixed(2) : parseFloat(this.article.price).toFixed(2),
              price_id: localArticle.length > 0 ? localArticle[0].price_id ? localArticle[0].price_id : null : null,
              online_price: localArticle.length > 0 ? parseFloat(localArticle[0].online_price).toFixed(2) : parseFloat(this.article.online_price).toFixed(2),
              online_price_id: localArticle.length > 0 ? localArticle[0].online_price_id ? localArticle[0].online_price_id : null : null,
              cost: localArticle.length > 0 ? parseFloat(localArticle[0].cost).toFixed(2) : parseFloat(this.article.cost).toFixed(2),
              cost_id: localArticle.length > 0 ? localArticle[0].cost_id ? localArticle[0].cost_id : null : null,
              ref: localArticle.length > 0 ? localArticle[0].ref : this.ref,
              barCode: localArticle.length > 0 ? localArticle[0].barCode : this.barCode
            }
            vValue.article_shops = localArticle.length > 0 ? localArticle[0].article_shops : this.getArticleShops(vValue)
            result.push(vValue)
            if (localArticle.length > 0) {
              result[result.length - 1].id = localArticle[0].id
            }
          })
        } else {
          value.value.forEach(localValue => {
            localResult.forEach(v => {
              const localArticle = this.article.variant_values.filter(sh => sh.variant_name === localValue.toString() +
                '/' + v.name.toString() || sh.variant_name === v.name.toString() + '/' + localValue.toString())
              let variant = []
              if (localArticle.length === 0) {
                this.ref = parseInt(this.ref) + 1
              }
              variant = v.variants
              if (variant.filter((artV) => artV.name === value.name).length === 0) { variant.push({ name: value.name, value: localValue, is_mark: value.is_mark }) }
              const vValue = {
                name: localArticle.length > 0 ? localArticle[0].name : localValue.toString() + '/' + v.name.toString(),
                variant_name: localArticle.length > 0 ? localArticle[0].variant_name : localValue.toString() + '/' + v.name.toString(),
                images: [],
                variants: variant,
                color: '#1FBC9C',
                price: localArticle.length > 0 ? parseFloat(localArticle[0].price).toFixed(2) : parseFloat(this.article.price).toFixed(2),
                price_id: localArticle.length > 0 ? localArticle[0].price_id ? localArticle[0].price_id : null : null,
                online_price: localArticle.length > 0 ? parseFloat(localArticle[0].online_price).toFixed(2) : parseFloat(this.article.online_price).toFixed(2),
                online_price_id: localArticle.length > 0 ? localArticle[0].online_price_id ? localArticle[0].online_price_id : null : null,
                cost: localArticle.length > 0 ? parseFloat(localArticle[0].cost).toFixed(2) : parseFloat(this.article.cost).toFixed(),
                cost_id: localArticle.length > 0 ? localArticle[0].cost_id ? localArticle[0].cost_id : null : null,
                ref: localArticle.length > 0 ? localArticle[0].ref : this.ref,
                barCode: localArticle.length > 0 ? localArticle[0].barCode : this.barCode
              }
              vValue.article_shops = localArticle.length > 0 ? localArticle[0].article_shops : this.getArticleShops(vValue)
              result.push(vValue)
              if (localArticle.length > 0) {
                result[result.length - 1].id = localArticle[0].id
              }
            })
          })
        }
        localResult = result
        if (index + 1 !== data.length) {
          result = []
        }
      })
      this.expanded = result
      this.article.variant_values = result
    },
    getArticleShops (v) {
      const result = []
      this.shops.forEach((shop) => {
        result.push({
          articles_shop_id: '',
          shop_id: shop.id,
          shop_name: shop.name,
          person_sale: true,
          online_sale: true,
          name: v.name,
          price: v.price,
          cost: v.cost,
          variant_cost: [{
            ref: this.ref,
            barCode: '',
            expire_date: null,
            stock: 1,
            under_inventory: 1,
            cost: 0.00
          }],
          cost_id: null,
          price_id: null,
          online_price: v.online_price,
          online_price_id: null,
          under_inventory: 1,
          stock: 1
        })
      })
      return result
    },
    removeVariant (tag) {
      if (!tag.is_mark) {
        this.article.variants = this.article.variants.filter(v => v !== tag)
        this.myComb()
      }
    },
    editVariant (variant) {
      this.variantManager = variant
      this.editedIndex = this.article.variants.indexOf(variant)
      this.name = variant.name
      this.select = variant.value
      this.newVariant = false
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>
