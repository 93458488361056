<template>
  <div>
    <v-row v-if="$route.name !== 'product_edit' && $route.name !== 'product_variant_edit'">
      <v-col cols="12" md="12" v-if="shopHeader">
        <v-tooltip max-width="350" right class="md-6">
          <template v-slot:activator="{ on, attrs }">
            {{ $vuetify.lang.t('$vuetify.articles.track_inventory') }}
            <v-icon
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <p> {{ $vuetify.lang.t("$vuetify.messages.warning_article_service") }}</p>
        </v-tooltip>
        <v-radio-group v-model="service" row>
          <v-radio
            :label="$vuetify.lang.t('$vuetify.menu.article')"
            :value="false"
          ></v-radio>
          <v-radio
            :label="$vuetify.lang.t('$vuetify.menu.service')"
            :value="true"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-data-table
      :hide-default-footer="!shopHeader"
      :headers="getHeader"
      :single-expand="true"
      show-expand
      :items="article.article_shops"

    >
      <template v-slot:[`item.person_sale`]="{ item }">
        <v-simple-checkbox v-model="item.person_sale"/>
      </template>
      <template v-slot:[`item.online_sale`]="{ item }">
        <v-simple-checkbox v-model="item.online_sale"/>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.price"
          large
          persistent
          :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
          :save-text="$vuetify.lang.t('$vuetify.actions.save')"
          @save="updateArticleShop('price', item)"
        >
          <div v-if="parseFloat(item.price).toFixed(2) === '0.00'" style="color: #ef700e">
            {{ `${getCurrency + " " + parseFloat(item.price).toFixed(2)}` }}
          </div>
          <div v-else>
            {{ `${parseFloat(item.price).toFixed(2)}` + " " + getCurrency }}
          </div>
          <template v-slot:input>
            <div class="mt-4 title">
              {{ $vuetify.lang.t("$vuetify.actions.edit") }}
            </div>
            <v-text-field-money
              v-model="item.price"
              :label="$vuetify.lang.t('$vuetify.actions.edit')"
              required
              :properties="{
                suffix: getCurrency,
                clearable: true
              }"
              :options="{
                length: 15,
                precision: 2,
                empty: 0.0
              }"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.online_price`]="{ item }">
        <v-edit-dialog
          v-if="$route.name !== 'product_list' || ($route.name === 'product_list' && access_permit.article.actions.edit)"
          :return-value.sync="item.online_price"
          large
          persistent
          :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
          :save-text="$vuetify.lang.t('$vuetify.actions.save')"
          @save="updateArticleShop('online_price', item)"
        >
          <div v-if="parseFloat(item.online_price).toFixed(2) === '0.00'" style="color: #ef700e">
            {{
              `${parseFloat(item.online_price).toFixed(2) + " " + getCurrency}`
            }}
          </div>
          <div v-else>
            {{
              `${parseFloat(item.online_price).toFixed(2) + " " + getCurrency}`
            }}
          </div>
          <template v-slot:input>
            <div class="mt-4 title">
              {{ $vuetify.lang.t("$vuetify.actions.edit") }}
            </div>
            <v-text-field-money
              v-model="item.online_price"
              :label="$vuetify.lang.t('$vuetify.actions.edit')"
              required
              :properties="{
                suffix: getCurrency,
                clearable: true
              }"
              :options="{
                length: 15,
                precision: 2,
                empty: 0.0
              }"
            />
          </template>
        </v-edit-dialog>
        <template v-else>
          <div v-if="parseFloat(item.online_price).toFixed(2) === '0.00'" style="color: #ef700e">
            {{ `${parseFloat(item.online_price).toFixed(2) + " " + getCurrency}` }}
          </div>
          <div v-else>
            {{ `${parseFloat(item.online_price).toFixed(2) + " " + getCurrency}` }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
        <v-btn
          color="rgb(31, 188, 156)"
          fab
          x-small
          dark
          @click="expand(!isExpanded)"
        >
          <v-icon v-if="isExpanded">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div v-if="item.variant_cost.length > 0">
            <variant-cost-list :article-shop="item" @calcInventory="calcInventory(item)"/>
          </div>
        </td>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import VariantCostList from './VariantCostList'

export default {
  name: 'ArticleShop',
  components: { VariantCostList },
  props: {
    article: {
      type: Object,
      default: function () {
        return {}
      }
    },
    shopHeader: {
      type: [Boolean, Number],
      default: true
    }
  },
  data () {
    return {
      service: false,
      snack: false,
      snackColor: '',
      snackText: '',
      pagination: {}
    }
  },
  watch: {
    service: function () {
      this.article.service = this.service
    },
    'article.variants': function () {
      if (this.article.variants.length === 0) {
        this.articleShopNoVariant()
      }
    },
    shops: function () {
      if (this.article.variants.length === 0) {
        this.articleShopNoVariant()
      }
    },
    'article.price': function (newV, oldV) {
      this.article.price_id = null
      this.updatePrice(newV, oldV)
    },
    'article.cost': function (newV, oldV) {
      this.article.cost_id = null
      this.updateCost(newV, oldV)
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'access_permit']),
    ...mapState('shop', ['shops']),
    ...mapState('article', ['managerArticle']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getHeader () {
      let headers = []
      headers = [
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shop_name'
        }]

      if ((this.article.composite ? !this.article.composite : false) && (this.article.variant_values ? this.article.variant_values.length > 0 : false)) {
        headers.push({
          text: this.$vuetify.lang.t('$vuetify.variants.variant'),
          value: 'name'
        })
      }
      if (this.$route.name === 'product_list') {
        headers.push({
          text: this.$vuetify.lang.t('$vuetify.articles.inventory'),
          value: 'inventory',
          select_filter: true
        })
      }
      headers.push({
        text: this.$vuetify.lang.t(
          '$vuetify.shop_article.person_sale'
        ),
        value: 'person_sale'
      })
      headers.push({
        text: this.$vuetify.lang.t('$vuetify.variants.price'),
        value: 'price'
      })
      headers.push({
        text: this.$vuetify.lang.t('$vuetify.articles.onlineSale'),
        value: 'online_sale'
      })
      headers.push({
        text: this.$vuetify.lang.t('$vuetify.onlinePrice'),
        value: 'online_price'
      })
      return headers
    }
  },
  created () {
    this.getShops()
  },
  methods: {
    ...mapActions('shop', ['getShops']),
    ...mapActions('articleShop', ['sendUpdateArticleShop']),
    updateArticleShop (action, item) {
      if (action === 'online_price') { item.online_price_id = null } else if (action === 'price') { item.price_id = null }
      if (this.$route.name === 'product_list') { this.sendUpdateArticleShop(item) }
    },
    calcInventory (item) {
      if (this.$route.name === 'product_list') {
        let cant = 0
        item.variant_cost.forEach((vc) => {
          cant += parseFloat(vc.stock)
        })
        item.inventory = cant
        this.$emit('calcInventory')
      }
    },
    articleShopNoVariant () {
      this.shops.forEach((shop) => {
        if (this.article.article_shops.filter(artS => artS.shop_id === shop.id).length === 0) {
          this.article.article_shops.push({
            name: this.article.name,
            articles_shop_id: null,
            shop_id: shop.id,
            shop_name: shop.name,
            person_sale: true,
            online_sale: true,
            local_cost: 0.00,
            variant_cost: [
              {
                ref: this.ref,
                barCode: '',
                expire_date: null,
                stock: 1,
                under_inventory: 1,
                cost: 0.00
              }
            ],
            price: this.article.price,
            price_id: null,
            online_price: this.article.price,
            online_price_id: null
          })
        }
      })
    },
    updatePrice (newV, oldV) {
      this.article.article_shops.map((articleShop) => {
        if (parseFloat(articleShop.price).toFixed(2) === '0.00' ||
          parseFloat(articleShop.price).toFixed(2) === parseFloat(oldV).toFixed(2)) {
          articleShop.price = this.article.price
          articleShop.price_id = null
          if (parseFloat(articleShop.online_price).toFixed(2) === '0.00' || parseFloat(articleShop.online_price).toFixed(2) === parseFloat(oldV).toFixed(2)) {
            articleShop.online_price = this.article.price
            articleShop.online_price_id = null
          }
        }
      })
    },
    updateCost (newV, oldV) {
      this.article.article_shops.map((articleShop) => {
        if (parseFloat(articleShop.cost).toFixed(2) === '0.00' || parseFloat(articleShop.cost).toFixed(2) === parseFloat(oldV).toFixed(2)) {
          articleShop.cost = this.article.cost
          articleShop.cost_id = null
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
