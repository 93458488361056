var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"850px","persistent":""},model:{value:(_vm.toogleEditModal),callback:function ($$v) {_vm.toogleEditModal=$$v},expression:"toogleEditModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.titles.edit", [ _vm.$vuetify.lang.t("$vuetify.menu.access") ])))])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"my-10",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',{attrs:{"cols":"12","justify":"space-around"}},[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.access.name'),"required":"","disabled":_vm.editAccess.name === 'CEO',"rules":_vm.formRule.position},model:{value:(_vm.editAccess.name),callback:function ($$v) {_vm.$set(_vm.editAccess, "name", $$v)},expression:"editAccess.name"}})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.access.accessEmail'
                )},model:{value:(_vm.editAccess.accessEmail),callback:function ($$v) {_vm.$set(_vm.editAccess, "accessEmail", $$v)},expression:"editAccess.accessEmail"}})],1),_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.access.description'
                )},model:{value:(_vm.editAccess.description),callback:function ($$v) {_vm.$set(_vm.editAccess, "description", $$v)},expression:"editAccess.description"}})],1)],1),_c('v-row',[_c('v-expansion-panels',{attrs:{"popout":""}},_vm._l((_vm.access_permit),function(access,j){return _c('v-col',{key:j,attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(access.title.value),expression:"access.title.value"}],attrs:{"color":"error"}},[_vm._v(" mdi-key-plus ")])]},proxy:true}],null,true)},[_c('v-switch',{attrs:{"title":_vm.$vuetify.lang.t(
                        '$vuetify.access.access.' +
                          access.title.name
                      )},on:{"change":function($event){return _vm.changeAccess(access)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('b',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.access.access." + access.title .name ))+" ")]),_c('v-tooltip',{staticClass:"md-6",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                              on,
                              attrs
                            }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.access.access.manager_help" )))])])],1)]},proxy:true}],null,true),model:{value:(access.title.value),callback:function ($$v) {_vm.$set(access.title, "value", $$v)},expression:"access.title.value"}})],1),_c('v-expansion-panel-content',{directives:[{name:"show",rawName:"v-show",value:(access.title.value),expression:"access.title.value"}]},[_c('v-row',_vm._l((access.actions),function(item,i){return _c('v-col',{key:i},[_c('v-switch',{attrs:{"label":_vm.$vuetify.lang.t(
                            '$vuetify.access.access.' +
                              i
                          )},model:{value:(access.actions[i]),callback:function ($$v) {_vm.$set(access.actions, i, $$v)},expression:"access.actions[i]"}})],1)}),1)],1)],1)],1)}),1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":_vm.isActionInProgress},on:{"click":function($event){return _vm.toogleEditModal(false)}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.actions.cancel"))+" ")],1),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":!_vm.formValid || _vm.isActionInProgress,"color":"primary","loading":_vm.isActionInProgress},on:{"click":_vm.updateRoleHandler}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.actions.save"))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }