import colors from 'vuetify/es5/util/colors'
import localStorage from '../../config/localStorage'
const SWITCH_FAST_CONFIG_MODAL = 'SWITCH_FAST_CONFIG_MODAL'
const CANCEL_CONFIG = 'CANCEL_CONFIG'
const state = {
  mode: 'light',
  showFastConfig: false,
  themeColor: localStorage.getTheme()
}

// getters
const getters = {
  getThemeColor: state => {
    return colors[state.themeColor].base
  }
}

const actions = {
  toogleFastConfig ({ commit }, showModal) {
    commit(SWITCH_FAST_CONFIG_MODAL, showModal)
  }
}

// mutations
const mutations = {
  setThemeColor (state, payload) {
    state.themeColor = payload
  },
  [SWITCH_FAST_CONFIG_MODAL] (state, showModal) {
    state.showFastConfig = showModal
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
