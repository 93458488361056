import api from '../config/api'

export default {
  fetchPartners (data) {
    return api.post('partner', data)
  },
  syncPayPalAccount (data) {
    return api.post('partner/paypal/sync', data)
  },
  syncStripeAccount (data) {
    return api.post('partner/stripe/sync', data)
  },
  partnerChangeState (data) {
    return api.post('changeState', data)
  },
  sendCreateRequest (partner) {
    return api.post('partner', partner)
  },
  sendUpdateRequest (partner) {
    return api.post('user-partner', partner)
  },
  sendDeleteRequest (partnerId) {
    return api.remove('partner/' + partnerId)
  },
  updateAvatar (id, avatar) {
    return api.post('partner/avatar/' + id, avatar)
  }
}
